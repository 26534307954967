import React, {Fragment, useContext} from 'react';
import {Button, Stack, useMediaQuery} from "@mui/material";
import {Logout, PersonOutlineOutlined} from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import { useNavigate } from 'react-router-dom';
import AuthContext from "./AuthContext";
import userService from "../users/UserService";
import Divider from "@mui/material/Divider";
import {useTheme} from "@mui/material/styles";
import NavigationContext from "../layout/NavigationContext";

export function LoginMenu() {
    const theme = useTheme();
    const { antiForgeryToken, user, refreshUser } = useContext(AuthContext);
    const navigate = useNavigate();
    const { freshHistNavigate, back } = useContext(NavigationContext);
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const logout  = async () => {
        const result = await userService.logout(antiForgeryToken);
        refreshUser();
        freshHistNavigate('/','Home');
    };

  const authenticatedView = () => (
      <Stack direction="row" spacing={1} alignItems="center">
          {!isMobile && <Typography sx={{fontWeight:800, color:"black"}}>Hello - {user.email}</Typography>}
          {!user.needsToConfirmAccountDetails &&
              <>
                  <Divider color={"black"} orientation="vertical" flexItem/>
                  <Button  onClick={() => navigate('/MyProfile')}>
                      <PersonOutlineOutlined sx={{fontSize: 22}}/>
                  </Button>
              </>
          }

          <Divider color={"black"} orientation="vertical" flexItem/>
          <Button key="logout" onClick={logout}>
              <Logout sx={{fontSize: 22}}/>
          </Button>
      </Stack>
  );

  const anonymousView = () => (
      <Fragment>
        <Button key="login" variant="contained" color="secondary" onClick={() => navigate('/login')} sx={{ml: 2}}>
          Login
        </Button>
      </Fragment>
  );

  return user?.loggedIn === true
      ? authenticatedView()
      : anonymousView();

}