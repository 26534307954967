const listForCompany  = async (antiForgeryToken, companyId, searchTerm) => {
    return  fetch(`api/Persons/FindForCompany/${companyId}?searchTerm=${searchTerm}`, {
        method: "GET",
        headers: buildAuthHeaders(antiForgeryToken)
    })
};

async function createPerson(antiForgeryToken, personData) {
    return fetch(`/api/Persons/CreatePerson`, {
        method: 'POST',
        body: JSON.stringify(personData),
        headers: buildAuthHeaders(antiForgeryToken)
    });
}

const updatePerson  = async (antiForgeryToken, formData) => {
    return fetch(`api/Persons/UpdatePerson`, {
        method: 'PATCH',
        headers: buildAuthHeaders(antiForgeryToken),
        body: JSON.stringify(formData)
    });
};

async function getPerson(antiForgeryToken, personId) {
    return fetch(`/api/Persons/GetPerson/${personId}`, {
        method: 'GET',
        headers: buildAuthHeaders(antiForgeryToken)
    });
}

async function removePerson(antiForgeryToken, personId) {
    return fetch(`/api/Persons/RemovePerson/${personId}`, {
        method: 'DELETE',
        headers: buildAuthHeaders(antiForgeryToken)
    });
}



function buildAuthHeaders(antiForgeryToken) {
    return !antiForgeryToken ? {} : {
        [antiForgeryToken.tokenName] : antiForgeryToken.token,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    };
}

const personService = Object.freeze({
    listForCompany,
    createPerson,
    updatePerson,
    getPerson,
    removePerson
});
export default personService;