import React, {useEffect, useState} from 'react';
import {Route, Routes, useNavigate} from 'react-router-dom';
import AppRoutes from './AppRoutes';
import Layout from './layout/Layout';
import './custom.css';
import { ThemeProvider } from '@mui/material/styles';
import {SnackbarProvider} from 'notistack';
import ThemeResolver from "./themes/ThemeResolver";
import AuthContext from './api-authorization/AuthContext';
import Box from "@mui/material/Box";
import {CircularProgress} from "@mui/material";
import SnackbarCloseButton from "./layout/SnackBarCloseButton";
import OneBusinessTheme from "./themes/OneBusinessTheme";
import NavigationContext from "./layout/NavigationContext";
import { LicenseInfo } from '@mui/x-license-pro';
import Login from "./users/Login";

function App() {
    LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY);
    const [theme, setTheme] = useState(OneBusinessTheme);
    const [userLoading, setUserLoading] = useState(true);
    const [user, setUser] = useState(null);
    const [antiForgeryToken, setAntiForgeryToken] = useState(null);
    const [history, setHistory] = useState([{ url: "/", description: "Homepage" }]);
    const navigate = useNavigate();
    
    React.useEffect(() => {
        const link = document.querySelector('link[rel*="icon"]') || document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        link.href = theme.faviconUrl;
        document.getElementsByTagName('head')[0].appendChild(link);

        document.title = theme.documentTitle;
    }, [theme]);
    
    const histNavigate = (path, description = "test") => {
        setHistory(prevHistory => [...prevHistory, { url: path, description }]);
        navigate(path);
    };

    const replaceTopHist = (path, description = "test") => {
        setHistory((state) => {
            let newState = [...state];
            if(newState.length > 0) {
                newState[newState.length - 1] = { url: path, description };
            } else {
                newState = [{ url: path, description }];
            }
            return newState;
        });
    };

    const freshHistNavigate = (path, description = "test") => {
        setHistory([{ url: path, description }]);
        navigate(path);
    };

    const back = (steps = 1) => {
        setHistory((state) => {
            const newState = state.slice(0, -steps);
            // If there's no history left, navigate to "/", otherwise the last entry.
            navigate(newState.length > 0 ? newState[newState.length - 1].url : "/");
            return newState;
        });
    };

    const navigationContext = { history, setHistory, replaceTopHist, freshHistNavigate, histNavigate, back };


    const refreshUser = () => {
        setUserLoading(true);
        return fetch('/api/Auth/AntiForgery')
            .then(response => response.json())
            .then(data => {
                setAntiForgeryToken(data);
                fetch('/api/Auth/GetLoggedInUserInfo',{
                    method: 'GET',
                    headers: {
                        [data.tokenName]: data.token
                    },
                })
                    .then(response => response.json())
                    .then(data => {
                        setUser(data);
                        setUserLoading(false);
                        setTheme(ThemeResolver(data.theme));
                    });
                })
    };

    useEffect(() => {
            refreshUser();
    }, []);

    let content;
    if (userLoading) {
        content = (
            <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                <CircularProgress />
            </Box>
        );
    }
    else {
        let subContent = (
            <>
                <SnackbarProvider
                    action={snackbarKey => <SnackbarCloseButton snackbarKey={snackbarKey} />}
                    autoHideDuration={8000} anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                />
                <Routes>
                    {AppRoutes.map((route, index) => {
                        const { element, ...rest } = route;
                        return <Route key={index} {...rest} element={element} />;
                    })}
                </Routes>
            </>
        );
        if(user?.loggedIn) {
            subContent = (
                <Layout>
                    <SnackbarProvider
                        action={snackbarKey => <SnackbarCloseButton snackbarKey={snackbarKey} />}
                        autoHideDuration={8000} anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                    />
                    <Routes>
                        {AppRoutes.map((route, index) => {
                            const { element, ...rest } = route;
                            return <Route key={index} {...rest} element={element} />;
                        })}
                    </Routes>
                </Layout>
            );
        }
        content = (
            <>
                <AuthContext.Provider value={{user, refreshUser, antiForgeryToken, userLoading}}>
                    <NavigationContext.Provider value={navigationContext}>
                        <ThemeProvider theme={theme}>
                            {subContent}
                        </ThemeProvider>
                    </NavigationContext.Provider>
                </AuthContext.Provider>
            </>
        );
    }

    return (
        <>
            {content}
        </>
    );
}

export default App;