import React from 'react';

const NavigationContext = React.createContext({
    history: [],
    setHistory: () => {},
    replaceTopHist: () => {},
    freshHistNavigate: () => {},
    histNavigate: () => {},
    back: () => {}
});

export default NavigationContext;