import * as React from 'react';
import {useNavigate, useParams} from "react-router-dom";
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {useContext, useEffect, useState} from "react";
import {enqueueSnackbar} from "notistack";
import CompanyDetails from "./CompanyDetails";
import CompanyContacts from "./CompanyContacts";
import {COMPANY_TABS, COMPANY_TYPE, RESOURCE_TYPE, ROLES} from "../enums";
import {CircularProgress} from "@mui/material";
import AuthContext from "../api-authorization/AuthContext";
import companyService from "./CompanyService";
import ListCustomerProducts from "../products/ListCustomerProducts";
import NavigationContext from "../layout/NavigationContext";
import TabList from "@mui/lab/TabList";
import TabContext from "@mui/lab/TabContext";
import TabPanel from '@mui/lab/TabPanel';
import Breadcrumb from "../layout/Breadcrumb";
import PostBoxDetails from "./PostBoxDetails";

export default function CompanyParent() {
    const { back,histNavigate,replaceTopHist } = useContext(NavigationContext);
    const { selectedTab} = useParams();
    const [tabValue, setTabValue] = React.useState(selectedTab ? parseInt(selectedTab) : 0);
    const { companyId } = useParams(); 
    const [isBusy, setIsBusy] = useState(true);
    const [companyData, setCompanyData] = useState();
    const { antiForgeryToken, user, refreshUser} = useContext(AuthContext);
    const navigate = useNavigate();

    const handleChange = (event, newTabValue) => {
        setTabValue(newTabValue);
    };

    async function fetchData() {
        let getCompanyRequest = {
            id: companyId
        };
        const result = await companyService.getCompany(antiForgeryToken, getCompanyRequest);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                setCompanyData(data);
                setIsBusy(false);
            } else {
                enqueueSnackbar(data.description);
            }
        }
    }

    useEffect( () => {
        fetchData();
    }, [companyId])

    let content;
    if (isBusy) {
        content = (
            <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                <CircularProgress />
            </Box>
        );
    } else {
        content = (
            <>
                {(user.role === ROLES.TENANT_ADMIN || user.role === ROLES.TENANT_STANDARD || user.role === ROLES.CLIENT_ADMIN || user.role === ROLES.CLIENT_STANDARD) &&(
                    <Breadcrumb breadcrumbItems={companyData.breadcrumbItems}/>
                )}
                
                <Typography variant="h5"  noWrap component="div" align={"left"}>
                    {companyData.companyType === 1 &&
                        <>
                            Company - {companyData.name}
                        </>
                    }
                    {companyData.companyType === 2 &&
                        <>
                            Post Box - {companyData.postBoxName}
                        </>
                    }
                </Typography>
                <TabContext value={tabValue.toString()}>
                    <Box>
                        <Box>
                            <TabList onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Details" value={COMPANY_TABS.DETAILS.toString()}  onClick={(e) =>{
                                replaceTopHist(`/Company/Update/${companyId}/${COMPANY_TABS.DETAILS}`,'Update Company');
                            }}/>
                            <Tab label="Contacts" value={COMPANY_TABS.CONTACTS.toString()}  onClick={(e) =>{
                                replaceTopHist(`/Company/Update/${companyId}/${COMPANY_TABS.CONTACTS}`,'Update Company');
                            }}/>
                            <Tab label="Products" value={COMPANY_TABS.PRODUCTS.toString()} onClick={(e) =>{
                                replaceTopHist(`/Company/Update/${companyId}/${COMPANY_TABS.PRODUCTS}`,'Update Company');
                            }}/>
                            {
                                (user.role === ROLES.TENANT_ADMIN ||
                                    user.role === ROLES.TENANT_STANDARD ||
                                    user.role === ROLES.CLIENT_STANDARD ||
                                    user.role === ROLES.CLIENT_ADMIN)
                                &&
                                <Tab label="Mail" value={COMPANY_TABS.MAIL.toString()} onClick={(e) => {
                                    e.preventDefault();
                                    histNavigate(`/Mail/CompanyMail/${companyData.id}`,'Company Mail');
                                }}/>
                            }
                            </TabList>
                    </Box>
                    <TabPanel value={COMPANY_TABS.DETAILS.toString()}>
                        {companyData.companyType === COMPANY_TYPE.COMPANY &&
                            <CompanyDetails antiForgeryToken={antiForgeryToken} companyData={companyData} user={user} refreshUser={refreshUser} fetchData={fetchData}/>   
                        }
                        {companyData.companyType === COMPANY_TYPE.POST_BOX &&
                            <PostBoxDetails antiForgeryToken={antiForgeryToken} companyData={companyData} user={user} refreshUser={refreshUser} fetchData={fetchData}/>
                        }
                    </TabPanel>
                    <TabPanel value={COMPANY_TABS.CONTACTS.toString()}>
                        <CompanyContacts companyId={companyId} antiForgeryToken={antiForgeryToken} refreshUser={refreshUser}/>
                    </TabPanel>
                    <TabPanel value={COMPANY_TABS.PRODUCTS.toString()}>
                        <ListCustomerProducts resourceType={companyData.companyType === COMPANY_TYPE.COMPANY ? RESOURCE_TYPE.COMPANY : RESOURCE_TYPE.POST_BOX} resourceId={companyId} antiForgeryToken={antiForgeryToken} refreshUser={refreshUser} user={user}/>
                    </TabPanel>
                </Box>
                </TabContext>
            </>
        );
    }

    return (
        <>
            {content}
        </>
    );
}