const downloadTemplate = async (antiForgeryToken) => {
    return fetch(`/api/CsvImport/GetTemplate`, {
        method: 'GET',
        headers: buildAuthHeaders(antiForgeryToken)
    });
}
const postCsvImportRow  = async (antiForgeryToken,rowData) => {
    return fetch(`/api/CsvImport/ImportRow`, {
        method: 'POST',
        body: JSON.stringify(rowData),
        headers: buildAuthHeaders(antiForgeryToken)
    });
};

const createImportJob  = async (antiForgeryToken,createImportBody) => {
    return fetch(`/api/CsvImport/CreateImportJob`, {
        method: 'POST',
        body: JSON.stringify(createImportBody),
        headers: buildAuthHeaders(antiForgeryToken)
    });
};

const listCsvImports  = async (antiForgeryToken) => {
    return fetch(`/api/CsvImport/ListCsvImports`, {
        method: 'GET',
        headers: buildAuthHeaders(antiForgeryToken)
    });
};

const listCsvImportJobRows  = async (antiForgeryToken,csvImportJobId) => {
    return fetch(`/api/CsvImport/ListCsvImportJobRows/${csvImportJobId}`, {
        method: 'GET',
        headers: buildAuthHeaders(antiForgeryToken)
    });
};

function buildAuthHeaders(antiForgeryToken) {
    return !antiForgeryToken ? {} : {
        [antiForgeryToken.tokenName] : antiForgeryToken.token,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    };
}

const importService = Object.freeze({
    postCsvImportRow,
    createImportJob,
    listCsvImports,
    listCsvImportJobRows,
    downloadTemplate
});
export default importService;