import React, {useContext, useEffect, useState} from 'react'
import TextField from "@mui/material/TextField";
import {CircularProgress, MenuItem, Stack} from "@mui/material";
import { useNavigate } from 'react-router-dom';
import {enqueueSnackbar} from "notistack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AuthContext from "../api-authorization/AuthContext";
import mailService from "../mail/MailService";
import dayjs from "dayjs";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import Button from "@mui/material/Button";
import NavigationContext from "../layout/NavigationContext";
import {DataGridPro, GridToolbarContainer, GridToolbarExport} from "@mui/x-data-grid-pro";
import clientService from "../clients/ClientService";

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarExport />
        </GridToolbarContainer>
    );
}

export const mailReportDefaultColumns = [
    {
        preDefinedReportKey: 0,
        columns:{
            receivedDate: true,
            companyName: true,
            personName: true,
            mailType: true,
            mailProcessStatus: true,
            sentDate: true,
            rts: false,
            companyNumber: false,
            postBoxName: false,
            postageFee: false,
            handlingFee: false,
            mailHoldType:false,
            mailHandlingType:false,
            mailForwardingFrequency: false,
            mailForwardingDays: false,
            mailClass: false,
            additionalInstructions: false,
            shredDate:false,
            clientName:false
        }
    },
    {
        preDefinedReportKey: 1,
        columns:{
            receivedDate: true,
            companyName: true,
            personName: true,
            mailType: true,
            mailProcessStatus: false,
            sentDate: false,
            rts: false,
            companyNumber: false,
            postBoxName: false,
            postageFee: false,
            handlingFee: false,
            mailHoldType:false,
            mailHandlingType:false,
            mailForwardingFrequency: false,
            mailForwardingDays: false,
            mailClass: false,
            additionalInstructions: false,
            shredDate:true,
            clientName:true
        }
    }
]

export default function ListMailReport() {
    const { histNavigate,back } = useContext(NavigationContext);
    const { antiForgeryToken, refreshUser} = useContext(AuthContext);
    const [isClientDataLoading, setIsClientDataLoading] = React.useState(true);
    const [isLoading, setIsLoading] = React.useState(true);
    const [mail, setMail] = React.useState([]);
    const navigate = useNavigate();
    const [clientData, setClientData] = useState();

    const [predefinedReport, setPredefinedReport] = useState(null);
    const [selectedClientId, setSelectedClientId] = useState(null);
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    const [mailProcessStatus, setMailProcessStatus] = useState(null);
    
    const [columnsToDisplay, setColumnsToDisplay] = useState(mailReportDefaultColumns[0].columns);
    
    useEffect(() => {
        fetchClientData();
    }, [])

    async function fetchClientData() {
        const result = await clientService.listClients(antiForgeryToken, "");
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        } else {
            let data = await result.json();
            if (result.ok) {
                setClientData(data.clients);
                setIsClientDataLoading(false);
            } else {
                enqueueSnackbar(data.description);
            }
        }
        setIsLoading(false);
    }

    const fetchMailData = async () => {
        setIsLoading(true);
        let searchRequest = {
            appScope: 1,
            startDate: startDate ? dayjs(startDate).format('YYYY-MM-DD') : null,
            endDate : endDate ? dayjs(endDate).format('YYYY-MM-DD') : null,
            clientId : selectedClientId,
            mailProcessStatus : mailProcessStatus,
            myMail: false,
            predefinedReport: predefinedReport
        };
        const result = await mailService.listMail(antiForgeryToken, searchRequest);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        let data = await result.json();
        if (result.ok) {
            setMail(data.mail);
            setIsLoading(false);
            if(predefinedReport != null) {
                setColumnsToDisplay(mailReportDefaultColumns.find(x => x.preDefinedReportKey === predefinedReport).columns);
            }
            else{
                setColumnsToDisplay(mailReportDefaultColumns[0].columns);
            }
        } else {
            enqueueSnackbar(data.description);
        }
    };

    const handleSearch = (event) => {
        event.preventDefault();
        fetchMailData();
    };

    const dateFormatter = (params) => {
        const date = new Date(params.value);
        if (isNaN(date.getTime()) || date.getFullYear() <= 1) {
            return "None";
        }
        return date.toLocaleDateString('en-GB');
    };

    const handleRowClick = (params) => {
        window.open(`/Mail/Update/${params.id}`, "_blank");
    };

    let content;
    if (isClientDataLoading) {
        content = (
            <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                <CircularProgress />
            </Box>
        );
    } else {
        content = (
            <Box sx={{height: '75vh'}}>
                <Box sx={{ position: 'sticky', top: 0, zIndex: 1, background: '#fff' }}>
                <Typography variant="h5" noWrap component="div" align={"left"} sx={{mb:4}}>
                    Mail report
                </Typography>
                <form onSubmit={handleSearch}>
                    <Stack direction="row" spacing={2} marginTop={4} sx={{width: "80%"}}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TextField
                                select
                                label="Pre-defined Report"
                                value={predefinedReport}
                                onChange={(e) => {
                                    setPredefinedReport(e.target.value);
                                    if(e.target.value == null) {
                                        setPredefinedReport(null);
                                    }
                                }}
                                variant="outlined"
                                margin="normal"
                                size="small"
                                sx={{mt:1, width:"35%"}}
                            >
                                <MenuItem key="0" value={null}>&nbsp;</MenuItem>
                                <MenuItem key="1" value={1}>Mail Shred Report</MenuItem>
                            </TextField>
                            <DatePicker
                                label="Start Date"
                                format={"DD/MM/YYYY"}
                                value={startDate}
                                onChange={(newValue) => setStartDate(newValue)}
                                slotProps={{ textField: { size: 'small' } }}
                            />
                            <DatePicker
                                label="End Date"
                                format={"DD/MM/YYYY"}
                                value={endDate}
                                onChange={(newValue) => setEndDate(newValue)}
                                slotProps={{ textField: { size: 'small' } }}
                            />
                        </LocalizationProvider>

                        <TextField
                            id="mailProcessStatus"
                            select
                            label="Status"
                            value={mailProcessStatus}
                            onChange={(e) => {
                                setMailProcessStatus(e.target.value);
                                if(e.target.value == null) {
                                    setMailProcessStatus(null);
                                }
                            }}
                            variant="outlined"
                            margin="normal"
                            size="small"
                            sx={{mt:1, width:"35%"}}
                        >
                            <MenuItem key="0" value={null}>&nbsp;</MenuItem>
                            <MenuItem key="1" value={1}>Received</MenuItem>
                            <MenuItem key="2" value={2}>Scanned</MenuItem>
                            <MenuItem key="3" value={3}>Sent</MenuItem>
                        </TextField>

                        <TextField
                            id="selectedClientId"
                            select
                            label="Client"
                            value={selectedClientId}
                            onChange={(e) => {
                                setSelectedClientId(e.target.value);
                                if(e.target.value == null) {
                                    setSelectedClientId(null);
                                }
                            }}
                            variant="outlined"
                            margin="normal"
                            size="small"
                            sx={{mt:1, width:"35%"}}
                        >
                            {clientData.map((client) => (
                                <MenuItem key={client.id} value={client.id}>
                                    {client.companyName}
                                </MenuItem>
                            ))}
                        </TextField>


                        <Button style={{height:"80%", width: "10%"}}  variant="contained" color="secondary" onClick={(e) => {
                            setStartDate(null);
                            setEndDate(null);
                            setSelectedClientId(null);
                            setMailProcessStatus(null);
                        }}>
                            Reset
                        </Button>
                        <Button style={{height:"80%", width: "10%"}}  variant="contained" color="secondary" type="submit">
                            Search
                        </Button>
                    </Stack>

                </form>
                </Box>
                <br />
                <DataGridPro
                    rows={mail}
                    columnVisibilityModel={columnsToDisplay}
                    columns={[
                        { field: 'receivedDate', headerName: 'Received Date', flex: 1, valueFormatter: dateFormatter},
                        { field: 'companyName', headerName: 'Company / Post Box', flex: 1 },
                        { field: 'personName', headerName: 'Person Name', flex: 1 },
                        { field: 'mailType', headerName: 'Mail Type', flex: 1 },
                        { field: 'scanBatchTitle', headerName: 'Batch', flex: 1},
                        { field: 'mailProcessStatus', headerName: 'Mail Status', flex: 1 },
                        { field: 'sentDate', headerName: 'Sent date', flex: 1 },
                        { field: 'companyNumber', headerName: 'Company Number', flex: 1 },
                        { field: 'postBoxName', headerName: 'Post Box Name', flex: 1 },
                        { field: 'postageFee', headerName: 'Postage', flex: 1, valueFormatter: (params) => params.value?.toFixed(2) },
                        { field: 'handlingFee', headerName: 'Handling', flex: 1, valueFormatter: (params) => params.value?.toFixed(2) },
                        { field: 'rts', headerName: 'RTS', flex: 1 },
                        { field: 'mailHoldType', headerName: 'Mail Hold Type', flex: 1 },
                        { field: 'mailHandlingType', headerName: 'Mail Handling Type', flex: 1 },
                        { field: 'mailForwardingFrequency', headerName: 'Mail Forwarding Frequency', flex: 1 },
                        { field: 'mailForwardingDays', headerName: 'Mail Forwarding Days', flex: 1 },
                        { field: 'mailClass', headerName: 'Mail Class', flex: 1 },
                        { field: 'additionalInstructions', headerName: 'Additional Instructions', flex: 1 },
                        { field: 'shredDate', headerName: 'Shred Date', flex: 1 },
                        { field: 'clientName', headerName: 'Client', flex: 1 },
                    ]}
                    hideFooter={true}
                    loading={isLoading}
                    slots={{
                        toolbar: CustomToolbar,
                    }}
                    onRowClick={handleRowClick}
                    sx={{
                        '.MuiDataGrid-columnHeaderTitle': {
                            WebkitTextStroke: '0.75px'
                        },
                    }}
                />
            </Box>
        );
    }

    return (
        <>
            {content}
        </>
    );
}
