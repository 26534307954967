import * as React from 'react';
import Box from "@mui/material/Box";
import {CircularProgress, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import {ACCOUNT_TABS, COMPANY_FORMATION_TABS} from "../enums";
import Button from "@mui/material/Button";
import {useForm} from "react-hook-form";

export default function FormationPackageSelect({formationData, createOrUpdateFormationData, parentBack}) {
    const {  handleSubmit, control, formState, setError,clearErrors } = useForm();
    
    const [isLoading, setIsLoading] = React.useState(false);
    
    const confirmAndContinue = async (formData) => {
        const newFormationData = {
            ...formationData,
            completedTab: COMPANY_FORMATION_TABS.PACKAGE_SELECT
        };
        await createOrUpdateFormationData(newFormationData);
    }

    let content;

    if (isLoading) {
        content = (
            <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                <CircularProgress />
            </Box>
        );
    }
    else {
        content = (
            <>
                <form onSubmit={handleSubmit(confirmAndContinue)}>
                    <Typography component="div" align={"left"} sx={{mb: 2, mt:4,color:"#458839",fontSize:"24px",fontWeight:"bolder"}}>
                        Display packages here
                    </Typography>
                    <Stack direction="row" spacing={2} sx={{mt: 2}}>
                        <Button variant="contained" color="secondary" onClick={(e) =>{
                            parentBack();
                        }}>Back</Button>
                        <Button variant="contained" color="secondary" type="submit">Confirm & Continue</Button>
                    </Stack>
                </form>
            </>
        );
    }


    return (
        <Box maxWidth={"100vh"}>
            {content}
        </Box>
    );
}