import * as React from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import {enqueueSnackbar} from "notistack";
import {useForm} from "react-hook-form";
import PrimaryContactForm from "./PrimaryContactForm";
import {
    Box,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Stack
} from "@mui/material";
import Button from "@mui/material/Button";
import inputService from "../../util/InputService";
import AuthContext from "../../api-authorization/AuthContext";
import personService from "./PersonService";
import Typography from "@mui/material/Typography";
import NavigationContext from "../../layout/NavigationContext";
import Breadcrumb from "../../layout/Breadcrumb";
import {ROLES} from "../../enums";

export default function UpdatePerson() {
    const { histNavigate, back } = useContext(NavigationContext);
    const { antiForgeryToken, refreshUser, user } = useContext(AuthContext);
    const {  handleSubmit, control, formState,getValues,setValue } = useForm();
    const [disableSubmit, setDisableSubmit] = useState(false);
    const { personId } = useParams();
    const navigate = useNavigate();

    const [isBusy, setBusy] = useState(true);
    const [personData, setPersonData] = useState();
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [isForwardingAddressAmlApproved, setIsForwardingAddressAmlApproved] = useState(false);
    const [forwardingAddressRequiresAmlApproval, setForwardingAddressRequiresAmlApproval] = useState(false);

    function checkIfPersonRequiresAmlApproval(e, field) {
        field.onChange(e); // This is needed to ensure react-hook-form's state is updated

        if(personData.isPrimaryContact){
            const addressFields = {
                addressLine1: getValues("address.addressLine1"),
                addressLine2: getValues("address.addressLine2"),
                locality: getValues("address.locality"),
                country: getValues("address.country"),
                postalCode: getValues("address.postalCode")
            };

            const originalAddressFields = {
                addressLine1: personData.address.addressLine1,
                addressLine2: personData.address.addressLine2,
                locality: personData.address.locality,
                country: personData.address.country,
                postalCode: personData.address.postalCode
            };
            const addressChanged = Object.keys(addressFields).some(fieldName => addressFields[fieldName] !== originalAddressFields[fieldName]);
            setForwardingAddressRequiresAmlApproval(addressChanged);   
        }
        if (!personData.isPrimaryContact) {
            setForwardingAddressRequiresAmlApproval(getValues("isPrimaryContact"));
        }
        if(!getValues("isPrimaryContact")){
            setValue("forwardingAddressAmlApproved", false);
            setIsForwardingAddressAmlApproved(false);
        }
    }

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };
    
    const removePerson  = async () => {
        setBusy(true);
        setDisableSubmit(true);
        const result = await personService.removePerson(antiForgeryToken, personId);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                enqueueSnackbar("Successfully removed company contact");
                back();
            } else {
                enqueueSnackbar(data.description);
            }
        }
        setDisableSubmit(false);
        setBusy(false);
    };


    useEffect(() => {
        async function fetchData() {
            const result = await personService.getPerson(antiForgeryToken, personId);
            if (result.status === 401) {
                refreshUser();
                navigate('/login');
            }
            else {
                let data = await result.json();
                if (result.ok) {
                    setPersonData(data);
                    setBusy(false);
                } else {
                    enqueueSnackbar(data.description);
                }
            }
        }
        fetchData();
    }, [])

    const handleFormSubmit  = async (formData) => {
        setBusy(true);
        setDisableSubmit(true);
        formData = inputService.trimFields(formData);
        formData.id = personId;
        formData.address.id = personData.address.id;
        const result = await personService.updatePerson(antiForgeryToken, formData);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                enqueueSnackbar("Successfully updated company contact");
                back();
            } else {
                enqueueSnackbar(data.description);
            }
        }
        setDisableSubmit(false);
        setBusy(false);
    };

    let content;
    if (isBusy) {
        content = (
            <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                <CircularProgress />
            </Box>
        );
    } else {
        content = (
            <Box sx={{ maxWidth: 1200 }}>
                {(user.role === ROLES.TENANT_ADMIN || user.role === ROLES.TENANT_STANDARD || user.role === ROLES.CLIENT_ADMIN || user.role === ROLES.CLIENT_STANDARD) &&(
                    <Breadcrumb breadcrumbItems={personData.breadcrumbItems}/>
                )}
                
                <Typography variant="h5" noWrap align={"left"} sx={{mb: 2}}>
                    View Contact - {personData.companyName}
                </Typography>
                <form onSubmit={handleSubmit(handleFormSubmit)}>
                <PrimaryContactForm 
                    formState={formState} 
                    getValues={getValues}
                    control={control} 
                    defaultValues={personData} 
                    isForwardingAddressAmlApproved={isForwardingAddressAmlApproved} 
                    setIsForwardingAddressAmlApproved={setIsForwardingAddressAmlApproved}
                    checkIfPersonRequiresAmlApproval={checkIfPersonRequiresAmlApproval}
                    forwardingAddressRequiresAmlApproval={forwardingAddressRequiresAmlApproval}
                    companyType={personData.companyType}
                    user={user}
                />
                <Stack direction="row" spacing={2} marginTop={2}>
                    <Button variant="contained" color="secondary" onClick={(e) => {
                        back();
                    }}>
                        Back
                    </Button>
                    <Button variant="contained" color="secondary"  onClick={handleDialogOpen} disabled={disableSubmit || personData.isPrimaryContact}>
                        Remove
                    </Button>
                    <Button variant="contained" color="secondary" type="submit" disabled={formState.isSubmitting} disabled={disableSubmit || (!isForwardingAddressAmlApproved && forwardingAddressRequiresAmlApproval)}>
                        Update
                    </Button>
                </Stack>
            </form>
                <Dialog
                    open={dialogOpen}
                    onClose={handleDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Warning"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you wish to remove {personData.nameOneLine}?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDialogClose}>No</Button>
                        <Button onClick={removePerson} autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        );
    }

    return (
        <>
            {content}
        </>
    );
}