import React from 'react';
import {InfoRounded} from "@mui/icons-material";
import {styled} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Tooltip, {tooltipClasses} from "@mui/material/Tooltip";
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';

const HtmlTooltipComponent = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} placement="right" />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: 'white',
        color: 'black',
        maxWidth: 540,
        border: '1px solid #dadde9',
        borderLeft: '10px solid #dadde9',
        borderRadius: '5px',
    },
}));
export default function HtmlTooltip({title,message}) {
    const cleanTitle = DOMPurify.sanitize(title);
    const cleanMessage = DOMPurify.sanitize(message);
    
    return (
        <HtmlTooltipComponent
            title={
                <React.Fragment>
                    <Typography sx={{fontWeight:"bolder",fontSize:"14px"}}>{cleanTitle}</Typography>
                    <Typography sx={{fontSize:"14px"}}>
                        {parse(cleanMessage)}
                    </Typography>
                </React.Fragment>
            }
        >
            <InfoRounded sx={{ml:4,color:"#458839",fontSize:"24px",fontWeight:"bolder",cursor:"pointer"}} />
        </HtmlTooltipComponent>
    );
}