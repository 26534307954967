import React, {useContext, useEffect, useState} from 'react'
import TextField from "@mui/material/TextField";
import {CircularProgress, MenuItem, Stack} from "@mui/material";
import { useNavigate } from 'react-router-dom';
import {enqueueSnackbar} from "notistack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AuthContext from "../api-authorization/AuthContext";
import dayjs from "dayjs";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import Button from "@mui/material/Button";
import NavigationContext from "../layout/NavigationContext";
import {DataGridPro, GridToolbarContainer, GridToolbarExport} from "@mui/x-data-grid-pro";
import clientService from "../clients/ClientService";
import reportService from "./ReportService";
import {COMPANY_TABS} from "../enums";

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarExport />
        </GridToolbarContainer>
    );
}

export default function CompanyReport() {
    const { histNavigate,back } = useContext(NavigationContext);
    const { antiForgeryToken, refreshUser} = useContext(AuthContext);
    const [isClientDataLoading, setIsClientDataLoading] = React.useState(true);
    const [isLoading, setIsLoading] = React.useState(true);
    const [companies, setCompanies] = React.useState([]);
    const navigate = useNavigate();
    const [clientData, setClientData] = useState();

    const [selectedClientId, setSelectedClientId] = useState(null);
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    
    useEffect(() => {
        fetchClientData();
    }, [])

    async function fetchClientData() {
        const result = await clientService.listClients(antiForgeryToken, "");
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        } else {
            let data = await result.json();
            if (result.ok) {
                setClientData(data.clients);
                setIsClientDataLoading(false);
            } else {
                enqueueSnackbar(data.description);
            }
        }
        setIsLoading(false);
    }

    const fetchCompanyData = async () => {
        setIsLoading(true);
        let companyReportRequest = {
            startDate: startDate ? dayjs(startDate).format('YYYY-MM-DD') : null,
            endDate : endDate ? dayjs(endDate).format('YYYY-MM-DD') : null,
            clientId : selectedClientId
        };
        const result = await reportService.companyReport(antiForgeryToken, companyReportRequest);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        let data = await result.json();
        if (result.ok) {
            setCompanies(data.items);
            setIsLoading(false);
        } else {
            enqueueSnackbar(data.description);
        }
    };

    const handleSearch = (event) => {
        event.preventDefault();
        fetchCompanyData();
    };

    const handleRowClick = (params) => {
        window.open(`/Company/Update/${params.row.companyId}/${COMPANY_TABS.DETAILS}`, "_blank");
    };
    
    const dateFormatter = (params) => {
        const date = new Date(params.value);
        if (params.value === null || isNaN(date.getTime()) || date.getFullYear() <= 1) {
            return "";
        }
        return date.toLocaleDateString('en-GB');
    };

    let content;
    if (isClientDataLoading) {
        content = (
            <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                <CircularProgress />
            </Box>
        );
    } else {
        content = (
            <Box sx={{height: '75vh'}}>
                <Box sx={{ position: 'sticky', top: 0, zIndex: 1, background: '#fff' }}>
                <Typography variant="h5" noWrap component="div" align={"left"} sx={{mb:4}}>
                    Company Report
                </Typography>
                <form onSubmit={handleSearch}>
                    <Stack direction="row" spacing={2} marginTop={4} sx={{width: "80%"}}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="Start Date"
                                format={"DD/MM/YYYY"}
                                value={startDate}
                                onChange={(newValue) => setStartDate(newValue)}
                                slotProps={{ textField: { size: 'small' } }}
                            />
                            <DatePicker
                                label="End Date"
                                format={"DD/MM/YYYY"}
                                value={endDate}
                                onChange={(newValue) => setEndDate(newValue)}
                                slotProps={{ textField: { size: 'small' } }}
                            />
                        </LocalizationProvider>
                        <TextField
                            id="selectedClientId"
                            select
                            label="Client"
                            value={selectedClientId}
                            onChange={(e) => {
                                setSelectedClientId(e.target.value);
                                if(e.target.value == null) {
                                    setSelectedClientId(null);
                                }
                            }}
                            variant="outlined"
                            margin="normal"
                            size="small"
                            sx={{mt:1, width:"35%"}}
                        >
                            {clientData.map((client) => (
                                <MenuItem key={client.id} value={client.id}>
                                    {client.companyName}
                                </MenuItem>
                            ))}
                        </TextField>


                        <Button style={{height:"80%", width: "10%"}}  variant="contained" color="secondary" onClick={(e) => {
                            setStartDate(null);
                            setEndDate(null);
                            setSelectedClientId(null);
                        }}>
                            Reset
                        </Button>
                        <Button style={{height:"80%", width: "10%"}}  variant="contained" color="secondary" type="submit">
                            Search
                        </Button>
                    </Stack>

                </form>
                </Box>
                <br />
                <DataGridPro
                    rows={companies}
                    initialState={{
                        columns: {
                            columnVisibilityModel: {
                                subscriptionCreatedDate: false,
                                subscriptionCancelledDate: false,
                            },
                        },
                    }}
                    columns={[
                        { field: 'clientName', headerName: 'Client Name', flex: 1 },
                        { field: 'number', headerName: 'Company Number', flex: 1 },
                        { field: 'name', headerName: 'Company Name', flex: 1 },
                        { field: 'createdAt', headerName: 'Created Date', flex: 1, valueFormatter: dateFormatter},
                        { field: 'productName', headerName: 'Subscription Product Name', flex: 1 },
                        { field: 'subscriptionCreatedDate', headerName: 'Subscription Created', flex: 1, valueFormatter: dateFormatter },
                        { field: 'subscriptionCancelledDate', headerName: 'Subscription Cancelled', flex: 1, valueFormatter: dateFormatter },
                    ]}
                    hideFooter={true}
                    loading={isLoading}
                    slots={{
                        toolbar: CustomToolbar,
                    }}
                    onRowClick={handleRowClick}
                    sx={{
                        '.MuiDataGrid-columnHeaderTitle': {
                            WebkitTextStroke: '0.75px'
                        },
                    }}
                />
            </Box>
        );
    }

    return (
        <>
            {content}
        </>
    );
}
