import * as React from 'react';
import Box from "@mui/material/Box";
import {
    CircularProgress,
    FormControl,
    FormControlLabel, FormHelperText, Grid, MenuItem,
    Radio,
    RadioGroup, Stack
} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useEffect} from "react";
import {COMPANY_FORMATION_TABS, ROLES} from "../enums";
import {Controller, useForm} from "react-hook-form";
import TextField from "@mui/material/TextField";
import {useTheme} from "@mui/material/styles";
import Button from "@mui/material/Button";
import HtmlTooltip from "../layout/HtmlTooltip";
import {countries} from "../util/Countries";

export default function FormationRegisteredOffice({formationData, createOrUpdateFormationData, parentBack}) {
    const {  handleSubmit, control, formState, setValue,trigger } = useForm();
    const theme = useTheme();
    const [isLoading, setIsLoading] = React.useState(false);
    const [useRaOfficeAddress, setUseRaOfficeAddress] = React.useState(formationData?.useRaOfficeAddress !== null ? formationData.useRaOfficeAddress : false);
    
    let raAddress={
        addressLine1: "123 Fake Street",
        addressLine2: "",
        locality: "London",
        country: "United Kingdom",
        postalCode: "SW1A 1AA"
    }
    
    const confirmAndContinue = async (formData) => {
        const newFormationData = {
            ...formationData,
            completedTab: COMPANY_FORMATION_TABS.REGISTERED_OFFICE,
            useRaOfficeAddress: useRaOfficeAddress,
            registeredOfficeAddress: formationData.registeredOfficeAddress ? { ...formationData.registeredOfficeAddress, ...formData.registeredOfficeAddress } : formData.registeredOfficeAddress,
        };
        await createOrUpdateFormationData(newFormationData);
    }

    useEffect(() => {
        setValue("registeredOfficeAddress.addressLine1", getDefaultAddressField("addressLine1",true));
        setValue("registeredOfficeAddress.addressLine2", getDefaultAddressField("addressLine1",true));
        setValue("registeredOfficeAddress.locality", getDefaultAddressField("locality",true));
        setValue("registeredOfficeAddress.country", getDefaultAddressField("country",true));
        setValue("registeredOfficeAddress.postalCode", getDefaultAddressField("postalCode",true));
        if(useRaOfficeAddress === true){
            trigger("registeredOfficeAddress.addressLine1");
            trigger("registeredOfficeAddress.addressLine2");
            trigger("registeredOfficeAddress.locality");
            trigger("registeredOfficeAddress.country");
            trigger("registeredOfficeAddress.postalCode");
        }
    }, [useRaOfficeAddress]);
    
    
    const getDefaultAddressField = (fieldName, resetFields = false) => {
        if(useRaOfficeAddress === true){
            return raAddress[fieldName];   
        }
        else if(resetFields) {
            return "";
        }
        else if (formationData.registeredOfficeAddress){
           return formationData.registeredOfficeAddress[fieldName]; 
        }
        else{
            return "";
        }
    }
    

    let content;
    
    let addressSection = (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
                <Controller
                    name="registeredOfficeAddress.addressLine1"
                    control={control}
                    defaultValue={getDefaultAddressField("addressLine1")}
                    rules={{ required: 'Address Line 1 is required' }}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label="Address Line 1 *"
                            variant="outlined"
                            InputLabelProps={{
                                style: { ...theme.inputLabelProps },
                            }}
                            margin="normal"
                            fullWidth
                            disabled={useRaOfficeAddress === true}
                            size="small"
                            error={!!formState.errors.registeredOfficeAddress?.addressLine1}
                            helperText={formState.errors.registeredOfficeAddress?.addressLine1 ? formState.errors.registeredOfficeAddress.addressLine1.message : ''}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                <Controller
                    name="registeredOfficeAddress.addressLine2"
                    control={control}
                    defaultValue={getDefaultAddressField("addressLine2")}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label="Address Line 2"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            disabled={useRaOfficeAddress === true}
                            size="small"
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                <Controller
                    name="registeredOfficeAddress.locality"
                    control={control}
                    defaultValue={getDefaultAddressField("locality")}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label="Town / City"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            disabled={useRaOfficeAddress === true}
                            size="small"
                            error={!!formState.errors.registeredOfficeAddress?.locality}
                            helperText={formState.errors.registeredOfficeAddress?.locality ? formState.errors.registeredOfficeAddress.locality.message : ''}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                <Controller
                    name="registeredOfficeAddress.country"
                    control={control}
                    defaultValue={getDefaultAddressField("country")}
                    rules={{ required: 'Country is required' }}
                    render={({field}) => (
                        <TextField
                            {...field}
                            select
                            label="Country"
                            variant="outlined"
                            InputLabelProps={{
                                style: {...theme.inputLabelProps},
                            }}
                            margin="normal"
                            fullWidth
                            disabled={useRaOfficeAddress === true}
                            size="small"
                            error={!!formState.errors.registeredOfficeAddress?.country}
                            helperText={formState.errors.registeredOfficeAddress?.country ? formState.errors.registeredOfficeAddress.country.message : ''}
                        >
                            <MenuItem key="0" value={0}>&nbsp;</MenuItem>

                            {countries.map((country) => (
                                <MenuItem key={country.code} value={country.label}>
                                    {country.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    )}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                <Controller
                    name="registeredOfficeAddress.postalCode"
                    control={control}
                    defaultValue={getDefaultAddressField("postalCode")}
                    rules={{ required: 'Post Code is required' }}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label="Post Code *"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            disabled={useRaOfficeAddress === true}
                            size="small"
                            error={!!formState.errors.registeredOfficeAddress?.postalCode}
                            helperText={formState.errors.registeredOfficeAddress?.postalCode ? formState.errors.registeredOfficeAddress.postalCode.message : ''}
                        />
                    )}
                />
            </Grid>
        </Grid>  
    );

    if (isLoading) {
        content = (
            <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                <CircularProgress />
            </Box>
        );
    }
    else {
        content = (
            <form onSubmit={handleSubmit(confirmAndContinue)}>
                <Typography component={"div"} align={"left"} sx={{mb: 2, mt:4,fontSize:"24px",fontWeight:"bolder"}}>
                    Registered Office Address
                </Typography>
                <Typography component={"div"} align={"left"} variant="formationLabelHeader" sx={{mt:2}}>
                    Would you like to use our Registered Office address?  *
                    <HtmlTooltip title={"Why should I use the Registered Agent service?"}
                                 message={"If you run your company from home and do not want your home address available to the public, you should consider using this service. <br /><br/> You cannot remove a registered office address from a company’s record after incorporation."}/>
                </Typography>
                <Controller
                    name="useRaOfficeAddress"
                    control={control}
                    defaultValue={formationData?.useRaOfficeAddress === null ? null : formationData?.useRaOfficeAddress.toString()}
                    rules={{required: `Please select if you'd like to use our Registered Office Address`}}
                    render={({field}) => (
                        <FormControl error={!!formState.errors.useRaOfficeAddress}  
                                     onChange={(e) => {
                                        field.onChange(e);
                                        setUseRaOfficeAddress(e.target.value === "true" ? true : false);
                                     }}
                        >
                            <RadioGroup {...field} row>
                                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                <FormControlLabel value={false} control={<Radio />} label="No" />
                            </RadioGroup>
                            {formState.errors.useRaOfficeAddress && (
                                <FormHelperText>{formState.errors.useRaOfficeAddress.message}</FormHelperText>
                            )}
                        </FormControl>
                    )}
                />
                
                {useRaOfficeAddress === false ? (
                    <>
                        <Typography component={"div"} align={"left"} variant="formationLabelHeader" sx={{mt:2}}>
                            Where will the company be registered?  *
                        </Typography>
                        <Typography component={"div"} align={"left"} variant="formationLabelSubHeader">
                            This address will be publicly available on the Companies House online register. We’ll use this address to send notifications to the company. You can use a business or home address that’s in the UK.
                        </Typography>
                        {addressSection}
                    </>
                ): useRaOfficeAddress === true ? (
                    <>
                        <Typography component={"div"} align={"left"} variant="formationLabelHeader" sx={{mt:2}}>
                            This will be the companies registered office address
                        </Typography>
                        <Typography component={"div"} align={"left"} variant="formationLabelSubHeader">
                            This address will be publicly available on the Companies House online register. We’ll use this address to send notifications to the company.
                        </Typography>
                        {addressSection}
                    </>
                ) : null}
                <br/>
                <Stack direction="row" spacing={2} sx={{mt: 2}}>
                    <Button variant="contained" color="secondary" onClick={(e) =>{
                        parentBack();
                    }}>Back</Button>
                    <Button variant="contained" color="secondary" type="submit">Confirm & Continue</Button>
                </Stack>
            </form>
        );
    }


    return (
        <Box maxWidth={"100vh"}>
            {content}
        </Box>
    );
}