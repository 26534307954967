const createClient  = async (antiForgeryToken, formData) => {
    return fetch(`api/Clients`, {
        method: 'POST',
        headers: buildAuthHeaders(antiForgeryToken),
        body: JSON.stringify(formData)
    });
};


const getClient = async (antiForgeryToken, clientId) => {
    return fetch(`api/Clients/${clientId}`, {
        method: 'GET',
        headers: buildAuthHeaders(antiForgeryToken)
    });
};

const updateClient = async (antiForgeryToken, formData) => {
    return fetch(`api/Clients`, {
        method: 'PATCH',
        headers: buildAuthHeaders(antiForgeryToken),
        body: JSON.stringify(formData)
    });
};

const listClients  = async (antiForgeryToken, searchTerm) => {
    return fetch(`api/Clients/Find?searchTerm=${searchTerm}`, {
        method: 'GET',
        headers: buildAuthHeaders(antiForgeryToken)
    });
};


function buildAuthHeaders(antiForgeryToken) {
    return !antiForgeryToken ? {} : {
        [antiForgeryToken.tokenName] : antiForgeryToken.token,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    };
}

const clientService = Object.freeze({
    createClient,
    listClients,
    updateClient,
    getClient
});
export default clientService;