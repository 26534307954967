import * as React from 'react';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import accountService from "../accounts/AccountService";
import {enqueueSnackbar} from "notistack";
import AuthContext from "../api-authorization/AuthContext";
import {CircularProgress} from "@mui/material";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import AccountDetailsConfirmation from "./AccountDetailsConfirmation";
import companyService from "../companies/CompanyService";
import CompanyDetailsConfirmation from "./CompanyDetailsConfirmation";
import FinalConfirmation from "./FinalConfirmation";


export default function AccountDetailsConfirmationParent() {
    const [accountLoading, setAccountLoading] = useState(true);
    const [companiesLoading, setCompaniesLoading] = useState(true);
    const [accountData, setAccountData] = useState();
    const [companies, setCompanies] = React.useState([]);
    const { antiForgeryToken, user, refreshUser} = useContext(AuthContext);

    const [tabValue, setTabValue] = React.useState(0);
    const [tabsProcessed, setTabsProcessed] = React.useState(0);
    const navigate = useNavigate();
    let content;
    const handleChange = (event, newTabValue) => {
        setTabValue(newTabValue);
    };

    useEffect( () => {
        getAccountData();
        getAccountCompanies();
    }, [user.accountId])

    async function getAccountCompanies() {
        let searchRequest = {
            appScope: 3,
            accountId: user.accountId,
            page: 0,
            pageSize: 20,
        };
        const result = await companyService.listCompanies(antiForgeryToken, searchRequest);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        let data = await result.json();
        if (result.ok) {
            setCompanies(data.companies);
            setCompaniesLoading(false);
        } else {
            enqueueSnackbar(data.description);
        }
    };
    
    async function progressTab(){
        let newTabValue = Number(tabValue) + 1;
        setTabValue(newTabValue);
        setTabsProcessed(Math.max(tabsProcessed, newTabValue));
    }


    async function getAccountData() {
        const result = await accountService.getAccount(antiForgeryToken, user.accountId);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                setAccountData(data);
                setAccountLoading(false);
            } else {
                enqueueSnackbar(data.description);
            }
        }
    }



    if (accountLoading || companiesLoading) {
        content = (
            <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                <CircularProgress />
            </Box>
        );
    } else {
        let companyTabs = companies.map((tab,index) => (
            <Tab key={`${index+1}`} label={`${tab.name} (${tab.number})`} value={`${index+1}`} disabled={tabsProcessed < (index+1)}/>
        ));
                
        let companyTabContent = companies.map((tab,index) => (
                <TabPanel value={`${index+1}`} key={`${index+1}`} >
                    <CompanyDetailsConfirmation accountData={accountData} companyId={tab.id} antiForgeryToken={antiForgeryToken} refreshUser={refreshUser} progressTab={progressTab}/>
                </TabPanel>
        ));
        
        let title = "Lets get started. Set up your account";
        if(companies.length > 0 && tabValue > 0) {
            title = `Almost done! Confirm your company details`;
        }

        if(tabValue > companies.length) {
            content=(
                <FinalConfirmation antiForgeryToken={antiForgeryToken} refreshUser={refreshUser}/>
            );
        }
        else{
            content = (
                <>
                    <Typography variant="h5" noWrap component="div" align={"left"} sx={{mb: 2}}>
                        {title}
                    </Typography>
                    <TabContext value={tabValue.toString()}>
                        <Box>
                            <Box>

                                <TabList onChange={handleChange} aria-label="basic tabs example">
                                    <Tab label="Details" value={"0"} disabled={false}/>
                                    {companyTabs}
                                    <Tab label="Confirm" value={`${companies.length+1}`} disabled={tabsProcessed <= companies.length} />
                                </TabList>
                            </Box>

                            <TabPanel value={"0"}>
                                <AccountDetailsConfirmation getAccountData={getAccountData} accountData={accountData} getAccountData={getAccountData}  antiForgeryToken={antiForgeryToken} refreshUser={refreshUser} progressTab={progressTab}/>
                            </TabPanel>
                            {companyTabContent}
                            <TabPanel value={`${companies.length+1}`}>
                                <FinalConfirmation antiForgeryToken={antiForgeryToken} refreshUser={refreshUser}/>
                            </TabPanel>
                        </Box>
                    </TabContext>
                </>
            );   
        }
    }

    return (
        <>
            {content}
        </>
    );
}