import React, {useContext, useEffect, useState} from 'react';
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import {CircularProgress, InputAdornment, Pagination, Stack} from "@mui/material";
import {useNavigate} from 'react-router-dom';
import {enqueueSnackbar} from "notistack";
import TransitionAlert from "../layout/TransitionAlert";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import companiesHouseService from "./CompaniesHouseService";
import AuthContext from "../api-authorization/AuthContext";
import NavigationContext from "../layout/NavigationContext";
import {DataGridPro} from "@mui/x-data-grid-pro";


export default function ListCompaniesHouse() {
    const { histNavigate, back } = useContext(NavigationContext);
    const { antiForgeryToken, user, refreshUser } = useContext(AuthContext);
    const [searchTerm, setSearchTerm] = useState("");
    const [hasSearched, setHasSearched] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(true);
    const [companies, setCompanies] = React.useState([]);
    const [rowCountState, setRowCountState] = React.useState(0);
    const [paginationModel, setPaginationModel] = React.useState({
        page: 0,
        pageSize: 10,
    });
    const navigate = useNavigate();

    useEffect(() => {
        if (user.loggedIn == false) {
            refreshUser();
            navigate('/login');
        }
    }, [user, navigate]);

    const fetchData = async () => {
        setIsLoading(true);
        let searchRequest = {
            searchTerm: searchTerm
        };
        const result = await companiesHouseService.findCompanies(antiForgeryToken, searchRequest);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                setCompanies(data.companies);
                setRowCountState(data.companies.length);
                setHasSearched(true);
                setIsLoading(false);
            } else {
                enqueueSnackbar(data.description);
            }
        }
    };

    const handlePageChange = (params) => {
        setPaginationModel({
            page: params.page,
            pageSize: params.pageSize,
        });
    };

    const handleSearch = (event) => {
        event.preventDefault();
        fetchData();
    };

    const handleRowClick = (params) => {
        histNavigate(`/CompaniesHouse/ViewCompany/${params.row.number}`,'View Company');
    };

    const dateFormatter = (params) => {
        const date = new Date(params.value);
        if (isNaN(date.getTime()) || date.getFullYear() <= 1) {
            return "None";
        }
        return date.toLocaleDateString('en-GB');
    };

    let searchForm = (
        <form onSubmit={handleSearch}>
            <TextField
                id="search-bar"
                className="text"
                value={searchTerm}
                onChange={(e) => {
                    setSearchTerm(e.target.value);
                }}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="end">
                            <IconButton type="submit">
                                <SearchIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                label="Search"
                variant="outlined"
                size="small"
                sx={{width: '100%', mt:2}}
            />
        </form>
    );

    let content;
    if (!hasSearched) {
        content = (
            <></>
        );
    }
    else if (isLoading || companies == null) {
        content = (
            <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                <CircularProgress />
            </Box>
        );
    } else {
        content = (
            <>
                <DataGridPro
                    rows={companies.slice(
                        paginationModel.page * paginationModel.pageSize,
                        paginationModel.page * paginationModel.pageSize + paginationModel.pageSize
                    )}
                    columns={[
                        { field: 'number', headerName: 'Company Number', flex: 1 },
                        { field: 'name', headerName: 'Company Name', flex: 2 },
                        { field: 'status', headerName: 'Status', flex: 1 },
                        { field: 'incorporationDate', headerName: 'Incorporation Date', flex: 1, valueFormatter: dateFormatter }
                    ]}
                    rowCount={paginationModel.pageSize}
                    pageSize={paginationModel.pageSize}
                    hideFooter={true}
                    page={paginationModel.page}
                    onPageChange={handlePageChange}
                    loading={isLoading}
                    onRowClick={handleRowClick}
                    getRowClassName={() => 'rowCursorPointer'}
                    sx={{
                        '.MuiDataGrid-columnHeaderTitle': {
                            WebkitTextStroke: '0.75px'
                        },
                    }}
                />
                <Box display="flex" justifyContent="flex-end">
                    <Pagination
                        count={Math.ceil(rowCountState / paginationModel.pageSize)}
                        page={paginationModel.page + 1}
                        onChange={(event, page) => handlePageChange({ page: page - 1, pageSize: paginationModel.pageSize })}
                    />
                </Box>
            </>
        );
    }
    return (
        <Box sx={{height:600}}>
            <Typography variant="h5" noWrap component="div" align={"left"} sx={{mb: 4}}>
                Companies House Search
            </Typography>
            <TransitionAlert message={"NOTE:   Search for companies directly from Companies House"} sx={{mb: 4}}/>
            {searchForm}
            <br />
            {content}
        </Box>
    );
}
