async function login(antiForgeryToken, userData) {
    return fetch(`/api/Auth/Login`, {
        method: 'POST',
        body: JSON.stringify(userData),
        headers: buildAuthHeaders(antiForgeryToken)
    });
}


async function updateUserProfile(antiForgeryToken, userData) {
    return fetch(`/api/Users/UpdateProfile`, {
        method: 'PATCH',
        headers: buildAuthHeaders(antiForgeryToken),
        body: JSON.stringify(userData)
    });
}

async function sendPasswordResetLink(userData) {
    return fetch(`/api/Auth/SendResetPasswordLink`, {
        method: 'POST',
        body: JSON.stringify(userData)
    });
}


async function getUserProfile(antiForgeryToken) {
    return fetch(`/api/Users/GetProfile`, {
        method: 'GET',
        headers: buildAuthHeaders(antiForgeryToken)
    });
}

async function acceptInvitation(antiForgeryToken, formData) {
    return fetch(`/api/Users/AcceptInvitation`, {
        method: 'POST',
        headers: buildAuthHeaders(antiForgeryToken),
        body: JSON.stringify(formData)
    });
}

async function resetPassword(antiForgeryToken, formData) {
    return fetch(`/api/Auth/ResetPassword`, {
        method: 'POST',
        headers: buildAuthHeaders(antiForgeryToken),
        body: JSON.stringify(formData)
    });
}

async function logout(antiForgeryToken) {
    return fetch(`/api/Auth/Logout`, {
        method: 'POST',
        headers: buildAuthHeaders(antiForgeryToken)
    });
}

async function adminSendPasswordResetLink(antiForgeryToken, userId) {
    return fetch(`/api/Auth/AdminSendResetPasswordLink/${userId}`, {
        method: 'POST',
        headers: buildAuthHeaders(antiForgeryToken)
    });
}

async function requestPasswordResetLink(antiForgeryToken, formData) {
    return fetch(`/api/Auth/SendResetPasswordLink`, {
        method: 'POST',
        headers: buildAuthHeaders(antiForgeryToken),
        body: JSON.stringify(formData)
    });
}

async function reInviteUser(antiForgeryToken, userId) {
    return fetch(`/api/Users/ReInviteUser/${userId}`, {
        method: 'POST',
        headers: buildAuthHeaders(antiForgeryToken)
    });
}

const getUser  = async (antiForgeryToken, userId) => {
    return fetch(`api/Users/GetUser/${userId}`, {
        method: 'GET',
        headers: buildAuthHeaders(antiForgeryToken)
    });
};

const updateUser  = async (antiForgeryToken, formData) => {
    return fetch(`api/Users/UpdateUser`, {
        method: 'PATCH',
        headers: buildAuthHeaders(antiForgeryToken),
        body: JSON.stringify(formData)
    });
};

async function createUser(antiForgeryToken, userData) {
    return fetch(`/api/Users/CreateUser`, {
        method: 'POST',
        body: JSON.stringify(userData),
        headers: buildAuthHeaders(antiForgeryToken)
    });
}

const listUsers  = async (antiForgeryToken, findUsersRequest) => {
    return fetch(`api/Users/FindUsers`, {
        method: 'POST',
        body: JSON.stringify(findUsersRequest),
        headers: buildAuthHeaders(antiForgeryToken)
    });
};

function buildAuthHeaders(antiForgeryToken) {
    return !antiForgeryToken ? {} : {
        [antiForgeryToken.tokenName] : antiForgeryToken.token,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    };
}

const userService = Object.freeze({
    login,
    getUser,
    updateUser,
    createUser,
    listUsers,
    updateUserProfile,
    getUserProfile,
    sendPasswordResetLink,
    adminSendPasswordResetLink,
    requestPasswordResetLink,
    acceptInvitation,
    logout,
    reInviteUser,
    resetPassword
});
export default userService;