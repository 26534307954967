import Box from "@mui/material/Box";
import {Card, CardActionArea, Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import * as React from "react";
import {useContext} from "react";
import NavigationContext from "../layout/NavigationContext";
import SideBarMailHandlingInstructions from "./SideBarMailHandlingInstructions";

export default function SideBarPersonCard({company, selectedRowId, handleCardClick, selectedMailType}) {
    const { histNavigate, back } = useContext(NavigationContext);
    
    return (
        <Box key={company.id}>
            <Card
                sx={{
                    backgroundColor: selectedRowId === company.id ? '#BFD6BB' : 'white',
                    opacity: company.canCheckInMail ? 1 : 0.5 // it will grey out the card if canCheckInMail is false
                }}>
                <CardActionArea  sx={{p:2}}
                                 onClick={() => handleCardClick(company)}>
                    <Grid container spacing={2}>
                        <Grid item xs={10} sm={10} md={10}>
                            <Typography variant="companyMailSearchHeader">
                                {company.personName}
                            </Typography>
                            <Typography variant="companyMailSearchSubHeader">
                                &nbsp;{company.personType}
                            </Typography>
                        </Grid>
                        <Grid item xs={2} sm={2} md={2}>
                            <Box display="flex" justifyContent="flex-end" style={{fontWeight:950}} color={(company.subscriptionStatus == "ACTIVE" || company.subscriptionStatus == "TRIALING") ? "green" : "red"}>
                                {company.subscriptionStatus}
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={0} sx={{mt:2,mb:2}}>
                        <Grid item xs={3} sm={3} md={3}>
                            <Typography variant="companyMailSearchHeavyFieldDescriptor" sx={{ lineHeight: '2.5' }}>
                                CLIENT NAME:
                            </Typography>
                        </Grid>
                        <Grid item xs={9} sm={9} md={9}>
                            <Typography variant="companyMailSearchHeader">
                                {company.clientName}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={0}>
                        <Grid item xs={3} sm={3} md={3}>
                            <Typography variant="companyMailSearchFieldDescriptor">
                                COMPANY NAME:
                            </Typography>
                        </Grid>
                        <Grid item xs={9} sm={9} md={9}>
                            <Typography variant="companyMailSearchFieldValue">
                                {company.companyName}
                            </Typography>
                        </Grid>
                        {company.tradingNames && company.tradingNames.length > 0 &&
                            <>
                                <Grid item xs={3} sm={3} md={3}>
                                    <Typography variant="companyMailSearchFieldDescriptor">
                                        TRADING NAMES:
                                    </Typography>
                                </Grid>
                                <Grid item xs={9} sm={9} md={9}>
                                    <Typography variant="companyMailSearchFieldValue">
                                        {company.tradingNames}
                                    </Typography>
                                </Grid>
                            </>
                        }
                        {company.previousNames && company.previousNames.length > 0 &&
                            <>
                                <Grid item xs={3} sm={3} md={3}>
                                    <Typography variant="companyMailSearchFieldDescriptor">
                                        PREVIOUS NAMES:
                                    </Typography>
                                </Grid>
                                <Grid item xs={9} sm={9} md={9}>
                                    <Typography variant="companyMailSearchFieldValue">
                                        {company.previousNames}
                                    </Typography>
                                </Grid>
                            </>
                        }
                    </Grid>
                    <SideBarMailHandlingInstructions company={company} selectedMailType={selectedMailType}/>
                </CardActionArea>
            </Card>
            <Divider />
        </Box>  
    );
}