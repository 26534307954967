import * as React from 'react';
import {useContext} from "react";
import NavigationContext from "./NavigationContext";
import {Breadcrumbs, Link, Stack} from "@mui/material";
import * as PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";

function NavigateNextIcon(props) {
    return null;
}

NavigateNextIcon.propTypes = {fontSize: PropTypes.string};
export default function Breadcrumb({breadcrumbItems = []}) {
    const { history,back,histNavigate } = useContext(NavigationContext);
    
    let breadCrumbs = breadcrumbItems.map((item, index) => {
              return(
                  <Button key={index} color="primary" variant="text" sx={{textDecoration:"underline"}} onClick={(e) => {
                      e.preventDefault();
                      histNavigate(item.url);
                  }}>{item.description}</Button>
              );
        }
    );
    return (
        <Stack spacing={2} sx={{mb: 2}}>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
                {breadCrumbs}
            </Breadcrumbs>
            <Divider />
        </Stack>
    );
}