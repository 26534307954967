const listCustomerProducts  = async (antiForgeryToken, resourceType, resourceId) => {
    return fetch(`/api/Products/ListCustomerProducts/${resourceType}/${resourceId}`, {
        method: 'GET',
        headers: buildAuthHeaders(antiForgeryToken)
    });
};

const listProducts  = async (antiForgeryToken, resourceType, resourceId) => {
    return fetch(`/api/Products/ListProducts/${resourceType}/${resourceId}`, {
        method: 'GET',
        headers: buildAuthHeaders(antiForgeryToken)
    });
};

const addProductsToCustomer  = async (antiForgeryToken, resourceType, resourceId, productPriceIds, paymentMethodId) => {
    return fetch(`api/Products/AddProductsToCustomer`, {
        method: 'POST',
        headers: buildAuthHeaders(antiForgeryToken),
        body: JSON.stringify({
            resourceType : parseInt(resourceType),
            resourceId : resourceId,
            productPriceIds : productPriceIds,
            paymentMethodId : paymentMethodId
        })
    });
};


function buildAuthHeaders(antiForgeryToken) {
    return !antiForgeryToken ? {} : {
        [antiForgeryToken.tokenName] : antiForgeryToken.token,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    };
}

const productService = Object.freeze({
    listCustomerProducts,
    listProducts,
    addProductsToCustomer
});
export default productService;