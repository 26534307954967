import React, {useContext, useEffect} from 'react';
import {enqueueSnackbar} from "notistack";
import {useNavigate, useParams, useLocation} from "react-router-dom";
import {BILLING_TABS, RESOURCE_TYPE} from "../enums";
import billingService from "./BillingService";
import AuthContext from "../api-authorization/AuthContext";
import Box from "@mui/material/Box";
import {CircularProgress} from "@mui/material";
import NavigationContext from "../layout/NavigationContext";


export default function FinishCardSetup() {
    const { histNavigate,setHistory, back } = useContext(NavigationContext);
    const { antiForgeryToken, refreshUser} = useContext(AuthContext);
    const { resourceType, resourceId } = useParams();
    const search = useLocation().search;
    const setupIntentId = new URLSearchParams(search).get('setup_intent');
    const navigate = useNavigate();
    const savedHistory = JSON.parse(localStorage.getItem("navigationHistory") || "[]");
    
    useEffect(() => {
        async function fetchData() {
            const savedHistory = JSON.parse(localStorage.getItem("navigationHistory") || "[]");
            setHistory(savedHistory);
            const result = await billingService.finishCardSetUp(antiForgeryToken, resourceType, resourceId, setupIntentId);
            if (result.status === 401) {
                refreshUser();
                navigate('/login');
            }
            else {
                let data = await result.json();
                if (result.ok) {
                    enqueueSnackbar("Successfully added new default card");
                    back(1);
                } else {
                    enqueueSnackbar(data.description);
                }
            }
        }

        fetchData();
    }, [])
    

    return (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="80vh">
            <i>Adding new card</i>
            <CircularProgress sx={{mt: 2}}/>
        </Box>
    );
}