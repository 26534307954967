import * as React from 'react';
import Box from "@mui/material/Box";
import {
    Checkbox,
    CircularProgress, FormControl, FormControlLabel, FormHelperText, Grid, MenuItem, Radio, RadioGroup, Stack
} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useContext, useEffect} from "react";
import {Controller, useForm} from "react-hook-form";
import TextField from "@mui/material/TextField";
import {useTheme} from "@mui/material/styles";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import companiesHouseService from "../companiesHouse/CompaniesHouseService";
import {enqueueSnackbar} from "notistack";
import {useNavigate} from "react-router-dom";
import AuthContext from "../api-authorization/AuthContext";
import {LoadingButton} from "@mui/lab";
import {countries} from "../util/Countries";
import {FORMATION_DIRECTOR_PERCENTAGE_OF_VOTING_RIGHTS} from "../enums";
import HtmlTooltip from "../layout/HtmlTooltip";

export default function FormationDirectorCorporateDetails({
                                                              formationData,
                                                              createOrUpdateDirectors,
                                                              directorData,
                                                              back,
                                                              directorShareholderType
                                                          }) {
    const {handleSubmit, control, formState, setValue, trigger} = useForm();
    const {antiForgeryToken, user, refreshUser} = useContext(AuthContext);
    const theme = useTheme();
    const [isLoading, setIsLoading] = React.useState(false);
    const [companyLoading, setCompanyLoading] = React.useState(false);
    const [validCompanyFound, setValidCompanyFound] = React.useState(false);
    const [searchedCompanyDetails, setSearchedCompanyDetails] = React.useState(null);
    const [searchedCompanyName, setSearchedCompanyName] = React.useState("");
    const [searchedCompanyNumber, setSearchedCompanyNumber] = React.useState("");
    const [companyNumberSearchTerm, setCompanyNumberSearchTerm] = React.useState("");
    const navigate = useNavigate();

    const confirmAndContinue = async (formData) => {
        formData.directorIsShareholder = formData.directorIsShareholder === null ? null : formData.directorIsShareholder === "true";
        formData.rightToAppointOrRemoveDirectors = formData.rightToAppointOrRemoveDirectors === null ? null : formData.rightToAppointOrRemoveDirectors === "true";
        formData.companyNumber = searchedCompanyNumber;
        formData.companyName = searchedCompanyName;
        const newFormationData = {
            ...formationData,
        };

        if (directorData) {
            newFormationData.directorShareholders = newFormationData.directorShareholders.map(director => {
                if (director.id === directorData.id) {
                    return {
                        ...director,
                        ...formData
                    };
                }
                return director;
            });
        } else {
            if(formData.directorIsShareholder === true) {
                formData.numberOfShares = 1;
            }
            formData.type = directorShareholderType;
            newFormationData.directorShareholders.push(formData);
        }

        await createOrUpdateDirectors(newFormationData.directorShareholders);
    }

    const findCompany = async () => {
        setCompanyLoading(true);
        const result = await companiesHouseService.getCompany(antiForgeryToken, companyNumberSearchTerm);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        } else {
            let data = await result.json();
            if (result.ok) {
                setValidCompanyFound(true);
                setSearchedCompanyDetails(data);
                setSearchedCompanyName(data.name);
                setSearchedCompanyNumber(data.number);
                setValue('companyName', data.name || "");
                trigger('companyName');
                setValue('correspondenceAddress.addressLine1', data.registeredOfficeAddress.addressLine1 || "");
                trigger('correspondenceAddress.addressLine1');
                setValue('correspondenceAddress.addressLine2', data.registeredOfficeAddress.addressLine2 || "");
                trigger('correspondenceAddress.addressLine2');
                setValue('correspondenceAddress.locality', data.registeredOfficeAddress.locality || "");
                trigger('correspondenceAddress.locality');
                setValue('correspondenceAddress.country', data.registeredOfficeAddress.country || "");
                trigger('correspondenceAddress.country');
                setValue('correspondenceAddress.postalCode', data.registeredOfficeAddress.postalCode || "");
                trigger('correspondenceAddress.postalCode');
            } else {
                setValidCompanyFound(false);
                enqueueSnackbar(data.description);
            }
            setCompanyLoading(false);
        }
    }

    const getDefaultAddressField = (fieldName) => {
        if (directorData != null && directorData.correspondenceAddress) {
            return directorData.correspondenceAddress[fieldName];
        } else {
            return "";
        }
    }

    useEffect( () => {
        console.log(directorData);
        if(directorData != null && directorData.companyNumber) {
            setCompanyNumberSearchTerm(directorData.companyNumber);
            setSearchedCompanyName(directorData.companyName);
            setSearchedCompanyNumber(directorData.companyNumber);
            setValidCompanyFound(true);
            setValue('companyName', directorData.companyName || "");
            trigger('companyName');
        }
    }, [])
    


    let content;

    if (isLoading) {
        content = (
            <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                <CircularProgress/>
            </Box>
        );
    } else {
        content = (
            <>
                <Typography component={"div"} align={"left"}
                            sx={{mb: 2, mt: 4, fontSize: "24px", fontWeight: "bolder"}}>
                    Corporate Director Details
                </Typography>
                <Typography component={"div"} align={"left"} variant="formationLabelHeader" sx={{mt: 2}}>
                    What is the company registration number? *
                    <HtmlTooltip title={"What is a company registration number (CRN)?"}
                                 message={"Your CRN can be found on your certificate of incorporation from Companies House that you received upon registration. It can also be found on: Any other official communications from Companies House."}/>
                </Typography>
                <Grid container spacing={2} sx={{mt: 1}}>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField
                            label="Company number *"
                            variant="outlined"
                            InputLabelProps={{
                                style: {...theme.inputLabelProps},
                            }}
                            value={companyNumberSearchTerm}
                            onChange={(e) => {
                                setCompanyNumberSearchTerm(e.target.value);
                                setValidCompanyFound(false);
                            }}
                            onKeyDown={async (e) => {
                                if (e.code === 'Enter' || e.code === 'NumpadEnter') {
                                    e.preventDefault();
                                    await findCompany();
                                }
                            }}
                            InputProps={{
                                startAdornment: (
                                    <LoadingButton
                                        color="secondary"
                                        onClick={async (e) => {
                                            await findCompany();
                                        }}
                                        loading={companyLoading}
                                        loadingPosition="start"
                                        startIcon={<SearchIcon/>}
                                    >
                                    </LoadingButton>
                                ),
                            }}
                            margin="normal"
                            fullWidth
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={0} sm={6} md={6} sx={{display: {xs: 'none', sm: 'block', md: 'block'}}}>
                    </Grid>
                </Grid>
                <form onSubmit={handleSubmit(confirmAndContinue)}>
                    {validCompanyFound &&
                        <>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} md={6}>
                                    <Controller
                                        name="companyName"
                                        control={control}
                                        rules={{required: 'Company name is required'}}
                                        render={({field}) => (
                                            <TextField
                                                {...field}
                                                label="Company Name"
                                                variant="outlined"
                                                InputLabelProps={{
                                                    style: {...theme.inputLabelProps},
                                                }}
                                                margin="normal"
                                                fullWidth
                                                disabled={true}
                                                size="small"
                                                error={!!formState.errors.companyName}
                                                helperText={formState.errors.companyName ? formState.errors.companyName.message : ''}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={0} sm={6} md={6} sx={{display: {xs: 'none', sm: 'block', md: 'block'}}}>
                                </Grid>
                            </Grid>
                            <Typography component={"div"} align={"left"} variant="formationLabelHeader" sx={{mt: 2}}>
                                {searchedCompanyName}’s registered office address
                            </Typography>
                            <Typography component={"div"} align={"left"} variant="formationLabelSubHeader">
                                This address will be used as the principal place of business or registered office
                                address of the corporate director.
                            </Typography>
                            <Grid container spacing={2} sx={{mt: 1}}>
                                <Grid item xs={12} sm={6} md={6}>
                                    <Controller
                                        name="correspondenceAddress.addressLine1"
                                        control={control}
                                        defaultValue={getDefaultAddressField("addressLine1")}
                                        rules={{required: 'Address Line 1 is required'}}
                                        render={({field}) => (
                                            <TextField
                                                {...field}
                                                label="Address Line 1 *"
                                                variant="outlined"
                                                InputLabelProps={{
                                                    style: {...theme.inputLabelProps},
                                                }}
                                                margin="normal"
                                                fullWidth
                                                disabled={true}
                                                size="small"
                                                error={!!formState.errors.correspondenceAddress?.addressLine1}
                                                helperText={formState.errors.correspondenceAddress?.addressLine1 ? formState.errors.correspondenceAddress.addressLine1.message : ''}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <Controller
                                        name="correspondenceAddress.addressLine2"
                                        control={control}
                                        defaultValue={getDefaultAddressField("addressLine2")}
                                        render={({field}) => (
                                            <TextField
                                                {...field}
                                                label="Address Line 2"
                                                variant="outlined"
                                                InputLabelProps={{
                                                    style: {...theme.inputLabelProps},
                                                }}
                                                margin="normal"
                                                fullWidth
                                                disabled={true}
                                                size="small"
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <Controller
                                        name="correspondenceAddress.locality"
                                        control={control}
                                        defaultValue={getDefaultAddressField("locality")}
                                        render={({field}) => (
                                            <TextField
                                                {...field}
                                                label="Town / City"
                                                variant="outlined"
                                                InputLabelProps={{
                                                    style: {...theme.inputLabelProps},
                                                }}
                                                margin="normal"
                                                fullWidth
                                                disabled={true}
                                                size="small"
                                                error={!!formState.errors.correspondenceAddress?.locality}
                                                helperText={formState.errors.correspondenceAddress?.locality ? formState.errors.correspondenceAddress.locality.message : ''}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <Controller
                                        name="correspondenceAddress.country"
                                        control={control}
                                        defaultValue={getDefaultAddressField("country")}
                                        render={({field}) => (
                                            <TextField
                                                {...field}
                                                select
                                                label="Country"
                                                variant="outlined"
                                                InputLabelProps={{
                                                    style: {...theme.inputLabelProps},
                                                }}
                                                margin="normal"
                                                fullWidth
                                                disabled={true}
                                                size="small"
                                            >
                                                <MenuItem key="0" value={0}>&nbsp;</MenuItem>

                                                {countries.map((country) => (
                                                    <MenuItem key={country.code} value={country.label}>
                                                        {country.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <Controller
                                        name="correspondenceAddress.postalCode"
                                        control={control}
                                        defaultValue={getDefaultAddressField("postalCode")}
                                        rules={{required: 'Post Code is required'}}
                                        render={({field}) => (
                                            <TextField
                                                {...field}
                                                label="Post Code *"
                                                variant="outlined"
                                                InputLabelProps={{
                                                    style: {...theme.inputLabelProps},
                                                }}
                                                margin="normal"
                                                fullWidth
                                                disabled={true}
                                                size="small"
                                                error={!!formState.errors.correspondenceAddress?.postalCode}
                                                helperText={formState.errors.correspondenceAddress?.postalCode ? formState.errors.correspondenceAddress.postalCode.message : ''}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                            <Typography component={"div"} align={"left"} variant="formationLabelHeader" sx={{mt: 2}}>
                                What is the name of the person acting on behalf of {searchedCompanyName} ? *
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={3} md={3}>
                                    <Controller
                                        name="title"
                                        control={control}
                                        defaultValue={directorData?.title}
                                        render={({field}) => (
                                            <TextField
                                                {...field}
                                                select
                                                label="Title"
                                                variant="outlined"
                                                InputLabelProps={{
                                                    style: {...theme.inputLabelProps},
                                                }}
                                                margin="normal"
                                                fullWidth
                                                size="small"
                                            >
                                                <MenuItem key="0" value={0}>&nbsp;</MenuItem>
                                                <MenuItem key="1" value={1}>Mr</MenuItem>
                                                <MenuItem key="2" value={2}>Mrs</MenuItem>
                                                <MenuItem key="3" value={3}>Ms</MenuItem>
                                                <MenuItem key="4" value={4}>Miss</MenuItem>
                                                <MenuItem key="5" value={5}>Dr</MenuItem>
                                            </TextField>
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3} md={3}>
                                    <Controller
                                        name="firstName"
                                        control={control}
                                        defaultValue={directorData?.firstName}
                                        rules={{required: 'First Name is required'}}
                                        render={({field}) => (
                                            <TextField
                                                {...field}
                                                label="First Name *"
                                                variant="outlined"
                                                InputLabelProps={{
                                                    style: {...theme.inputLabelProps},
                                                }}
                                                margin="normal"
                                                fullWidth
                                                size="small"
                                                error={!!formState.errors.firstName}
                                                helperText={formState.errors.firstName ? formState.errors.firstName.message : ''}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3} md={3}>
                                    <Controller
                                        name="middleNames"
                                        control={control}
                                        defaultValue={directorData?.middleNames}
                                        render={({field}) => (
                                            <TextField
                                                {...field}
                                                label="Middle Name(s)"
                                                variant="outlined"
                                                InputLabelProps={{
                                                    style: {...theme.inputLabelProps},
                                                }}
                                                margin="normal"
                                                fullWidth
                                                size="small"
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3} md={3}>
                                    <Controller
                                        name="lastName"
                                        control={control}
                                        defaultValue={directorData?.lastName}
                                        rules={{required: 'Last Name is required'}}
                                        render={({field}) => (
                                            <TextField
                                                {...field}
                                                label="Last Name *"
                                                variant="outlined"
                                                InputLabelProps={{
                                                    style: {...theme.inputLabelProps},
                                                }}
                                                margin="normal"
                                                fullWidth
                                                size="small"
                                                error={!!formState.errors.lastName}
                                                helperText={formState.errors.lastName ? formState.errors.lastName.message : ''}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                            <Typography component={"div"} align={"left"} variant="formationLabelHeader" sx={{mt: 2}}>
                                Will this corporate director be a shareholder of the company? *
                            </Typography>
                            <Controller
                                name="directorIsShareholder"
                                control={control}
                                defaultValue={directorData === null || directorData.directorIsShareholder === null ? null : directorData.directorIsShareholder.toString()}
                                rules={{required: `Please select if this director will be a shareholder`}}
                                render={({field}) => (
                                    <FormControl error={!!formState.errors.directorIsShareholder}>
                                        <RadioGroup {...field} row>
                                            <FormControlLabel value={true} control={<Radio/>} label="Yes"/>
                                            <FormControlLabel value={false} control={<Radio/>} label="No"/>
                                        </RadioGroup>
                                        {formState.errors.directorIsShareholder && (
                                            <FormHelperText>{formState.errors.directorIsShareholder.message}</FormHelperText>
                                        )}
                                    </FormControl>
                                )}
                            />
                            <Typography component={"div"} align={"left"} variant="formationLabelHeader" sx={{mt: 2}}>
                                Right to appoint or remove Directors
                            </Typography>
                            <Typography component={"div"} align={"left"} variant="formationLabelSubHeader">
                                Will this corporate director hold the right to appoint or remove the majority of the board of directors.
                            </Typography>
                            <Controller
                                name="rightToAppointOrRemoveDirectors"
                                control={control}
                                defaultValue={directorData === null || directorData.rightToAppointOrRemoveDirectors === null ? null : directorData.rightToAppointOrRemoveDirectors.toString()}
                                rules={{required: `Please select if this director will be a shareholder`}}
                                render={({field}) => (
                                    <FormControl error={!!formState.errors.rightToAppointOrRemoveDirectors}>
                                        <RadioGroup {...field} row>
                                            <FormControlLabel value={true} control={<Radio/>} label="Yes"/>
                                            <FormControlLabel value={false} control={<Radio/>} label="No"/>
                                        </RadioGroup>
                                        {formState.errors.rightToAppointOrRemoveDirectors && (
                                            <FormHelperText>{formState.errors.rightToAppointOrRemoveDirectors.message}</FormHelperText>
                                        )}
                                    </FormControl>
                                )}
                            />
                            <Typography component={"div"} align={"left"} variant="formationLabelHeader" sx={{mt: 2}}>
                                Ownership of voting rights
                            </Typography>
                            <Typography component={"div"} align={"left"} variant="formationLabelSubHeader">
                                Please select the following percentage of voting rights in the director holds directly or indirectly in the company.
                            </Typography>
                            <Controller
                                name="percentageOfVotingRights"
                                control={control}
                                defaultValue={directorData?.percentageOfVotingRights}
                                rules={{required: `Please select the ownership of voting rights`}}
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        select
                                        variant="outlined"
                                        InputLabelProps={{
                                            style: {...theme.inputLabelProps},
                                        }}
                                        margin="normal"
                                        sx={{width: "50%"}}
                                        size="small"
                                        error={!!formState.errors.percentageOfVotingRights}
                                        helperText={formState.errors.percentageOfVotingRights ? formState.errors.percentageOfVotingRights.message : ''}
                                    >
                                        <MenuItem
                                            key={FORMATION_DIRECTOR_PERCENTAGE_OF_VOTING_RIGHTS.LESS_THAN_25.toString()}
                                            value={FORMATION_DIRECTOR_PERCENTAGE_OF_VOTING_RIGHTS.LESS_THAN_25}>Less
                                            than 25%</MenuItem>
                                        <MenuItem
                                            key={FORMATION_DIRECTOR_PERCENTAGE_OF_VOTING_RIGHTS.BETWEEN_25_AND_50.toString()}
                                            value={FORMATION_DIRECTOR_PERCENTAGE_OF_VOTING_RIGHTS.BETWEEN_25_AND_50}>Between
                                            25% and 50%</MenuItem>
                                        <MenuItem
                                            key={FORMATION_DIRECTOR_PERCENTAGE_OF_VOTING_RIGHTS.GREATER_THAN_50_LESS_THAN_75.toString()}
                                            value={FORMATION_DIRECTOR_PERCENTAGE_OF_VOTING_RIGHTS.GREATER_THAN_50_LESS_THAN_75}>Greater
                                            than 50%, but less than 75%</MenuItem>
                                        <MenuItem
                                            key={FORMATION_DIRECTOR_PERCENTAGE_OF_VOTING_RIGHTS.GREATER_THAN_75.toString()}
                                            value={FORMATION_DIRECTOR_PERCENTAGE_OF_VOTING_RIGHTS.GREATER_THAN_50_LESS_THAN_75}>75%
                                            or more</MenuItem>
                                    </TextField>
                                )}
                            />
                            <Typography component={"div"} align={"left"} variant="formationLabelHeader" sx={{mt:2}}>
                                Agree to become a director
                            </Typography>
                            <Controller
                                name="directorAgreed"
                                control={control}
                                defaultValue={directorData != null && directorData.directorAgreed !== null ? directorData.directorAgreed : false}
                                rules={{ required: 'I confirm that the company and person named here agrees to become a corporate director of the company.' }}
                                render={({ field }) => (
                                    <FormControl error={!!formState.errors.directorAgreed}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    {...field}
                                                    color="primary"
                                                    checked={field.value}
                                                />
                                            }
                                            label={`I confirm that the person named here agrees to become a director of the company.`}
                                        />
                                        <FormHelperText>
                                            {formState.errors.directorAgreed ? formState.errors.directorAgreed.message : ''}
                                        </FormHelperText>
                                    </FormControl>
                                )}
                            />

                        </>
                    }
                    <Stack spacing={2} direction="row" sx={{mt: 2}}>
                        <Button variant="contained" color="secondary" sx={{mt: 4}} onClick={(e) => {
                            back();
                        }}>Back</Button>
                        <Button variant="contained" color="secondary" sx={{mt: 4}} type="submit"
                                disabled={!validCompanyFound}
                        >Confirm & Continue</Button>
                    </Stack>
                </form>
            </>
        );
    }


    return (
        <Box maxWidth={"100vh"}>
            {content}
        </Box>
    );
}