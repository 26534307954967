import {COMPANY_MAIL_SETTINGS_INSTRUCTIONS_TYPE, COMPANY_TABS, MAIL_TYPE} from "../enums";
import {Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import * as React from "react";
import {useContext, useEffect, useState} from "react";
import NavigationContext from "../layout/NavigationContext";

export default function SideBarMailHandlingInstructions({company, selectedMailType}) {
    const { histNavigate } = useContext(NavigationContext);
    const [companyHandlingInstructions, setCompanyHandlingInstructions] = useState({});
    
    useEffect( () => {
        if(selectedMailType === MAIL_TYPE.ROYAL_MAIL_LETTER ||
            selectedMailType === MAIL_TYPE.ROYAL_MAIL_LARGE_LETTER ||
            selectedMailType === MAIL_TYPE.COMPANIES_HOUSE ||
            selectedMailType === MAIL_TYPE.THE_PENSION_REGULATOR ||
            selectedMailType === MAIL_TYPE.HMRC ||
            selectedMailType === MAIL_TYPE.OTHER_STATUTORY_MAIL ||
            selectedMailType === MAIL_TYPE.OFFICE_OF_NATIONAL_STATISTICS ||
            selectedMailType === MAIL_TYPE.DVLA ||
            selectedMailType === MAIL_TYPE.INFORMATION_COMMISSIONERS_OFFICE) {
            setCompanyHandlingInstructions(company.handlingInstructions[COMPANY_MAIL_SETTINGS_INSTRUCTIONS_TYPE.LETTER]);
        }
        else if(selectedMailType === MAIL_TYPE.ROYAL_MAIL_SMALL_PARCEL || selectedMailType === MAIL_TYPE.ROYAL_MAIL_MEDIUM_PARCEL || selectedMailType === MAIL_TYPE.ROYAL_MAIL_LARGE_PARCEL) {
            setCompanyHandlingInstructions(company.handlingInstructions[COMPANY_MAIL_SETTINGS_INSTRUCTIONS_TYPE.PARCEL]);
        }
        else if(selectedMailType === MAIL_TYPE.OTHER_SPECIAL_DELIVERY) {
            setCompanyHandlingInstructions(company.handlingInstructions[COMPANY_MAIL_SETTINGS_INSTRUCTIONS_TYPE.SPECIAL]);
        }
        else if(selectedMailType === MAIL_TYPE.PRINTED_MATTER){
            setCompanyHandlingInstructions(company.handlingInstructions[COMPANY_MAIL_SETTINGS_INSTRUCTIONS_TYPE.PRINTED]);
        }
    }, [selectedMailType,companyHandlingInstructions]);

    return (
      <>
          <Grid container spacing={0} sx={{mt:2}}>
              {companyHandlingInstructions.mailHandlingType && (
                  <>
                      <Grid item xs={3} sm={3} md={3}>
                          <Typography variant="companyMailSearchFieldDescriptor" sx={{ lineHeight: '2.5' }}>
                              HANDLING:
                          </Typography>
                      </Grid>
                      <Grid item xs={9} sm={9} md={9}>
                          <Typography variant="companyMailSearchHeader">
                              {companyHandlingInstructions.mailHandlingType?.toUpperCase()}
                          </Typography>
                      </Grid>
                  </>
              )}
          </Grid>
          <Grid container spacing={0} sx={{mt:2}}>
              {company.mailNotes && company.mailNotes.length > 0 &&
                  <>
                      <Grid item xs={3} sm={3} md={3}>
                          <Typography variant="companyMailSearchFieldDescriptor">
                              NOTES:
                          </Typography>
                      </Grid>
                      <Grid item xs={9} sm={9} md={9}>
                          <Typography variant="companyMailSearchFieldValue">
                              {company.mailNotes}
                          </Typography>
                      </Grid>
                  </>
              }
              {company.legacyNotes && company.legacyNotes.length > 0 &&
                  <>
                      <Grid item xs={3} sm={3} md={3}>
                          <Typography variant="companyMailSearchFieldDescriptor">
                              LEGACY NOTES:
                          </Typography>
                      </Grid>
                      <Grid item xs={9} sm={9} md={9}>
                          <Typography variant="companyMailSearchFieldValue">
                              {company.legacyNotes}
                          </Typography>
                      </Grid>
                  </>
              }
              {companyHandlingInstructions.additionalInstructions && companyHandlingInstructions.additionalInstructions.length > 0 &&
                  <>
                      <Grid item xs={3} sm={3} md={3}>
                          <Typography variant="companyMailSearchFieldDescriptor">
                              ADDT'L INST:
                          </Typography>
                      </Grid>
                      <Grid item xs={9} sm={9} md={9}>
                          <Typography variant="companyMailSearchFieldValue">
                              {companyHandlingInstructions.additionalInstructions}
                          </Typography>
                      </Grid>
                  </>
              }
              <Grid item xs={9} sm={9} md={9} sx={{mt: 2}}>
                  <Typography variant="companyMailSearchFieldDescriptor">
                      <Button key={`${company.id}_linkTo`} color="primary" variant="text"
                              sx={{textDecoration: "underline", padding:0, margin:0}} onClick={(e) => {
                          e.preventDefault();
                          histNavigate(`/Company/Update/${company.companyId}/${COMPANY_TABS.DETAILS}`, 'Update Company');
                      }}>View Company</Button>
                  </Typography>
              </Grid>
              {company.levenshteinDistance !== null &&(
                  <Grid item xs={3} sm={3} md={3} sx={{mt: 2}}>
                      <Box display="flex" justifyContent="flex-end" color={(company.levenshteinDistance === 0) ? "green" : "orange"}>
                          {(company.levenshteinDistance === 0) ? "Good Match" : "Medium Match"}
                      </Box>
                  </Grid>
              )}
          </Grid>
      </>  
    );
}