import * as React from 'react';
import Box from "@mui/material/Box";
import {
    CircularProgress,
    Stack,
} from "@mui/material";
import {useForm} from "react-hook-form";
import {useTheme} from "@mui/material/styles";
import Button from "@mui/material/Button";

export default function FormationConfirmation({formationData, createOrUpdateFormationData,submitFormation,parentBack}) {
    const {  handleSubmit, control, formState, setValue } = useForm();
    const theme = useTheme();
    const [isLoading, setIsLoading] = React.useState(false);
    let content;
    
    if (isLoading) {
        content = (
            <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                <CircularProgress />
            </Box>
        );
    }
    else {
            content = (
                <form onSubmit={handleSubmit(submitFormation)}>
                    <Stack direction="row" spacing={2} sx={{mt: 2}}>
                        <Button variant="contained" color="secondary" onClick={(e) =>{
                            parentBack();
                        }}>Back</Button>
                        <Button variant="contained" color="secondary" sx={{mt: 4}} type="submit">Confirm &
                            Submit formation</Button>
                    </Stack>
                </form>
            );
    }


    return (
        <Box maxWidth={"100vh"}>
            {content}
        </Box>
    );
}