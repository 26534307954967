import * as React from 'react';
import Box from "@mui/material/Box";
import {Autocomplete, CircularProgress, MenuItem, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useContext, useState} from "react";
import {useNavigate} from "react-router-dom";
import NavigationContext from "../layout/NavigationContext";
import AuthContext from "../api-authorization/AuthContext";
import {Edit, InfoRounded} from "@mui/icons-material";
import {COMPANY_FORMATION_TABS} from "../enums";
import {Controller, useForm} from "react-hook-form";
import TextField from "@mui/material/TextField";
import {styled, useTheme} from "@mui/material/styles";
import Button from "@mui/material/Button";
import {enqueueSnackbar} from "notistack";
import formationService from "./FormationService";
import HtmlTooltip from "../layout/HtmlTooltip";
import Tooltip, {tooltipClasses} from "@mui/material/Tooltip";

const HtmlTooltipComponent = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} placement="right" />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: 'white',
        color: 'black',
        maxWidth: 540,
        border: '1px solid #dadde9',
        borderLeft: '10px solid #dadde9',
        borderRadius: '5px',
    },
}));
export default function FormationYourCompany({formationData, createOrUpdateFormationData, goToTab, parentBack}) {
    const { histNavigate, back } = useContext(NavigationContext);
    const { antiForgeryToken, user, refreshUser } = useContext(AuthContext);
    const {  handleSubmit, control, formState, setError,clearErrors } = useForm();
    const theme = useTheme();
    
    const [sicCodes, setSicCodes] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const [sensitiveWordsSupportingDocFile, setSensitiveWordsSupportingDocFile] = useState(null);
    const [sensitiveWordsSupportingDocFileChanged, setSensitiveWordsSupportingDocFileChanged] = useState(false);

    const navigate = useNavigate();

    const fetchSicCodeData = async () => {
        setIsLoading(true);
        const result = await formationService.listSicCodes(antiForgeryToken);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                setSicCodes(data.sicCodes);
                setIsLoading(false);
            } else {
                enqueueSnackbar(data.description);
            }
        }
    };

    React.useEffect(() => {
        fetchSicCodeData();
    }, []);

    const confirmAndContinue = async (formData) => {
        const newFormationData = {
            ...formationData,
            countryOfRegistration: formData.countryOfRegistration,
            registeredEmailAddress: formData.registeredEmailAddress,
            sicCodes: formData.sicCodes,
            completedTab: COMPANY_FORMATION_TABS.YOUR_COMPANY
        };
        if(sensitiveWordsSupportingDocFileChanged){
            const formData = new FormData();
            formData.append('FormationId', formationData.id);
            formData.append('FileUpload', sensitiveWordsSupportingDocFile);
            const result = await formationService.uploadSensitiveWordsDocument(antiForgeryToken, formData);
            if (result.status === 401) {
                refreshUser();
                navigate('/login');
            }
            else {
                let data = await result.json();
                if (result.ok) {
                    enqueueSnackbar(`Successfully uploaded supporting document`);
                    await createOrUpdateFormationData(newFormationData);
                } else {
                    enqueueSnackbar(data.description);
                }
            }
        }
        else{
            await createOrUpdateFormationData(newFormationData);   
        }
    }

    let content;

    if (isLoading) {
        content = (
            <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                <CircularProgress />
            </Box>
        );
    }
    else {
        content = (
            <form onSubmit={handleSubmit(confirmAndContinue)}>
                <Typography component={"div"} align={"left"} sx={{mb: 2, mt:4,color:"#458839",fontSize:"24px",fontWeight:"bolder"}}>
                    {formationData.companyName}
                    <Edit sx={{ml:4, cursor:"pointer"}} onClick={(e) =>{goToTab(COMPANY_FORMATION_TABS.NAME_CHECK);}}/>
                </Typography>
                {(formationData.sensitiveWords && formationData.sensitiveWords.length > 0) && (
                    <>
                        <Typography component={"div"} align={"left"} variant="formationSubHeader">
                            The company name you’ve chosen contains a sensitive word which you need permission to use - <strong>{formationData.sensitiveWords}</strong>
                        </Typography>
                        <Typography component={"div"} align={"left"} variant="formationLabelHeader" sx={{mt:2}}>
                            Upload supporting documents
                            {/*
                                Need to manually add the component here as parsing the html won't allow us to do a target="_blank"
                            */}
                            <HtmlTooltipComponent
                                title={
                                    <React.Fragment>
                                        <Typography sx={{fontWeight:"bolder",fontSize:"14px"}}>Do I need to upload supporting documents?</Typography>
                                        <Typography sx={{fontSize:"14px"}}>
                                            You only need to upload supporting documents if your company name contains a sensitive word. Some words like ‘King’ and ‘dental’ are classed as sensitive words.<br /><br /> Check the <a href='https://www.gov.uk/government/publications/incorporation-and-names/annex-a-sensitive-words-and-expressions-or-words-that-could-imply-a-connection-with-government' target="_blank">guidance on names</a> for help with how to get permission to use them.
                                        </Typography>
                                    </React.Fragment>
                                }
                            >
                                <InfoRounded sx={{ml:4,color:"#458839",fontSize:"24px",fontWeight:"bolder",cursor:"pointer"}} />
                            </HtmlTooltipComponent>
                        </Typography>
                        <Typography component={"div"} align={"left"} variant="formationLabelSubHeader">
                            Please upload all documents in a .pdf file format only and the file size should be less than 8mb.
                        </Typography>
                        <Controller
                            name="sensitiveWordsSupportingDocFile"
                            control={control}
                            render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                <Button
                                    variant="outlined"
                                    component="label"
                                >
                                    {
                                        value 
                                            ? value.name 
                                            : formationData.sensitiveWordsSupportingDocsFileName 
                                                ? formationData.sensitiveWordsSupportingDocsFileName
                                                : "Upload File" 
                                    }
                                    <input
                                        type="file"
                                        hidden
                                        name={name}
                                        onBlur={onBlur}
                                        ref={ref}
                                        accept=".pdf"
                                        onChange={e => {
                                            const file = e.target.files[0];
                                            const maxFileSize = 8388608; // Max file size 8MB (8 Megabytes)
                                            
                                            if (file.type === "application/pdf") {
                                                if (file.size <= maxFileSize) {
                                                    onChange(file);
                                                    setSensitiveWordsSupportingDocFileChanged(true);
                                                } else {
                                                    enqueueSnackbar("The uploaded file is too large. Please upload a file of size 8MB or less.");
                                                }
                                            } else {
                                                enqueueSnackbar("Please upload a PDF file.");
                                            }
                                        }}
                                    />
                                </Button>
                            )}
                        />
                    </>
                )}
                
                <Typography component={"div"} align={"left"} variant="formationLabelHeader" sx={{mt:2}}>
                    Where will the company be registered?  *
                    <HtmlTooltip title={"Does it matter where my company is registered?"}
                                 message={"You can’t change where the company is registered after you’ve set it up.<br/> If you want to use a Welsh name ending, the company must be registered in Wales (not 'England and Wales')."}/>
                </Typography>
                
                <Controller
                    name="countryOfRegistration"
                    control={control}
                    rules={{required: 'Country of registration is required'}}
                    defaultValue={formationData?.countryOfRegistration}
                    render={({field}) => (
                        <TextField
                            {...field}
                            select
                            variant="outlined"
                            InputLabelProps={{
                                style: { ...theme.inputLabelProps },
                            }}
                            margin="normal"
                            size="small"
                            sx={{
                                width:"50%"
                            }}
                            error={!!formState.errors.countryOfRegistration}
                            helperText={formState.errors.countryOfRegistration ? formState.errors.countryOfRegistration.message : ''}
                        >
                            <MenuItem key="1" value={1}>England and Wales</MenuItem>
                            <MenuItem key="2" value={2}>Northern Ireland</MenuItem>
                            <MenuItem key="3" value={3}>Scotland</MenuItem>
                            <MenuItem key="4" value={4}>Wales</MenuItem>
                        </TextField>
                    )}
                />
                
                
                <Typography component={"div"} align={"left"} variant="formationLabelHeader" sx={{mt:2}}>
                    What is the company's registered email address?  *
                    <HtmlTooltip title={"What will this email address be used for?"}
                                 message={"This is an email address that Companies House can use to contact the company or someone acting on its behalf. It will not be shown on the public register."}/>
                </Typography>

                <Controller
                    name="registeredEmailAddress"
                    control={control}
                    defaultValue={formationData?.registeredEmailAddress}
                    rules={{
                        required: `Company's registered email address is required`,
                        pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: 'Invalid email address',
                        }}}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            variant="outlined"
                            InputLabelProps={{
                                style: { ...theme.inputLabelProps },
                            }}
                            margin="normal"
                            sx={{
                                width:"50%"
                            }}
                            size="small"
                            error={!!formState.errors.registeredEmailAddress}
                            helperText={formState.errors.registeredEmailAddress ? formState.errors.registeredEmailAddress.message : ''}
                        />
                    )}
                />

                <Typography component={"div"} align={"left"} variant="formationLabelHeader" sx={{mt: 2}}>
                    What will the business be doing? *
                    {/*
                        Need to manually add the component here as parsing the html won't allow us to do a target="_blank"
                    */}
                    <HtmlTooltipComponent
                        title={
                            <React.Fragment>
                                <Typography sx={{fontWeight:"bolder",fontSize:"14px"}}>I can’t find my business activity, what do I do?</Typography>
                                <Typography sx={{fontSize:"14px"}}>
                                    Search using keywords that best describe what your company does. If you can’t find an exact match, you can check the list of <a href='https://resources.companieshouse.gov.uk/sic/' target='_blank'>trade descriptions</a>, also known as Standard Industrial Classification codes.
                                </Typography>
                            </React.Fragment>
                        }
                    >
                        <InfoRounded sx={{ml:4,color:"#458839",fontSize:"24px",fontWeight:"bolder",cursor:"pointer"}} />
                    </HtmlTooltipComponent>
                </Typography>
                <Typography component={"div"} align={"left"} variant="formationLabelSubHeader">
                    Start typing a keyword to see a list of business activities.
                </Typography>
                
                <Controller
                    name="sicCodes"
                    control={control}
                    defaultValue={formationData?.sicCodes || []}
                    rules={{ required: 'Please select at least 1 sic code',maxLength: {value: 4, message: 'You can select up to 4 sic codes' }}}
                    render={({ field: { onChange, value }, }) => (
                        <Autocomplete
                            multiple
                            sx={{mt: 2}}
                            options={sicCodes}
                            getOptionLabel={(option) => `${option.code} - ${option.description}`}
                            defaultValue={formationData?.sicCodes?.map(option => option.id) || []}
                            value={value.map((id) => sicCodes.find((option) => option.id === id))}
                            onChange={(_, data) => {
                                if (data.length >= value.length) {
                                    const newItem = data[data.length - 1];

                                    const isDuplicate = value.some(item => item === newItem.id);

                                    if (!isDuplicate) {
                                        if (data.length <= 4) {
                                            onChange(data.map(option => option.id));
                                            clearErrors('sicCodes');
                                        } else {
                                            setError('sicCodes', {
                                                type: 'manual',
                                                message: 'You can only select up to 4 SIC codes'
                                            });
                                        }
                                    } else {
                                        setError('sicCodes', {
                                            type: 'manual',
                                            message: 'You have already selected this SIC code'
                                        });
                                    }
                                } else {
                                    onChange(data.map(option => option.id));
                                }
                            }}
                            renderInput={(params) => (
                                <div>
                                    <TextField
                                        {...params}
                                        label="Sic Codes"
                                        placeholder=""
                                        error={!!formState.errors.sicCodes}
                                        helperText={formState.errors.sicCodes ? formState.errors.sicCodes.message : ''}
                                    />
                                </div>
                            )}
                        />
                    )}
                />
                <Stack direction="row" spacing={2} sx={{mt: 2}}>
                    <Button variant="contained" color="secondary" onClick={(e) =>{
                        parentBack();
                    }}>Back</Button>
                    <Button variant="contained" color="secondary" type="submit">Confirm & Continue</Button>
                </Stack>
            </form>
        );
    }


    return (
        <Box maxWidth={"100vh"}>
            {content}
        </Box>
    );
}