import * as React from 'react';
import Typography from "@mui/material/Typography";
import {FIND_COMPANIES_MAIL_SETTINGS_RESULT_TYPE} from "../enums";
import SideBarCompanyCard from "./SideBarCompanyCard";
import SideBarPostBoxCard from "./SideBarPostBoxCard";
import SideBarPersonCard from "./SideBarPersonCard";


export default function CompanySearchResultsSideBar({companies, setSelectedCompanyId, setSelectedPersonName, setDisplayNoCompanySelectedError, selectedRowId, setSelectedRowId, setPostageFee, setHandlingFee, setCanSelectedCompanyCheckInMail, selectedMailType, setAvailableActions}) {

    const handleCardClick = (company) => {
        if(selectedRowId == "" || selectedRowId != company.id){
            setSelectedRowId(company.id);
            setSelectedCompanyId(company.companyId);
            if(company.personName != null){
                setSelectedPersonName(company.personName);
            }
            setDisplayNoCompanySelectedError(false);
            if (company.mailForwarding === true) { // If MAIL FORWARDING is not NO
                setPostageFee(company.clientDefaultMailPostageFee);
                setHandlingFee(company.clientDefaultMailHandlingFee);
            } else {
                setPostageFee("");
                setHandlingFee("");
            }
            setCanSelectedCompanyCheckInMail(company.canCheckInMail);   
            setAvailableActions(company.availableActions);
        }
        else{
            setSelectedRowId("");
            setSelectedCompanyId("");
            setSelectedPersonName("");
            setDisplayNoCompanySelectedError(false);
            setPostageFee("");
            setHandlingFee("");
            setCanSelectedCompanyCheckInMail(false);
            setAvailableActions({});
        }
    };
    
    

    
    return(
        <>
            {
                (companies && companies.length > 0) ?
                    companies.map(company => {
                        return (
                            <>
                                {company.resultType == FIND_COMPANIES_MAIL_SETTINGS_RESULT_TYPE.COMPANY &&
                                    <SideBarCompanyCard company={company} selectedRowId={selectedRowId}
                                                        handleCardClick={handleCardClick} selectedMailType={selectedMailType}/>
                                }
                                {company.resultType == FIND_COMPANIES_MAIL_SETTINGS_RESULT_TYPE.PERSON &&
                                    <SideBarPersonCard company={company} selectedRowId={selectedRowId}
                                                        handleCardClick={handleCardClick} selectedMailType={selectedMailType}/>
                                }
                                {company.resultType == FIND_COMPANIES_MAIL_SETTINGS_RESULT_TYPE.POST_BOX &&
                                    <SideBarPostBoxCard company={company} selectedRowId={selectedRowId}
                                                        handleCardClick={handleCardClick} selectedMailType={selectedMailType}/>
                                }
                            </>
                        )
                    })
                    :
                    <Typography variant="body1" sx={{ml: 4, mt:4}}>No results found.</Typography>
            }
        </>
    );
}