import React, {useContext, useEffect, useState} from 'react'
import {
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    MenuItem, Stack
} from "@mui/material";
import { useNavigate } from 'react-router-dom';
import {enqueueSnackbar} from "notistack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AuthContext from "../api-authorization/AuthContext";
import NavigationContext from "../layout/NavigationContext";
import {DataGridPro, GridToolbarContainer, GridToolbarExport} from "@mui/x-data-grid-pro";
import reportService from "./ReportService";
import Button from "@mui/material/Button";
import clientService from "../clients/ClientService";
import TextField from "@mui/material/TextField";

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarExport />
        </GridToolbarContainer>
    );
}

export default function ListPaymentDisputes() {
    const { histNavigate,back } = useContext(NavigationContext);
    const { antiForgeryToken, refreshUser} = useContext(AuthContext);
    const [isLoading, setIsLoading] = React.useState(true);
    const [isClientDataLoading, setIsClientDataLoading] = React.useState(true);
    const [selectedClientId, setSelectedClientId] = useState("");
    
    const navigate = useNavigate();
    const [paymentDisputes, setPaymentDisputes] = useState();
    const [clientData, setClientData] = useState();
    
    const [dataNotFoundDialogOpen, setDataNotFoundDialogOpen] = React.useState(false);

    const handleDataNotFoundDialogOpen = () => {
        setDataNotFoundDialogOpen(true);
    };

    const handleDataNotFoundDialogClose = () => {
        setDataNotFoundDialogOpen(false);
    };
    
    useEffect(() => {
        fetchPaymentDisputes();
    }, [])

    useEffect(() => {
        async function fetchClientData() {
            const result = await clientService.listClients(antiForgeryToken, "");
            if (result.status === 401) {
                refreshUser();
                navigate('/login');
            } else {
                let data = await result.json();
                if (result.ok) {
                    setClientData(data.clients);

                } else {
                    enqueueSnackbar(data.description);
                }
            }
            setIsClientDataLoading(false);
        }
        fetchClientData();
    }, [])

    async function fetchPaymentDisputes() {
        const result = await reportService.findPaymentDisputes(antiForgeryToken, selectedClientId);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        } else {
            let data = await result.json();
            if (result.ok) {
                setPaymentDisputes(data.paymentDisputes);
            } else {
                enqueueSnackbar(data.description);
            }
        }
        setIsLoading(false);
    }

    const dateFormatter = (params) => {
        const date = new Date(params.value);
        if (isNaN(date.getTime()) || date.getFullYear() <= 1) {
            return "None";
        }
        return date.toLocaleDateString('en-GB');
    };
    
    const handleRowClick = (params) => {
        if(params.row.resourceId === null || 
            params.row.resourceId === undefined ||
            params.row.subscriptionId === null ||
            params.row.subscriptionId === undefined ||
            params.row.resourceType === 0) 
        {
            handleDataNotFoundDialogOpen();
        }
        else{
            window.open(`/Billing/Subscription/${params.row.resourceType}/${params.row.resourceId}/${params.row.subscriptionId}`, "_blank");
        }
    };
    
    let content;
    if (isLoading || isClientDataLoading) {
        content = (
            <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                <CircularProgress />
            </Box>
        );
    } else {
        content = (
            <Box sx={{height: '80vh'}}>
                <Box sx={{position: 'sticky', top: 0, zIndex: 1}}>
                    <Typography variant="h5" noWrap component="div" align="left" sx={{mb: 4}}>
                        Payment Defaults{' '}
                        <Box component="span" sx={{fontStyle: 'italic', pt: 0.25, fontSize: '0.875rem'}}>
                            - {paymentDisputes.length} Records Returned
                        </Box>
                    </Typography>
                </Box>
                <Stack direction="row" spacing={2} marginTop={4} sx={{width: "80%"}}>
                    <TextField
                        select
                        id="clientId"
                        value={selectedClientId}
                        onChange={(e) => {
                            setSelectedClientId(e.target.value);
                        }}
                        label="Client"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        size="small"
                        sx={{width:"35%"}}
                    >
                        {clientData.map((client) => (
                            <MenuItem key={client.id} value={client.id} selected={selectedClientId === client.id}>
                                {client.companyName}
                            </MenuItem>
                        ))}
                    </TextField>
                    <Button variant="contained" color="secondary" onClick={(e) => {
                        fetchPaymentDisputes();
                    }}>
                        Search
                    </Button>
                </Stack>
                    <br/>
                    <DataGridPro
                        rows={paymentDisputes}
                        columns={[
                            {field: 'invoiceId', headerName: 'Invoice #', flex: 1},
                            {field: 'dueDate', headerName: 'Due Date', flex: 1, valueFormatter: dateFormatter},
                            {field: 'companyName', headerName: 'Company Name', flex: 1},
                            {field: 'productName', headerName: 'Product Name', flex: 1},
                            {field: 'amountDue', headerName: 'Amount', flex: 1},
                            {field: 'paymentMethod', headerName: 'Payment Method', flex: 1},
                            {field: 'failureReason', headerName: 'Failure Reason', flex: 1},
                            {field: 'attemptCount', headerName: 'Attempts', flex: 1},
                            {
                                field: 'ageInDays', headerName: 'Age', flex: 1, renderHeader: (params) => (
                                    <>
                                        <Typography sx={{WebkitTextStroke: "0.75px"}}>Age</Typography>
                                        <Typography variant="caption">&nbsp;(in days)</Typography>
                                    </>
                                ),
                            }
                        ]}
                        hideFooter={true}
                        loading={isLoading}
                        onRowClick={handleRowClick}
                        getRowClassName={() => 'rowCursorPointer'}
                        slots={{
                            toolbar: CustomToolbar,
                        }}
                        sx={{
                            '.MuiDataGrid-columnHeaderTitle': {
                                WebkitTextStroke: '0.75px'
                            },
                        }}
                    />
                    <Dialog
                        open={dataNotFoundDialogOpen}
                        onClose={handleDataNotFoundDialogClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {"Warning"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                We couldn't find a record of this subscription. It may have been removed. Please contact
                                an administrator.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleDataNotFoundDialogClose} autoFocus>Ok</Button>
                        </DialogActions>
                    </Dialog>
            </Box>
    );
    }

    return (
        <>
            {content}
        </>
    );
}
