import React, {useContext, useEffect, useState} from 'react';
import { useForm, Controller } from 'react-hook-form';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {Box, MenuItem, Stack} from "@mui/material";
import {useNavigate} from "react-router-dom";
import Typography from "@mui/material/Typography";
import TransitionAlert from "../layout/TransitionAlert";
import clientService from "./ClientService";
import {useTheme} from "@mui/material/styles";
import {enqueueSnackbar} from "notistack";
import {CLIENT_TABS, ROLES} from "../enums";
import AuthContext from "../api-authorization/AuthContext";
import inputService from "../util/InputService";
import NavigationContext from "../layout/NavigationContext";

export default function CreateClient() {
    const { histNavigate,back } = useContext(NavigationContext);
    const { user, antiForgeryToken, refreshUser} = useContext(AuthContext);
    const {  handleSubmit, control, formState, watch } = useForm();
    const [disableSubmit, setDisableSubmit] = useState(false);
    const navigate = useNavigate();
    const theme = useTheme();
    const watchEmail = watch(`email`);
    
    useEffect(() => {
        if (user.loggedIn == false || user.role !== ROLES.TENANT_ADMIN) {
            navigate('/login');
        }
    }, [user, navigate]);


    const handleFormSubmit  = async (formData) => {
        setDisableSubmit(true);
        formData = inputService.trimFields(formData);
        const result = await clientService.createClient(antiForgeryToken, formData);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                if (data.error) {
                    enqueueSnackbar(data.error.description);
                } else {
                    enqueueSnackbar("Successfully created client");
                }
                histNavigate(`/Client/Update/${data}/${CLIENT_TABS.DETAILS}`, 'Update Client');
            } else {
                enqueueSnackbar(data.description); // Assuming 'error' property in data
            }
        }
        setDisableSubmit(false);
    };

    const validateNoSpaces = (value) => {
        if (/\s/.test(value)) {
            return "Company name must not contain spaces.";
        }
        return true;
    };
    
    return (
        <Box sx={{ width: 600 }}>
            <Box sx={{mb: 2}}>
                <Typography variant="formHeader" noWrap align={"left"}>
                    Register a new client account
                </Typography>
            </Box>
            <TransitionAlert message={`NOTE:   Register a new ${user.tenantFriendlyName} client account`}/>
            <form onSubmit={handleSubmit(handleFormSubmit)}>
                <Controller
                    name="title"
                    control={control}
                    defaultValue={0}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            select
                            label="Title"
                            variant="outlined"
                            InputLabelProps={{
                                style: { ...theme.inputLabelProps },
                            }}
                            margin="normal"
                            fullWidth
                            size="small"
                        >
                            <MenuItem key="0" value={0}>&nbsp;</MenuItem>
                            <MenuItem key="1" value={1}>Mr</MenuItem>
                            <MenuItem key="2" value={2}>Mrs</MenuItem>
                            <MenuItem key="3" value={3}>Ms</MenuItem>
                            <MenuItem key="4" value={4}>Miss</MenuItem>
                            <MenuItem key="5" value={5}>Dr</MenuItem>
                        </TextField>
                    )}
                />

                <Controller
                    name="firstName"
                    control={control}
                    defaultValue=""
                    rules={{ required: 'First Name is required' }}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label="First Name *"
                            variant="outlined"
                            InputLabelProps={{
                                style: { ...theme.inputLabelProps },
                            }}
                            margin="normal"
                            fullWidth
                            size="small"
                            error={!!formState.errors.firstName}
                            helperText={formState.errors.firstName ? formState.errors.firstName.message : ''}
                        />
                    )}
                />

                <Controller
                    name="lastName"
                    control={control}
                    defaultValue=""
                    rules={{ required: 'Last Name is required' }}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label="Last Name *"
                            variant="outlined"
                            InputLabelProps={{
                                style: { ...theme.inputLabelProps },
                            }}
                            margin="normal"
                            fullWidth
                            size="small"
                            error={!!formState.errors.lastName}
                            helperText={formState.errors.lastName ? formState.errors.lastName.message : ''}
                        />
                    )}
                />

                <Controller
                    name="phoneNumber"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label="Phone"
                            variant="outlined"
                            InputLabelProps={{
                                style: { ...theme.inputLabelProps },
                            }}
                            margin="normal"
                            fullWidth
                            size="small"
                        />
                    )}
                />

                <Controller
                    name="companyName"
                    control={control}
                    defaultValue=""
                    rules={{
                        required: 'Company name is required',
                        validate: validateNoSpaces
                    }}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label="Company Name *"
                            variant="outlined"
                            InputLabelProps={{
                                style: { ...theme.inputLabelProps },
                            }}
                            margin="normal"
                            fullWidth
                            size="small"
                            error={!!formState.errors.companyName}
                            helperText={formState.errors.companyName ? formState.errors.companyName.message : ''}
                        />
                    )}
                />

                <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    rules={{
                        required: 'Email is required',
                        pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: 'Invalid email address',
                        },
                    }}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label="Email *"
                            variant="outlined"
                            InputLabelProps={{
                                style: { ...theme.inputLabelProps },
                            }}
                            margin="normal"
                            fullWidth
                            size="small"
                            error={!!formState.errors.email}
                            helperText={formState.errors.email ? formState.errors.email.message : ''}
                        />
                    )}
                />

                <Controller
                    name="repeatEmail"
                    control={control}
                    defaultValue=""
                    rules={{
                        required: 'Repeat Email is required',
                        validate: {
                            emailEqual: value => (value === watchEmail) || "Email's are not identical",
                        },
                    }}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label="Repeat Email *"
                            variant="outlined"
                            InputLabelProps={{
                                style: { ...theme.inputLabelProps },
                            }}
                            margin="normal"
                            fullWidth
                            size="small"
                            error={!!formState.errors.repeatEmail}
                            helperText={formState.errors.repeatEmail ? formState.errors.repeatEmail.message : ''}
                        />
                    )}
                />
                
                <Stack direction="row" spacing={2} marginTop={2}>
                    <Button variant="contained" color="secondary" type="submit" disabled={disableSubmit}>
                        Submit
                    </Button>
                </Stack>
            </form>
        </Box>
    );
}