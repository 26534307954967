import * as React from 'react';
import { useForm } from 'react-hook-form';
import {useContext, useEffect, useState} from "react";
import Button from "@mui/material/Button";
import {Box, CircularProgress, Stack} from "@mui/material";
import {enqueueSnackbar} from "notistack";
import {useNavigate, useParams} from "react-router-dom";
import Typography from "@mui/material/Typography";
import CreateUserForm from "../users/CreateUserForm";
import clientService from "./ClientService";
import AuthContext from "../api-authorization/AuthContext";
import inputService from "../util/InputService";
import {CLIENT_TABS, ROLES, USER_ROLES} from "../enums";
import userService from "../users/UserService";
import NavigationContext from "../layout/NavigationContext";

export default function CreateClientUser() {
    const { histNavigate,back } = useContext(NavigationContext);
    const { antiForgeryToken,user, refreshUser } = useContext(AuthContext);
    const {  handleSubmit, control, formState, watch } = useForm();
    const [clientLoading, setClientLoading] = useState(true);
    const [clientData, setClientData] = useState();
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [isBusy, setBusy] = useState(false);
    const navigate = useNavigate();
    const { clientId } = useParams();

    useEffect(() => {
        if (user.loggedIn == false || (user.role !== ROLES.TENANT_ADMIN && user.role !== ROLES.CLIENT_ADMIN)) {
            refreshUser();
            navigate('/login');
        }
    }, [user, navigate]);

    useEffect(() => {
        async function fetchData() {
            const result = await clientService.getClient(antiForgeryToken, clientId);
            if (result.status === 401) {
                refreshUser();
                navigate('/login');
            }
            else {
                let data = await result.json();
                if (result.ok) {
                    setClientData(data);
                    setClientLoading(false);
                } else {
                    enqueueSnackbar(data.description);
                }
            }
        }
        fetchData();
    }, [])
    
    const handleFormSubmit  = async (formData) => {
        setBusy(true);
        setDisableSubmit(true);
        formData = inputService.trimFields(formData);
        formData.clientId = clientData.id;
        const result = await userService.createUser(antiForgeryToken, formData);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                if(data.error){
                    enqueueSnackbar(data.error.description);
                }
                else{
                    enqueueSnackbar("Successfully created client user");
                }
                if(user.role === ROLES.TENANT_ADMIN){
                    histNavigate(`/Client/Update/${clientId}/${CLIENT_TABS.USERS}`,'Client');
                }
                else{
                    histNavigate('Users/ListClientUsers','User Search');
                }
            } else {
                enqueueSnackbar(data.description);
            }
        }
        setDisableSubmit(false);
        setBusy(false);
    };

    let content;
    if (isBusy || clientLoading) {
        content = (
            <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                <CircularProgress />
            </Box>
        );
    } else {
        content = (
            <Box sx={{ maxWidth: 1200 }}>
                <Typography variant="h5" noWrap align={"left"} sx={{mb: 2}}>
                    User - Add User to {clientData.companyName}
                </Typography>
                <form onSubmit={handleSubmit(handleFormSubmit)}>
                    <CreateUserForm formState={formState} control={control} watch={watch} adminRole={USER_ROLES.CLIENT_ADMIN} standardRole={USER_ROLES.CLIENT_STANDARD} hasActiveAdminUsers={clientData.hasActiveAdminUsers}/>
                    <Stack direction="row" spacing={2} marginTop={2}>
                        <Button variant="contained" color="secondary" onClick={(e) => {
                            back();
                        }}>
                            Back
                        </Button>
                        <Button variant="contained" color="secondary" type="submit" disabled={disableSubmit}>
                            Submit
                        </Button>
                    </Stack>
                </form>
            </Box>
        );
    }

    return (
        <>
            {content}
        </>
    );
}