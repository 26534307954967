export const countries = [
    { code: 'GB_ENG', label: 'England', suggested:true,flagCode:'EN' },
    { code: 'GB_SCT', label: 'Scotland', suggested:true,flagCode:'SC' },
    { code: 'GB_WLS', label: 'Wales', suggested:true,flagCode:'WL' },
    { code: 'GB_NIR', label: 'Northern Ireland', suggested:true,flagCode: 'NI' },
    { code: 'GBR', label: 'United Kingdom', suggested:true,flagCode: 'GB' },
    { code: 'USA', label: 'USA', suggested:true,flagCode: 'GB' },
    { code: 'AUS', label: 'Australia', suggested:true,flagCode: 'GB' },
    { code: 'AUT', label: 'Austria', suggested:true,flagCode: 'GB' },
    { code: 'BEL', label: 'Belgium', suggested:true,flagCode: 'GB' },
    { code: 'CAN', label: 'Canada', suggested:true,flagCode: 'GB' },
    { code: 'HRV', label: 'Croatia', suggested:true,flagCode: 'GB' },
    { code: 'CYP', label: 'Cyprus', suggested:true,flagCode: 'GB' },
    { code: 'CZE', label: 'Czech Republic', suggested:true,flagCode: 'GB' },
    { code: 'DNK', label: 'Denmark', suggested:true,flagCode: 'GB' },
    { code: 'EST', label: 'Estonia', suggested:true,flagCode: 'GB' },
    { code: 'FRA', label: 'France', suggested:true,flagCode: 'GB' },
    { code: 'DEU', label: 'Germany', suggested:true,flagCode: 'GB' },
    { code: 'GRC', label: 'Greece', suggested:true,flagCode: 'GB' },
    { code: 'HUN', label: 'Hungary', suggested:true,flagCode: 'GB' },
    { code: 'IRL', label: 'Ireland', suggested:true,flagCode: 'GB' },
    { code: 'ITA', label: 'Italy', suggested:true,flagCode: 'GB' },
    { code: 'LTU', label: 'Lithuania', suggested:true,flagCode: 'GB' },
    { code: 'NLD', label: 'Netherlands', suggested:true,flagCode: 'GB' },
    { code: 'NZL', label: 'New Zealand', suggested:true,flagCode: 'GB' },
    { code: 'NOR', label: 'Norway', suggested:true,flagCode: 'GB' },
    { code: 'POL', label: 'Poland', suggested:true,flagCode: 'GB' },
    { code: 'PRT', label: 'Portugal', suggested:true,flagCode: 'GB' },
    { code: 'ZAF', label: 'South Africa', suggested:true,flagCode: 'GB' },
    { code: 'ESP', label: 'Spain', suggested:true,flagCode: 'GB' },
    { code: 'SWE', label: 'Sweden', suggested:true,flagCode: 'GB' },
    { code: 'MLT', label: 'Malta', suggested:true,flagCode: 'GB' }
];