import * as React from 'react';
import Box from "@mui/material/Box";
import {
    Autocomplete,
    CircularProgress,
    FormControl,
    FormControlLabel, FormHelperText, Grid, MenuItem,
    Radio,
    RadioGroup, Stack
} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useEffect} from "react";
import {Controller, useForm} from "react-hook-form";
import TextField from "@mui/material/TextField";
import {useTheme} from "@mui/material/styles";
import Button from "@mui/material/Button";
import {countries} from "../util/Countries";
import HtmlTooltip from "../layout/HtmlTooltip";

export default function FormationSecretaryPersonDetails({formationData, createOrUpdateDirectors, directorData, back, directorShareholderType}) {
    const {  handleSubmit, control, formState, setValue } = useForm();
    const theme = useTheme();
    const [isLoading, setIsLoading] = React.useState(false);
    const [useRaCorrespondenceAddress, setUseRaCorrespondenceAddress] = React.useState(directorData?.useRaCorrespondenceAddress || formationData.useRaCorrespondenceAddress);
    
    
    
    let raAddress={
        addressLine1: "123 Fake Street",
        addressLine2: "",
        locality: "London",
        country: "United Kingdom",
        postalCode: "SW1A 1AA"
    }
    
    const confirmAndContinue = async (formData) => {
        if(formData.useRaCorrespondenceAddress === "true") {
            formData.useRaCorrespondenceAddress = true;
        }
        else{
            formData.useRaCorrespondenceAddress = false;
        }
        
        const newFormationData = {
            ...formationData,
        };
        
        if(directorData) {
            newFormationData.directorShareholders = newFormationData.directorShareholders.map(director => {
                if(director.id === directorData.id) {
                    return {
                        ...director,
                        ...formData
                    };
                }
                return director;
            });
        } else {
            formData.type = directorShareholderType;
            newFormationData.directorShareholders.push(formData);
        }
        
        await createOrUpdateDirectors(newFormationData.directorShareholders);
    }

    useEffect(() => {
        setValue("correspondenceAddress.addressLine1", getDefaultAddressField("addressLine1"));
        setValue("correspondenceAddress.addressLine2", getDefaultAddressField("addressLine1"));
        setValue("correspondenceAddress.locality", getDefaultAddressField("locality"));
        setValue("correspondenceAddress.country", getDefaultAddressField("country"));
        setValue("correspondenceAddress.postalCode", getDefaultAddressField("postalCode"));
    }, [useRaCorrespondenceAddress]);


    const getDefaultAddressField = (fieldName) => {
        if(useRaCorrespondenceAddress === true){
            return raAddress[fieldName];
        }
        else if (directorData != null && directorData.correspondenceAddress){
            return directorData.correspondenceAddress[fieldName];
        }
        else{
            return "";
        }
    }

    let content;
    
    let correspondenceAddressSection = (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
                <Controller
                    name="correspondenceAddress.addressLine1"
                    control={control}
                    defaultValue={getDefaultAddressField("addressLine1")}
                    rules={{ required: 'Address Line 1 is required' }}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label="Address Line 1 *"
                            variant="outlined"
                            InputLabelProps={{
                                style: { ...theme.inputLabelProps },
                            }}
                            margin="normal"
                            fullWidth
                            disabled={useRaCorrespondenceAddress === true}
                            size="small"
                            error={!!formState.errors.correspondenceAddress?.addressLine1}
                            helperText={formState.errors.correspondenceAddress?.addressLine1 ? formState.errors.correspondenceAddress.addressLine1.message : ''}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                <Controller
                    name="correspondenceAddress.addressLine2"
                    control={control}
                    defaultValue={getDefaultAddressField("addressLine2")}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label="Address Line 2"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            disabled={useRaCorrespondenceAddress === true}
                            size="small"
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                <Controller
                    name="correspondenceAddress.locality"
                    control={control}
                    defaultValue={getDefaultAddressField("locality")}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label="Town / City"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            disabled={useRaCorrespondenceAddress === true}
                            size="small"
                            error={!!formState.errors.correspondenceAddress?.locality}
                            helperText={formState.errors.correspondenceAddress?.locality ? formState.errors.correspondenceAddress.locality.message : ''}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                <Controller
                    name="correspondenceAddress.country"
                    control={control}
                    defaultValue={getDefaultAddressField("country")}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            select
                            label="Country"
                            variant="outlined"
                            InputLabelProps={{
                                style: { ...theme.inputLabelProps },
                            }}
                            margin="normal"
                            fullWidth
                            disabled={useRaCorrespondenceAddress === true}
                            size="small"
                        >
                            <MenuItem key="0" value={0}>&nbsp;</MenuItem>

                            {countries.map((country) => (
                                <MenuItem key={country.code} value={country.label}>
                                    {country.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    )}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                <Controller
                    name="correspondenceAddress.postalCode"
                    control={control}
                    defaultValue={getDefaultAddressField("postalCode")}
                    rules={{ required: 'Post Code is required' }}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label="Post Code *"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            disabled={useRaCorrespondenceAddress === true}
                            size="small"
                            error={!!formState.errors.correspondenceAddress?.postalCode}
                            helperText={formState.errors.correspondenceAddress?.postalCode ? formState.errors.correspondenceAddress.postalCode.message : ''}
                        />
                    )}
                />
            </Grid>
        </Grid>  
    );

    if (isLoading) {
        content = (
            <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                <CircularProgress />
            </Box>
        );
    }
    else {
        content = (
            <form onSubmit={handleSubmit(confirmAndContinue)}>
                <Typography component={"div"} align={"left"} sx={{mb: 2, mt:4,fontSize:"24px",fontWeight:"bolder"}}>
                    Secretary Details
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={3} md={3}>
                        <Controller
                            name="title"
                            control={control}
                            defaultValue={directorData?.title}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    select
                                    label="Title"
                                    variant="outlined"
                                    InputLabelProps={{
                                        style: { ...theme.inputLabelProps },
                                    }}
                                    margin="normal"
                                    fullWidth
                                    size="small"
                                >
                                    <MenuItem key="0" value={0}>&nbsp;</MenuItem>
                                    <MenuItem key="1" value={1}>Mr</MenuItem>
                                    <MenuItem key="2" value={2}>Mrs</MenuItem>
                                    <MenuItem key="3" value={3}>Ms</MenuItem>
                                    <MenuItem key="4" value={4}>Miss</MenuItem>
                                    <MenuItem key="5" value={5}>Dr</MenuItem>
                                </TextField>
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3} md={3}>
                        <Controller
                            name="firstName"
                            control={control}
                            defaultValue={directorData?.firstName}
                            rules={{ required: 'First Name is required' }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="First Name *"
                                    variant="outlined"
                                    InputLabelProps={{
                                        style: { ...theme.inputLabelProps },
                                    }}
                                    margin="normal"
                                    fullWidth
                                    size="small"
                                    error={!!formState.errors.firstName}
                                    helperText={formState.errors.firstName ? formState.errors.firstName.message : ''}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3} md={3}>
                        <Controller
                            name="middleNames"
                            control={control}
                            defaultValue={directorData?.middleNames}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="Middle Name(s)"
                                    variant="outlined"
                                    InputLabelProps={{
                                        style: { ...theme.inputLabelProps },
                                    }}
                                    margin="normal"
                                    fullWidth
                                    size="small"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3} md={3}>
                        <Controller
                            name="lastName"
                            control={control}
                            defaultValue={directorData?.lastName}
                            rules={{ required: 'Last Name is required' }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="Last Name *"
                                    variant="outlined"
                                    InputLabelProps={{
                                        style: { ...theme.inputLabelProps },
                                    }}
                                    margin="normal"
                                    fullWidth
                                    size="small"
                                    error={!!formState.errors.lastName}
                                    helperText={formState.errors.lastName ? formState.errors.lastName.message : ''}
                                />
                            )}
                        />
                    </Grid>
                </Grid>

            <Typography component={"div"} align={"left"} variant="formationLabelHeader" sx={{mt:2}}>
                Would you like to use our correspondence address?  *
            </Typography>
            <Controller
                    name="useRaCorrespondenceAddress"
                    control={control}
                    defaultValue={directorData === null || directorData.useRaCorrespondenceAddress === null ? null : directorData.useRaCorrespondenceAddress.toString()}
                    rules={{required: `Please select if you'd like to use our Registered Office Address`}}
                    render={({field}) => (
                        <FormControl error={!!formState.errors.useRaCorrespondenceAddress}  
                                     onChange={(e) => {
                                        field.onChange(e);
                                        setUseRaCorrespondenceAddress(e.target.value === "true" ? true : false);
                                     }}
                        >
                            <RadioGroup {...field} row>
                                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                <FormControlLabel value={false} control={<Radio />} label="No" />
                            </RadioGroup>
                            {formState.errors.useRaCorrespondenceAddress && (
                                <FormHelperText>{formState.errors.useRaCorrespondenceAddress.message}</FormHelperText>
                            )}
                        </FormControl>
                    )}
                />
                
                {useRaCorrespondenceAddress === false ? (
                    <>
                        <Typography component={"div"} align={"left"} variant="formationLabelHeader" sx={{mt:2}}>
                            What is the Directors correspondence address?  *
                        </Typography>
                        <Typography component={"div"} align={"left"} variant="formationLabelSubHeader">
                            This address will be publicly available on the Companies House online register. You can use a business or home address and it doesn't have to be in the UK.
                        </Typography>
                        {correspondenceAddressSection}
                    </>
                ): useRaCorrespondenceAddress === true ? (
                    <>
                        <Typography component={"div"} align={"left"} variant="formationLabelHeader" sx={{mt:2}}>
                            This is the Secretary's correspondence address
                        </Typography>
                        <Typography component={"div"} align={"left"} variant="formationLabelSubHeader">
                            This address will be publicly available on the Companies House online register. You can use a business or home address and it doesn't have to be in the UK.
                            <HtmlTooltip title={"Why is this correspondence address showing?"}
                                         message={"You are seeing this address as you have chosen to subscribe to the Registered Agent service. This service ensures that your home address is kept private and off of the Companies House public register.<br/<br/>You cannot remove a Directors address from a company’s record after incorporation."}/>
                        </Typography>
                        {correspondenceAddressSection}
                    </>
                ) : null}
                <br/>
                <Stack spacing={2} direction="row" sx={{mt: 2}}>
                    <Button variant="contained" color="secondary" sx={{mt: 4}} onClick={(e) => {
                        back();
                    }}>Back</Button>
                    <Button variant="contained" color="secondary" sx={{mt: 4}} type="submit"
                    disabled={useRaCorrespondenceAddress === null}
                    >Confirm & Continue</Button>
                </Stack>
            </form>
        );
    }


    return (
        <Box maxWidth={"100vh"}>
            {content}
        </Box>
    );
}