import * as React from 'react';
import Box from "@mui/material/Box";
import {
    Checkbox,
    CircularProgress,
    FormControlLabel,
     Stack,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import {
    COMPANY_FORMATION_TABS,
    FORMATION_DIRECTOR_SHAREHOLDER_TYPE
} from "../enums";
import {Controller, useForm} from "react-hook-form";
import {useTheme} from "@mui/material/styles";
import Button from "@mui/material/Button";
import {FormGroup} from "reactstrap";
import {useEffect} from "react";
import TextField from "@mui/material/TextField";
import {enqueueSnackbar} from "notistack";

export default function FormationElectronicSignature({formationData, createOrUpdateFormationData,parentBack}) {
    const {  handleSubmit, control, formState, setValue, trigger } = useForm();
    const theme = useTheme();
    const [isLoading, setIsLoading] = React.useState(true);
    const [checkBoxes, setCheckBoxes] = React.useState({});
    const [selectedDirectorType, setSelectedDirectorType] = React.useState(FORMATION_DIRECTOR_SHAREHOLDER_TYPE.NONE);
    const [directorType, setDirectorType] = React.useState(FORMATION_DIRECTOR_SHAREHOLDER_TYPE.NONE);

    useEffect(() => {
        const newCheckBoxes = {};
        formationData.directorShareholders.filter(director => director.numberOfShares !== null && director.numberOfShares > 0).forEach((director) => {
            newCheckBoxes[director.id] = {
                townOfBirth: {checked: director.authQuestionTownOfBirthSelected, answer: director.authQuestionTownOfBirthAnswer},
                mothersMaidenName: {checked: director.authQuestionMothersMaidenNameSelected, answer: director.authQuestionMothersMaidenNameAnswer},
                fathersFirstName: {checked: director.authQuestionFathersFirstNameSelected, answer: director.authQuestionFathersFirstNameAnswer},
                telephoneNumber: {checked: director.authQuestionTelephoneNumberSelected, answer: director.authQuestionTelephoneNumberAnswer},
                nationalInsuranceNumber: {checked: director.authQuestionNationalInsuranceNumberSelected, answer: director.authQuestionNationalInsuranceNumberAnswer},
                passportNumber: {checked: director.authQuestionPassportNumberSelected, answer: director.authQuestionPassportNumberAnswer}
            };
        });
        setCheckBoxes(newCheckBoxes);
        setIsLoading(false);
    }, [formationData]);
    
    
    const confirmAndContinue = async (formData) => {
        const newFormationData = {
            ...formationData,
            completedTab: COMPANY_FORMATION_TABS.ELECTRONIC_SIGNATURE,
        };
        let signaturesValid = true;

        formationData.directorShareholders.filter(director => director.numberOfShares !== null && director.numberOfShares > 0).forEach((director, index) => {
            
            const checkboxNames = [
                `${director.id}_townOfBirthAuthQuestionCheckbox`,
                `${director.id}_mothersMaidenNameAuthQuestionCheckbox`,
                `${director.id}_fathersFirstNameAuthQuestionCheckbox`,
                `${director.id}_telephoneNumberAuthQuestionCheckbox`,
                `${director.id}_nationalInsuranceNumberAuthQuestionCheckbox`,
                `${director.id}_passportNumberAuthQuestionCheckbox`
            ]

            const checkedCheckboxes = checkboxNames.filter(name => formData[name]);

            if (checkedCheckboxes.length !== 3) {
                signaturesValid = false;
                enqueueSnackbar("Please select 3 security questions for " + director.fullName, {variant: 'error'});
                return;  // Exit the function without saving changes if condition not met
            }

            director.authQuestionTownOfBirthSelected = formData[`${director.id}_townOfBirthAuthQuestionCheckbox`];
            director.authQuestionTownOfBirthAnswer = formData[`${director.id}_townOfBirthAuthQuestionAnswer`];
            director.authQuestionMothersMaidenNameSelected = formData[`${director.id}_mothersMaidenNameAuthQuestionCheckbox`];
            director.authQuestionMothersMaidenNameAnswer = formData[`${director.id}_mothersMaidenNameAuthQuestionAnswer`];
            director.authQuestionFathersFirstNameSelected = formData[`${director.id}_fathersFirstNameAuthQuestionCheckbox`];
            director.authQuestionFathersFirstNameAnswer = formData[`${director.id}_fathersFirstNameAuthQuestionAnswer`];
            director.authQuestionTelephoneNumberSelected = formData[`${director.id}_telephoneNumberAuthQuestionCheckbox`];
            director.authQuestionTelephoneNumberAnswer = formData[`${director.id}_telephoneNumberAuthQuestionAnswer`];
            director.authQuestionNationalInsuranceNumberSelected = formData[`${director.id}_nationalInsuranceNumberAuthQuestionCheckbox`];
            director.authQuestionNationalInsuranceNumberAnswer = formData[`${director.id}_nationalInsuranceNumberAuthQuestionAnswer`];
            director.authQuestionPassportNumberSelected = formData[`${director.id}_passportNumberAuthQuestionCheckbox`];
            director.authQuestionPassportNumberAnswer = formData[`${director.id}_passportNumberAuthQuestionAnswer`];
        });
        
        if(signaturesValid) {
            await createOrUpdateFormationData(newFormationData);
        }
    }

    const countChecked = (directorId) => {
        return Object.values(checkBoxes[directorId])
            .filter((item) => item.checked === true)
            .length;
    };

    const renderCheckBox = React.useCallback((directorId, checkbox, label) => {
        return (
            <>
                <Controller
                    name={`${directorId}_${checkbox}AuthQuestionCheckbox`}
                    control={control}
                    defaultValue={checkBoxes[directorId][checkbox].checked}
                    render={({field}) => (
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox
                                    {...field}
                                    checked={field.value}
                                    disabled={countChecked(directorId) >= 3 && !field.value}
                                    onChange={(e) => {
                                        // Update react-hook-form's form state
                                        field.onChange(e.target.checked);
                                        // Update our local state
                                        setCheckBoxes(prevCheckBoxes => ({
                                            ...prevCheckBoxes,
                                            [directorId]: {
                                                ...prevCheckBoxes[directorId],
                                                [checkbox]:{
                                                    ...prevCheckBoxes[directorId][checkbox],
                                                    checked: e.target.checked
                                                }
                                            }
                                        }));
                                    }}
                                />}
                                label={label}
                            />
                        </FormGroup>
                    )}
                />
                {checkBoxes[directorId][checkbox].checked && (
                    <>
                        <Controller
                            name={`${directorId}_${checkbox}AuthQuestionAnswer`}
                            control={control}
                            defaultValue={checkBoxes[directorId][checkbox].answer}
                            rules={{
                                required: 'Answer is required',
                                minLength: {
                                    value: 3,
                                    message: 'Answer must be at least 3 characters long'
                                },
                                maxLength: {
                                    value: 3,
                                    message: 'Answer must be at most 3 characters long'
                                }
                            }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    variant="outlined"
                                    InputLabelProps={{
                                        style: { ...theme.inputLabelProps },
                                    }}
                                    inputProps={{
                                        maxLength: 3,
                                    }}
                                    sx={{ml:5,mt:0}}
                                    margin="normal"
                                    size="small"
                                    error={!!formState.errors[`${directorId}_${checkbox}AuthQuestionAnswer`]}
                                    helperText={formState.errors[`${directorId}_${checkbox}AuthQuestionAnswer`] ? formState.errors[`${directorId}_${checkbox}AuthQuestionAnswer`].message : ''}
                                />
                            )}
                        />
                    </>
                )}
            </>

        );
    }, [checkBoxes,formState.errors]);
    
    
    let content;
    
    if (isLoading) {
        content = (
            <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                <CircularProgress />
            </Box>
        );
    }
    else {
        
        if(directorType === FORMATION_DIRECTOR_SHAREHOLDER_TYPE.NONE) {
            content = (
                <form onSubmit={handleSubmit(confirmAndContinue)}>
                    <Typography component={"div"} align={"left"}
                                sx={{mb: 2, mt: 4, fontSize: "24px", fontWeight: "bolder"}}>
                        Electronic Signatures
                    </Typography>
                    <Typography component={"div"} align={"left"} variant="formationLabelSubHeader">
                        Companies House requires answers to three different personal questions for each PSC. They use
                        this as an online signature. Please select three questions and provide the answers for each PSC.
                    </Typography>

                    {formationData.directorShareholders.filter(director => director.numberOfShares !== null && director.numberOfShares > 0).map((director) => {
                        return (
                            <>
                                <Typography component={"div"} align={"left"} variant="formationLabelHeader"
                                            sx={{mt: 2}}>
                                    {director.fullName}
                                </Typography>
                                <Typography component={"div"} align={"left"} variant="formationLabelSubHeader">
                                    Please choose 3 out of 6 answers for the following questions
                                </Typography>
                                {renderCheckBox(director.id, 'townOfBirth', 'Town of birth (First 3 letters only)')}
                                {renderCheckBox(director.id, 'mothersMaidenName', "Mother's maiden name (First 3 letters only)")}
                                {renderCheckBox(director.id, 'fathersFirstName', "Father's first name (First 3 letters only)")}
                                {renderCheckBox(director.id, 'telephoneNumber', 'Telephone number (Last 3 digits only)')}
                                {renderCheckBox(director.id, 'nationalInsuranceNumber', 'National Insurance number (Last 3 digits only)')}
                                {renderCheckBox(director.id, 'passportNumber', 'Passport number (Last 3 digits only)')}
                            </>
                        );

                    })}

                    <br/>
                    <Stack direction="row" spacing={2} sx={{mt: 2}}>
                        <Button variant="contained" color="secondary" onClick={(e) => {
                            parentBack();
                        }}>Back</Button>
                        <Button variant="contained" color="secondary" sx={{mt: 4}} type="submit">Confirm &
                            Continue</Button>
                    </Stack>
                </form>
            );
        }
    }


    return (
        <Box maxWidth={"100vh"}>
            {content}
        </Box>
    );
}