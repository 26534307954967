import * as React from 'react';
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import {
    Business,
    Dashboard, FolderOpen,
    Groups, MailOutline,
    PersonOutlined
} from "@mui/icons-material";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import {useContext} from "react";
import AuthContext from "../../api-authorization/AuthContext";
import Box from "@mui/material/Box";
import {useTheme} from "@mui/material/styles";
import NavigationContext from "../NavigationContext";
export default function AccountMenu({open, setOpen, isMobile}) {
    const { freshHistNavigate,back } = useContext(NavigationContext);
    const theme = useTheme();
    const { user } = useContext(AuthContext);

    return(
        <Box display="flex" flexDirection="column" sx={{ height: '90vh'}}>
            <Box>
            <ListItem key="home" disablePadding disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                    sx={{
                        minHeight: 48,
                        justifyContent: 'initial',
                        px: 2.5,
                    }}
                    onClick={(e) => {
                        e.preventDefault();
                        if(isMobile) {
                            setOpen(false);
                        }
                        freshHistNavigate("/");
                    }}>
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: open ? 3 : 0,
                            justifyContent: 'initial',
                        }}
                        style={{ color: '#fff' }}>
                        <Dashboard />
                    </ListItemIcon>
                    <ListItemText primary={<Typography style={{ color: 'white', fontWeight: 600, fontSize:15}}>Home</Typography>}  sx={{ display: open ? "block" : "none" }}/>
                </ListItemButton>
            </ListItem>
            
            
            <ListItem key="myAccount" disablePadding>
                <ListItemButton
                    sx={{
                        minHeight: 48,
                        justifyContent: 'initial',
                        px: 2.5,
                    }}
                    onClick={(e) => {
                    e.preventDefault();
                    if(isMobile) {
                        setOpen(false);
                    }
                    freshHistNavigate(`/Account/Update/${user.accountId}/0`,'Update Account');
                }}>
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: open ? 3 : 0,
                            justifyContent: 'initial',
                        }}
                        style={{ color: '#fff' }}>
                        <PersonOutlined />
                    </ListItemIcon>
                    <ListItemText primary={<Typography style={{ color: 'white', fontWeight: 600, fontSize:15}}>My Account</Typography>} sx={{ display: open ? "block" : "none" }}/>
                </ListItemButton>
            </ListItem>
                

            {user.multipleCompanies === true || !user.companyId ? (
                <ListItem key="company" disablePadding>
                    <ListItemButton
                        sx={{
                            minHeight: 48,
                            justifyContent: 'initial',
                            px: 2.5,
                        }}
                        onClick={(e) => {
                        e.preventDefault();
                        if(isMobile) {
                            setOpen(false);
                        }
                        freshHistNavigate("/Company/MyCompanies");
                    }}>
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: open ? 3 : 0,
                                justifyContent: 'initial',
                            }}
                            style={{ color: '#fff' }}>
                            <Business />
                        </ListItemIcon>
                        <ListItemText primary={<Typography style={{ color: 'white', fontWeight: 600, fontSize:15}}>My Companies</Typography>} sx={{ display: open ? "block" : "none" }}/>
                    </ListItemButton>
                </ListItem>
            ) : (
                <ListItem key="company" disablePadding>
                    <ListItemButton
                        sx={{
                            minHeight: 48,
                            justifyContent: 'initial',
                            px: 2.5,
                        }}
                        onClick={(e) => {
                        e.preventDefault();
                        if(isMobile) {
                            setOpen(false);
                        }
                        freshHistNavigate(`/Company/Update/${user.companyId}/0`);
                    }}>
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: open ? 3 : 0,
                                justifyContent: 'initial',
                            }}
                            style={{ color: '#fff' }}>
                            <Business />
                        </ListItemIcon>
                        <ListItemText primary={<Typography style={{ color: 'white', fontWeight: 600, fontSize:15}}>My Company</Typography>} sx={{ display: open ? "block" : "none" }}/>
                    </ListItemButton>
                </ListItem>
            )}

            <ListItem key="myMail" disablePadding>
                <ListItemButton
                    sx={{
                        minHeight: 48,
                        justifyContent: 'initial',
                        px: 2.5,
                    }}
                    onClick={(e) => {
                    e.preventDefault();
                    if(isMobile) {
                        setOpen(false);
                    }
                    freshHistNavigate("/Mail/ListMyMail",'Mail Search');
                }}>
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: open ? 3 : 0,
                            justifyContent: 'initial',
                        }}
                        style={{ color: '#fff' }}>
                        <MailOutline />
                    </ListItemIcon>
                    <ListItemText primary={<Typography style={{ color: 'white', fontWeight: 600, fontSize:15}}>My Mail</Typography>} sx={{ display: open ? "block" : "none" }}/>
                </ListItemButton>
            </ListItem>

            <ListItem key="myTeam" disablePadding>
                <ListItemButton
                    sx={{
                        minHeight: 48,
                        justifyContent: 'initial',
                        px: 2.5,
                    }}
                    onClick={(e) => {
                    e.preventDefault();
                    if(isMobile) {
                        setOpen(false);
                    }
                    freshHistNavigate("/Users/MyTeam",'Team');
                }}>
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: open ? 3 : 0,
                            justifyContent: 'initial',
                        }}
                        style={{ color: '#fff' }}>
                        <Groups />
                    </ListItemIcon>
                    <ListItemText primary={<Typography style={{ color: 'white', fontWeight: 600, fontSize:15}}>My Team</Typography>} sx={{ display: open ? "block" : "none" }}/>
                </ListItemButton>
            </ListItem>
                
        </Box>
        {open === true && (
            <Box mt="auto" p={2} >
                <Typography style={{ color: 'white', fontWeight: 600, fontSize:10}}>
                    {theme.menuFooterText}
                </Typography>
            </Box>
        )}
    </Box>
    );
}