
const companyNameSearch  = async (antiForgeryToken, companyNameSearchRequest) => {
    return fetch(`api/Formations/CompanyNameSearch`, {
        method: 'POST',
        headers: buildAuthHeaders(antiForgeryToken),
        body: JSON.stringify(companyNameSearchRequest)
    });
};

const createFormation  = async (antiForgeryToken, createFormationRequest) => {
    return fetch(`api/Formations/CreateFormation`, {
        method: 'POST',
        headers: buildAuthHeaders(antiForgeryToken),
        body: JSON.stringify(createFormationRequest)
    });
};

const updateFormation  = async (antiForgeryToken, updateFormationRequest) => {
    return fetch(`api/Formations/UpdateFormation`, {
        method: 'PATCH',
        headers: buildAuthHeaders(antiForgeryToken),
        body: JSON.stringify(updateFormationRequest)
    });
};

const submitFormation  = async (antiForgeryToken, formationId) => {
    return fetch(`api/Formations/SubmitFormation/${formationId}`, {
        method: 'POST',
        headers: buildAuthHeaders(antiForgeryToken)
    }); 
};

const getFormation  = async (antiForgeryToken, companyFormationId) => {
    return fetch(`/api/Formations/GetFormation/${companyFormationId}`, {
        method: 'GET',
        headers: buildAuthHeaders(antiForgeryToken)
    });
};

const listSicCodes  = async (antiForgeryToken) => {
    return fetch(`/api/Formations/ListSicCodes`, {
        method: 'GET',
        headers: buildAuthHeaders(antiForgeryToken)
    });
};

async function uploadSensitiveWordsDocument(antiForgeryToken, formData) {
    return fetch(`/api/Formations/UploadSensitiveWordsDocument`, {
        method: 'POST',
        body: formData,
        headers: buildFormAuthHeaders(antiForgeryToken)
    });
}

function buildFormAuthHeaders(antiForgeryToken){
    return !antiForgeryToken ? {} : {
        [antiForgeryToken.tokenName] : antiForgeryToken.token
    };
}

const listFormations  = async (antiForgeryToken, findFormationsRequest) => {
    return fetch(`api/Formations/FindFormations`, {
        method: 'POST',
        body: JSON.stringify(findFormationsRequest),
        headers: buildAuthHeaders(antiForgeryToken)
    });
};

function buildAuthHeaders(antiForgeryToken) {
    return !antiForgeryToken ? {} : {
        [antiForgeryToken.tokenName] : antiForgeryToken.token,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    };
}

const formationService = Object.freeze({
    companyNameSearch,
    createFormation,
    updateFormation,
    getFormation,
    listSicCodes,
    submitFormation,
    uploadSensitiveWordsDocument,
    listFormations
});
export default formationService;