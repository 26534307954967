import React, {useContext, useEffect, useState} from 'react'
import {CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import { useNavigate } from 'react-router-dom';
import {enqueueSnackbar} from "notistack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AuthContext from "../api-authorization/AuthContext";
import NavigationContext from "../layout/NavigationContext";
import {DataGridPro, GridToolbarContainer, GridToolbarExport} from "@mui/x-data-grid-pro";
import reportService from "./ReportService";
import Button from "@mui/material/Button";
import {COMPANY_TABS} from "../enums";

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarExport />
        </GridToolbarContainer>
    );
}

export default function ListVirtualSquatters() {
    const { histNavigate,back } = useContext(NavigationContext);
    const { antiForgeryToken, refreshUser} = useContext(AuthContext);
    const [isLoading, setIsLoading] = React.useState(true);
    const navigate = useNavigate();
    const [virtualSquatters, setVirtualSquatters] = useState();
    const [dataNotFoundDialogOpen, setDataNotFoundDialogOpen] = React.useState(false);

    const handleDataNotFoundDialogOpen = () => {
        setDataNotFoundDialogOpen(true);
    };

    const handleDataNotFoundDialogClose = () => {
        setDataNotFoundDialogOpen(false);
    };
    
    useEffect(() => {
        fetchVirtualSquatters();
    }, [])

    async function fetchVirtualSquatters() {
        const result = await reportService.findVirtualSquatters(antiForgeryToken);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        } else {
            let data = await result.json();
            if (result.ok) {
                setVirtualSquatters(data.virtualSquatters);
            } else {
                enqueueSnackbar(data.description);
            }
        }
        setIsLoading(false);
    }

    const dateFormatter = (params) => {
        const date = new Date(params.value);
        if (isNaN(date.getTime()) || date.getFullYear() <= 1) {
            return "None";
        }
        return date.toLocaleDateString('en-GB');
    };

    const handleRowClick = (params) => {
        if(params.row.resourceId === null ||
            params.row.resourceId === undefined ||
            params.row.resourceType === 0)
        {
            handleDataNotFoundDialogOpen();
        }
        else{
            window.open(`/Company/Update/${params.row.resourceId}/${COMPANY_TABS.DETAILS}`, "_blank");
        }
    };

    let content;
    if (isLoading) {
        content = (
            <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                <CircularProgress />
            </Box>
        );
    } else {
        content = (
            <Box sx={{height: '80vh'}}>
                <Box sx={{ position: 'sticky', top: 0, zIndex: 1, background: '#fff' }}>
                    <Typography variant="h5" noWrap component="div" align={"left"} sx={{mb:4}}>
                        Virtual Squatters
                    </Typography>
                </Box>
                <br />
                <DataGridPro
                    rows={virtualSquatters}
                    columns={[
                        { field: 'number', headerName: 'Company Number', flex: 1},
                        { field: 'name', headerName: 'Company Name', flex: 1},
                        { field: 'registeredAddress', headerName: 'Registered Address', flex: 1},
                        { field: 'incorporationDate', headerName: 'Incorporation Date', flex: 1, valueFormatter: dateFormatter },
                        { field: 'companyCategory', headerName: 'Company Category', flex: 1 },
                        { field: 'status', headerName: 'Company Status', flex: 1 },
                        { field: 'accountsCategory', headerName: 'Accounts Category', flex: 1 },
                        { field: 'processDate', headerName: 'Date Added', flex: 1, valueFormatter: dateFormatter},
                        { field: 'clientName', headerName: 'Client Name', flex: 1},
                    ]}
                    onRowClick={handleRowClick}
                    getRowClassName={() => 'rowCursorPointer'}
                    hideFooter={true}
                    loading={isLoading}
                    slots={{
                        toolbar: CustomToolbar,
                    }}
                    sx={{
                        '.MuiDataGrid-columnHeaderTitle': {
                            WebkitTextStroke: '0.75px'
                        },
                    }}
                />
                <Dialog
                    open={dataNotFoundDialogOpen}
                    onClose={handleDataNotFoundDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Warning"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            We couldn't find a record of this company. It may have been removed, or it never existed.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDataNotFoundDialogClose} autoFocus>Ok</Button>
                    </DialogActions>
                </Dialog>
            </Box>
        );
    }

    return (
        <>
            {content}
        </>
    );
}
