import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {useContext} from "react";
import AuthContext from "../api-authorization/AuthContext";
import {useTheme} from "@mui/material/styles";
import {useNavigate, useParams} from "react-router-dom";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function TermsAndConditionsTabs() {
    const theme = useTheme();
    const { antiForgeryToken} = useContext(AuthContext);
    const { selectedTab} = useParams();
    const [tabValue, setTabValue] = React.useState(selectedTab ? parseInt(selectedTab) : 0);
    const navigate = useNavigate();
    let content;
    const handleChange = (event, newTabValue) => {
        setTabValue(newTabValue);
    };


    return  (
        <>
            <Typography variant="h5" noWrap component="div" align={"left"} sx={{mb: 2}}>
                Terms And Conditions
            </Typography>
            <Box>
                <Box>
                    <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Terms & Conditions" id={`simple-tab-0`} aria-controls={`simple-tabpanel-0`}/>
                        <Tab label="Privacy Policy" id={`simple-tab-1`} aria-controls={`simple-tabpanel-1`}/>
                        <Tab label="Cookie Policy" id={`simple-tab-1`} aria-controls={`simple-tabpanel-1`}/>
                        <Tab label="Refund & Cancellation Policy" id={`simple-tab-1`} aria-controls={`simple-tabpanel-1`}/>
                    </Tabs>
                </Box>
                <TabPanel value={tabValue} index={0}>
                    Please take the time to read the following Terms and Conditions carefully.

                    onebusiness.co.uk is the Website developed by TheFormationsCompany.com Limited. By using the Site you are deemed to have accepted these terms and conditions outlined below. The Site is aimed at users aged 18 years and over



                    General
                    1.1.         The following definitions apply:

                    1.1.1.     “We”, “our”, “us”, “onebusiness.co.uk ”, and “One Business” means onebusiness.co.uk Limited. “You”, “your” and “user” describes you in the capacity of any business/ formed company or person in the capacity of a business entity with which you are associated or acting on behalf of while visiting TheFormationsCompany.com Limited. “Site” and “Website” refer to onebusiness.co.uk.

                    1.1.2.     “Services” describes the company formation services and/or related materials provided in conjunction with Companies House and additional advertised TFC services, but does not include services provided by authorised agents offered on our website”.

                    1.1.3.     “Renewable Services” are all subscription services, incorporating the Products, provided by Us or a Third Party Product Provider, that require monthly and/or annual renewals.

                    1.1.4.     “Authorised Agents” are third party companies offering services on our website outside of the Services to the business entity/ formed company;

                    1.1.5       “Specific Terms and Conditions” means the Specific Terms and Conditions tailored to cover any Product or Service that you may purchase through the Website and which take priority over these General Terms and Conditions to the extent of any conflict between them.

                    1.2.         Please read these terms and conditions before authorising any payment for services offered by us. By consenting to our terms and conditions means you have agree to be legally bound by them each time you use our services. We reserve the right to modify and amend our website and the terms and conditions when necessary.

                    1.3.         We do not make a full investigation of our clients’ circumstances. Accordingly we will not be liable for any loss or damage, financial or otherwise, which could have been avoided if you took qualified legal or other advice and which was not foreseeable to both parties when you made your order or were not caused by any breach by us.

                    1.4.         We operate a “Fair Use Policy” (FUP) which means the protection of the availability of our Services to our users. Any activity we deem to be detrimental to the provision of our Services will at our discretion be modified, amended and/or terminated without notice.

                    1.4.1.     “Fair Use” under our FUP is defined as any multiple instances or purchases of the Services per user, parent company, address or postcode in any 12 month period and is subject to our discretion.

                    Use of your Personal Information
                    The Data Protection legislation sets out rights over how personal data is used and erasure in specific circumstances. However, we may not always be able to agree with the exercise of such rights, as often personal data remains necessary in relation to the purpose for which it was originally collected and processed. Further information is available in our Privacy Policy which can be viewed on our website at any time.

                    2.1.         What personal information we process

                    The categories of personal data we process include general personal data (which includes normal personal data, personal identity, email addresses and personal financial data) and special categories of personal data if these have been provided to us (which includes nationality and ethnicity)

                    2.2.         We may collect personal information about you in relation to the formed company

                    2.2.1.     From you when you agree to take a service or product from us, in which case this may include your contact details, such as name, address, telephone number(s) and date of birth, how you will pay for the product or service and your bank, credit and/or debit card details

                    2.2.2.     From you when you contact us with an enquiry or in response to a communication from us, in which case, this may tell us something about how you use our services

                    2.2.3.     From documents that are available to the public, such as Experian Credit Reference Database, Companies House and the Full Electoral Roll.

                    2.3.         How we use your personal information

                    When you use our services, the personal data is necessary in relation to the purpose for which it was originally intended. We process personal information to fulfil our contract with the formed company, or where you or we have a legitimate interest in doing so, where otherwise permitted by law, or to comply with applicable law and regulation. We use personal information for:

                    Service Provision and internal processing (i.e. to assess and / or provide and to service the contract)
                    Management of relationship (i.e to develop the formed company’s relationship with us)
                    Training and service review (i.e to help us to enhance our services and quality of the services to our company customers)
                    Statistical analysis and buying behaviour (i.e to help us to enhance our products and services or delivery channels for our company customers to keep costs down)
                    Fulfilling other services and products with our Authorised Agents available on our website (i.e services and products to assist our company customers with the management and performance of their businesses)
                    Complying with legal obligations (i.e to prevent, investigate and prosecute crime, including fraud and money laundering)


                    2.4.         When the Services/ Renewable Services has been completed, we may still process personal information where we have a legitimate interest in doing so, where we are permitted by law, or to complete with applicable laws and regulations. This includes Companies House requests, complaint handling, anti money laundering, reporting to our regulator – HM Revenue and Customs.

                    Our legitimate interests to conduct marketing campaigns to all Companies formed or using our Services, this includes the account holders and the stakeholders in such Companies. Any marketing campaigns will be for services / products (both first and third party) to support the Services supplied to You.



                    2.5.         How We Share the Information

                    We may share data with a range of organisations which enable us to fulfil our contract with the company customer, or where we have legitimate interests to do so, or otherwise are required by applicable law and regulation. We can provide more specific details on written request.

                    Formed Company/ business entity information may be passed onto other companies, organisations with our parent group/ management control for marketing purposes. Such communications are for the formed company/business entities and not for individuals.

                    2.6.         You may amend any information provided to us as part of registration on the Website at any time.

                    2.7.         You have a right to complain to the Information Commissioner’s office (https://ww.ico.org.uk ), which regulates the processing of personal data. You may also seek a judicial remedy.



                    Your Obligations
                    3.1.         We require payment in advance of providing any goods or services and the cost of services ordered will be confirmed at the time of payment.  As most of our business is conducted with business (limited or sole trader) customers all prices are listed exclusive of VAT unless otherwise indicated.  Appropriate VAT is calculated in a populated basket pre-purchase.

                    3.2.         You authorise us, and you hereby appoint us as your agent, to make payment of the registration fees to Companies House that you are obliged to pay to Companies House in respect of the incorporation of your new company(ies), with any such payments treated by us as disbursements made on your behalf, and accordingly shown separately on your invoice.

                    3.3.         We only provide services on the basis that you have given us full and proper instructions and the authority to lawfully carry out those instructions.

                    3.4.         When you are acting on behalf of a person or company other than yourself, you have collected full and proper consent and authority to lawfully act on the behalf of and represent that third party. Further, by using our Services on behalf of a person or company, you are confirming you have undertaken the relevant identification and verification of the person or company and you agree to be holding copies of the documentation upon which we can rely on and you will produce to us if requested.

                    3.5.         You undertake full responsibility to complete all documentation required by law including, but not restricted to, the Inland Revenue and Companies House.

                    3.6.         You undertake to ensure the accuracy and completeness of the information you provide us and accept all liability for the rejection of any documents or services due to inaccuracies or incompleteness on your behalf.

                    3.7.         You accept that it is your responsibility to ensure that any company name you choose is available for registration and can be lawfully used by you. We accept no liability for your choice of name.

                    3.8.         You must ensure that material given to us for our hosting service is free from defamatory matter and does not infringe on any rights.

                    3.9.         You have sole responsibility in all respects for all use of, and for protecting the confidentiality of any username and password that may be given to you or selected by you for use on our website.

                    3.10.      You agree to abide by our Fair Use Policy

                    3.11.      Any breach of our Fair Use Policy means you will be subject to a penalty payment of a minimum of £25.00 plus VAT

                    3.12. You agree not to use these Services in a fraudulent manner or provide any incorrect or fraudulent information; should we have suspicion of such conduct we will cancel Services with immediate effect and there will be no refunds any fees/ payments received.

                    3.13. Where applicable, you accept and adhere to each Third Party Provider’s terms and condition for each Product within any relevant Renewable Service offering.

                    3.14. You shall be solely responsible for procuring and maintaining Your network connections and telecommunications links from Your systems to Our data centres, and all problems, conditions, delays, delivery failures and all other loss or damage arising from or relating to Your network connections or telecommunications links or caused by the internet; and

                    3.15. You shall be solely responsible for ensuring your running operating systems can support the Third Party Providers’ products and services.



                    Our Obligations
                    4.1.         We will exercise reasonable care in compiling our site, use reasonable efforts to make our site available to you at all times and take the steps to endeavour to secure any personal data or credit card information you give us.

                    4.2.         We will try to ensure that our prices displayed on our website are accurate. We reserve the right to alter the prices on our website at any time. If we need to do so, we will inform you if a product’s correct price is higher than that stated in your order. If this is the case you may cancel the order and decide whether or not to order the product at the correct price.

                    4.3.         While we will use reasonable endeavours to meet the time estimates given on our website, these do remain estimates and are not guaranteed.

                    4.4.         We are not obliged to accept any request from you or your representatives, or to continue to perform any service. We reserve the right to reject any request or to discontinue the performance of any service without liability.

                    4.5.         In relation to the purchase of products, we accept no liability for any indirect or consequential loss or damage, or for any loss of data, profit, revenue or business (whether direct or indirect), however caused, if not foreseeable to both parties.

                    4.6.         In relation to company formations, we accept no liability for any fraudulent or unauthorised use for any company held under the TheFormationsCompany.com name and address.

                    4.7.         We do not accept responsibility for the accuracy of any part of any search or other reports where it is apparent that it is not derived from information in a public register, or for any inaccuracy, omission or other error in any public register upon which our search or report is based.

                    4.8.         We may directly or through an intermediary ask another contractor to carry out some or all of any work which you instruct us to carry out for you. Steps will be taken to select the suitability of our agents, although we have no control over their activities and therefore accept no responsibility for the services provided to you by that agent.

                    4.9.         Certain links, including hypertext links, in our site may take you outside our site onto sites operated by other people. Links are provided for your convenience and inclusion of any link does not imply endorsement or approval by us of the linked site, its operator or its content. We take no responsibility for and give no warranties, guarantees or representations in respect of these linked sites. We are not responsible for the content of any website outside our website.

                    4.10.      We reserve the right at any time and without notice to alter any aspect of our sites including the services offered by us.

                    4.11.      We reserve the right at our sole discretion to deny users access to our website or any part of our website without notice and to decline to provide the service to any user that is in breach of the terms and conditions including the Fair Use Policy.

                    4.12.      We will try to make our website available but cannot guarantee that our website will operate continuously or without interruptions or be error free and can accept no liability for its unavailability at any time

                    4.13.      You must not attempt to interfere with the proper working of our website and, in particular, you must not attempt to circumvent security, tamper with, hack into, or otherwise disrupt any computer system, server, website, router or any other internet connected device. We shall not be liable to you for any breach of the terms and conditions or any failure to provide or delay in providing our services through our website resulting from any event or circumstance beyond our reasonable control including, without limitation, breakdown of systems or network access, fire, explosion or accident.

                    4.14.      We shall not be liable to you for any breach of the terms and conditions or any failure to provide or delay in providing our services through our website resulting from any event or circumstance beyond our reasonable control including, but not limited to, breakdown of systems or network access, fire, explosion or accident.

                    4.15.      We cannot and do not guarantee or warrant that any material available for downloading from our website will be free from infection, viruses and/or other code that has contaminating or destructive properties. You are responsible for implementing sufficient procedures and virus checks (including anti-virus and other security checks) to satisfy your particular requirements for the accuracy of data input and output. You are responsible for ensuring your computer system meets all relevant technical specifications necessary to use our website and is compatible with our website.

                    4.16.      While we do our best to ensure that all materials and information published on our website are accurate, we make no representations or warranties about the accuracy, completeness, or suitability for any purpose of the information and related graphics published on our website. The information contained in our website may contain technical inaccuracies or typographical errors and is intended to be a general indication of our services only. Any implied terms including those as to quality, fitness for purpose, compliance with description or sample are excluded unless you deal as a consumer. If you do deal as a consumer these terms do not affect your statutory rights.

                    4.17.      All intellectual property rights (including copyrights, patents, trade marks) where registered or not shall remain our property. This also includes the design, text, graphics and other material on our website and the selection or arrangement thereof are the copyright of us or other third parties. Permission is granted to electronically copy and print in hard copy portions of our site solely in connection with the acquisition of goods or services through our website. Any other use of materials on our website (including reproduction for purposes other than those noted above and alteration, modification, distribution, or republication) without our prior written permission is strictly prohibited.

                    4.18.      Advertisers and sponsors are responsible for ensuring that material submitted for inclusion on the website complies with all legal and regulatory requirements and does not contain any material which is objectionable, including, without limit, information which is defamatory, obscene, threatening or untrue. We do not take any responsibility for any such material or any error in inaccuracy in advertising material.

                    4.19. We are not liable or responsible for your acceptance of any Third Party Providers’ terms and conditions or to comply with the same. It is your responsibility and yours alone.

                    4.20 We reserve the right to make changes to these Terms and Conditions from time to time.



                    How we use cookies
                    5.1.         A cookie is a small file which asks permission to be placed on your computer’s hard drive. Once you agree, the file is added and the cookie helps analyse web traffic or lets you know when you visit a particular site. Cookies allow web applications to respond to you as an individual. The web application can tailor its operations to your needs, likes and dislikes by gathering and remembering information about your preferences.

                    5.2.         We use traffic log cookies to identify which pages are being used. This helps us analyse data about web page traffic and improve our website in order to tailor it to customer needs. We only use this information for statistical analysis purposes.

                    5.3.         Overall, cookies help us provide you with a better website, by enabling us to monitor which pages you find useful and which you do not. A cookie in no way gives us access to your computer or any information about you, other than the data you choose to share with us.

                    5.4.         You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. This may prevent you from taking full advantage of the website.



                    Additional product specific terms and conditions
                    6.1.         Registered Address, Service Address, Registered Agent and Mail Receiving & Forwarding Services

                    6.1.1.     In these conditions, all these services: Register Address, Service Address, Registered Agent and Mail Receiving and Forwarding Services, as Renewable Services will be known as “RA Services”

                    6.1.1.2.     The address used by TFC for the RA Services will be at any address/ premises as sold through the Website or products. We will update you should there be any changes to the address.

                    6.1.1.3.     You are not permitted to use our address at TFC as your trading address; you are only permitted to use the address for Registered Address only.

                    6.1.1.4.     You are only permitted to use our RA Services for any brands which are part of the Company with our prior authority and consent (in which we reserve the right to charge a fee for such consent(s).l Further, failure to seeking consent from us to use the RA Services for brands means we can cancel the Services in accordance with clause 6.1.17

                    6.1.2.     The duty owed by us to you shall consist of either of or all of the following services as might be purchased by you: (a) The receiving from the Post Office Authorities of letters addressed to you and either arranging for the collection thereof by you from our premises during normal office hours or posting same to you.  Such alternative to be at  our sole discretion.

                    6.1.3.1     You agree by accepting these terms and taking the RA services not to carry on any business which could be construed, conceived or interpreted by us or any other party/ authority as illegal, defamatory, immoral or obscene and you further agree with us not to use our address and/or telephone and fax numbers nor the offices / premises, whether directly or indirectly, for any such purpose or purposes as aforesaid.

                    6.1.3.2.     By using our RA Services, you agree to comply with all the legal obligations for your Company for all your websites, communications, signage and social media in accordance with Regulation 24 of the Company, Limited Liability Partnership and Business (Names and Trading Disclosures) Regulations 2015 to disclose the registered company name together with Regulation 25 to show the company’s registered number and registered address. This clause is in conjunction with Clause 6.1.8. We reserve to cancel the RA Service in accordance with Clause 6.1.17 if you fail to adhere to legal requirements for the Company.

                    6.1.4.     You agree not to send or deliver or cause to be sent or to be delivered to our premises any noxious, harmful, illegal, immoral deteriorating, dangerous or bulky material object or thing and in the event of the same being so sent or delivered, TFC’s rights shall be as contained in Clause 6 of these conditions.

                    6.1.5.     TFC shall be entitled to refuse to accept any parcel, chattel, packet or other object other than letters addressed to you unless previous written arrangements have been entered into by you and agreed in writing by us.

                    6.1.6.     In the event of an ordinary parcel, chattel packet or other object other than letters addressed to you being delivered at our RA Service’s address, TFC shall bear no responsibility whatsoever either to the sender or to you or to anyone else.  In the event of you failing to remove same within one month from receiving Notice thereof (of which will be sent to you by the email address supplied shall be deemed sufficient Notice) then we shall be empowered to deal with the same as it thinks fit.

                    6.1.7.     We shall be entitled to charge you handling and storage charges for any parcel, chattel, packet or other object received and/or stored at our address for you under these RA Services.

                    6.1.8.     You acknowledge and agree not to advertise the telephone, telex and fax numbers of our address/ premises without first obtaining our written consent.

                    6.1.9.     Regarding Mail and/or Telephone, Telex and Fax Services, (if any) these terms relates solely to Mail and Messages addressed to the Director, Principal, Firm, Customer or Company named in the application order/ form completed on the Site.

                    6.1.10.   We will not disclose or use the Director/ Company Customer’s private address in any manner other than those agreed to by you in this agreement save in the event that we are obliged to disclose by law or by the order of a court of competent jurisdiction.

                    6.1.11.   You shall reimburse us immediately for all sums of money expended by us pursuant to the Agreement itself or in connection with the sending to you any letter(s), message(s) and in connection with any other services used.

                    6.1.12.   You will fully indemnify TFC against any expenses, costs, claims, damages or penalties incurred by us in connection with this Agreement howsoever occasioned including through defamation, suing or being sued as a result of the breach whatsoever and howsoever committed by you or any third parties.

                    6.1.13.   In the event of you failing to discharge any of your liabilities to us within one week of such payment becoming due you hereby irrevocably authorise TFC to retain any correspondence and any article(s) belonging to you until you make the payment to settle the debts owed to us by you.

                    6.1.14.   In the event of late payment by you, TFC reserves the right to charge interest at the rate of eight (8) per cent above Barclays Bank Base Rate from time to time on a daily basis. Should we need to recover any of our Fees, you will reimburse all our costs and expenses incurred in the collection or recovery of any overdue amount.

                    6.1.15.   And it is also declared that we shall have a general lien on all belongings of your company that may be on our premises and/or for all moneys owed by you to us on any account whatsoever.

                    6.1.16.   The agreement for these RA Services between TFC and you is subject to written Notice of termination to be given by either party by email  and to expire at any time one month after sending the same notification by you to us or by us to you respectively.

                    6.1.17.   In the event of a breach by you of any of these conditions, we reserve the right to  terminate this Agreement forthwith without any explanation whatsoever as to reasons by sending written notice of such termination to you of which will be sent to you by the email address supplied shall be deemed sufficient notice. Should this occur, we will not provide refunds of any cancellations any Services purchased.

                    6.1.18.   The Limit of the liability of TFC in  respect of any act, omission, neglect, delay or default by it or by its servants or agents whether by way of the Law of Contract and/or by way of liability for negligence shall not exceed five (5)  pounds.

                    6.1.19.   You agree that during the period of your booking and for a further period of six (6) months thereafter, you shall not employ any person who has been in the employment of TFC at any time during the period of your booking, provided that, if any such employment or offer of employment is agreed or made and accepted, you shall pay to us a fee equivalent to fifteen (15) per centum of such employee’s current salary.

                    6.1.20.    For the  RA Services, non-payment for renewal of the Service means you  will be deemed irrevocably to have authorised us (and to have irrevocably consented to our so doing) to change with immediate effect the Registered Office Address of the Company to your residential address or to such other address previously notified to us by you for this purpose. This clause is in read in conjunction clauses 6.1.22.1 and 6.1.22.2

                    6.1.20.1. You understand and agree to any mail other than statutory mail from HMRC and Companies House will be returned to sender unless the subscriber has also purchased a London Office Mail Forwarding service and is current and active, if expired mail will also be returned to sender.

                    6.1.20.2.  You agree to pay the amounts agreed according to the terms stipulated by us. Upon sign up, the client will set up an automated payment account with Stripe.com to pay the yearly subscription fee.

                    6.1.21    You agree to pay the amounts agreed according to the terms stipulated by us. Upon sign up, you accept that we will set up an automated payment account with Stripe.com to pay the yearly subscription fee. Cancellation requirements are set within clause 6.2

                    6.1.22.1.   By accepting these terms and conditions, you are granting us authorisation to file with Companies House as an authorised person for, and on behalf of, the company, the statutory forms required to implement the services you are agreeing to take under this agreement for the full term of the agreement. If said services are cancelled, terminated, or shall expire as a result of failing to make the required payment, you are granting us authorisation to file the statutory forms required to terminate these services which includes to change the registered/ service addresses to your last known correspondence/ residential address notified to us by you. We will charge you an administration fee for updating Companies House.

                    6.1.22.2.   The statutory forms that we reserve the right to file include, but are not limited to, the following: AP01, AP02, AP03, AP04, TM01, TM02, SH01, AD01, AD02, CH01 and CH02.

                    6.1.23        At the discretion of the Customer Service Manager, post collection may be arranged, by appointment only with a minimum of 2 business working day notice, between the hours of 10am – 4pm Monday – Friday.

                    6.1.24   Please note even though your company takes RA Services, the Companies House Registrar has statutory rights under the Companies House Act 1986 to service notices to any directors or persons of significant control on any addresses held by them, including your home addresses. Therefore we are not able to confirm or guarantee all correspondence or notices sent from Companies House will be via the RA Service.

                    6.2   Non Payment and Cancellation of RA Services

                    6.2.1.     We will always try and renew your/ the company’s subscription. Non-payment of the RA Services will cause your/ the company’s account to fall into arrears and will trigger a 30 day period in which you must pay and/or update the payment details on the account. After the 30 day period has completed and you have not paid and/or updated payment details then the account will be cancelled.

                    6.2.2.     We reserve the right to recover from you/ the Company any arrears for non payment of the RA Services and any associated costs/ charges we have incurred including recovery costs.

                    6.2.3.     If you/ the Company does not wish to renew the subscription, it is your responsibility to provide a minimum of 30 days notice of cancellation to us together with undertaking the following within the cancellation period:

                    6.2.3.1.     Changing the Registered Office Address for the company on the register at Companies House; and

                    6.2.3.2.     Changing the correspondence for all Directors, Secretaries, Shareholders and the Persons of Significant Control for the company on the register at Companies House.

                    6.2.4.     We cannot process the cancellation of the subscription unless the above steps are completed and are shown at Companies House.

                    6.2.5.     If the Company is dissolved or in any strike off status at Companies House, you are still required to follow our cancellation process. Companies House do not notify us of any change of status of any companies under RA Services. It is you/ the company’s sole responsibility to inform us.

                    6.3   Bespoke Logo Design

                    6.3.1.     Process:  To start the logo design process you will be required to provide information to us including but not necessarily limited to your name, your company’s name, your mailing address, and some further information about your company and industry.   This information once collected or received by us constitutes the design brief (“Design Brief”).  At this point, in a timeframe reasonably communicated to you, we will, at our reasonable discretion, create a number of designs options from which you can select one for further refinement over a maximum of three (3) iterations, and final approval, not to be unreasonably withheld.

                    6.3.2.     Intellectual Property Rights:  All the rights of the logo artwork and logo design created and designed for you will be transferred to you upon final approval, subject to all applicable fees due up to that point being remitted.   In the event that there are any pending payments owed by you then all of the rights in and to the logo design, including any trademark property, will remain vested in, or be transferred to, us.  After receipt of any outstanding fees we do not hold any rights to the logo design created for you, we do not file trademark applications with respect to the logos designed for you, and you are free to register the logo design created for you as a trademark with the appropriate authority.

                    6.3.3.     Delivery:  All logos and iterations thereof are delivered to you electronically ‘as is’ in the following file formats for maximum flexibility; .PNG/.JPEG/.PDF/.AI.  It is your responsibility to ensure, where you are printing your logo, that you double-check the printer colour matching as colours in print format do not always match to colours on computer screens.

                    6.3.4.     Refund Policy:  Full refunds can be offered to you for up to 14 days from purchase where a Design Brief has not been provided.  After 14 days any refunds will be subject to the Administration Charge described in Clause 8.  Where a Design Brief has been provided, irrespective of time since purchase, no refunds can be made with respect to the purchase.

                    6.3.5.     Warranties & Liability:  We warrant that designs are of our own creation and are not copied from, or inspired by, existing designs or trademarks.  We do not warrant that a design the same as or similar to that designed by us, but of which we are not aware at the time of creation, does not exist.  If for some coincidental reason a logo designed by us looks identical to another party’s logo, the sole remedy available to you will be for us to provide you with a free redesign to create a new logo.  It is your responsibility prior to final approval to ensure the logo, designed in consultation with you, does not infringe on any intellectual property rights and you agree to indemnify us against all losses, demands, claims, charges, penalties, fines, damages, costs and expenses arising out of or in connection with your use of the logo.  In addition, where you supply us with an image to include within your logo design you will guarantee the originality of such image(s) and accordingly indemnify us against all losses, demands, claims, charges, penalties, fines, damages, costs and expenses in relation to such image(s).

                    6.4      Anti-Fraud Service:  The Anti-Fraud Service will help stop unauthorised change requests to your registered company, such as a change of registered office or change of directors.  Your company will be monitored and you will be notified immediately by email of any such unauthorised change requests to your registered company.  We do not guarantee that you will be protected from any/all fraudulent activity. If you are a victim of fraud you should file a report with your local law enforcement immediately.

                    6.5     The Persons of Significant Control Register (PSC Register):  The PSC Register is a register of individuals or legal entities that have significant control over a company. Companies need to provide the information on the PSC register to Companies House as part of the new Confirmation Statement (which replaces the Annual Return process). Companies are required by law to store, maintain and make the PSC Register available for inspection.  The PSC Register can never be blank.  We will, on your behalf, store, maintain and provide copies of the PSC Register upon request.  You must inform us immediately of any changes to ensure the PSC Register is kept up to date.  Failure to provide accurate information and failure to comply with notices requiring someone to provide information are criminal offences and may result in a fine and/or a prison sentence of up to two years.

                    6.6     Verification of Identity

                    6.6.1.     We are regulated by the Money Laundering, Terrorist Financing and Transfer of Funds (Information on the Payer) Regulations 2017 (“MLR 2017”) when you choose TFC to provide you with certain Services. We are obligated to carry out a digital ID verification check for the purposes of checking your identity and address as our customers (companies, officers and shareholders).

                    As an online organisation, we use technology to ensure that this legal requirement is satisfied with as little inconvenience to you. To verify the information regarding your identity you provide, we may make searches about you with a Credit Reference Agency or Fraud Prevention Agency; this will include information from the Electoral Roll. The agencies will record the details of the search, whether or not the transaction proceeds through to formation at Companies House.

                    We can ask you to provide us with copies of up-to-date proof of identity during the process online..

                    6.6.2.      We will use these identity documents, together with any electronic check, as detailed

                    above, to satisfy our anti-money laundering requirements. Any documents provided to us will be

                    recorded and copied for audit purposes as part of these requirements.

                    6.6.3.     Please note that any searches and copy documents will be securely maintained on our systems against the formed company for your data and papers in pursuance of our data protection policy (Please see our privacy policy). The uses that will be made of the data will be as follows:

                    to provide confirmation of the identity of the person(s) providing it;
                    to verify the directors/ shareholders of the company when accessing the service, and
                    iii. any requirements by the regulators and/or the fraud prevention agencies (if applicable)

                    6.6.4.     The data will be stored for as long as required by law, which in

                    most cases will be our usual retention period from the end date of the service/ agreement. The data will be stored for longer than this if necessary, such as when litigation has arisen or may be pending, checks have or may become relevant in proceedings.

                    6.6.5.     Should we not be able to successfully verify your identity and address using our electronic ID verification process, we will require you to provide further proof of your identity and address  and/ or certified copies of the original documents to satisfy our Anti-Money Laundering procedures. Failure to comply with any request for such documents within set timescales may result in the cancellation of Services and without refund.

                    6.6.6.     As a Register Agent Provider we have a responsibility to carry out due diligence checks from time to time on our customers (companies, officers and shareholders) to which we provide ongoing services. Should any of our checks result in either the suspicion or the discovery of illegal or unethical activities, we reserve the right to terminate services without notice and without refund.

                    6.7.1.     International Identity Checks: if we require international customers to undertake our International Identity Check service to satisfy the due diligence requirements for the MLR for the services, please note there will be an additional cost incurred which you and/or their representative will be fully responsible for.

                    6.7.2.     The current fee for this International Identity Check will be £12.00 per check per person. This fee is payable by you whether the company formation proceeds to be registered at Companies House or not.

                    6.7.3.     You agree for this fee to be charged and to be taken from your card payment details supplied for the service.

                    6.7.4.     If the company formation does not proceed to being registered at Companies House (for whatever the reason) then you agree and authorise for the International Identity Check fees to be deducted from any monies paid to us already for Services.

                    6.8.    Formation Bundle Subscriptions

                    6.8.1.     These conditions apply to any formation subscription bundles purchased on our site in conjunction with the rest of these terms and conditions. Such Renewable Services will be known as “Formation Bundle” for these terms.

                    6.8.2.     You agree to not exceed the use of the Services and Products within the Formation Bundle package you purchase. If you require additional or top up elements to your Formation Bundle for any Services, you must notify us for such upgrades; you agree for any additional charges for Services to be payable/ added to your subscription payments.

                    6.8.3.1.   We will always renew the Formation Bundle subscription on renewal. Non-payment of the Formation Bundle will cause your/ the company’s account to fall into arrears and will trigger a 30 day period in which you must pay and/or update the payment details on the account. After the 30 day period has completed, and you have not paid and/or updated payment details then we reserve the right for the account will be cancelled.

                    6.8.3.2.   You accept that upon either cancellation of Formation Bundle or failure to pay will mean access to the Services and Products will be prevented and any data can be deleted/ removed without further notice to you.

                    6.8.4.     We reserve the right to recover from you/ the Company any arrears for non payment of the Formation Bundle and any associated costs/ charges we have incurred including recovery costs.

                    6.8.5.     If you/ the Company does not wish to renew the subscription, it is your responsibility to provide a minimum of 30 days notice of cancellation to us. Should your Formation Bundle include RA Services then you will need to also follow the requirements as set out in Clause 6.2.

                    6.8.6.     Any refunds of the subscription will be subject to our Refund Policy.

                    6.8.7.     You shall not access all or any part of the Services in order to build a product or service which competes with the Services.

                    Jurisdiction
                    7.1.         Our relationship with you will be governed by English law and will be subject to the exclusive jurisdiction of the English courts.

                    Refund Policy
                    8.1.         Where no customer actions have taken place post package purchase, and specifically no company application has been submitted to Companies House for review, customers are eligible for a refund subject to the following:

                    8.1.1.      The request for refunds is made within 28 days of the purchase date;

                    8.1.2. An administration charge of the lesser of £15, or the cost of the product or service, will be assessed to cover merchant charges and other incidental expenses including processing the refund (the “Administration Charge”).

                    8.2.         Once a company has been submitted to Companies House no refunds can be given for the Services or in respect to any parts of the package including the Services.

                    8.3.         Refunds for any additional products and services purchased through the site (which are not subscription based) will be offered subject to the following:

                    8.3.1.     The request for refunds is made within 28 days of the purchase date;

                    8.3.2.     Additional products and services were purchased separately to or alongside any package but within a package or as a special offer alongside a package;

                    8.3.3.     We or any third parties sub-contracted by us have not commenced work on the provision of such products or services;

                    8.3.4.     An administration charge of the lesser of £15, or the cost of the product or service, will be assessed to cover merchant charges and other incidental expenses including processing the refund (the “Administration Charge”).

                    8.4.        Renewable Services and Subscription based service refunds will be subject any separate terms of such services but will be subject to a minimum notice terms set within clause 6.2 and any pro rata will be a monthly basis only.

                    8.5.         To request a termination of your services you must notify us immediately by using our website contact form or in writing by post to the registered address displayed on our website.



                    Services and/ or Products provided by Third Parties
                    9.1.         The Products which form part of the Services or the Renewable Services (with the exception of CreditFocus and LegalManager ) are supplied by Third Party Product Providers. As such, additional terms and conditions govern Your use of each Product and it is a requirement of Your subscription to the Renewable Services that You read and accept all additional terms and conditions of each Third Party Product Provider listed below and of CreditFocus prior to using the Services and/or relevant Product.

                    Bonline: https://www.bonline.com/

                    9.1.2.         Please note we do not accept any liability for any loss or damage from you using any of the Third Party Products or by the Third Party Provider ending the product or service. We shall not be liable for any incidental or consequential losses in respect to the Third Party Products.

                    9.2. The Business Bank Account service is provided by Barclays Bank UK PLC and subject to the terms and conditions as set forth by Barclays at the time of account opening.

                    9.3. Where you request a bank account through The Business Bank Account service you confirm that you agree to be contacted directly by Barclays Bank UK PLC for the purposes of fulfilling the bank account request for the formed Company.

                    9.4. Where you request a bank account through The Business Bank Account service on behalf of a third party for whom you are making a company formation application, you confirm that the third party, the primary contact listed on the company formation application, has agreed to be contacted directly by Barclays Bank UK PLC for the purposes of fulfilling the bank account request.

                    9.5.         More information on the Business Bank Account service and terms and conditions related to claiming any associated cash back can be found at http://www.theformationscompany.com/bank-account-information.

                    9.6.         Cash back is not available for customers who have bought the £5 formation offer.

                    9.7.         As part of The Formations Company package that you purchase you may be offered complimentary access to My BusinessWorks which is provided by Business Centric Services Group Limited. As part of the registration process we will pass certain personal information to Business Centric Services Group Limited in order to provide you with this service. If you wish to cancel your MyBusinessWorks subscription then please contact us directly.

                    9.8.         Where you agree / have agreed to find out more about third party products and/or services, you agree that your details may be passed to the third party provider to fulfil legal requirements, for marketing purposes and/or to provide you with new product information that we think may be of interest to you. You acknowledge that TheFormationsCompany.com Ltd. (“TFC”) does not receive any referral fee(s) in respect of such introduction but that TFC may receive income from such third party(s) if you choose to engage in a commercial relationship with such third party service provider(s) after the date of introduction. Where you choose to engage in any form of relationship with a third party that has been introduced by TFC, that relationship shall be governed by the terms and conditions of that third party, and TFC shall have not responsibility or liability with respect to that relationship.



                    Website Disclaimer
                    10.1.      The information contained in this website is for general information purposes only. The information is provided by TheFormationsCompany.com and while we endeavour to keep the information up to date and correct, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability with respect to the website or the information, products, services, or related graphics contained on the website for any purpose. Any reliance you place on such information is therefore strictly at your own risk.

                    10.2.      In no event will we be liable for any loss or damage including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of this website.

                    10.3.      Through this website you are able to link to other websites which are not under the control of TheFormationsCompany.com. We have no control over the nature, content and availability of those sites. The inclusion of any links does not necessarily imply a recommendation or endorse the views expressed within them.

                    10.4.      Every effort is made to keep the website up and running smoothly. However, TheFormationsCompany.com takes no responsibility for, and will not be liable for, the website being temporarily unavailable due to technical issues beyond our control.



                    Copyright notice
                    11.1.      This website and its content is copyright of One Business – © OneBusiness 2024. All rights reserved.

                    11.2.      Any redistribution or reproduction of part or all of the contents in any form is prohibited other than the following:

                    11.2.1.   You may print or download to a local hard disk extracts for your personal and non-commercial use only;

                    11.2.2.   You may copy the content to individual third parties for their personal use, but only if you acknowledge the website as the source of the material;

                    11.2.3.   You may not, except with our express written permission, distribute or commercially exploit the content. Nor may you transmit it or store it in any other website or other form of electronic retrieval system.



                    Accessibility Statement
                    12.1.      TheFormationsCompany.com is committed to making resources and services accessible to as many of our users as possible and where practicable any users with disabilities who need them.

                    12.2.      The design and construction of this site has therefore taken into account current accessibility standards at the time of development.

                    12.3.      We will work with clients and visitors to provide a reasonable accommodation to your needs. If you have a question about the accessibility of any of our services or resources, please contact us at support@theformationscompany.com.



                    Frequent User Program
                    13.1.      The requirements for joining the Frequent User Program (at our discretion) are:

                    13.1.1      You are obligated to comply with all due diligence and requirements under MLR 2017 as set by us under this Program and these terms and conditions; failure to adhere to our MLR 2017 requirements means we reserve the right to refuse the joining of our Program and / or subsequently be removed from the Program.

                    13.1.2 We are obligated to carry out a digital ID verification check for the purposes of checking you and/or your professional business’s identity and address. You and/ or your organisation agree to our due diligence requirements to use this Program.

                    13.1.3 We perform electronic ID checks to verify all directors and shareholders of any company formation application to Companies House. Any applications submitted by you which require further due diligence; you agree to provide all relevant information to our requests to satisfy the same. If the due diligence is not satisfied to our requirements, we will not proceed in providing the Services to you.

                    13.2 Professional Frequent Users:

                    13.2.1 If you are a business or a provider which  is deemed a “Relevant Person” under the MLR  2017, are subject to the same Money Laundering regulations as us and are on this Program, we may (at our discretion) accept to rely on  your own ID checks and /or verification in accordance with the MLR and our requirements.

                    13.3 If this reliance option is made available to you under this Program, it is on the follow strict requirements:

                    13.3.1 You hold your customers’ relevant identification documents and have completed Customer Due Diligence (CDD) as defined under regulation 27 of the MLR.

                    13.3.2 You agree and accept that we can place reliance on your CDD measures for our own CDD requirements for the services in accordance with regulation 39 of the MLR.

                    13.3.3 You agree to hold a copy of such CDD information to our order for a period of 5 years and to supply immediately copies of the same upon request for any due diligence queries from us.

                    13.3.4 We reserve the right to undertake our own CDD if we deem it necessary on any application or services under this Program.

                    13.3.5 We reserve the right to remove this reliance option from your Program use if we deem it necessary.

                    13.3      TheFormationsCompany.com reserves the right to add and/or remove any person(s)/organisation from the Frequent User Program at any time without notice.

                    13.4      TheFormationsCompany.com reserves the right to modify, amend and/or terminate the Frequent User Program offer at any time without notice.
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    In this Privacy Policy the terms, ‘we’ or ‘us’ is The Formations Company.

                    Your privacy is important to us and we are committed to keeping your information secure and managing it in accordance with our legal responsibilities under applicable data protection laws. We are registered with the UK Information Commissioner’s Office (ICO) as a data controller under registration number ZA155749.

                    Please read this Privacy Statement carefully as it contains important information to help you understand how and why we process any personal information that you give to us.

                    What does ‘processing’ mean when it comes to personal data?

                    The term ‘processing’ refers to any manual or automated action performed on personal data. So that includes (but isn’t limited to) collecting it, recording it, organising it, structuring it, storing it, altering it, retrieving it, consulting it, transmitting it, making it available somewhere, combining it, restricting it, or erasing or destroying it.

                    What  information do we collect about you?

                    We may collect, use, store and transfer different kinds of personal data about you which we have grouped together as follows:

                    Identity Data includes first name, maiden name, last name, username or similar identifier, title, date of birth and gender.

                    Contact Data includes billing address, delivery address, email address and telephone numbers.

                    Financial Data includes bank account and payment card details.

                    Transaction Data includes details about payments and other details of products and services you or your business have purchased from us.

                    Technical Data includes internet protocol (IP) address, your login data, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform, and other technology on the devices you use to access this website.

                    Profile Data includes your username and password, purchases or orders made by you or your business, your interests, preferences, feedback and survey responses.

                    Usage Data includes information about how you use our website, products and services.

                    Marketing and Communications Data includes your preferences in receiving marketing from us and our third parties and your communication preferences.

                    Please note any data about a company or about a person in capacity for a company would not necessarily fall under a data subject or personal information. Should any the of the records held be about a specific company director of a company customer and information/ processing decision does not relate to them as an individual then it will not be deemed personal data and will not fall within the scope for any requests/processing.

                    Special Categories of Personal Data

                    We don’t generally process information referred to as ‘special categories of personal data’. So that’s data about your health, racial or ethnic origin, political opinions, religious or philosophical beliefs, trade union memberships, and so on. We’ll only do this if you give it to us voluntarily, or we need it for a service you’ve asked us for. And wherever we can we’ll keep the collection and use of this type of data to a minimum.

                    If you fail to provide personal data

                    Where we need to collect personal data by law, or under the terms of a contract we have with you or your business, and you fail to provide that data when requested, we may not be able to perform the contract we have or are trying to enter into with you (for example, to provide you with goods or services). In this case, we may have to cancel a product or service you have with us but we will notify you if this is the case at the time.

                    How we use your Information

                    We process the info we collect from you to:

                    Provide to you or your company products and services and to administer your account (including billing you) when we are dealing with your account through our website, in writing or over the phone so we can give you any information or help you ask us for

                    So we can comply with our legal obligations for the prevention of financial crime and money laundering, include checking your identity, and for any audits we need to do to carry out any duties we’ve agreed to do in any contracts we have with you.

                    For services and legal services of third party providers on our panel, who we quote or refer on behalf of.

                    We will process your information in order to meet our contractual obligations to you, where have a legitimate interest to do so, to tell you about products and services you or your business might be interested in  so you can use the interactive features of our services when you choose to (like the online chat service on our website) to help us train our people and improve our service to you and where we are permitted by law or to comply with applicable laws and regulation.

                    PURPOSE	LEGAL BASIS
                    Providing a service and internal processing
                    To assess your needs and provide you with suitable products and services
                    ·      Contractual obligation to provide you with, or a proposal including a costs estimate

                    ·      Where special categories of personal data are processed, these are necessary to assess your needs

                    To service and administer your matter including billing
                    ·      Legitimate interests to provide and manage the service

                    Contractual obligation



                    To verify the identity of our clients	·      To comply with legal obligations to prevent money laundering
                    To confirm, update and improve our client records	·      To comply with legal obligations in the Data Protection legislation
                    To provide you with any information on the services that you have requested
                    ·      To meet our contractual obligation to provide information on the services you have requested



                    Relationship Management
                    To manage and develop our relationship with you	·      Legitimate interest to service your matter and improve our service to you
                    To inform you of products and services that may be of interest to your business(es), where you have chosen to be made aware of this



                    ·

                    With your consent
                    Legitimate interests



                    Training and development
                    For training purposes and to improve our service to you	·      Legitimate interests to improve our services and develop our employees
                    Complying with Legal Obligations
                    To prevent, investigate and prosecute crime, fraud and money laundering
                    ·      To comply with legal obligations for prevention of financial crime and money laundering



                    For auditing purpose	·      To comply with our legitimate interest to conduct audits
                    If we are obliged to disclose information by reason of any law, regulation or court order
                    ·      To comply with legal obligations





                    Other
                    To transfer information to any entity which may acquire rights in us	·      Legitimate interests for commercial interests
                    For any other purpose to which you agree.	·      With your consent


                    How long we keep your personal information

                    We follow the laws that apply when it comes to how long we hold on to your personal information. So we’ll destroy it or make it anonymous when we don’t need it anymore.

                    Our retention periods are:

                    Type of Personal Information	Retention Period
                    General personal data which includes your normal personal data, personal identity and personal financial data	·      6 years after the end of our business relationship with you, or the end of your matter which ever comes later
                    Material we need for legal reasons i.e. documents that show we’ve done our ‘due diligence’ to prevent fraud, financial crime and money laundering. That includes things like copies of your passport, driver’s licence, bank statements and any other documents you give us.	·      5 years after the end of our business relationship with you, or the end of your matter which ever comes later
                    Special categories of personal data	·      6 years after the end of our business relationship with you
                    Call recordings	·    3 months
                    CCTV – digital images if you visit our offices	·      90 days


                    Who we share your information with

                    There are a few third-party companies and organisations we might give your information to. They are:

                    Any company within the LegalZoom Group, for the purposes set out in this notice (information and customer relationship, software and service improvements, to provide you with any information, applications, products or services that you have requested);

                    our service providers and agents (including their sub-contractors) or third parties which process information on our behalf (e.g. internet service and platform providers, payment processing providers and those organisations we engage to help us send communications to you) so that they may help us to provide you with the applications, products, services and information you have requested or which we believe is of interest to you ;

                    regulatory authorities, government bodies and any other third party necessary – we might need to do this to make sure we’re following our legal obligations and/ or regulations;

                    third parties used to facilitate payment transactions;

                    credit reference and fraud prevention agencies;

                    insurers – so we can give you the right level of financial cover, or if you make a claim against us;

                    Our own and our Group professional advisors and auditors for the purpose of seeking professional advice or to meet our audit responsibilities;

                    government departments like HMRC, Companies House, Local London Authorities and Trading Standards – for legal reasons;

                    our auditors and external assessment bodies – this is so we can meet any regulatory or quality assurance standards and accreditations we need to, and so we can give you quality services;

                    third parties for marketing purposes (e.g. our partners and other third parties with whom we work and whose products or services we think will interest you in the operation of your business activities. For example, financial services organisations (such as banks, insurers, finance providers), payment solutions providers, software and services providers that provide business solutions).

                    Information Security

                    We invest appropriate resources to protect your personal information, from loss, misuse, unauthorised access, modification or disclosure. However, no internet-based site can be 100% secure and we cannot be held responsible for unauthorised or unintended access that is beyond our control.

                    How we keep your data secure

                    We do everything we can to keep your personal information safe. So we have systems in place to protect it from loss, misuse, unauthorised access, modification or disclosure.

                    Where we store your personal data

                    We usually hold your data inside the UK and we will only ever send your data outside of the UK to:

                    Comply with a legal duty or legitimate interest

                    Provide our products and services

                    If we do transfer information outside of the UK, we will make sure that it is protected in the same way as if it was being used in the UK. To do this, we will use one or more of these safeguards:

                    We will only transfer your personal data to countries that have been deemed to provide an adequate level of protection for personal data.

                    Where we use certain service providers, we may use specific contracts approved for use in the UK which give personal data the same protection it has in the UK.

                    If any of our external third parties are based outside the UK so their processing of your personal data will involve a transfer of data outside the UK.

                    You can find out more about these safeguards on the European Commission Justice and Information Commissioner’s Office (ICO) websites.

                    Third-party links

                    This website may include links to third-party websites, plug-ins and applications. Clicking on those links or enabling those connections may allow third parties to collect or share data about you. We do not control these third-party websites and are not responsible for their privacy statements. When you leave our website, we encourage you to read the privacy policy of every website you visit.

                    Updates

                    We will keep this Privacy Policy under review and make updates from time to time. Any minor changes to this Privacy Statement will be posted on this page and we will communicate any major changes to you.

                    If you want to see or change the data we have about you

                    You have the right to ask us for a copy of the personal information we hold about you.

                    And if any of it’s wrong, you can ask us to put it right, or delete it. Under certain circumstances you might also have the right to ask us to stop processing your personal information – but this doesn’t apply where there’s a legal or legitimate business reason for us to keep doing it.

                    If you’d like to do this, please email us or write to Data Protection Supervisor, 6th Floor, 9 Appold Street, London, EC2A 2AP.

                    We try to respond to all legitimate requests within one month. Occasionally it could take us longer than a month if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated.

                    In relation to all of these rights, please write to us at the address below.

                    If you want to contact us or complain about the way we’ve handled your personal dataYou can contact us by writing to us at:

                    Data Protection Supervisor, The Formations Company, 6th Floor, 9 Appold Street, London, EC2A 2AP or at compliance@theformationscompany.com

                    If you are located within the European Economic Area (EEA) and you wish to contact us in respect to any personal data we’ve handled, please contact our EU Representative: Instant EU GDPR Representative Ltd https://legalzoomlimited.gdprlocal.com/eu  on email contact@gdprlocal.com and/ or telephone + 353 15 549 700. The EU Representative  Dublin address is INSTANT EU GDPR REPRESENTATIVE LTD 69 Esker Woods Drive, Lucan Co. Dublin Ireland.

                    You can also complain to the Information Commissioner’s Office at any time – go to their website www.ico.org.uk to find out how. We would, however, appreciate the chance to deal with your concerns before you approach the ICO so please contact us in the first instance.
                </TabPanel>
                <TabPanel value={tabValue} index={2}>
                    A cookie is a technology for remembering information between web pages. It is the term given to describe a type of message that is given to a Web browser by a Web server.  Because of cookies, your web browser can remember you are logged in, or have visited a site before, or what your personal preferences are making your browsing experience better.

                    Cookies and Tracking technologies: technologies such as cookies, tags and scripts are used by us and our partners (such as advertising, marketing and analytics), affiliates or analytics or service providers. These technologies may be used in analysing trends, administering the Sites, tracking users’ movements around the Sites and to gather demographic information about our user base as a whole. We may receive reports based on the use of these technologies by such companies on an individual as well as aggregated basis. The third-party cookies (stored by a different domain to the visited page’s domain) used by us are outlined in the table below. Unless otherwise stated on the site, the data is anonymised.

                    Some or all of these tools may be used to track information about your behaviour on our website depending on your region. F
                </TabPanel>
                <TabPanel value={tabValue} index={3}>
                    Info about refunds here
                </TabPanel>
            </Box>
        </>
    );
}