import React, {useContext, useState} from 'react';
import { useForm, Controller } from 'react-hook-form';
import TextField from "@mui/material/TextField";
import {
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid, MenuItem, Stack
} from "@mui/material";
import {useNavigate} from "react-router-dom";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TransitionAlert from "../layout/TransitionAlert";
import {useTheme} from "@mui/material/styles";
import companyService from "./CompanyService";
import {enqueueSnackbar} from "notistack";
import Box from "@mui/material/Box";
import {ROLES} from "../enums";
import NavigationContext from "../layout/NavigationContext";
import inputService from "../util/InputService";

export default function CompanyDetails({antiForgeryToken, user, companyData, refreshUser, fetchData}) {
    const { back,histNavigate } = useContext(NavigationContext);
    const theme = useTheme();
    const { handleSubmit, control, formState } = useForm();
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [removeDialogOpen, setRemoveDialogOpen] = React.useState(false);
    const [sendCompanyEmailDialogOpen, setSendCompanyEmailDialogOpen] = React.useState(false);
    const [selectedEmailType, setSelectedEmailType] = React.useState(0);
    
    const [isBusy, setIsBusy] = useState(false);
    const navigate = useNavigate();

    let readonly = true;
    if(user.role === ROLES.TENANT_ADMIN || user.role === ROLES.TENANT_STANDARD || user.role === ROLES.CLIENT_ADMIN || user.role === ROLES.CLIENT_STANDARD){
        readonly = false;
    }
    
    const handleRemoveDialogOpen = () => {
        setRemoveDialogOpen(true);
    };

    const handleRemoveDialogClose = () => {
        setRemoveDialogOpen(false);
    };

    const handleSendCompanyEmailOpen = () => {
        setSendCompanyEmailDialogOpen(true);
    };

    const handleConfStatementDialogClose = () => {
        setSendCompanyEmailDialogOpen(false);
    };

    const handleFormSubmit  = async (formData) => {
        setDisableSubmit(true);
        formData = inputService.trimFields(formData);
        formData.id = companyData.id;
        formData.thirdPartyId = companyData.thirdPartyId;
        const result = await companyService.updateCompany(antiForgeryToken, formData);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                fetchData();
                enqueueSnackbar("Successfully updated company");
            } else {
                enqueueSnackbar(data.description);
            }
        }
        setDisableSubmit(false);
    };
    
    const sendCompanyEmail = async () => {
        setIsBusy(true);
        let sendEmailRequest = {
            companyId: companyData.id,
            emailType: selectedEmailType
        };
        const result = await companyService.sendCompanyEmail(antiForgeryToken, sendEmailRequest);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                enqueueSnackbar("Successfully triggered email");
            } else {
                enqueueSnackbar(data.description);
            }
        }
        setSendCompanyEmailDialogOpen(false);
        setIsBusy(false);
    }
    
    const removeCompany  = async () => {
        setIsBusy(true);
        const result = await companyService.removeCompanyFromAccount(antiForgeryToken, companyData.id);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                enqueueSnackbar("Successfully removed company from account");
                back();
            } else {
                enqueueSnackbar(data.description);
            }
        }
        setRemoveDialogOpen(false);
        setIsBusy(false);
    };

    const refreshCompany  = async () => {
        setIsBusy(true);
        const result = await companyService.refreshCompany(antiForgeryToken, companyData.id);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                enqueueSnackbar("A request has been sent to refresh this company");
            } else {
                enqueueSnackbar(data.description);
            }
        }
        setRemoveDialogOpen(false);
        setIsBusy(false);
    };
    

    let officers = companyData.officers.length === 0 ?
        `None found`
        : companyData.officers?.map(officer => (
            `${officer.name} \n${officer.address.oneLine} \n\n`
        )).join('');

    let pscs = companyData.pscs.length === 0 ?
        `None found`
        : companyData.pscs?.map(psc => (
            `${psc.name} \n${psc.address.oneLine} ${
                psc.natureOfControls.map(nature => `\n${nature.natureOfControlReadable}`).join('')
            }\n\n`
        )).join('');
    

    let sicCodes = companyData.sicCodes.length === 0 ?
        `None found`
        : companyData.sicCodes?.map(sicCode => (
            `${sicCode.code} - ${sicCode.description} \n`
        )).join('');


    let content;
    if (isBusy) {
        content = (
            <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                <CircularProgress />
            </Box>
        );
    } else {
        content = (
            <>
                <form onSubmit={handleSubmit(handleFormSubmit)}>
                    <TransitionAlert
                        message={"NOTE:   This information is retrieved directly from companies house public register and cannot be edited here."}/>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={8} md={6}>
                            {(user.role ===  ROLES.TENANT_ADMIN || user.role === ROLES.TENANT_STANDARD) &&
                                <Controller
                                    name="Client"
                                    control={control}
                                    defaultValue={companyData.clientName}
                                    render={({field}) => (
                                        <TextField
                                            {...field}
                                            label="Client"
                                            variant="outlined"
                                            InputLabelProps={{
                                                style: {...theme.inputLabelProps},
                                            }}
                                            margin="normal"
                                            fullWidth
                                            size="small"
                                            disabled={true}
                                        />
                                    )}
                                />
                            }
                            <Controller
                                name="Incorporation Date"
                                control={control}
                                defaultValue={companyData.incorporationDate}
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        label="Incorporation Date"
                                        variant="outlined"
                                        InputLabelProps={{
                                            style: {...theme.inputLabelProps},
                                        }}
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                        disabled={true}
                                    />
                                )}
                            />
                            {(user.role ===  ROLES.TENANT_ADMIN || user.role === ROLES.TENANT_STANDARD) &&
                                <Controller
                                    name="Status"
                                    control={control}
                                    defaultValue={companyData.status}
                                    render={({field}) => (
                                        <TextField
                                            {...field}
                                            label="Status"
                                            variant="outlined"
                                            InputLabelProps={{
                                                style: {...theme.inputLabelProps},
                                            }}
                                            margin="normal"
                                            fullWidth
                                            size="small"
                                            disabled={true}
                                        />
                                    )}
                                />
                            }
                           <Controller
                                name="Nature Of Control (SIC)"
                                control={control}
                                defaultValue={sicCodes}
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        label="Nature Of Business (SIC)"
                                        disabled={true}
                                        multiline
                                        rows={8}
                                        variant="outlined"
                                        InputLabelProps={{
                                            style: {...theme.inputLabelProps},
                                        }}
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={8} md={6}>
                            <Controller
                                name="Company Number"
                                control={control}
                                defaultValue={companyData.number}
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        label="Company Number"
                                        variant="outlined"
                                        InputLabelProps={{
                                            style: {...theme.inputLabelProps},
                                        }}
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                        disabled={true}
                                    />
                                )}
                            />
                            <Controller
                                name="Company Name"
                                control={control}
                                defaultValue={companyData.name}
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        label="Company Name"
                                        variant="outlined"
                                        InputLabelProps={{
                                            style: {...theme.inputLabelProps},
                                        }}
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                        disabled={true}
                                    />
                                )}
                            />
                            <Controller
                                name="previousNames"
                                control={control}
                                defaultValue={companyData.previousNames}
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        label="Previous Names"
                                        variant="outlined"
                                        InputLabelProps={{
                                            style: {...theme.inputLabelProps},
                                        }}
                                        sx={{mt:2}}
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                        disabled={true}
                                    />
                                )}
                            />
                            <Controller
                                name="tradingNames"
                                control={control}
                                defaultValue={companyData.tradingNames}
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        label="Trading Names"
                                        variant="outlined"
                                        InputLabelProps={{
                                            style: {...theme.inputLabelProps},
                                        }}
                                        sx={{mt:2}}
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                        disabled={readonly}
                                    />
                                )}
                            />
                            <Controller
                                name="authenticationCode"
                                control={control}
                                defaultValue={companyData.authenticationCode}
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        label="Authentication Code"
                                        variant="outlined"
                                        InputLabelProps={{
                                            style: {...theme.inputLabelProps},
                                        }}
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                    />
                                )}
                            />
                            <Controller
                                name="utrNumber"
                                control={control}
                                defaultValue={companyData.utrNumber}
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        label="UTR Number"
                                        variant="outlined"
                                        InputLabelProps={{
                                            style: {...theme.inputLabelProps},
                                        }}
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={14} sm={10} md={8}>
                            <Typography variant="h6" noWrap component="div" align={"left"} sx={{mt: 1}}>
                                Officers
                            </Typography>
                            <Controller
                                name="Officers"
                                control={control}
                                defaultValue={officers}
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        disabled={true}
                                        multiline
                                        rows={8}
                                        variant="outlined"
                                        InputLabelProps={{
                                            style: {...theme.inputLabelProps},
                                        }}
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={8} sm={6} md={4} sx={{display: {xs: 'none', sm: 'none', md: 'block'}}}>
                        </Grid>
                        <Grid item xs={14} sm={10} md={8}>
                            <Typography variant="h6" noWrap component="div" align={"left"} sx={{mb: 1, mt: 1}}>
                                PSCs
                            </Typography>
                            <Controller
                                name="Pscs"
                                control={control}
                                defaultValue={pscs}
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        disabled={true}
                                        multiline
                                        rows={8}
                                        variant="outlined"
                                        InputLabelProps={{
                                            style: {...theme.inputLabelProps},
                                        }}
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={8} sm={6} md={4} sx={{display: {xs: 'none', sm: 'none', md: 'block'}}}>
                        </Grid>
                    {(user.role === ROLES.TENANT_ADMIN || user.role === ROLES.TENANT_STANDARD) && (
                        <Stack direction="row" spacing={2} marginTop={4} marginBottom={4} marginLeft={4}>
                            <Controller
                                name="emailType"
                                control={control}
                                defaultValue={selectedEmailType}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        select
                                        label="Email"
                                        variant="outlined"
                                        InputLabelProps={{
                                            style: { ...theme.inputLabelProps },
                                        }}
                                        margin="normal"
                                        size="small"
                                        fullWidth
                                        SelectProps={{ style: { maxWidth: "400px", minWidth:"400px" } }}
                                        onChange={(e) => {
                                            setSelectedEmailType(e.target.value);
                                            field.onChange(e); // keep this line
                                        }}
                                    >
                                        <MenuItem key={0} value={0}>&nbsp;</MenuItem>
                                        <MenuItem key={7} value={7}>Confirmation Statement Overdue</MenuItem>
                                    </TextField>
                                )}
                            />
                            <Button variant="contained" color="secondary" sx={{minWidth:140,maxWidth:140}} onClick={handleSendCompanyEmailOpen}>
                                Send Email
                            </Button>
                        </Stack>
                    )}
                    </Grid>
                    <Stack direction="row" spacing={2} marginTop={2}>
                        <Button variant="contained" color="secondary" onClick={(e) => {
                            back();
                        }}>
                            Back
                        </Button>
                        <Button variant="contained" color="secondary" type="submit" disabled={disableSubmit}>
                            Update
                        </Button>
                        {(user.role === ROLES.TENANT_ADMIN || user.role === ROLES.TENANT_STANDARD) && (
                            <Button variant="contained" color="secondary" onClick={refreshCompany}>
                                Refresh Company
                            </Button>
                        )}
                        <Button variant="contained" color="secondary" onClick={handleRemoveDialogOpen}>
                            Remove Company
                        </Button>
                        {
                            user.role == ROLES.ACCOUNT_ADMIN && user.multipleCompanies === false
                                ?
                                <Button variant="contained" color="secondary"
                                        onClick={() => histNavigate(`/Account/AddCompany/${user.accountId}`, 'Add Company')}>
                                    Add Company
                                </Button>
                                : (
                                    <>
                                    </>
                                )
                        }

                    </Stack>
                </form>
                <Dialog
                    open={removeDialogOpen}
                    onClose={handleRemoveDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Warning"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you wish to remove {companyData.name}?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleRemoveDialogClose}>No</Button>
                        <Button onClick={removeCompany} autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={sendCompanyEmailDialogOpen}
                    onClose={handleConfStatementDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Warning"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you wish to trigger an email for this company?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleConfStatementDialogClose}>No</Button>
                        <Button onClick={sendCompanyEmail} autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }

    return (
        <>
            {content}
        </>
    );
}