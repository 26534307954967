import React, {useContext, useEffect, useRef, useState} from 'react'
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from "@mui/material/TextField";
import {
    CircularProgress,
    Grid,
    InputAdornment, ListSubheader, MenuItem,
    Paper,
    Stack
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import {Controller, useForm} from "react-hook-form";
import Button from "@mui/material/Button";
import {enqueueSnackbar} from "notistack";
import AuthContext from "../api-authorization/AuthContext";
import {useNavigate} from "react-router-dom";
import companyService from "../companies/CompanyService";
import CompanySearchResultsSideBar from "./companySearchResultsSideBar";
import mailService from "../mail/MailService";
import {useTheme} from "@mui/material/styles";
import {MAIL_PROCESSING_ACTIONS, MAIL_PROCESSING_PAGE, MAIL_TYPE, ROLES} from "../enums";
import {generateMailLabelXml} from "../labels/GenerateMailLabelXml";
import labelService from "../labels/LabelService";
import {generateMailInformationLabelXml} from "../labels/GenerateMailInformationLabelXml";
import Divider from "@mui/material/Divider";
import {QuestionMark} from "@mui/icons-material";
import Drawer from "@mui/material/Drawer";

export default function CheckInMail() {
    const { antiForgeryToken, user, refreshUser} = useContext(AuthContext);
    const theme = useTheme();
    
    const {  handleSubmit, control, formState, setValue } = useForm();
    const [hasSearchedCompanies, setHasSearchedCompanies] = React.useState(false);
    const [companySearchLoading, setCompanySearchLoading] = React.useState(false);
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [selectedMailType, setSelectedMailType] = useState(0);
    
    const [companyResults, setCompanyResults] = React.useState([]);
    const [companySearchTerm, setCompanySearchTerm] = useState("");
    const [personSearchTerm, setPersonSearchTerm] = useState("");
    
    const [selectedCompanyId, setSelectedCompanyId] = useState("");
    const [selectedPersonName, setSelectedPersonName] = useState("");
    const [displayNoCompanySelectedError, setDisplayNoCompanySelectedError] = useState(false);
    const [selectedRowId, setSelectedRowId] = useState("");
    const [canSelectedCompanyCheckInMail, setCanSelectedCompanyCheckInMail] = useState(false);
    
    const navigate = useNavigate();
    
    const [selectedFile, setSelectedFile] = useState(null);
    const hiddenFileInput = useRef(null);
    const [previewImage, setPreviewImage] = useState(null);
    const [infoDrawerOpen, setInfoDrawerOpen] = useState(false);
    const [availableActions, setAvailableActions] = useState({});

    const handleInfoDrawerOpen = () => {
        setInfoDrawerOpen(true);
    };

    const handleInfoDrawerClose = () => {
        setInfoDrawerOpen(false);
    };

    const handleFile = (event) => {
        setSelectedFile(event);
    };

    useEffect(() => {
        if (user.loggedIn == false || (user.role !== ROLES.TENANT_ADMIN && user.role != ROLES.TENANT_STANDARD)) {
            refreshUser();
            navigate('/login');
        }
    }, [user, navigate]);

    async function setPostageFee(postageFee){
        setValue("postageFee", formatFee(postageFee));
    }

    async function setHandlingFee(handlingFee){
        setValue("handlingFee", formatFee(handlingFee));
    }

    function formatFee(valueToFormat) {
        let value = parseFloat(valueToFormat);
        if (!isNaN(value)) {
            if (value > 999.99) {
                value = 999.99;
            }
            value = value.toFixed(2);
        } else {
            value = '';
        }
        return value;
    }
    
    const getFormData = (data) => {
        const formData = new FormData();
        formData.append('FileUpload', selectedFile);
        formData.append('CompanyId', selectedCompanyId);
        formData.append('PersonName', selectedPersonName);
        formData.append("ItemId", data.itemId);
        formData.append("MailType", data.mailType);
        formData.append("Notes", data.notes);

        formData.append("HandlingFee", data.handlingFee);
        formData.append("PostageFee", data.postageFee);
        return formData;
    }

    const checkInAndCheckoutMail = async (data) => {
        if(selectedCompanyId === ""){
            setDisplayNoCompanySelectedError(true);
            return;
        }
        if(canSelectedCompanyCheckInMail === false){
            enqueueSnackbar("Cannot submit scanned mail for this company");
            return;
        }
        setIsSubmitting(true);
        const formData = getFormData(data);
        const result = await mailService.checkInAndCheckoutMail(antiForgeryToken, formData);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                enqueueSnackbar(`Successfully checked in and checked out a '${data.mailType}' mail for ${data.companyName}`);
                printLabel(data.mailId);
                refreshForm();
            } else {
                enqueueSnackbar(data.description);
            }
        }
        setIsSubmitting(false);
    };

    const printLabel = async (mailId) => {
        const result = await labelService.getMailLabel(antiForgeryToken, mailId);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        let data = await result.json();
        if (result.ok) {
            const xml = generateMailLabelXml(data);
            let printers = window.dymo.label.framework.getPrinters();
            let connectedPrinter = printers.find(printer => printer.isConnected === true);
            if(!connectedPrinter){
                enqueueSnackbar("An error occured: No printers found");
                console.log(xml);
            }
            else {
                let label = window.dymo.label.framework.openLabelXml(xml);
                label.print(connectedPrinter.name);
            }

        } else {
            enqueueSnackbar(data.description);
        }
    };

    const printMailInformationLabel = async (mailId) => {
        const result = await labelService.getMailInformationLabel(antiForgeryToken, mailId);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        let data = await result.json();
        if (result.ok) {
            const xml = generateMailInformationLabelXml(data);
            let printers = window.dymo.label.framework.getPrinters();
            let connectedPrinter = printers.find(printer => printer.isConnected === true);
            if(!connectedPrinter){
                enqueueSnackbar("An error occured: No printers found");
                console.log(xml);
            }
            else {
                let label = window.dymo.label.framework.openLabelXml(xml);
                label.print(connectedPrinter.name);
            }

        } else {
            enqueueSnackbar(data.description);
        }
    };
    
    const checkInMail  = async (data) => {
        if(selectedCompanyId === ""){
            setDisplayNoCompanySelectedError(true);
            return;
        }
        if(canSelectedCompanyCheckInMail === false){
            enqueueSnackbar("Cannot submit scanned mail for this company");
            return;
        }
        setIsSubmitting(true);
        const formData = getFormData(data);
        const result = await mailService.checkInMail(antiForgeryToken, formData);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                enqueueSnackbar(`Successfully checked in a '${data.mailType}' for ${data.companyName}`);
                if(data.printInformationLabel){
                    printMailInformationLabel(data.mailId);
                }
                refreshForm();
            } else {
                enqueueSnackbar(data.description);
            }
        }
        setIsSubmitting(false);
    };

    const refreshForm = () => {
        setIsSubmitting(false);
        setDisplayNoCompanySelectedError(false);
        setSelectedFile(null);
        if(hiddenFileInput.current){
            hiddenFileInput.current.value = "";
        }
        
        setPreviewImage(null);
        setValue("itemId", "");
        setValue("notes", "");
        setValue("postageFee", "");
        setValue("handlingFee", "");
        setValue("mailType", "");
        setCompanySearchTerm("");
        setPersonSearchTerm("");
        setSelectedRowId("");
        setSelectedPersonName("");
        setCompanyResults([]);
        setSelectedCompanyId("");
        setHasSearchedCompanies(false);
        setAvailableActions({});
    };

    
    const fetchCompanies = async () => {
        setCompanySearchLoading(true);
        let searchRequest = {
            companySearchTerm: companySearchTerm === "" ? null : companySearchTerm,
            personSearchTerm: personSearchTerm === "" ? null : personSearchTerm
        }
        const result = await companyService.findCompaniesMailSettings(antiForgeryToken, searchRequest);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        let data = await result.json();
        if (result.ok) {
            setAvailableActions({});
            setCompanyResults(data);
            setHasSearchedCompanies(true);
        } else {
            enqueueSnackbar(data.description);
        }
        setCompanySearchLoading(false);
    };

    const handleUploadClick = (event) => {
        hiddenFileInput.current.click();
    };
    // Call a function (passed as a prop from the parent component)
    // to handle the user-selected file
    const handleUploadChange = (event) => {
        const fileUploaded = event.target.files[0];
        handleFile(fileUploaded);
        if (event.target.files && event.target.files[0]) {
            let img = event.target.files[0];

            const reader = new FileReader();
            if (fileUploaded.type === 'application/pdf') {
                setPreviewImage(fileUploaded.name);
            } else {
                const reader = new FileReader();
                reader.onload = (fileLoadedEvent) => {
                    setPreviewImage(fileLoadedEvent.target.result);
                };

                reader.readAsDataURL(img);
            }
        }
    };
    
    const handleCompanySearch = (event) => {
        event.preventDefault();
        fetchCompanies();
    };
    
    let companyResultsContent = (
        <></>
    );
    
    if(companySearchLoading){
        companyResultsContent = (
            <Box display="flex" justifyContent="center" alignItems="center" height="20vh">
                <CircularProgress />
            </Box>
        );
    }
    else if(hasSearchedCompanies){
        companyResultsContent = (
            <CompanySearchResultsSideBar 
                companies={companyResults.companies} 
                setSelectedCompanyId={setSelectedCompanyId} 
                setSelectedPersonName={setSelectedPersonName} 
                setDisplayNoCompanySelectedError={setDisplayNoCompanySelectedError}
                selectedRowId={selectedRowId}
                setSelectedRowId={setSelectedRowId}
                setPostageFee={setPostageFee}
                setHandlingFee={setHandlingFee}
                setCanSelectedCompanyCheckInMail={setCanSelectedCompanyCheckInMail}
                selectedMailType={selectedMailType}
                setAvailableActions={setAvailableActions}
            />
        );
        
    }
    
    let content;
    if(isSubmitting){
        content = (
            <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                <CircularProgress/>
            </Box>
        );
    }
    else{
        content = (
            <Box style={{ height: 400 }}>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="h5" noWrap component="div" align={"left"} sx={{mb:4}} flexGrow={1}>
                        Check-In Mail
                    </Typography>
                    <Box>
                        <IconButton onClick={handleInfoDrawerOpen}
                                    sx={{
                                        backgroundColor: theme.palette.primary.main,
                                        color: "white",
                                        '&:hover': {
                                            color: theme.palette.primary.main
                                        }
                                    }}
                        >
                            <QuestionMark/>
                        </IconButton>
                        <Drawer
                            anchor="right"
                            open={infoDrawerOpen}
                            onClose={handleInfoDrawerClose}
                        >
                            <Box
                                sx={{
                                    width: 800,
                                    height: "100%",
                                    bgcolor: theme.palette.secondary.main,
                                    color: theme.palette.text.primary,
                                    padding: 4,
                                }} // Set the width of your Drawer
                                role="presentation"
                                onClick={handleInfoDrawerClose}
                                onKeyDown={handleInfoDrawerClose}
                            >
                                <h2>Check-In Mail</h2>
                                <br/>
                                Once a search result has been selected and is highlighted in green, there are multiple
                                functions a user can select to process the mail item.
                                <br/>
                                <br/>
                                <strong>Check-In</strong> – records a mail item against the search result. The mail image is not retained
                                and you are unable to return to this mail item.
                                <br/>
                                <br/>
                                <strong>Express Checkout</strong> – records a mail item against the search result selected. Mail is
                                checked-in and out immediately and a label is printed.
                                <br/>
                                <br/>
                                <strong>Reset</strong> – clears the search information on this page.
                                <br/>
                                <br/>
                                <Button onClick={handleInfoDrawerClose} variant="contained"
                                        color="secondary">Close</Button>
                            </Box>
                        </Drawer>
                    </Box>
                </Stack>
                <Grid container spacing={4} sx={{pr:2}}>
                    <Grid item xs={12} sm={8} md={6}>
                        <form onSubmit={handleCompanySearch}>
                            <TextField
                                id="search-bar"
                                className="text"
                                value={companySearchTerm}
                                onChange={(e) => {
                                    setCompanySearchTerm(e.target.value.toUpperCase());
                                }}
                                sx={{width: '100%'}}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton type="submit">
                                                <SearchIcon/>
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                label="Company & Post Box Search"
                                variant="outlined"
                                size="small"
                            />
                            <TextField
                                id="search-bar"
                                className="text"
                                value={personSearchTerm}
                                onChange={(e) => {
                                    setPersonSearchTerm(e.target.value.toUpperCase());
                                }}
                                sx={{mt: 4, width: '100%'}}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton type="submit">
                                                <SearchIcon/>
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                label="People Search"
                                variant="outlined"
                                size="small"
                            />
                        </form>
                        <Controller
                            name="itemId"
                            control={control}
                            defaultValue=""
                            render={({field}) => (
                                <TextField
                                    {...field}
                                    label="Item ID"
                                    variant="outlined"
                                    sx={{mt: 4}}
                                    InputLabelProps={{
                                        style: { ...theme.inputLabelProps },
                                    }}
                                    margin="normal"
                                    fullWidth
                                    size="small"
                                />
                            )}
                        />
                        <Controller
                            name="notes"
                            control={control}
                            defaultValue=""
                            render={({field}) => (
                                <TextField
                                    {...field}
                                    multiline
                                    rows={4}
                                    label="Mail Notes"
                                    variant="outlined"
                                    sx={{mt: 4}}
                                    InputLabelProps={{
                                        style: { ...theme.inputLabelProps },
                                    }}
                                    margin="normal"
                                    fullWidth
                                    size="small"
                                />
                            )}
                        />
                        <Box sx={{mt: 4}}>
                            <Button variant="contained" color="secondary" onClick={handleUploadClick} >
                                Upload File
                            </Button>
                            <input
                                type="file"
                                onChange={handleUploadChange}
                                ref={hiddenFileInput}
                                style={{ display: "none" }} // Make the file input element invisible
                            />
                        </Box>
                        <Box sx={{height: 200, mt: 4}}>
                            {
                                previewImage ?
                                    (previewImage.endsWith('.pdf') ?
                                            <p>{previewImage}</p> :
                                            <img style={{maxWidth: '100%', maxHeight: '100%'}}  src={previewImage} alt="Preview" />
                                    )
                                    : null
                            }
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={8} md={6}>
                        <form>

                            <Grid container spacing={10}>
                                <Grid item xs={8} sm={8} md={8}>
                                    <Stack direction="row" spacing={2}>
                                        <Controller
                                            name="mailType"
                                            control={control}
                                            defaultValue={selectedMailType}
                                            rules={{required: 'Mail type is required'}}
                                            render={({field}) => (
                                                <TextField
                                                    {...field}
                                                    select
                                                    label="Mail Type *"
                                                    variant="outlined"
                                                    margin="normal"
                                                    size="small"
                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                        setSelectedMailType(e.target.value);
                                                    }}
                                                    fullWidth={true}
                                                    error={!!formState.errors.mailType}
                                                    helperText={formState.errors.mailType ? formState.errors.mailType.message : ''}
                                                >
                                                    <ListSubheader>Statutory Mail</ListSubheader>
                                                    <MenuItem key={MAIL_TYPE.COMPANIES_HOUSE.toString()} value={MAIL_TYPE.COMPANIES_HOUSE}>Companies House</MenuItem>
                                                    <MenuItem key={MAIL_TYPE.HMRC.toString()} value={MAIL_TYPE.HMRC}>HMRC</MenuItem>
                                                    <MenuItem key={MAIL_TYPE.THE_PENSION_REGULATOR.toString()} value={MAIL_TYPE.THE_PENSION_REGULATOR}>The Pension Regulator</MenuItem>
                                                    <MenuItem key={MAIL_TYPE.INFORMATION_COMMISSIONERS_OFFICE.toString()} value={MAIL_TYPE.INFORMATION_COMMISSIONERS_OFFICE}>Information Commissioners Office</MenuItem>
                                                    <MenuItem key={MAIL_TYPE.OFFICE_OF_NATIONAL_STATISTICS.toString()} value={MAIL_TYPE.OFFICE_OF_NATIONAL_STATISTICS}>Office of National Statistics</MenuItem>
                                                    <MenuItem key={MAIL_TYPE.DVLA.toString()} value={MAIL_TYPE.DVLA}>DVLA</MenuItem>
                                                    <MenuItem key={MAIL_TYPE.OTHER_STATUTORY_MAIL.toString()} value={MAIL_TYPE.OTHER_STATUTORY_MAIL}>Other - Statutory Mail</MenuItem>
                                                    <ListSubheader>Non-Statutory Mail</ListSubheader>
                                                    <MenuItem key={MAIL_TYPE.ROYAL_MAIL_LETTER.toString()} value={MAIL_TYPE.ROYAL_MAIL_LETTER}>Royal Mail - Letter</MenuItem>
                                                    <MenuItem key={MAIL_TYPE.ROYAL_MAIL_LARGE_LETTER.toString()} value={MAIL_TYPE.ROYAL_MAIL_LARGE_LETTER}>Royal Mail - Large Letter</MenuItem>
                                                    <MenuItem key={MAIL_TYPE.ROYAL_MAIL_SMALL_PARCEL.toString()} value={MAIL_TYPE.ROYAL_MAIL_SMALL_PARCEL}>Royal Mail - Small Parcel</MenuItem>
                                                    <MenuItem key={MAIL_TYPE.ROYAL_MAIL_MEDIUM_PARCEL.toString()} value={MAIL_TYPE.ROYAL_MAIL_MEDIUM_PARCEL}>Royal Mail - Medium Parcel</MenuItem>
                                                    <MenuItem key={MAIL_TYPE.ROYAL_MAIL_LARGE_PARCEL.toString()} value={MAIL_TYPE.ROYAL_MAIL_LARGE_PARCEL}>Royal Mail - Large Parcel</MenuItem>
                                                    <MenuItem key={MAIL_TYPE.OTHER_SPECIAL_DELIVERY.toString()} value={MAIL_TYPE.OTHER_SPECIAL_DELIVERY}>Other - Special Delivery</MenuItem>
                                                    <MenuItem key={MAIL_TYPE.PRINTED_MATTER.toString()} value={MAIL_TYPE.PRINTED_MATTER}>Printed Matter</MenuItem>
                                                </TextField>
                                            )}
                                        />
                                    </Stack>
                                </Grid>
                                <Grid item xs={4} sm={4} md={4}>
                                    <Stack direction="row" spacing={2} justifyContent="flex-end">
                                        <Button variant="contained" color="secondary" onClick={refreshForm}>
                                            Reset
                                        </Button>
                                    </Stack>
                                </Grid>
                            </Grid>
                            <Divider sx={{mt: 2.5, mb: 2.5, borderBottomWidth: 1}}/>
                            <Grid container spacing={10}>
                                <Grid item xs={12} sm={6} md={6}>
                                    <Stack direction="row" spacing={2}>
                                        <Controller
                                            name="postageFee"
                                            control={control}
                                            defaultValue=""
                                            render={({field, fieldState: {error}}) => (
                                                <TextField
                                                    {...field}
                                                    onBlur={(e) => {
                                                        let value = parseFloat(e.target.value);
                                                        value = formatFee(value);
                                                        field.onBlur();
                                                        field.onChange(value);
                                                    }}
                                                    label="Postage"
                                                    type="number"
                                                    variant="outlined"
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">£</InputAdornment>,
                                                    }}
                                                    fullWidth={true}
                                                    InputLabelProps={{
                                                        style: {...theme.inputLabelProps},
                                                    }}
                                                    margin="normal"
                                                    size="small"
                                                />
                                            )}
                                        />
                                        <Controller
                                            name="handlingFee"
                                            control={control}
                                            defaultValue=""
                                            render={({field}) => (
                                                <TextField
                                                    {...field}
                                                    onBlur={(e) => {
                                                        let value = parseFloat(e.target.value);
                                                        value = formatFee(value);
                                                        field.onBlur();
                                                        field.onChange(value);
                                                    }}
                                                    label="Handling"
                                                    type="number"
                                                    variant="outlined"
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">£</InputAdornment>,
                                                    }}
                                                    fullWidth={true}
                                                    InputLabelProps={{
                                                        style: {...theme.inputLabelProps},
                                                    }}
                                                    margin="normal"
                                                    size="small"
                                                />
                                            )}
                                        />
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <Stack direction="row" spacing={2} justifyContent="flex-end">
                                        <Button variant="contained" color="secondary" onClick={handleSubmit(checkInMail)}
                                                disabled={isSubmitting} sx={{alignItems:"flex-end"}}>
                                            Check-In
                                        </Button>
                                        <Button variant="contained" color="secondary"
                                                onClick={handleSubmit(checkInAndCheckoutMail)} disabled={isSubmitting} sx={{ml:2}}>
                                            Express Checkout
                                        </Button>
                                    </Stack>
                                </Grid>
                            </Grid>

                            <Paper sx={{
                                mt: 4,
                                maxHeight: "70vh",
                                minHeight: "70vh",
                                overflow: 'auto',
                                border: displayNoCompanySelectedError === true ? '1px solid #D32F2F' : ''
                            }} variant="outlined">
                                {companyResultsContent}
                            </Paper>
                            <span id={"noCompanySelectedErrorMessage"} style={{
                                display: displayNoCompanySelectedError === true ? 'block' : 'none',
                                color: "#d32f2f",
                                fontWeight: 400,
                                fontSize: "0.75rem",
                                lineHeight: 1.66,
                                textAlign: "left",
                                marginTop: "4px",
                                marginRight: "14px",
                                marginBottom: 0,
                                marginLeft: "14px"
                            }}>Please select a company</span>
                        </form>
                    </Grid>
                </Grid>
            </Box>  
        );
    }

    return (
        content
    );
}