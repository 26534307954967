import * as React from 'react';
import Box from "@mui/material/Box";
import {
    CircularProgress, InputAdornment, MenuItem, Select
} from "@mui/material";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import {useContext, useState} from "react";
import {enqueueSnackbar} from "notistack";
import {useNavigate} from "react-router-dom";
import NavigationContext from "../layout/NavigationContext";
import AuthContext from "../api-authorization/AuthContext";
import {CheckCircleOutlined, HighlightOffRounded} from "@mui/icons-material";
import Button from "@mui/material/Button";
import formationService from "./FormationService";
import {COMPANY_FORMATION_TABS} from "../enums";
import Divider from "@mui/material/Divider";

export default function FormationNameCheck({formationData, createOrUpdateFormationData}) {
    const { histNavigate, back } = useContext(NavigationContext);
    const { antiForgeryToken, user, refreshUser } = useContext(AuthContext);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedCompanyType, setSelectedCompanyType] = useState("LIMITED");
    const [previousSearchTerm, setPreviousSearchTerm] = useState(formationData.companyName);
    const [isLoading, setIsLoading] = React.useState(false);
    const [hasSearched, setHasSearched] = React.useState(formationData.companyName != null && formationData.companyName.length > 0);
    const [companyNameAvailable, setCompanyNameAvailable] = React.useState(formationData.companyNameAvailable);
    const [sensitiveWordResult, setSensitiveWordResult] = React.useState(formationData.sensitiveWords);
    
    const navigate = useNavigate();
    
    const fetchData = async () => {
        setIsLoading(true);
        let searchRequest = {
            searchTerm: searchTerm + " " + selectedCompanyType,
        };
        const result = await formationService.companyNameSearch(antiForgeryToken, searchRequest);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                await setCompanyNameAvailable(data.nameAvailable);
                await setSensitiveWordResult(data.sensitiveWords);
                await setPreviousSearchTerm(data.searchTerm);
                await setHasSearched(true);
            } else {
                enqueueSnackbar(data.description);
            }
        }
        setIsLoading(false);
    };

    const handleSearch = (event) => {
        event.preventDefault();
        fetchData();
    };
    
    const resetSearch = async () => {
        await setHasSearched(false);
        await setCompanyNameAvailable(false);
    }

    const next = async () => {
        const newFormationData = {
            ...formationData,
            companyName: previousSearchTerm,
            companyNameAvailable: companyNameAvailable,
            sensitiveWords: sensitiveWordResult,
            completedTab: COMPANY_FORMATION_TABS.NAME_CHECK,
        };
        await createOrUpdateFormationData(newFormationData);
    }
    
    let content;

    if (isLoading) {
        content = (
            <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                <CircularProgress />
            </Box>
        );
    } 
    else if(hasSearched && companyNameAvailable) {
        let sensitiveWordResultContent;
        
        if (sensitiveWordResult && sensitiveWordResult.length > 0) {
            sensitiveWordResultContent = (
                <>
                    <Typography component="div" align={"left"} sx={{mt:2}}>
                        However, please note, you will need the following supporting evidence:
                    </Typography>
                    <Typography component="div" align={"left"}>
                        Sensitive word evidence for: <span style={{fontWeight:"bolder"}}>{sensitiveWordResult}</span>
                    </Typography>
                    <Typography component="div" align={"left"} sx={{mt:2}}>
                        For more information please click <a href="https://www.gov.uk/government/publications/incorporation-and-names/annex-a-sensitive-words-and-expressions-or-words-that-could-imply-a-connection-with-government" target="_blank">here</a>.
                    </Typography>
                </>
            );
        }
        
        
        content = (
            <>
                <Typography component="div" align={"left"} sx={{mb: 2, fontSize:"20px",mt:4}}>
                    <CheckCircleOutlined  sx={{fontSize:"45px",mb:1,mr:3,color:"#7E7E7E"}}/>
                    <span style={{color:"#458839",fontSize:"24px",fontWeight:"bolder"}}>{previousSearchTerm.toUpperCase()}</span>
                </Typography>
                <Typography component="div" align={"left"} sx={{mt: 2, fontSize:"20px"}}>
                    Congratulations! This name is available to register.
                </Typography>
                <Typography component="div" align={"left"} sx={{mt:2}}>
                    Click Next to choose a package and set up your limited company from as little as £9.99, plus the companies house filing fees. Its quick and easy, our team does it all!
                </Typography>
                {sensitiveWordResultContent}
                <Button variant="contained" color="secondary" sx={{mt: 2}} onClick={(e) =>{next();}}>Next</Button>
                <Typography sx={{mt: 2, textDecoration:"underline", cursor:"pointer"}} onClick={(e) =>{resetSearch();}}>Or Search Again</Typography>
            </>
        );
    }
    else {
        let previousSearchContent; 
        if (hasSearched && !companyNameAvailable) {
            previousSearchContent = (
                <Typography component="div" align={"left"} sx={{mb: 2, fontSize:"20px",mt:4}}>
                    <HighlightOffRounded sx={{fontSize:"45px",mb:1,mr:3,color:"#7E7E7E"}}/>
                    <span style={{color:"#c22a2a",fontSize:"24px",fontWeight:"bolder"}}>{previousSearchTerm}</span>
                    <Typography component="div" align={"left"} sx={{mt: 2, fontSize:"20px"}}>
                        We’re sorry this name is not available to register. Please search again.
                    </Typography>
                    <Typography sx={{mt: 2, textDecoration:"underline", cursor:"pointer"}} onClick={(e) =>{resetSearch();}}>Search Again</Typography>
                </Typography>
            );
        }
        
        content = (
            <>
                <Typography variant="h5" component="div" align={"left"} sx={{mb: 2}}>
                    Company Name Check
                </Typography>
                <form onSubmit={handleSearch}>
                    <TextField
                        id="search-bar"
                        className="text"
                        value={searchTerm}
                        onChange={(e) => {
                            setSearchTerm(e.target.value);
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Divider sx={{ height: 28, m: 0.5, borderWidth:1, borderColor:'grey.500', borderLeft: 1 }} orientation="vertical" />
                                    <Select
                                        displayEmpty
                                        value={selectedCompanyType}
                                        onChange={(e) => {
                                            setSelectedCompanyType(e.target.value);
                                        }}
                                        sx={{
                                            p: 0,
                                            minWidth: 'auto',
                                            '& .MuiOutlinedInput-notchedOutline': { border: 0 },
                                            '&:focus-within .MuiOutlinedInput-notchedOutline': { border: 0 },
                                            '&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { border: 0 },
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': { border: 0 },
                                        }}
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "right"
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "right"
                                            }
                                        }}
                                    >
                                        <MenuItem value={"LIMITED"}>LIMITED</MenuItem>
                                        <MenuItem value={"LTD"}>LTD</MenuItem>
                                    </Select>
                                </InputAdornment>
                            ),
                        }}
                        label="Search"
                        variant="outlined"
                        size="small"
                        sx={{width: '100%', mt: 2}}
                    />
                    <Button variant="contained" color="secondary" sx={{mt: 2}} type="submit">Next</Button>
                </form>
                {previousSearchContent}
            </>
        );
    }
    

    return (
        <Box maxWidth={"100vh"}>
            {content}
        </Box>
    );
}