import * as React from 'react';
import Typography from '@mui/material/Typography';
import {useContext} from "react";
import AuthContext from "../api-authorization/AuthContext";
import ListAccountUsers from "../accounts/ListAccountUsers";

export default function MyTeam() {
    const { antiForgeryToken, user, refreshUser } = useContext(AuthContext);

    return (
        <>
            <Typography variant="h5" noWrap component="div" align={"left"} sx={{mb: 2}}>
                My Team
            </Typography>
            <ListAccountUsers accountId={user.accountId} antiForgeryToken={antiForgeryToken} refreshUser={refreshUser} pageSize={10}/>
        </>
    );
}