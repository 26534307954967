import * as React from 'react';
import Box from "@mui/material/Box";
import {
    Checkbox,
    CircularProgress, Grid, MenuItem, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import {COMPANY_FORMATION_TABS, FORMATION_DIRECTOR_SHAREHOLDER_TYPE} from "../enums";
import {useForm} from "react-hook-form";
import TextField from "@mui/material/TextField";
import {useTheme} from "@mui/material/styles";
import Button from "@mui/material/Button";
import FormationDirectorPersonDetails from "./FormationDirectorPersonDetails";
import {Edit, RemoveCircleOutline} from "@mui/icons-material";
import FormationSecretaryPersonDetails from "./FormationSecretaryPersonDetails";
import FormationDirectorCorporateDetails from "./FormationDirectorCorporateDetails";
import FormationSecretaryCorporateDetails from "./FormationSecretaryCorporateDetails";

export default function FormationDirectors({formationData, createOrUpdateFormationData, parentBack}) {
    const {  handleSubmit, control, formState, setValue } = useForm();
    const theme = useTheme();
    const [isLoading, setIsLoading] = React.useState(false);
    const [selectedDirectorType, setSelectedDirectorType] = React.useState(formationData.directorShareholders.length > 0 ? FORMATION_DIRECTOR_SHAREHOLDER_TYPE.NONE : FORMATION_DIRECTOR_SHAREHOLDER_TYPE.DIRECTOR_PERSON);
    const [directorType, setDirectorType] = React.useState(formationData.directorShareholders.length > 0 ? FORMATION_DIRECTOR_SHAREHOLDER_TYPE.NONE : FORMATION_DIRECTOR_SHAREHOLDER_TYPE.DIRECTOR_PERSON);
    const [useRaOfficeAddress, setUseRaOfficeAddress] = React.useState(formationData?.useRaOfficeAddress !== null ? formationData.useRaOfficeAddress : false);
    const [editDirectorData, setEditDirectorData] = React.useState(null);
    const [lastDirectorPersonId, setLastDirectorPersonId] = React.useState(null);
    
    
    const confirmAndContinue = async (formData) => {
        const newFormationData = {
            ...formationData,
            completedTab: COMPANY_FORMATION_TABS.DIRECTORS_AND_SECRETARIES,
            formData
        };
        await createOrUpdateFormationData(newFormationData);
    }
    
    const createOrUpdateDirectors = async (directorData) => {
        const newFormationData = {
            ...formationData,
            directorShareholders: directorData
        };
        await createOrUpdateFormationData(newFormationData, false);
        setEditDirectorData(null);
    }
    
    const removeDirector = async (directorId) => {
        const newFormationData = {
            ...formationData,
            directorShareholders: formationData.directorShareholders.filter(director => director.id !== directorId)
        };
        await createOrUpdateFormationData(newFormationData, false);
    }

    const editDirector = async (directorId) => {
        const director = formationData.directorShareholders.find(director => director.id === directorId);
        setEditDirectorData(director);
        setDirectorType(director.type);
    }
    
    const setDirectorAsPrimaryContact = async (directorId) => {
        const newFormationData = {
            ...formationData,
            directorShareholders: formationData.directorShareholders.map(director => {
                if(director.id === directorId) {
                    return {
                        ...director,
                        isPrimaryContact: true
                    };
                }
                return {
                    ...director,
                    isPrimaryContact: false
                };
            })
        };
        await createOrUpdateFormationData(newFormationData, false);
    }
    
    const back = async () => {
        if(formationData.directorShareholders.length === 0) {
            parentBack();
        }
        else {
            setDirectorType(FORMATION_DIRECTOR_SHAREHOLDER_TYPE.NONE);
            setEditDirectorData(null);
        }
    }

    React.useEffect(() => {
        if(formationData.directorShareholders.filter(director => director.type === FORMATION_DIRECTOR_SHAREHOLDER_TYPE.DIRECTOR_PERSON).length === 1) {
            setLastDirectorPersonId(formationData.directorShareholders.filter(director => director.type === FORMATION_DIRECTOR_SHAREHOLDER_TYPE.DIRECTOR_PERSON)[0].id);
        }
        else{
            setLastDirectorPersonId(null);
        }
    }, [formationData]);
    

    let content;
    

    if (isLoading) {
        content = (
            <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                <CircularProgress />
            </Box>
        );
    }
    else {
        
        if(directorType === FORMATION_DIRECTOR_SHAREHOLDER_TYPE.NONE) {
            content = (
                <form onSubmit={handleSubmit(confirmAndContinue)}>
                    <Typography component={"div"} align={"left"} sx={{mb: 2, mt:4,fontSize:"24px",fontWeight:"bolder"}}>
                        Directors & Secretaries
                    </Typography>


                    <TableContainer>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{fontWeight:"bolder"}}>Name</TableCell>
                                    <TableCell sx={{fontWeight:"bolder"}}>Business Role</TableCell>
                                    <TableCell sx={{fontWeight:"bolder"}}>Correspondence Address</TableCell>
                                    <TableCell sx={{fontWeight:"bolder"}}>Primary Contact</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {formationData.directorShareholders?.filter(x=> x.type === FORMATION_DIRECTOR_SHAREHOLDER_TYPE.DIRECTOR_PERSON || x.type === FORMATION_DIRECTOR_SHAREHOLDER_TYPE.DIRECTOR_COMPANY || x.type === FORMATION_DIRECTOR_SHAREHOLDER_TYPE.SECRETARY_PERSON || x.type === FORMATION_DIRECTOR_SHAREHOLDER_TYPE.SECRETARY_COMPANY)
                                    .map((row) => (
                                    <TableRow
                                        key={row.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {row.fullName}
                                        </TableCell>
                                        <TableCell>{row.type === FORMATION_DIRECTOR_SHAREHOLDER_TYPE.DIRECTOR_PERSON || row.type === FORMATION_DIRECTOR_SHAREHOLDER_TYPE.DIRECTOR_COMPANY ? "Director" : "Secretary"}</TableCell>
                                        <TableCell>{row.correspondenceAddress?.oneLine}</TableCell>
                                        <TableCell>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <Checkbox checked={row.isPrimaryContact} disabled={formationData.directorShareholders.length === 1} onChange={() => {
                                                    setDirectorAsPrimaryContact(row.id);
                                                }}/>
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <Stack direction="row" spacing={2}>
                                                <Edit onClick={(e) => {
                                                    editDirector(row.id);
                                                }} sx={{
                                                    color: theme.palette.primary.main,
                                                    cursor: "pointer"
                                                }}/>
                                                
                                                
                                                {((lastDirectorPersonId === null || lastDirectorPersonId !== row.id) && !row.isPrimaryContact) && (
                                                    <RemoveCircleOutline
                                                        onClick={(e) => {
                                                            removeDirector(row.id);
                                                        }} sx={{
                                                        color: theme.palette.primary.main,
                                                        cursor: "pointer"
                                                    }}/>
                                                )}
                                            </Stack>
                                            
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Typography component={"div"} align={"left"} variant="formationLabelHeader" sx={{mt:2}}>
                        What type of company director or secretary would you like to add?
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={7}>
                            <TextField
                                select
                                variant="outlined"
                                defaultValue={selectedDirectorType}
                                onChange={(e) => {
                                    setSelectedDirectorType(e.target.value);
                                }}
                                InputLabelProps={{
                                    style: { ...theme.inputLabelProps },
                                }}
                                margin="normal"
                                fullWidth
                                size="small"
                            >
                                <MenuItem key={FORMATION_DIRECTOR_SHAREHOLDER_TYPE.DIRECTOR_PERSON.toString()} value={FORMATION_DIRECTOR_SHAREHOLDER_TYPE.DIRECTOR_PERSON}>A director that is a person</MenuItem>
                                <MenuItem key={FORMATION_DIRECTOR_SHAREHOLDER_TYPE.SECRETARY_PERSON.toString()}  value={FORMATION_DIRECTOR_SHAREHOLDER_TYPE.SECRETARY_PERSON}>A secretary that is a person</MenuItem>
                                <MenuItem key={FORMATION_DIRECTOR_SHAREHOLDER_TYPE.DIRECTOR_COMPANY.toString()}  value={FORMATION_DIRECTOR_SHAREHOLDER_TYPE.DIRECTOR_COMPANY}>A director that is a corporate body (corporate director)</MenuItem>
                                <MenuItem key={FORMATION_DIRECTOR_SHAREHOLDER_TYPE.SECRETARY_COMPANY.toString()}  value={FORMATION_DIRECTOR_SHAREHOLDER_TYPE.SECRETARY_COMPANY}>A secretary that is a corporate body (corporate secretary)</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={12} md={5}>
                            <Button sx={{mt:2,ml:2}} variant="contained" color="secondary" disabled={selectedDirectorType === FORMATION_DIRECTOR_SHAREHOLDER_TYPE.NONE} onClick={(e) => {
                                setDirectorType(selectedDirectorType);
                            }}>
                                Add
                            </Button>
                        </Grid>
                    </Grid>
                    

                    <br/>
                    <Stack direction="row" spacing={2} sx={{mt: 2}}>
                        <Button variant="contained" color="secondary" onClick={(e) =>{
                            parentBack();
                        }}>Back</Button>
                        <Button variant="contained" color="secondary" type="submit">Confirm & Continue</Button>
                    </Stack>
                </form>
            );
        }
        else if(directorType === FORMATION_DIRECTOR_SHAREHOLDER_TYPE.DIRECTOR_PERSON) {
            content = (
              <FormationDirectorPersonDetails formationData={formationData} createOrUpdateDirectors={createOrUpdateDirectors} directorData={editDirectorData} back={back} directorShareholderType={directorType}/>  
            );
        }
        else if(directorType === FORMATION_DIRECTOR_SHAREHOLDER_TYPE.SECRETARY_PERSON) {
            content = (
                <FormationSecretaryPersonDetails formationData={formationData} createOrUpdateDirectors={createOrUpdateDirectors} directorData={editDirectorData} back={back} directorShareholderType={directorType} />
            );
        }
        else if(directorType === FORMATION_DIRECTOR_SHAREHOLDER_TYPE.DIRECTOR_COMPANY) {
            content = (
                <FormationDirectorCorporateDetails formationData={formationData} createOrUpdateDirectors={createOrUpdateDirectors} directorData={editDirectorData} back={back} directorShareholderType={directorType}/>
            );
        }
        else if(directorType === FORMATION_DIRECTOR_SHAREHOLDER_TYPE.SECRETARY_COMPANY){
            content = (
                <FormationSecretaryCorporateDetails formationData={formationData} createOrUpdateDirectors={createOrUpdateDirectors} directorData={editDirectorData} back={back} directorShareholderType={directorType}/>
            );
        }
    }


    return (
        <Box maxWidth={"100vh"}>
            {content}
        </Box>
    );
}