import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {useContext, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import TextField from "@mui/material/TextField";
import {CircularProgress, Container} from "@mui/material";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";
import AuthContext from "../api-authorization/AuthContext";
import userService from "./UserService";
import CssBaseline from "@mui/material/CssBaseline";
import {useTheme} from "@mui/material/styles";
import Alert from "@mui/material/Alert";

export default function Login() {
    const theme = useTheme();
    const {  handleSubmit, control, formState, watch } = useForm();
    const { antiForgeryToken,  user, refreshUser } = useContext(AuthContext);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [isBusy, setIsBusy] = useState(false);
    const [loginFailureMessage, setLoginFailureMessage] = useState('');
    const navigate = useNavigate();

    React.useEffect(() => {
        if(user?.loggedIn) {
            navigate('/');
        }
    }, [navigate, user]);


    const handleFormSubmit  = async (formData) => {
        setDisableSubmit(true);
        const result = await userService.login(antiForgeryToken, formData);

        let data = await result.json();
        if (data.success) {
            await refreshUser();
            navigate('/');
        } else {
            setLoginFailureMessage(data.message);
        }
        setDisableSubmit(false);
        setIsBusy(false);
    };

    let content;
    if (isBusy) {
        content = (
            <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                <CircularProgress />
            </Box>
        );
    } else {
        content = (
            <>
            <CssBaseline />
            <Box
                sx={{
                    backgroundImage: `url(${theme.loginBackgroundImage})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    width: '100%',
                    height: '100vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
             <Container component="main" sx={{backgroundColor:"white",border:2,borderColor:'grey.200',pt:6,pb:10, width:"500px" }}>
                 <Box
                     component="form" noValidate onSubmit={handleSubmit(handleFormSubmit)}
                     sx={{
                         marginTop: 4,
                         display: 'flex',
                         flexDirection: 'column',
                         alignItems: 'center',
                     }}
                 >
                     <img src={theme.loginLogo} alt="logo" style={{height: "80px"}}/>
                     <Typography variant="h5" sx={{mt: 4, mb: 3}}>
                         Welcome!
                     </Typography>
                     <Typography variant="h6">
                         Enter your details to get started
                     </Typography>
                     {loginFailureMessage !== '' &&
                         <Alert sx={{width: "80%", mt: 2}} severity="error">{loginFailureMessage}</Alert>
                     }
                     <Controller
                         name="emailAddress"
                         control={control}
                         defaultValue=""
                         rules={{required: 'Email address is required'}}
                         render={({field}) => (
                             <TextField
                                 {...field}
                                 label="Email address"
                                 variant="outlined"
                                 margin="normal"
                                 sx={{width: "80%"}}
                                 InputLabelProps={{
                                     shrink: true,
                                 }}
                                 size="small"
                                 error={!!formState.errors.emailAddress}
                                 helperText={formState.errors.emailAddress ? formState.errors.emailAddress.message : ''}
                             />
                         )}
                     />
                     <Controller
                         name="password"
                         control={control}
                         defaultValue=""
                         rules={{required: 'Password is required'}}
                         render={({field}) => (
                             <TextField
                                 {...field}
                                 label="Password"
                                 variant="outlined"
                                 margin="normal"
                                 sx={{width: "80%"}}
                                 InputLabelProps={{
                                     shrink: true,
                                 }}
                                 size="small"
                                 type="password"
                                 error={!!formState.errors.password}
                                 helperText={formState.errors.password ? formState.errors.password.message : ''}
                             />
                         )}
                     />
                     <Typography sx={{mt: 1, textAlign: "right", width: "70%"}} variant="forgotPasswordLink">
                         <a href={"/Users/RequestPasswordReset"}>Forgot Password?</a>
                     </Typography>
                     <br/>
                     <Button sx={{width: "20%"}} variant="contained" color="secondary" type="submit"
                             disabled={disableSubmit}>
                         Login
                     </Button>
                 </Box>
             </Container>
            </Box>
            </>
        );
    }

    return (
        <>
            {content}
        </>
    );
}