const findCompanies  = async (antiForgeryToken, searchRequest) => {
    return fetch(`api/CompaniesHouse/Find`, {
        method: 'POST',
        headers: buildAuthHeaders(antiForgeryToken),
        body: JSON.stringify(searchRequest)
    });
};

const getCompany  = async (antiForgeryToken, companyNumber) => {
    return fetch(`api/CompaniesHouse/GetCompany/${companyNumber}`, {
        method: 'GET',
        headers: buildAuthHeaders(antiForgeryToken)
    });
};

const companiesHouseNameSearch  = async (antiForgeryToken, companiesHouseNameSearchRequest) => {
    return fetch(`api/CompaniesHouse/CompaniesHouseNameSearch`, {
        method: 'POST',
        headers: buildAuthHeaders(antiForgeryToken),
        body: JSON.stringify(companiesHouseNameSearchRequest)
    });
};


function buildAuthHeaders(antiForgeryToken) {
    return !antiForgeryToken ? {} : {
        [antiForgeryToken.tokenName] : antiForgeryToken.token,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    };
}

const companiesHouseService = Object.freeze({
    findCompanies,
    getCompany,
    companiesHouseNameSearch
});
export default companiesHouseService;