import * as React from 'react';
import { useForm } from 'react-hook-form';
import {useContext, useEffect, useState} from "react";
import Button from "@mui/material/Button";
import {Box, CircularProgress, Stack} from "@mui/material";
import {enqueueSnackbar} from "notistack";
import {useNavigate, useParams} from "react-router-dom";
import Typography from "@mui/material/Typography";
import AuthContext from "../api-authorization/AuthContext";
import inputService from "../util/InputService";
import {CLIENT_TABS, ROLES, USER_ROLES} from "../enums";
import UpdateUserForm from "../users/UpdateUserForm";
import userService from "../users/UserService";
import NavigationContext from "../layout/NavigationContext";
import Breadcrumb from "../layout/Breadcrumb";

export default function UpdateClientUser() {
    const { histNavigate,back } = useContext(NavigationContext);
    const { antiForgeryToken, user, refreshUser} = useContext(AuthContext);
    const {  handleSubmit, control, formState, watch } = useForm();
    const [userData, setUserData] = useState();
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [isBusy, setBusy] = useState(true);
    const navigate = useNavigate();
    const { userId } = useParams();

    useEffect(() => {
        async function fetchData() {
            const result = await userService.getUser(antiForgeryToken, userId);
            if (result.status === 401) {
                refreshUser();
                navigate('/login');
            }
            else {
                let data = await result.json();
                if (result.ok) {
                    setUserData(data);
                    setBusy(false);
                } else {
                    enqueueSnackbar(data.description);
                }
            }
        }
        fetchData();
    }, [])
    
    const handleFormSubmit  = async (formData) => {
        setBusy(true);
        setDisableSubmit(true);
        formData = inputService.trimFields(formData);
        formData.userId = userData.id;
        const result = await userService.updateUser(antiForgeryToken, formData);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                enqueueSnackbar("Successfully updated client user");
            } else {
                enqueueSnackbar(data.description);
            }
        }
        setDisableSubmit(false);
        setBusy(false);
    };

    const reInviteUser  = async () => {
        setBusy(true);
        setDisableSubmit(true);
        const result = await userService.reInviteUser(antiForgeryToken, userId);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                enqueueSnackbar("Successfully re-invited user");
                histNavigate(`/Client/Update/${userData.clientId}/${CLIENT_TABS.USERS}`,'Client');
            } else {
                enqueueSnackbar(data.description);
            }
        }
        setDisableSubmit(false);
        setBusy(false);
    };

    const sendResetPasswordLink  = async () => {
        setBusy(true);
        setDisableSubmit(true);
        const result = await userService.adminSendPasswordResetLink(antiForgeryToken, userId);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                enqueueSnackbar("Successfully sent reset password");
                histNavigate(`/Client/Update/${userData.clientId}/${CLIENT_TABS.USERS}`,'Client');
            } else {
                enqueueSnackbar(data.description);
            }
        }
        setDisableSubmit(false);
        setBusy(false);
    };

    const isDateNull = (dateParam) => {
        const date = new Date(dateParam);
        if (isNaN(date.getTime()) || date.getFullYear() <= 1) {
            return true;
        }
        return false;
    };


    let content;
    if (isBusy) {
        content = (
            <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                <CircularProgress />
            </Box>
        );
    } else {
        let readonly = true;
        if(user.role === ROLES.TENANT_ADMIN || user.role === ROLES.CLIENT_ADMIN || user.userId == userData.id){
            readonly = false;
        }
        
        content = (
            <Box sx={{ maxWidth: 1200 }}>
                {(user.role === ROLES.TENANT_ADMIN || user.role === ROLES.TENANT_STANDARD || user.role === ROLES.CLIENT_ADMIN || user.role === ROLES.CLIENT_STANDARD) &&(
                    <Breadcrumb breadcrumbItems={userData.breadcrumbItems}/>
                )}
                
                <Typography variant="h5" noWrap align={"left"} sx={{mb: 2}}>
                    User - {userData.email}
                </Typography>
                <form onSubmit={handleSubmit(handleFormSubmit)}>
                    <UpdateUserForm formState={formState} control={control} watch={watch} defaultValues={userData} adminRole={USER_ROLES.CLIENT_ADMIN} standardRole={USER_ROLES.CLIENT_STANDARD} user={user} readonly={readonly}/>
                    <Stack direction="row" spacing={2} marginTop={2}>
                        <Button variant="contained" color="secondary" onClick={(e) => {
                            navigate(-1);
                        }}>
                            Back
                        </Button>
                        {!readonly && (
                            <>
                                <Button variant="contained" color="secondary" type="submit" disabled={disableSubmit}>
                                    Update
                                </Button>
                                {isDateNull(userData.activatedAt) && (
                                    <Button variant="contained" color="secondary" onClick={reInviteUser}>
                                        Send Activation
                                    </Button>
                                )}
                                <Button variant="contained" color="secondary" onClick={sendResetPasswordLink}>
                                    Reset Password
                                </Button>
                            </>
                        )}
                    </Stack>
                </form>
            </Box>
        );
    }

    return (
        <>
            {content}
        </>
    );
}