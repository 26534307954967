import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import Button from "@mui/material/Button";
import {CircularProgress, Grid, Stack} from "@mui/material";
import {enqueueSnackbar} from "notistack";
import {useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";
import accountService from "../accounts/AccountService";
import Typography from "@mui/material/Typography";
import {useTheme} from "@mui/material/styles";
import {ArrowRight} from "@mui/icons-material";

export default function FinalConfirmation({antiForgeryToken, refreshUser}) {
    const theme = useTheme();
    const { handleSubmit, control, formState } = useForm();
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [isBusy, setBusy] = useState(false);
    const navigate = useNavigate();

    const handleFormSubmit  = async (formData) => {
        setDisableSubmit(true);
        const result = await accountService.confirmDetailsForUsersAccount(antiForgeryToken);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                enqueueSnackbar("Successfully updated account");
                await refreshUser();
                navigate('/');
            } else {
                enqueueSnackbar(data.description); // Assuming 'error' property in data
            }
        }
        setDisableSubmit(false);
    };


    let content;
    if (isBusy) {
        content = (
            <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                <CircularProgress />
            </Box>
        );
    } else {
        content = (
            <Box sx={{ maxWidth:1200, padding: 4 }}>
                <form onSubmit={handleSubmit(handleFormSubmit)}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={8} md={6}>
                            <Typography variant="h5" color="text.primary" gutterBottom>
                                Congratulations, set up is complete!
                            </Typography>
                            <Typography sx={{mt:2,mb:2,fontWeight:500}}>Thanks for confirming your account and company contact information.
                                Click below to access your Registered Agent account, powered by British Monomarks!</Typography>
                            <Typography sx={{mt:4,mb:2,fontSize:"1.2rem",fontWeight:950}}>Features</Typography>
                            <Grid container alignItems="center" sx={{ flexWrap: 'nowrap',mt:2 }}>
                                <Grid item>
                                    <ArrowRight sx={{ fontSize: "22px", color: 'yourColor', mt: 'auto', mb: 'auto' }} />
                                </Grid>
                                <Grid item xs zeroMinWidth>
                                    <Typography sx={{ textAlign: "left", overflowWrap: 'anywhere',fontSize:"1rem",fontWeight:500 }}>
                                        View your digital company mail and manage your forwarding information
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container alignItems="center" sx={{ flexWrap: 'nowrap' }}>
                                <Grid item>
                                    <ArrowRight sx={{ fontSize: "22px", color: 'yourColor', mt: 'auto', mb: 'auto' }} />
                                </Grid>
                                <Grid item xs zeroMinWidth>
                                    <Typography sx={{ textAlign: "left", mt: 1, overflowWrap: 'anywhere',fontSize:"1rem",fontWeight:500 }}>
                                        View your invoices, update your billing information and manage your subscriptions
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container alignItems="center" sx={{ flexWrap: 'nowrap' }}>
                                <Grid item>
                                    <ArrowRight sx={{ fontSize: "22px", color: 'yourColor', mt: 'auto', mb: 'auto' }} />
                                </Grid>
                                <Grid item xs zeroMinWidth>
                                    <Typography sx={{ textAlign: "left", mt: 1, overflowWrap: 'anywhere',fontSize:"1rem",fontWeight:500 }}>
                                        File your Companies House confirmation statements
                                    </Typography>
                                </Grid>
                            </Grid>
                            
                            <Stack direction="row" spacing={2} marginTop={4} marginBottom={4}>
                                <Button variant="contained" color="secondary" type="submit" disabled={disableSubmit}>
                                    Go to dashboard
                                </Button>
                            </Stack>
                            <Typography sx={{mt: 6,fontSize:"0.8rem"}} variant="loginInfoMessages">By accessing your account you are accepting the Registered Agent
                                &nbsp;<a target={"_blank"} href={"/GuestTermsAndConditions"}>Terms and Conditions</a>&nbsp;of service.</Typography>
                        </Grid>
                        <Grid item xs={12} sm={8} md={6} sx={{textAlign:"center"}}>
                            <img src={theme.loginLogo} alt="logo" style={{height: "120px"}}/>
                        </Grid>
                    </Grid>

                </form>
            </Box>
        );
    }

    return (
        <>
            {content}
        </>
    );
}