import IconButton from "@mui/material/IconButton";
import { useSnackbar } from 'notistack';
import * as React from 'react';
import CloseIcon from "@mui/icons-material/Close";

function SnackbarCloseButton({ snackbarKey }) {
    const { closeSnackbar } = useSnackbar();

    return (
        <IconButton onClick={() => closeSnackbar(snackbarKey)} style={{ color: '#fff' }}>
            <CloseIcon />
        </IconButton>
    );
}

export default SnackbarCloseButton;