import React, {useContext, useState} from 'react'
import {
    Pagination,
    Stack
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {enqueueSnackbar} from "notistack";
import {useNavigate} from "react-router-dom";
import userService from "../users/UserService";
import NavigationContext from "../layout/NavigationContext";
import {DataGridPro} from "@mui/x-data-grid-pro";

export default function ListAccountUsers({accountId, antiForgeryToken, refreshUser, pageSize}) {
    const { histNavigate, back } = useContext(NavigationContext);
    const [searchTerm, setSearchTerm] = useState("");
    const [isLoading, setIsLoading] = React.useState(true);
    const [users, setUsers] = React.useState([]);
    const [rowCountState, setRowCountState] = React.useState(0);
    const [paginationModel, setPaginationModel] = React.useState({
        page: 0,
        pageSize: pageSize,
    });
    const navigate = useNavigate();

    const fetchData = async () => {
        setPaginationModel((prevModel) => ({...prevModel, page: 0}));
        let searchRequest = {
            appScope: 3,
            searchTerm: searchTerm,
            accountId: accountId
        };
        const result = await userService.listUsers(antiForgeryToken, searchRequest);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        let data = await result.json();
        if (result.ok) {
            setUsers(data.users);
            setRowCountState(data.users.length);
            setIsLoading(false);
        } else {
            enqueueSnackbar(data.description);
        }
    };


    React.useEffect(() => {
        fetchData();
    }, []);

    const handlePageChange = (params) => {
        setPaginationModel({
            page: params.page,
            pageSize: params.pageSize,
        });
    };
    
    const handleRowClick = (params) => {
        histNavigate(`/Account/UpdateUser/${params.id}`,'Update User');
    };

    const dateFormatter = (params) => {
        const date = new Date(params.value);
        if (isNaN(date.getTime()) || date.getFullYear() <= 1) {
            return "None";
        }
        return date.toLocaleDateString('en-GB');
    };

    return (
        <Box style={{ height: 400 }}>
            <br />
            <DataGridPro
                rows={users.slice(
                    paginationModel.page * paginationModel.pageSize,
                    paginationModel.page * paginationModel.pageSize + paginationModel.pageSize
                )}
                columns={[
                    { field: 'email', headerName: 'Email', flex: 1 },
                    { field: 'status', headerName: 'Status', flex: 1 },
                    { field: 'role', headerName: 'Role', flex: 1 },
                    { field: 'dateCreated', headerName: 'Date Created', flex: 1, valueFormatter: dateFormatter },
                    { field: 'activatedAt', headerName: 'Activation Date', flex: 1, valueFormatter: dateFormatter  },
                    { field: 'lastLogin', headerName: 'Last Login', flex: 1, valueFormatter: dateFormatter  }
                ]}
                rowCount={paginationModel.pageSize}
                pageSize={paginationModel.pageSize}
                hideFooter={true}
                page={paginationModel.page}
                onPageChange={handlePageChange}
                loading={isLoading}
                onRowClick={handleRowClick}
                getRowClassName={() => 'rowCursorPointer'}
                autoHeight
                sx={{
                    '.MuiDataGrid-columnHeaderTitle': {
                        WebkitTextStroke: '0.75px'
                    },
                }}
            />
            <Box display="flex" justifyContent="flex-end">
                <Pagination
                    count={Math.ceil(rowCountState / paginationModel.pageSize)}
                    page={paginationModel.page + 1}
                    onChange={(event, page) => handlePageChange({ page: page - 1, pageSize: paginationModel.pageSize })}
                />
            </Box>
            <Stack direction="row" spacing={2} marginTop={2}>
                <Button variant="contained" color="secondary" onClick={(e) => {
                    e.preventDefault();
                    histNavigate(`/Account/CreateUser/${accountId}`,'Create User');
                }}>
                    Add User
                </Button>
            </Stack>
        </Box>
    );

}