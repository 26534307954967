import React, {useContext, useEffect} from 'react';
import AuthContext from "../api-authorization/AuthContext";
import CompanyQuickSearch from "./CompanyQuickSearch";
import {useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";
import {CircularProgress} from "@mui/material";
import NavigationContext from "../layout/NavigationContext";
import AccountDetailsConfirmationParent from "../onboarding/AccountDetailsConfirmationParent";

export default function Home() {
    const { histNavigate,back } = useContext(NavigationContext);
    const { user, userLoading, refreshUser } = useContext(AuthContext);
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(true);

    //Because we're not doing any authenticated actions until the user searches, use this as a hearbeat to check if the user is still logged in
    useEffect(() => {
        fetch('/api/Auth/AntiForgery')
            .then(response => response.json())
            .then(data => {
                fetch('/api/Auth/GetLoggedInUserInfo',{
                    method: 'GET',
                    headers: {
                        [data.tokenName]: data.token
                    },
                })
                    .then(response => response.json())
                    .then(data => {
                        if(data.loggedIn === false){
                            refreshUser();
                            navigate('/login');
                        }
                    });
            })
    }, []);
    
    let content = (
        <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
            <CircularProgress />
        </Box>
    )
    
    if(!userLoading && user.loggedIn){
        content = (
            <>
                {user.needsToConfirmAccountDetails === true ? (
                    <AccountDetailsConfirmationParent/>
                ) : (
                    <CompanyQuickSearch/>
                )}
            </>
        )
    }
    
    return content;
}
