import * as React from 'react';
import Typography from '@mui/material/Typography';
import {useContext} from "react";
import AuthContext from "../api-authorization/AuthContext";
import ListAccountFormations from "./ListAccountFormations";

export default function MyFormations() {
    const { antiForgeryToken, user, refreshUser} = useContext(AuthContext);

    
    return (
        <>
            <Typography variant="h5" noWrap component="div" align={"left"} sx={{mb: 4}}>
                My Formations
            </Typography>
            <ListAccountFormations accountId={user.accountId} antiForgeryToken={antiForgeryToken} refreshUser={refreshUser}/>
        </>
    );
}