import React, {useContext, useState} from 'react';
import { useForm, Controller } from 'react-hook-form';
import TextField from "@mui/material/TextField";
import {
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid, InputAdornment, MenuItem, Select, Stack
} from "@mui/material";
import {useNavigate} from "react-router-dom";
import Button from "@mui/material/Button";
import {useTheme} from "@mui/material/styles";
import companyService from "./CompanyService";
import {enqueueSnackbar} from "notistack";
import Box from "@mui/material/Box";
import {ROLES} from "../enums";
import NavigationContext from "../layout/NavigationContext";
import inputService from "../util/InputService";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import Dayjs from 'dayjs';
import Divider from "@mui/material/Divider";

export default function PostBoxDetails({antiForgeryToken, user, companyData, refreshUser, fetchData}) {
    const { back,histNavigate } = useContext(NavigationContext);
    const theme = useTheme();
    const { handleSubmit, control, formState, setValue } = useForm();
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [removeDialogOpen, setRemoveDialogOpen] = React.useState(false);
    const [sendCompanyEmailDialogOpen, setSendCompanyEmailDialogOpen] = React.useState(false);
    const [selectedEmailType, setSelectedEmailType] = React.useState(0);
    
    const [isBusy, setIsBusy] = useState(false);
    const navigate = useNavigate();

    let readonly = true;
    if(user.role === ROLES.TENANT_ADMIN || user.role === ROLES.TENANT_STANDARD || user.role === ROLES.CLIENT_ADMIN || user.role === ROLES.CLIENT_STANDARD){
        readonly = false;
    }
    
    const handleRemoveDialogOpen = () => {
        setRemoveDialogOpen(true);
    };

    const handleRemoveDialogClose = () => {
        setRemoveDialogOpen(false);
    };

    const handleSendCompanyEmailOpen = () => {
        setSendCompanyEmailDialogOpen(true);
    };

    const handleConfStatementDialogClose = () => {
        setSendCompanyEmailDialogOpen(false);
    };

    const handleFormSubmit  = async (formData) => {
        setDisableSubmit(true);
        formData = inputService.trimFields(formData);
        formData.id = companyData.id;
        const result = await companyService.updatePostBox(antiForgeryToken, formData);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                fetchData();
                enqueueSnackbar("Successfully updated post box");
            } else {
                enqueueSnackbar(data.description);
            }
        }
        setDisableSubmit(false);
    };
    
    const sendCompanyEmail = async () => {
        setIsBusy(true);
        let sendEmailRequest = {
            companyId: companyData.id,
            emailType: selectedEmailType
        };
        const result = await companyService.sendCompanyEmail(antiForgeryToken, sendEmailRequest);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                enqueueSnackbar("Successfully triggered email");
            } else {
                enqueueSnackbar(data.description);
            }
        }
        setSendCompanyEmailDialogOpen(false);
        setIsBusy(false);
    }
    
    const removePostBox  = async () => {
        setIsBusy(true);
        const result = await companyService.removePostBoxFromAccount(antiForgeryToken, companyData.id);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                enqueueSnackbar("Successfully removed post box from account");
                back();
            } else {
                enqueueSnackbar(data.description);
            }
        }
        setRemoveDialogOpen(false);
        setIsBusy(false);
    };

    
    
    let content;
    if (isBusy) {
        content = (
            <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                <CircularProgress />
            </Box>
        );
    } else {
        content = (
            <>
                <form onSubmit={handleSubmit(handleFormSubmit)}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={6}>
                            <Controller
                                name="Client"
                                control={control}
                                defaultValue={companyData.clientName}
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        label="Client"
                                        variant="outlined"
                                        InputLabelProps={{
                                            style: {...theme.inputLabelProps},
                                        }}
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                        disabled={true}
                                    />
                                )}
                            />
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Controller
                                    name="postBoxStartDate"
                                    control={control}
                                    defaultValue={companyData.postBoxStartDate != null ? Dayjs(companyData.postBoxStartDate) : null}
                                    render={({ field }) => (
                                        <DatePicker
                                            {...field}
                                            label="Start Date"
                                            format={"DD/MM/YYYY"}
                                            slotProps={{ textField: { size: 'small', fullWidth: true } }}
                                            variant="outlined"
                                            margin="normal"
                                            sx={{mt:2.1}}
                                            fullWidth
                                            size="small"
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Controller
                                    name="postBoxEndDate"
                                    control={control}
                                    defaultValue={companyData.postBoxEndDate != null ? Dayjs(companyData.postBoxEndDate) : null}
                                    render={({ field }) => (
                                        <DatePicker
                                            {...field}
                                            label="End Date"
                                            format={"DD/MM/YYYY"}
                                            slotProps={{ textField: { size: 'small', fullWidth: true } }}
                                            sx={{mt:2.9}}
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            size="small"
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                            <Controller
                                name="postBoxNotes"
                                control={control}
                                defaultValue={companyData.postBoxNotes}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        multiline
                                        rows={5}
                                        label="Box Notes"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <Controller
                                name="postBoxStatus"
                                control={control}
                                defaultValue={companyData.postBoxStatus}
                                rules={{ required: 'Status is required' }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        select
                                        label="Status"
                                        variant="outlined"
                                        InputLabelProps={{
                                            style: { ...theme.inputLabelProps },
                                        }}
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                        disabled={readonly}
                                        error={!!formState.errors.status}
                                        helperText={formState.errors.status ? formState.errors.status.message : ''}
                                    >
                                        <MenuItem key="1" value={1}>Enabled</MenuItem>
                                        <MenuItem key="2" value={2}>Disabled</MenuItem>
                                    </TextField>
                                )}
                            />
                            <Controller
                                name="postBoxName"
                                control={control}
                                defaultValue={companyData.postBoxNameType === 1 ? companyData.postBoxName.replace("BM BOX ", "") : companyData.postBoxName}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label={companyData.postBoxNameType === 1 ? "Post Box Number *" : "Post Box Name *"}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                        disabled={true}
                                        InputProps={{
                                            maxLength: companyData.postBoxNameType === 1 ? 4: 100,
                                            startAdornment: companyData.postBoxNameType === 1 ?
                                                <InputAdornment position="start">BM BOX</InputAdornment> : null,
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <Divider sx={{ height: 28, m: 0.5, borderWidth:1, borderColor:'grey.500', borderLeft: 1 }} orientation="vertical" />
                                                    <Select
                                                        displayEmpty
                                                        value={companyData.postBoxNameType}
                                                        disabled={true}
                                                        sx={{
                                                            p: 0,
                                                            minWidth: 'auto',
                                                            '& .MuiOutlinedInput-notchedOutline': { border: 0 },
                                                            '&:focus-within .MuiOutlinedInput-notchedOutline': { border: 0 },
                                                            '&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { border: 0 },
                                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': { border: 0 },
                                                            '& .MuiSelect-root:focus': {
                                                                background: 'none',
                                                            },
                                                        }}
                                                        MenuProps={{
                                                            anchorOrigin: {
                                                                vertical: "bottom",
                                                                horizontal: "right"
                                                            },
                                                            transformOrigin: {
                                                                vertical: "top",
                                                                horizontal: "right"
                                                            }
                                                        }}
                                                    >
                                                        <MenuItem value={1}>Number</MenuItem>
                                                        <MenuItem value={2}>Name</MenuItem>
                                                    </Select>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                            <Controller
                                name="previousNames"
                                control={control}
                                defaultValue={companyData.previousNames}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Previous Names"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                    {(user.role === ROLES.TENANT_ADMIN || user.role === ROLES.TENANT_STANDARD) && (
                        <>
                            <Grid item xs={12} sm={12} md={6}>
                                    <Controller
                                        name="emailType"
                                        control={control}
                                        defaultValue={selectedEmailType}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                select
                                                label="Email"
                                                variant="outlined"
                                                InputLabelProps={{
                                                    style: { ...theme.inputLabelProps },
                                                }}
                                                size="small"
                                                fullWidth
                                                margin="normal"
                                                onChange={(e) => {
                                                    setSelectedEmailType(e.target.value);
                                                    field.onChange(e); // keep this line
                                                }}
                                            >
                                                <MenuItem key={0} value={0}>&nbsp;</MenuItem>
                                                <MenuItem key={7} value={7}>Confirmation Statement Overdue</MenuItem>
                                            </TextField>
                                        )}
                                    />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <Button variant="contained" color="secondary" sx={{minWidth:140,maxWidth:140,mt:2}} onClick={handleSendCompanyEmailOpen}>
                                    Send Email
                                </Button>
                            </Grid>
                        </>
                    )}
                    </Grid>
                    <Stack direction="row" spacing={2} marginTop={4}>
                        <Button variant="contained" color="secondary" onClick={(e) => {
                            back();
                        }}>
                            Back
                        </Button>
                        <Button variant="contained" color="secondary" type="submit" disabled={disableSubmit}>
                            Update
                        </Button>
                        <Button variant="contained" color="secondary" onClick={handleRemoveDialogOpen}>
                            Remove Post Box
                        </Button>
                        {
                            user.role == ROLES.ACCOUNT_ADMIN && user.multipleCompanies === false
                                ?
                                <Button variant="contained" color="secondary"
                                        onClick={() => histNavigate(`/Account/AddCompany/${user.accountId}`, 'Add Company')}>
                                    Add Company
                                </Button>
                                : (
                                    <>
                                    </>
                                )
                        }

                    </Stack>
                </form>
                <Dialog
                    open={removeDialogOpen}
                    onClose={handleRemoveDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Warning"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you wish to remove {companyData.postBoxName}?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleRemoveDialogClose}>No</Button>
                        <Button onClick={removePostBox} autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={sendCompanyEmailDialogOpen}
                    onClose={handleConfStatementDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Warning"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you wish to trigger an email for this company?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleConfStatementDialogClose}>No</Button>
                        <Button onClick={sendCompanyEmail} autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }

    return (
        <>
            {content}
        </>
    );
}