import React from 'react';
import {ErrorOutline} from "@mui/icons-material";
import {styled} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Tooltip, {tooltipClasses} from "@mui/material/Tooltip";

const HtmlTooltipComponent = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} placement="right" />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: 'white',
        color: 'black',
        maxWidth: 540,
        border: '1px solid #dadde9',
        borderLeft: '10px solid #dadde9',
        borderRadius: '5px',
    },
}));
export default function TabErrorAlert({title,messages}) {
    return (
        <HtmlTooltipComponent
            title={
                <React.Fragment>
                    <Typography sx={{fontWeight:"bolder",fontSize:"14px"}}>{title}</Typography>
                    {messages.map((msg, index) => (
                        <Typography key={index} sx={{fontSize: "14px"}}>
                            {msg}
                        </Typography>
                    ))}
                </React.Fragment>
            }
        >
            <ErrorOutline sx={{ml:1,color:"red",cursor:"pointer"}} />
        </HtmlTooltipComponent>
    );
}