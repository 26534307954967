async function getCheckoutLabels(antiForgeryToken, mailCheckoutId) {
    return fetch(`/api/Label/GetCheckoutLabels/${mailCheckoutId}`, {
        method: 'GET',
        headers: buildAuthHeaders(antiForgeryToken)
    });
}

async function getCompanyLabel(antiForgeryToken, companyId) {
    return fetch(`/api/Label/GetCompanyLabel/${companyId}`, {
        method: 'GET',
        headers: buildAuthHeaders(antiForgeryToken)
    });
}

async function getMailLabel(antiForgeryToken, mailId) {
    return fetch(`/api/Label/GetMailLabel/${mailId}`, {
        method: 'GET',
        headers: buildAuthHeaders(antiForgeryToken)
    });
}

async function getMailInformationLabel(antiForgeryToken, mailId) {
    return fetch(`/api/Label/GetMailInformationLabel/${mailId}`, {
        method: 'GET',
        headers: buildAuthHeaders(antiForgeryToken)
    });
}

async function getRtsLabel(antiForgeryToken, mailId) {
    return fetch(`/api/Label/GetRtsLabel/${mailId}`, {
        method: 'GET',
        headers: buildAuthHeaders(antiForgeryToken)
    });
}

function buildAuthHeaders(antiForgeryToken) {
    return !antiForgeryToken ? {} : {
        [antiForgeryToken.tokenName] : antiForgeryToken.token,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    };
}


const labelService = Object.freeze({
    getCheckoutLabels,
    getCompanyLabel,
    getMailLabel,
    getMailInformationLabel,
    getRtsLabel
});
export default labelService;