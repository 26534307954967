import * as React from 'react';
import Typography from '@mui/material/Typography';
import {useContext} from "react";
import AuthContext from "../api-authorization/AuthContext";
import ListClientUsers from "../clients/ListClientUsers";


export default function ClientUsers() {
    const { antiForgeryToken, user, refreshUser } = useContext(AuthContext);

    return (
        <>
            <Typography variant="h5" noWrap component="div" align={"left"} sx={{mb: 2}}>
                Manage Users
            </Typography>
            <ListClientUsers clientId={user.clientId} antiForgeryToken={antiForgeryToken} refreshUser={refreshUser} user={user}/>
        </>
    );
}