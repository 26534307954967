import * as React from 'react';
import Box from "@mui/material/Box";
import {
    Checkbox,
    CircularProgress,
    FormControl, FormControlLabel, FormHelperText, Grid,
    MenuItem, Radio,
    RadioGroup, Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import {COMPANY_FORMATION_TABS, FORMATION_DIRECTOR_SHAREHOLDER_TYPE, FORMATION_OTHER_SHARE_CURRENCY} from "../enums";
import {Controller, useForm} from "react-hook-form";
import TextField from "@mui/material/TextField";
import {useTheme} from "@mui/material/styles";
import Button from "@mui/material/Button";
import FormationShareholderPersonDetails from "./FormationShareholderPersonDetails";
import {FormGroup} from "reactstrap";
import FormationSecretaryCorporateDetails from "./FormationSecretaryCorporateDetails";
import HtmlTooltip from "../layout/HtmlTooltip";
import FormationShareholderCorporateDetails from "./FormationShareholderCorporateDetails";
import {Edit, RemoveCircleOutline} from "@mui/icons-material";
import FormationDirectorPersonDetails from "./FormationDirectorPersonDetails";
import FormationSecretaryPersonDetails from "./FormationSecretaryPersonDetails";
import FormationDirectorCorporateDetails from "./FormationDirectorCorporateDetails";

export default function FormationShareholders({formationData, createOrUpdateFormationData, parentBack}) {
    const {  handleSubmit, control, formState, setValue } = useForm();
    const theme = useTheme();
    const [isLoading, setIsLoading] = React.useState(false);
    const [selectedDirectorType, setSelectedDirectorType] = React.useState(FORMATION_DIRECTOR_SHAREHOLDER_TYPE.NONE);
    const [directorType, setDirectorType] = React.useState(FORMATION_DIRECTOR_SHAREHOLDER_TYPE.NONE);
    const [useRaOfficeAddress, setUseRaOfficeAddress] = React.useState(formationData?.useRaOfficeAddress !== null ? formationData.useRaOfficeAddress : false);
    const [editDirectorData, setEditDirectorData] = React.useState(null);
    const [useDefaultShareValue, setUseDefaultShareValue] = React.useState(formationData?.useDefaultShareValue !== null ? formationData.useDefaultShareValue : true);
    const [totalShares, setTotalShares] = React.useState(0);
    const [shareholderShares, setShareholderShares] = React.useState({});
    const [lastDirectorPersonId, setLastDirectorPersonId] = React.useState(null);
    
    const confirmAndContinue = async (formData) => {
        const newFormationData = {
            ...formationData,
            completedTab: COMPANY_FORMATION_TABS.SHAREHOLDERS,
            useDefaultShareValue : formData.useDefaultShareValue === "true" ? true : false,
            otherShareCurrency: formData.otherShareCurrency,
            otherShareValue: formData.otherShareValue,
            companySharesConfirmed: formData.companySharesConfirmed
        };

        for (const key in formData) {
            if (key.startsWith("numberOfShares_")) {
                const directorId = key.split("_")[1];
                let director = newFormationData.directorShareholders.find(dir => dir.id === directorId);
                if (director) {
                    director.numberOfShares = parseInt(formData[key], 10);
                }
            }
        }
        
        await createOrUpdateFormationData(newFormationData);
    }
    
    const createOrUpdateDirectors = async (directorData) => {
        const newFormationData = {
            ...formationData,
            directorShareholders: directorData
        };
        await createOrUpdateFormationData(newFormationData, false);
        setEditDirectorData(null);
    }

    const removeDirector = async (directorId) => {
        const newFormationData = {
            ...formationData,
            directorShareholders: formationData.directorShareholders.filter(director => director.id !== directorId)
        };
        await createOrUpdateFormationData(newFormationData, false);
    }

    const editDirector = async (directorId) => {
        const director = formationData.directorShareholders.find(director => director.id === directorId);
        setEditDirectorData(director);
        setDirectorType(director.type);
    }

    const calculateTotalShares = () => {
        let total = 0;
        for (const id in shareholderShares) {
            if(shareholderShares[id] !== null && shareholderShares[id] !== undefined && shareholderShares[id] > 0){
                total += shareholderShares[id];
            }
        }
        setTotalShares(total);
    };

    React.useEffect(() => {
        calculateTotalShares();
    }, [shareholderShares]);

    React.useEffect(() => {
        if (formationData.directorShareholders) {
            const initialShares = formationData.directorShareholders
                .filter(row => row.directorIsShareholder || (row.type === FORMATION_DIRECTOR_SHAREHOLDER_TYPE.SHAREHOLDER_PERSON) || (row.type === FORMATION_DIRECTOR_SHAREHOLDER_TYPE.SHAREHOLDER_COMPANY))
                .reduce((acc, shareholder) => { acc[shareholder.id] = shareholder.numberOfShares; 
                    return acc; 
                    }, {});
            setShareholderShares(initialShares);
        }
    }, []);
    
    
    const back = async () => {
        setDirectorType(FORMATION_DIRECTOR_SHAREHOLDER_TYPE.NONE);
        setEditDirectorData(null);
    }

    React.useEffect(() => {
        if(formationData.directorShareholders.filter(director => director.type === FORMATION_DIRECTOR_SHAREHOLDER_TYPE.DIRECTOR_PERSON).length === 1) {
            setLastDirectorPersonId(formationData.directorShareholders.filter(director => director.type === FORMATION_DIRECTOR_SHAREHOLDER_TYPE.DIRECTOR_PERSON)[0].id);
        }
        else{
            setLastDirectorPersonId(null);
        }
    }, [formationData]);
    

    let content;

    function formatDecimal(valueToFormat) {
        let value = parseFloat(valueToFormat);
        if (!isNaN(value)) {
            if (value > 999.99) {
                value = 999.99;
            }
            value = value.toFixed(2);
        } else {
            value = '';
        }
        return value;
    }
    

    if (isLoading) {
        content = (
            <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                <CircularProgress />
            </Box>
        );
    }
    else {
        
        if(directorType === FORMATION_DIRECTOR_SHAREHOLDER_TYPE.NONE) {
            content = (
                <form onSubmit={handleSubmit(confirmAndContinue)}>
                    <Typography component={"div"} align={"left"} sx={{mb: 2, mt:4,fontSize:"24px",fontWeight:"bolder"}}>
                        Shareholder details
                    </Typography>
                    <Typography component={"div"} align={"left"} variant="formationLabelSubHeader">
                        The company will be using ‘Ordinary’ shares in British Pounds (GBP).
                    </Typography>
                    <Typography component={"div"} align={"left"} variant="formationLabelHeader" sx={{mt:2}}>
                        Choose a value for each share
                        <HtmlTooltip title={""}
                                     message={"Shareholders must pay the company the value of all shares they own.<br />Choosing a low share value means they’ll pay less.<br/>The share value is not linked to how much the company is worth."}/>
                    </Typography>
                    <Controller
                        name="useDefaultShareValue"
                        control={control}
                        defaultValue={formationData?.useDefaultShareValue === true ? "true" : "false"}
                        rules={{required: `Please select a value for each share`}}
                        render={({field}) => (
                            <FormControl error={!!formState.errors.useDefaultShareValue}
                                         onChange={(e) => {
                                             field.onChange(e);
                                             setUseDefaultShareValue(e.target.value === "true" ? true : false);
                                         }}
                            >
                                <RadioGroup {...field} row>
                                    <FormControlLabel value={true} control={<Radio />} label="£1" />
                                    <FormControlLabel value={false} control={<Radio />} label="Other" />
                                </RadioGroup>
                                {formState.errors.useDefaultShareValue && (
                                    <FormHelperText>{formState.errors.useDefaultShareValue.message}</FormHelperText>
                                )}
                            </FormControl>
                        )}
                    />

                    {useDefaultShareValue === false &&
                        (
                            <>
                                <Typography component={"div"} align={"left"} variant="formationLabelHeader" sx={{mt:2}}>
                                    What currency will the shares be issued in?
                                </Typography>
                                <Controller
                                    name="otherShareCurrency"
                                    control={control}
                                    defaultValue={formationData?.otherShareCurrency}
                                    rules={{required: `Please select a value for each share`}}
                                    render={({field}) => (
                                        <FormControl error={!!formState.errors.otherShareCurrency}
                                        >
                                            <RadioGroup
                                                {...field}
                                                // Make sure to parse the number back to string before setting it into the DOM
                                                onChange={(e) => field.onChange(Number(e.target.value))}
                                            >
                                                <FormControlLabel value={FORMATION_OTHER_SHARE_CURRENCY.GBP.toString()} control={<Radio />} label="£ (GBP)" />
                                                <FormControlLabel value={FORMATION_OTHER_SHARE_CURRENCY.EUR.toString()} control={<Radio />} label="€ (EUR)" />
                                                <FormControlLabel value={FORMATION_OTHER_SHARE_CURRENCY.USD.toString()} control={<Radio />} label="$ (USD)" />
                                            </RadioGroup>
                                            {formState.errors.otherShareCurrency && (
                                                <FormHelperText>{formState.errors.otherShareCurrency.message}</FormHelperText>
                                            )}
                                        </FormControl>
                                    )}
                                />
                                <Controller
                                    name="otherShareValue"
                                    control={control}
                                    defaultValue={formationData?.otherShareValue ? formationData.otherShareValue : 1.00}
                                    rules={{ required: 'Share Value is required' }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            variant="outlined"
                                            InputLabelProps={{
                                                style: { ...theme.inputLabelProps },
                                            }}
                                            onBlur={(e) => {
                                                let value = parseFloat(e.target.value);
                                                value = formatDecimal(value);
                                                field.onBlur();
                                                field.onChange(value);
                                            }}
                                            margin="normal"
                                            fullWidth
                                            size="small"
                                            error={!!formState.errors.otherShareValue}
                                            helperText={formState.errors.otherShareValue ? formState.errors.otherShareValue.message : ''}
                                        />
                                    )}
                                />
                            </>
                        )
                    }

                    <Typography component={"div"} align={"left"} variant="formationLabelHeader" sx={{mt:2}}>
                        Assign shares
                        <HtmlTooltip title={"What is a Shareholder?"}
                                     message={"Shareholders own one or more shares in a company. They get a share of company profits and often have a say in important company issues, like appointing new directors."}/>
                    </Typography>
                    <Typography component={"div"} align={"left"} variant="formationLabelSubHeader" sx={{mt:2}}>
                        Only assign shares to persons or corporate entities you would like to become a company shareholder.
                    </Typography>
                    
                    <TableContainer>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{fontWeight:"bolder"}}>Name</TableCell>
                                    <TableCell sx={{fontWeight:"bolder"}}>Business Role</TableCell>
                                    <TableCell sx={{fontWeight:"bolder"}}>Share Type</TableCell>
                                    <TableCell sx={{fontWeight:"bolder"}}>Number of Shares</TableCell>
                                    <TableCell sx={{fontWeight:"bolder"}}>Share Percentage</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {formationData.directorShareholders?.filter(row => row.directorIsShareholder 
                                    || (row.type === FORMATION_DIRECTOR_SHAREHOLDER_TYPE.SHAREHOLDER_PERSON || row.type === FORMATION_DIRECTOR_SHAREHOLDER_TYPE.SHAREHOLDER_COMPANY)).map((row) => (
                                    <TableRow
                                        key={row.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {row.firstName}
                                        </TableCell>
                                        <TableCell>
                                            {row.type === FORMATION_DIRECTOR_SHAREHOLDER_TYPE.DIRECTOR_PERSON || row.type === FORMATION_DIRECTOR_SHAREHOLDER_TYPE.DIRECTOR_COMPANY ? 
                                                    "Director" 
                                                : row.type === FORMATION_DIRECTOR_SHAREHOLDER_TYPE.SHAREHOLDER_PERSON || row.type === FORMATION_DIRECTOR_SHAREHOLDER_TYPE.SHAREHOLDER_COMPANY ?
                                                    "Shareholder"
                                                :
                                                    "Secretary"}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            Ordinary
                                        </TableCell>
                                        <TableCell>
                                            <Controller
                                                name={`numberOfShares_${row.id}`}
                                                control={control}
                                                defaultValue={row.numberOfShares || 0}
                                                rules={{ required: 'Number of shares is required' }}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        onChange={e => {
                                                            e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                                            setShareholderShares({...shareholderShares, [row.id]: Number(e.target.value)});
                                                            field.onChange(e);
                                                        }}
                                                        variant="outlined"
                                                        InputLabelProps={{
                                                            style: { ...theme.inputLabelProps },
                                                        }}
                                                        margin="normal"
                                                        size="small"
                                                        error={!!formState.errors[`numberOfShares_${row.id}`]}
                                                        helperText={formState.errors[`numberOfShares_${row.id}`] ? formState.errors[`numberOfShares_${row.id}`].message : ''}
                                                    />
                                                )}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            {Number(((shareholderShares[row.id] / totalShares) * 100).toFixed(2))} %
                                        </TableCell>
                                        <TableCell>
                                            <Stack direction="row" spacing={2}>
                                                <Edit onClick={(e) => {
                                                    editDirector(row.id);
                                                }} sx={{
                                                    color: theme.palette.primary.main,
                                                    cursor: "pointer"
                                                }}/>


                                                {((lastDirectorPersonId === null || lastDirectorPersonId !== row.id) && !row.isPrimaryContact) && (
                                                    <RemoveCircleOutline
                                                        onClick={(e) => {
                                                            removeDirector(row.id);
                                                        }} sx={{
                                                        color: theme.palette.primary.main,
                                                        cursor: "pointer"
                                                    }}/>
                                                )}
                                            </Stack>

                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    
                    <Typography component={"div"} align={"left"} variant="formationLabelHeader" sx={{mt:2}}>
                        Would you like to add another shareholder?
                    </Typography>

                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={7}>
                            <TextField
                                select
                                variant="outlined"
                                defaultValue={selectedDirectorType}
                                onChange={(e) => {
                                    setSelectedDirectorType(e.target.value);
                                }}
                                InputLabelProps={{
                                    style: { ...theme.inputLabelProps },
                                }}
                                margin="normal"
                                fullWidth
                                size="small"
                            >
                                <MenuItem key={FORMATION_DIRECTOR_SHAREHOLDER_TYPE.SHAREHOLDER_PERSON.toString()} value={FORMATION_DIRECTOR_SHAREHOLDER_TYPE.SHAREHOLDER_PERSON}>A shareholder that is a person</MenuItem>
                                <MenuItem key={FORMATION_DIRECTOR_SHAREHOLDER_TYPE.SHAREHOLDER_COMPANY.toString()}  value={FORMATION_DIRECTOR_SHAREHOLDER_TYPE.SHAREHOLDER_COMPANY}>A shareholder that is a corporate body (corporate shareholder)</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={12} md={5}>
                            <Button variant="contained" color="secondary" 
                                    sx={{mt:2,ml:2}}
                                    disabled={selectedDirectorType === FORMATION_DIRECTOR_SHAREHOLDER_TYPE.NONE} onClick={(e) => {
                                setDirectorType(selectedDirectorType);
                            }}>
                                Add
                            </Button>
                        </Grid>
                    </Grid>
                    
                    <Typography component={"div"} align={"left"} variant="formationLabelHeader" sx={{mt:2}}>
                        Confirmation of company shares
                    </Typography>
                    <Controller
                        name="companySharesConfirmed"
                        control={control}
                        defaultValue={formationData?.companySharesConfirmed}
                        rules={{required: `Please confirm the company shares`}}
                        render={({ field }) => (
                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox {...field} checked={field.value}  onChange={(e) => {
                                        // Update react-hook-form's form state
                                        field.onChange(e.target.checked);
                                    }}/>}
                                    label={`I confirm the company has a total of ${totalShares} ordinary shares(s) with a total of in value.`}
                                />
                                {formState.errors.companySharesConfirmed && (
                                    <FormHelperText error={true}>{formState.errors.companySharesConfirmed.message}</FormHelperText>
                                )}
                            </FormGroup>
                        )}
                    />
                    <br/>
                    <Stack direction="row" spacing={2} sx={{mt: 2}}>
                        <Button variant="contained" color="secondary" onClick={(e) =>{
                            parentBack();
                        }}>Back</Button>
                        <Button variant="contained" color="secondary" type="submit">Confirm & Continue</Button>
                    </Stack>
                </form>
            );
        }
        else if(directorType === FORMATION_DIRECTOR_SHAREHOLDER_TYPE.SHAREHOLDER_PERSON) {
            content = (
              <FormationShareholderPersonDetails formationData={formationData} createOrUpdateDirectors={createOrUpdateDirectors} directorData={editDirectorData} back={back} directorShareholderType={directorType} />
            );
        }
        else if(directorType === FORMATION_DIRECTOR_SHAREHOLDER_TYPE.SHAREHOLDER_COMPANY) {
            content = (
                <FormationShareholderCorporateDetails formationData={formationData} createOrUpdateDirectors={createOrUpdateDirectors} directorData={editDirectorData} back={back} directorShareholderType={directorType} />
            );
        }
        else if(directorType === FORMATION_DIRECTOR_SHAREHOLDER_TYPE.DIRECTOR_PERSON) {
            content = (
                <FormationDirectorPersonDetails formationData={formationData} createOrUpdateDirectors={createOrUpdateDirectors} directorData={editDirectorData} back={back} directorShareholderType={directorType}/>
            );
        }
        else if(directorType === FORMATION_DIRECTOR_SHAREHOLDER_TYPE.SECRETARY_PERSON) {
            content = (
                <FormationSecretaryPersonDetails formationData={formationData} createOrUpdateDirectors={createOrUpdateDirectors} directorData={editDirectorData} back={back} directorShareholderType={directorType} />
            );
        }
        else if(directorType === FORMATION_DIRECTOR_SHAREHOLDER_TYPE.DIRECTOR_COMPANY) {
            content = (
                <FormationDirectorCorporateDetails formationData={formationData} createOrUpdateDirectors={createOrUpdateDirectors} directorData={editDirectorData} back={back} directorShareholderType={directorType}/>
            );
        }
        else if(directorType === FORMATION_DIRECTOR_SHAREHOLDER_TYPE.SECRETARY_COMPANY){
            content = (
                <FormationSecretaryCorporateDetails formationData={formationData} createOrUpdateDirectors={createOrUpdateDirectors} directorData={editDirectorData} back={back} directorShareholderType={directorType}/>
            );
        }
    }


    return (
        <Box maxWidth={"100vh"}>
            {content}
        </Box>
    );
}