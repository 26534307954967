const trimFields = data => {
    return Object.keys(data).reduce((acc, key) => {
        if (typeof data[key] === 'string') {
            acc[key] = data[key].trim();
        } else {
            acc[key] = data[key];
        }
        return acc;
    }, {});
};


const inputService = Object.freeze({
    trimFields
});
export default inputService;