import * as React from 'react';
import { useParams } from "react-router-dom";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {ACCOUNT_TABS, BILLING_TABS, COMPANY_TABS, RESOURCE_TYPE, ROLES} from "../enums";
import accountService from "../accounts/AccountService";
import {enqueueSnackbar} from "notistack";
import AuthContext from "../api-authorization/AuthContext";
import {CircularProgress} from "@mui/material";
import companyService from "../companies/CompanyService";
import ListPaymentHistory from "./ListPaymentHistory";
import ListPaymentMethods from "./ListPaymentMethods";
import Breadcrumb from "../layout/Breadcrumb";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

export default function BillingParent() {
    const { selectedTab, resourceType, resourceId} = useParams();
    const [resourceLoading, setResourceLoading] = useState(true);
    const [resourceData, setResourceData] = useState();
    const { antiForgeryToken, refreshUser, user} = useContext(AuthContext);
    
    const [tabValue, setTabValue] = React.useState(selectedTab ? parseInt(selectedTab) : 0);
    const navigate = useNavigate();
    
    let content;
    const handleChange = (event, newTabValue) => {
        setTabValue(newTabValue);
    };

    useEffect( () => {
        async function fetchData() {
            let result;

            if(resourceType == RESOURCE_TYPE.ACCOUNT){
                result = await accountService.getAccount(antiForgeryToken, resourceId);
            }
            else{
                let getCompanyRequest = {
                    id: resourceId
                };
                result = await companyService.getCompany(antiForgeryToken, getCompanyRequest);
            }
            
            if (result.status === 401) {
                refreshUser();
                navigate('/login');
            }
            else {
                let data = await result.json();
                if (result.ok) {
                    setResourceData(data);
                    setResourceLoading(false);
                } else {
                    enqueueSnackbar(data.description); 
                }
            }
        }
        fetchData();
    }, [resourceId, resourceType])

    
    
    if (resourceLoading) {
        content = (
            <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                <CircularProgress />
            </Box>
        );
    } else {
        let breadcrumbItems = [
        ];
        if(resourceType == RESOURCE_TYPE.ACCOUNT){
            breadcrumbItems.push({description: `Account - ${resourceData.email}`, url: `/Account/Update/${resourceData.id}/${ACCOUNT_TABS.DETAILS}`});
        }
        else if(resourceType == RESOURCE_TYPE.COMPANY){
            breadcrumbItems.push({description: `Company - ${resourceData.name}`, url: `/Company/Update/${resourceData.id}/${COMPANY_TABS.DETAILS}`});
        }
        else if(resourceType == RESOURCE_TYPE.POST_BOX){
            breadcrumbItems.push({description: `Post Box - ${resourceData.name}`, url: `/Company/Update/${resourceData.id}/${COMPANY_TABS.DETAILS}`});
        }
        
        content = (
            <>
                {(user.role === ROLES.TENANT_ADMIN || user.role === ROLES.TENANT_STANDARD || user.role === ROLES.CLIENT_ADMIN || user.role === ROLES.CLIENT_STANDARD) &&(
                    <Breadcrumb breadcrumbItems={breadcrumbItems}/>
                )}
                <Typography variant="h5" noWrap component="div" align={"left"} sx={{mb: 2}}>
                    Billing - {resourceType == RESOURCE_TYPE.ACCOUNT ? resourceData.email : resourceData.name}
                </Typography>
                <Box>
                    <Box>
                        <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Payment method" id={`simple-tab-${BILLING_TABS.PAYMENT_METHOD}`} aria-controls={`simple-tabpanel-${BILLING_TABS.PAYMENT_METHOD}`}/>
                            <Tab label="Payment history" id={`simple-tab-${BILLING_TABS.PAYMENT_HISTORY}`} aria-controls={`simple-tabpanel-${BILLING_TABS.PAYMENT_HISTORY}`}/>
                        </Tabs>
                    </Box>
                    <TabPanel value={tabValue} index={0}>
                        <ListPaymentMethods antiForgeryToken={antiForgeryToken} refreshUser={refreshUser} resourceType={resourceType} resourceId={resourceId} resourceData={resourceData}/>
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                        <ListPaymentHistory antiForgeryToken={antiForgeryToken} refreshUser={refreshUser} resourceType={resourceType} resourceId={resourceId}/>
                    </TabPanel>
                </Box>
            </>
        );
    }

    return (
        <>
            {content}
        </>
    );
}