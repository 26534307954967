import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {useContext, useState} from "react";
import {enqueueSnackbar} from "notistack";
import {Controller, useForm} from "react-hook-form";
import TextField from "@mui/material/TextField";
import {Container, Link} from "@mui/material";
import Button from "@mui/material/Button";
import userService from "./UserService";
import AuthContext from "../api-authorization/AuthContext";
import CssBaseline from "@mui/material/CssBaseline";
import {useTheme} from "@mui/material/styles";
import {useNavigate} from "react-router-dom";
import inputService from "../util/InputService";

export default function RequestPasswordReset() {
    const theme = useTheme();
    const { antiForgeryToken} = useContext(AuthContext);
    const {  handleSubmit, control, formState } = useForm();
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [resetPasswordLinkSent, setResetPasswordLinkSent] = useState(false);
    const navigate = useNavigate();
    
    const handleFormSubmit  = async (formData) => {
        setDisableSubmit(true);
        formData = inputService.trimFields(formData);
        const result = await userService.requestPasswordResetLink(antiForgeryToken, formData);

        let data = await result.json();
        if (result.ok) {
            enqueueSnackbar("Successfully sent password reset link");
            setResetPasswordLinkSent(true);
        } else {
            enqueueSnackbar(data.description);
        }

        setDisableSubmit(false);
    };

    return  (
        <>
            <CssBaseline />
            <Box
                sx={{
                    backgroundImage: `url(${theme.loginBackgroundImage})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    width: '100%',
                    height: '100vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Container component="main" sx={{backgroundColor:"white",border:2,borderColor:'grey.200',pt:6,pb:10, width:"500px"}}>
                    <Box
                        component="form" noValidate onSubmit={handleSubmit(handleFormSubmit)}
                        sx={{
                            marginTop: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <img src={theme.loginLogo} alt="logo" style={{height: "80px"}}/>

                        {resetPasswordLinkSent ?
                            <>
                                <Typography variant="h5" sx={{mt:4, mb:3}}>
                                    Check your email
                                </Typography>
                                <Typography variant="h6">
                                    If the email address you have provided is registered with us, you will receive an email to reset your password.
                                </Typography>
                                <Typography sx={{mt:2}} variant="loginInfoMessages">Did not receive the email? Check your spam folder. Or  <Link sx={{cursor:"pointer"}} onClick={
                                    () => setResetPasswordLinkSent(false)
                                }>try another email address</Link></Typography>
                            </>
                            :
                            <>
                                <Typography variant="h5" sx={{mt:4, mb:3}}>
                                    Forgot Password?
                                </Typography>
                                <Typography variant="h6">
                                    Enter the email you used to register your account below and we will send you a link to reset your password.
                                </Typography>
                                <Controller
                                    name="email"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: 'Email is required' }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label="Email"
                                            variant="outlined"
                                            margin="normal"
                                            sx={{width: "70%"}}
                                            fullWidth
                                            size="small"
                                            error={!!formState.errors.email}
                                            helperText={formState.errors.email ? formState.errors.email.message : ''}
                                        />
                                    )}
                                />
                                <br />
                                <Button sx={{width:"30%"}} variant="contained" color="secondary" type="submit" disabled={disableSubmit}>
                                    Send email
                                </Button>
                            </>
                        }
                        
                    </Box>
                </Container>
            </Box>
        </>
    );
}