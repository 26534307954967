import * as React from 'react';
import { useParams } from "react-router-dom";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {RESOURCE_TYPE, ROLES} from "../enums";
import accountService from "../accounts/AccountService";
import {enqueueSnackbar} from "notistack";
import AuthContext from "../api-authorization/AuthContext";
import {
    Checkbox,
    CircularProgress,
    Dialog, DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, FormControlLabel,
    Grid, MenuItem,
    Pagination,
    Stack
} from "@mui/material";
import companyService from "../companies/CompanyService";
import billingService from "./BillingService";
import {Controller, useForm} from "react-hook-form";
import TextField from "@mui/material/TextField";
import {useTheme} from "@mui/material/styles";
import Button from "@mui/material/Button";
import {FormGroup} from "reactstrap";
import NavigationContext from "../layout/NavigationContext";
import {DataGridPro} from "@mui/x-data-grid-pro";
import Breadcrumb from "../layout/Breadcrumb";

export default function SubscriptionDetail() {
    const { histNavigate,back,freshHistNavigate } = useContext(NavigationContext);
    const { antiForgeryToken, refreshUser, user} = useContext(AuthContext);
    const theme = useTheme();
    const { handleSubmit, control, formState } = useForm();
    const { resourceType, resourceId, subscriptionId} = useParams();
    const [resourceLoading, setResourceLoading] = useState(true);
    const [subscriptionLoading, setSubscriptionLoading] = useState(true);
    const [resourceData, setResourceData] = useState();
    const [subscriptionData, setSubscriptionData] = useState();
    const [reAttemptInvoicesList, setReAttemptInvoicesList] = useState([]);
    const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState();
    const [paymentMethods, setPaymentMethods] = useState();
    const [paymentMethodsLoading, setPaymentMethodsLoading] = useState(true);
    
    const navigate = useNavigate();
    const [rowCountState, setRowCountState] = React.useState(0);
    const [paginationModel, setPaginationModel] = React.useState({
        page: 0,
        pageSize: 5,
    });

    const [cancelDialogOpen, setCancelDialogOpen] = React.useState(false);
    const [isCancelConfirmed, setIsCancelConfirmed] = useState(false);


    const handleCancelDialogOpen = () => {
        setCancelDialogOpen(true);
    };

    const handleCancelDialogClose = () => {
        setCancelDialogOpen(false);
    };
    
    let content;

    async function fetchResourceData() {
        let result;

        if(resourceType == RESOURCE_TYPE.ACCOUNT){
            result = await accountService.getAccount(antiForgeryToken, resourceId);
        }
        else{
            let getCompanyRequest = {
                id: resourceId
            };
            result = await companyService.getCompany(antiForgeryToken, getCompanyRequest);
        }

        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                setResourceData(data);
                setResourceLoading(false);
            } else {
                enqueueSnackbar(data.description);
            }
        }
    }

    async function getPaymentMethods() {
        const result = await billingService.listPaymentMethods(antiForgeryToken, resourceType, resourceId);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                setPaymentMethods(data.cards);
                setPaymentMethodsLoading(false);
            } else {
                enqueueSnackbar(data.description);
            }
        }
    }

    async function fetchSubscriptionDetail() {
        let result;
        result = await billingService.getSubscriptionDetails(antiForgeryToken, resourceType, resourceId, subscriptionId);

        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                setSubscriptionData(data);
                setSubscriptionLoading(false);
                setSelectedPaymentMethodId(data.defaultPaymentMethodId);
            } else {
                enqueueSnackbar(data.description);
            }
        }
    }
    
    useEffect( () => {
        fetchResourceData();
        fetchSubscriptionDetail();
        getPaymentMethods();
    }, [resourceId, resourceType, subscriptionId]);

    async function reAttemptInvoices() {
        setSubscriptionLoading(true);
        let result;
        result = await billingService.reAttemptInvoices(antiForgeryToken, resourceType, resourceId, reAttemptInvoicesList);

        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                fetchSubscriptionDetail();
                enqueueSnackbar("Successfully sent request to re-attempt invoices");
            } else {
                enqueueSnackbar(data.description);
            }
        }
        setSubscriptionLoading(false);
    }

    async function cancelSubscription() {
        setSubscriptionLoading(true);
        let result;
        result = await billingService.cancelSubscription(antiForgeryToken, resourceType, resourceId, subscriptionId);

        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                enqueueSnackbar("Successfully cancelled subscription");
                back();
            } else {
                enqueueSnackbar(data.description);
            }
        }
        setSubscriptionLoading(false);
    }

    const updateSubscriptionPaymentMethod = async () => {
        setSubscriptionLoading(true);
        let result;
        result = await billingService.updateSubscriptionPaymentMethod(antiForgeryToken, resourceType, resourceId, subscriptionId,selectedPaymentMethodId);

        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                enqueueSnackbar("Successfully updated subscription");
            } else {
                enqueueSnackbar(data.description);
            }
        }
        setSubscriptionLoading(false);
    };

    const dateFormatter = (params) => {
        const date = new Date(params.value);
        if (isNaN(date.getTime()) || date.getFullYear() <= 1) {
            return "None";
        }
        return date.toLocaleDateString('en-GB');
    };

    const handlePageChange = (params) => {
        setPaginationModel({
            page: params.page,
            pageSize: params.pageSize,
        });
    };
    
    
    if (resourceLoading || subscriptionLoading || paymentMethodsLoading) {
        content = (
            <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                <CircularProgress />
            </Box>
        );
    } else {
        let resourceName;
        if(resourceType == RESOURCE_TYPE.ACCOUNT){
            resourceName = resourceData.email;
        }
        else if(resourceType == RESOURCE_TYPE.COMPANY) {
            resourceName = resourceData.name;
        }
        else if(resourceType == RESOURCE_TYPE.POST_BOX) {
            resourceName = resourceData.postBoxName;
        }
        content = (
            <Box sx={{ maxWidth: 1400 }}>
                {(user.role === ROLES.TENANT_ADMIN || user.role === ROLES.TENANT_STANDARD || user.role === ROLES.CLIENT_ADMIN || user.role === ROLES.CLIENT_STANDARD) &&(
                    <Breadcrumb breadcrumbItems={subscriptionData.breadcrumbItems}/>
                )}
                <Typography variant="h5" noWrap component="div" align={"left"} sx={{mb: 2}}>
                    Subscription Detail - {subscriptionData.description}
                </Typography>
                <form>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={8} md={6}>
                            <Controller
                                name="status"
                                control={control}
                                defaultValue={ subscriptionData.status}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Status"
                                        variant="outlined"
                                        InputLabelProps={{
                                            style: { ...theme.inputLabelProps },
                                        }}
                                        sx={{
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: (field.value === "ACTIVE" || field.value=="TRIALING") ? "green" : "red",
                                                fontWeight: 950,
                                            },
                                        }}
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                        disabled={true}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={8} md={6}>
                            <Controller
                                name="created"
                                control={control}
                                defaultValue={ subscriptionData.dateCreated}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Created"
                                        variant="outlined"
                                        InputLabelProps={{
                                            style: { ...theme.inputLabelProps },
                                        }}
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                        disabled={true}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={8} md={6}>
                            <Controller
                                name="trialPeriodEnd"
                                control={control}
                                defaultValue={ subscriptionData.trialPeriodEnd ? subscriptionData.trialPeriodEnd : "-"}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Trial Period End"
                                        variant="outlined"
                                        InputLabelProps={{
                                            style: { ...theme.inputLabelProps },
                                        }}
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                        disabled={true}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={8} md={6}>
                            <Controller
                                name="currentPeriod"
                                control={control}
                                defaultValue={ subscriptionData.currentPeriod}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Current Period"
                                        variant="outlined"
                                        InputLabelProps={{
                                            style: { ...theme.inputLabelProps },
                                        }}
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                        disabled={true}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={8} md={6}>
                            <Controller
                                name="nextInvoiceDate"
                                control={control}
                                defaultValue={ subscriptionData.nextInvoiceDate}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Next Invoice"
                                        variant="outlined"
                                        InputLabelProps={{
                                            style: { ...theme.inputLabelProps },
                                        }}
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                        disabled={true}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={8} md={6}>
                            <Controller
                                name="dateCanceledAt"
                                control={control}
                                defaultValue={ subscriptionData.dateCanceledAt ? subscriptionData.dateCanceledAt : "-"}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Cancellation Date"
                                        variant="outlined"
                                        InputLabelProps={{
                                            style: { ...theme.inputLabelProps },
                                        }}
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                        disabled={true}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={8} md={6}>
                            <Controller
                                name="interval"
                                control={control}
                                defaultValue={ subscriptionData.interval}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Interval"
                                        variant="outlined"
                                        InputLabelProps={{
                                            style: { ...theme.inputLabelProps },
                                        }}
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                        disabled={true}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={8} md={6}>
                            <Controller
                                name="amount"
                                control={control}
                                defaultValue={ subscriptionData.amount}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Amount (ex vat)"
                                        variant="outlined"
                                        InputLabelProps={{
                                            style: { ...theme.inputLabelProps },
                                        }}
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                        disabled={true}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                </form>
                <Typography variant="h6" sx={{mt:2, mb:2}}>Outstanding Invoices</Typography>
                <Typography>The below is a list of outstanding invoices. Payments will retry 3 times automatically and your service will be suspended if payment is not made successfully.</Typography>
                <Box style={{ height: 400, width: '100%' }} sx={{mt:2}}>
                    <DataGridPro
                        rows={subscriptionData.unpaidInvoices.slice(
                            paginationModel.page * paginationModel.pageSize,
                            paginationModel.page * paginationModel.pageSize + paginationModel.pageSize
                        )}
                        columns={[
                            { field: 'invoiceNumber', headerName: 'Invoice #', flex: 1 },
                            { field: 'dateCreated', headerName: 'Due Date', flex: 1, valueFormatter: dateFormatter },
                            { field: 'amountDue', headerName: 'Amount (ex VAT)', flex: 1 },
                            { field: 'cardUsed', headerName: 'Payment Method', flex: 2},
                            {field: 'failureReason', headerName:'Failure Reason', flex:3},
                            {field: 'attempts', headerName:'Attempts', flex:1},
                            {field: 'status', headerName:'Status', flex:1},
                            {
                                field: "checkboxes",
                                headerName: "",
                                flex: 1,
                                renderCell: (params) => (
                                    <div>
                                        <input
                                            type="checkbox"
                                            checked={reAttemptInvoicesList.includes(params.row.id)}
                                            onChange={(e) => {
                                                const checked = e.target.checked;
                                                setReAttemptInvoicesList(prev =>
                                                    checked
                                                        ? [...prev, params.row.id]
                                                        : prev.filter(num => num !== params.row.id)
                                                );
                                            }}
                                        />
                                    </div>
                                )
                            }
                        ]}
                        rowCount={rowCountState}
                        pagination
                        pageSize={paginationModel.pageSize}
                        page={paginationModel.page}
                        onPageChange={handlePageChange}
                        hideFooter={true}
                        autoHeight
                        loading={subscriptionLoading}
                        sx={{
                            '.MuiDataGrid-columnHeaderTitle': {
                                WebkitTextStroke: "0.75px"
                            }
                        }}
                    />
                    <Box display="flex" justifyContent="flex-end">
                        <Pagination
                            count={Math.ceil(rowCountState / paginationModel.pageSize)}
                            page={paginationModel.page + 1}
                            onChange={(event, page) => handlePageChange({ page: page - 1, pageSize: paginationModel.pageSize })}
                        />
                    </Box>
                    {resourceData.clientBillingEnabled && (
                        <Stack direction="row" spacing={2} marginTop={2}>
                            <TextField
                                id="paymentMethods"
                                select
                                label="Payment Method"
                                value={selectedPaymentMethodId}
                                onChange={(e) => {
                                    setSelectedPaymentMethodId(e.target.value);
                                }}
                                variant="outlined"
                                margin="normal"
                                size="small"
                                sx={{mt:1, width:"35%"}}
                            >
                                {paymentMethods.map((paymentMethod, index) => {
                                    return (
                                        <MenuItem key={index} value={paymentMethod.id}>{paymentMethod.name} - {paymentMethod.brand} - {paymentMethod.last4} - {paymentMethod.expiryDate}</MenuItem>
                                    )
                                })}
                            </TextField>

                            <Button variant="contained" color="secondary" onClick={(e) => {
                                updateSubscriptionPaymentMethod();
                            }}>
                                Update Card
                            </Button>
                        </Stack>
                    )}
                    <Stack direction="row" spacing={2} marginTop={2}>
                        <Button variant="contained" color="secondary" onClick={(e) => {
                            back();
                        }}>
                            Back
                        </Button>

                        
                        {subscriptionData.unpaidInvoices.length > 0 && (
                            <Button variant="contained" color="secondary" onClick={(e) => {
                                reAttemptInvoices()
                            }} disabled={reAttemptInvoicesList.length <= 0}>
                                Retry Payment
                            </Button>
                        )}
                        {(subscriptionData.status?.toLowerCase() !== 'canceled'.toLowerCase() && (user.role === ROLES.TENANT_ADMIN || user.role === ROLES.TENANT_STANDARD || user.role === ROLES.CLIENT_ADMIN || user.role === ROLES.CLIENT_STANDARD)) && (
                            <Button variant="contained" color="secondary" onClick={(e) => {
                                handleCancelDialogOpen()
                            }}>
                                Cancel
                            </Button>
                        )}
                    </Stack>
                </Box>
                <Dialog
                    open={cancelDialogOpen}
                    onClose={handleCancelDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Warning"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            This subscription for '{ resourceName}' is to be cancelled with immediate effect. The subscription and any mail forwarding will stop immediately, any scheduled payments will not be collected and any outstanding invoices will be closed.
                        </DialogContentText>
                        <Controller
                            name="isCancelConfirmed"
                            control={control}
                            defaultValue={false}
                            render={({ field }) => (
                                <FormGroup>
                                    <FormControlLabel
                                        sx={{mt:2, mb:2}}
                                        control={
                                            <Checkbox
                                                {...field}
                                                checked={field.value}
                                                onChange={(e) => {
                                                    setIsCancelConfirmed(e.target.checked);
                                                    field.onChange(e);
                                                }}
                                            />
                                        }
                                        label="I confirm and understand the above."
                                    />
                                </FormGroup>
                            )}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCancelDialogClose}>Close</Button>
                        <Button onClick={cancelSubscription} autoFocus disabled={!isCancelConfirmed}>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        );
    }

    return (
        <>
            {content}
        </>
    );
}