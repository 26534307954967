import React, {useContext, useState} from 'react'
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import {InputAdornment, Pagination, Stack} from "@mui/material";
import { useNavigate } from 'react-router-dom';
import {enqueueSnackbar} from "notistack";
import TransitionAlert from "../layout/TransitionAlert";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {CLIENT_TABS, ROLES} from "../enums";
import Button from "@mui/material/Button";
import clientService from "./ClientService";
import AuthContext from "../api-authorization/AuthContext";
import NavigationContext from "../layout/NavigationContext";
import {DataGridPro} from "@mui/x-data-grid-pro";

export default function ListClients() {
    const { histNavigate,back } = useContext(NavigationContext);
    const { antiForgeryToken,user, refreshUser } = useContext(AuthContext);
    const [searchTerm, setSearchTerm] = useState("");
    const [isLoading, setIsLoading] = React.useState(true);
    const [clients, setClients] = React.useState([]);
    const [rowCountState, setRowCountState] = React.useState(0);
    const [paginationModel, setPaginationModel] = React.useState({
        page: 0,
        pageSize: 20,
    });
    const navigate = useNavigate();

    const fetchData = async () => {
        setIsLoading(true);
        setPaginationModel((prevModel) => ({...prevModel, page: 0}));
        const result = await clientService.listClients(antiForgeryToken, searchTerm);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                setClients(data.clients);
                setRowCountState(data.clients.length);
            } else {
                enqueueSnackbar(data.description);
            }
        }
        setIsLoading(false);
    };

    React.useEffect(() => {
        fetchData();
    }, []);

    const handlePageChange = (params) => {
        setPaginationModel({
            page: params.page,
            pageSize: params.pageSize,
        });
    };

    const handleSearch = (event) => {
        event.preventDefault();
        setPaginationModel({ page: 0, pageSize: paginationModel.pageSize });
        fetchData();
    };

    const handleRowClick = (params) => {
        histNavigate(`/Client/Update/${params.id}/${CLIENT_TABS.DETAILS}`,'Client');
    };

    return (
        <Box sx={{height: 400}}>
            <Typography variant="h5" noWrap component="div" align={"left"} sx={{mb:4}}>
                Client search
            </Typography>
            <TransitionAlert message={"NOTE:   Add new, edit and search existing clients"}/>
            <form onSubmit={handleSearch}>
                <TextField
                    id="search-bar"
                    value={searchTerm}
                    onChange={(e) => {
                        setSearchTerm(e.target.value);
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="end">
                                <IconButton type="submit">
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    label="Search Clients"
                    variant="outlined"
                    size="small"
                    style={{ width: '100%' }}
                />
            </form>
            <br />
            <DataGridPro
                rows={clients.slice(
                    paginationModel.page * paginationModel.pageSize,
                    paginationModel.page * paginationModel.pageSize + paginationModel.pageSize
                )}
                columns={[
                    { field: 'dateCreated', headerName: 'Created date', flex: 1},
                    { field: 'companyName', headerName: 'Client Name', flex: 1 },
                    { field: 'name', headerName: 'Name', flex: 1 },
                    { field: 'status', headerName: 'Status', flex: 1 },
                    { field: 'userLogin', headerName: 'User Login', flex: 1 }
                ]}
                rowCount={paginationModel.pageSize}
                pageSize={paginationModel.pageSize}
                hideFooter={true}
                page={paginationModel.page}
                onPageChange={handlePageChange}
                loading={isLoading}
                onRowClick={handleRowClick}
                getRowClassName={() => 'rowCursorPointer'}
                autoHeight
                sx={{
                    '.MuiDataGrid-columnHeaderTitle': {
                        WebkitTextStroke: '0.75px'
                    },
                }}
            />
            <br/>
            <Box display="flex" justifyContent="flex-end">
                <Pagination
                    count={Math.ceil(rowCountState / paginationModel.pageSize)}
                    page={paginationModel.page + 1}
                    onChange={(event, page) => handlePageChange({ page: page - 1, pageSize: paginationModel.pageSize })}
                />
            </Box>
            {user.role === ROLES.TENANT_ADMIN && (
                <Stack direction="row" spacing={2} marginTop={1}>
                    <Button variant="contained" color="secondary" onClick={(e) => {
                        histNavigate("/Client/Create",'Create Client');
                    }}>
                        Add
                    </Button>
                </Stack>
            )}
        </Box>
    );
}
