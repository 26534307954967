import * as React from 'react';
import Box from "@mui/material/Box";
import {
    Checkbox,
    CircularProgress,
    FormControl,
    FormControlLabel, FormHelperText, Grid, MenuItem,
    Radio,
    RadioGroup, Stack
} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useEffect} from "react";
import {Controller, useForm} from "react-hook-form";
import TextField from "@mui/material/TextField";
import {useTheme} from "@mui/material/styles";
import Button from "@mui/material/Button";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import {FORMATION_DIRECTOR_PERCENTAGE_OF_VOTING_RIGHTS} from "../enums";
import {countries} from "../util/Countries";

export default function FormationDirectorPersonDetails({formationData, createOrUpdateDirectors, directorData, back, directorShareholderType}) {
    const {  handleSubmit, control, formState, setValue } = useForm();
    const theme = useTheme();
    const [isLoading, setIsLoading] = React.useState(false);
    
    const [directorFirstName, setDirectorFirstName] = React.useState(directorData?.firstName);
    const [directorMiddleNames, setDirectorMiddleNames] = React.useState(directorData?.middleNames);
    const [directorLastName, setDirectorLastName] = React.useState(directorData?.lastName);
    const [homeAddressLine1, setHomeAddressLine1] = React.useState(directorData?.homeAddress?.addressLine1);
    const [homeAddressLine2, setHomeAddressLine2] = React.useState(directorData?.homeAddress?.addressLine2);
    const [homeAddressLocality, setHomeAddressLocality] = React.useState(directorData?.homeAddress?.locality);
    const [homeAddressCountry, setHomeAddressCountry] = React.useState(directorData?.homeAddress?.country);
    const [homeAddressPostalCode, setHomeAddressPostalCode] = React.useState(directorData?.homeAddress?.postalCode);
    
    let raAddress={
        addressLine1: "123 Fake Street",
        addressLine2: "",
        locality: "London",
        country: "United Kingdom",
        postalCode: "SW1A 1AA"
    }
    
    const confirmAndContinue = async (formData) => {
        if (formData.dateOfBirth) {
            formData.dateOfBirth = dayjs(formData.dateOfBirth).format('YYYY-MM-DD');
        }
        
        formData.directorIsShareholder = formData.directorIsShareholder === null ? null : formData.directorIsShareholder === "true";
        formData.rightToAppointOrRemoveDirectors = formData.rightToAppointOrRemoveDirectors === null ? null : formData.rightToAppointOrRemoveDirectors === "true";
        
        const newFormationData = {
            ...formationData,
        };
        
        if(directorData) {
            newFormationData.directorShareholders = newFormationData.directorShareholders.map(director => {
                if(director.id === directorData.id) {
                    return {
                        ...director,
                        ...formData,
                        homeAddress: director.homeAddress ? { ...director.homeAddress, ...formData.homeAddress } : formData.homeAddress,
                        correspondenceAddress: director.correspondenceAddress ? { ...director.correspondenceAddress, ...formData.correspondenceAddress } : formData.correspondenceAddress
                    };
                }
                return director;
            });
        } else {
            if(formData.directorIsShareholder === true) {
                formData.numberOfShares = 1;
            }
            formData.type = directorShareholderType;
            newFormationData.directorShareholders.push(formData);
        }
        
        await createOrUpdateDirectors(newFormationData.directorShareholders);
    }
    
    
    const getDefaultAddressField = (fieldName) => {
        if(formationData.useRaOfficeAddress === true){
            return raAddress[fieldName];   
        }
        else if (directorData != null && directorData.correspondenceAddress){
           return directorData.correspondenceAddress[fieldName]; 
        }
        else{
            return "";
        }
    }
    
    const uncheckConfirmationBoxes = () => {
        setValue("addressConfirmed", false);
        setValue("directorAgreed", false);
    }
    
    
    let content;
    
    let correspondenceAddressSection = (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
                <Controller
                    name="correspondenceAddress.addressLine1"
                    control={control}
                    defaultValue={getDefaultAddressField("addressLine1")}
                    rules={{ required: 'Address Line 1 is required' }}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label="Address Line 1 *"
                            variant="outlined"
                            InputLabelProps={{
                                style: { ...theme.inputLabelProps },
                            }}
                            onChange={(e) => {uncheckConfirmationBoxes(); field.onChange(e);}}
                            margin="normal"
                            fullWidth
                            disabled={formationData.useRaOfficeAddress === true}
                            size="small"
                            error={!!formState.errors.correspondenceAddress?.addressLine1}
                            helperText={formState.errors.correspondenceAddress?.addressLine1 ? formState.errors.correspondenceAddress.addressLine1.message : ''}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                <Controller
                    name="correspondenceAddress.addressLine2"
                    control={control}
                    defaultValue={getDefaultAddressField("addressLine2")}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label="Address Line 2"
                            variant="outlined"
                            InputLabelProps={{
                                style: { ...theme.inputLabelProps },
                            }}
                            onChange={(e) => {uncheckConfirmationBoxes(); field.onChange(e);}}
                            margin="normal"
                            fullWidth
                            disabled={formationData.useRaOfficeAddress === true}
                            size="small"
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                <Controller
                    name="correspondenceAddress.locality"
                    control={control}
                    defaultValue={getDefaultAddressField("locality")}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label="Town / City"
                            variant="outlined"
                            InputLabelProps={{
                                style: { ...theme.inputLabelProps },
                            }}
                            onChange={(e) => {uncheckConfirmationBoxes(); field.onChange(e);}}
                            margin="normal"
                            fullWidth
                            disabled={formationData.useRaOfficeAddress === true}
                            size="small"
                            error={!!formState.errors.correspondenceAddress?.locality}
                            helperText={formState.errors.correspondenceAddress?.locality ? formState.errors.correspondenceAddress.locality.message : ''}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                <Controller
                    name="correspondenceAddress.country"
                    control={control}
                    defaultValue={getDefaultAddressField("country")}
                    render={({field}) => (
                        <TextField
                            {...field}
                            select
                            label="Country"
                            variant="outlined"
                            InputLabelProps={{
                                style: {...theme.inputLabelProps},
                            }}
                            onChange={(e) => {uncheckConfirmationBoxes(); field.onChange(e);}}
                            margin="normal"
                            fullWidth
                            disabled={formationData.useRaOfficeAddress === true}
                            size="small"
                        >
                            <MenuItem key="0" value={0}>&nbsp;</MenuItem>

                            {countries.map((country) => (
                                <MenuItem key={country.code} value={country.label}>
                                    {country.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    )}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                <Controller
                    name="correspondenceAddress.postalCode"
                    control={control}
                    defaultValue={getDefaultAddressField("postalCode")}
                    rules={{ required: 'Post Code is required' }}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label="Post Code *"
                            variant="outlined"
                            InputLabelProps={{
                                style: { ...theme.inputLabelProps },
                            }}
                            onChange={(e) => {uncheckConfirmationBoxes(); field.onChange(e);}}
                            margin="normal"
                            fullWidth
                            disabled={formationData.useRaOfficeAddress === true}
                            size="small"
                            error={!!formState.errors.correspondenceAddress?.postalCode}
                            helperText={formState.errors.correspondenceAddress?.postalCode ? formState.errors.correspondenceAddress.postalCode.message : ''}
                        />
                    )}
                />
            </Grid>
        </Grid>  
    );

    if (isLoading) {
        content = (
            <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                <CircularProgress />
            </Box>
        );
    }
    else {
        content = (
            <form onSubmit={handleSubmit(confirmAndContinue)}>
                <Typography component={"div"} align={"left"} sx={{mb: 2, mt:4,fontSize:"24px",fontWeight:"bolder"}}>
                    Director Details
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={3} md={3}>
                        <Controller
                            name="title"
                            control={control}
                            defaultValue={directorData?.title}
                            rules={{ required: 'Title is required' }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    select
                                    label="Title"
                                    variant="outlined"
                                    InputLabelProps={{
                                        style: { ...theme.inputLabelProps },
                                    }}
                                    onChange={(e) => {uncheckConfirmationBoxes(); field.onChange(e);}}
                                    margin="normal"
                                    fullWidth
                                    size="small"
                                    error={!!formState.errors.title}
                                    helperText={formState.errors.title ? formState.errors.title.message : ''}
                                >
                                    <MenuItem key="0" value={0}>&nbsp;</MenuItem>
                                    <MenuItem key="1" value={1}>Mr</MenuItem>
                                    <MenuItem key="2" value={2}>Mrs</MenuItem>
                                    <MenuItem key="3" value={3}>Ms</MenuItem>
                                    <MenuItem key="4" value={4}>Miss</MenuItem>
                                    <MenuItem key="5" value={5}>Dr</MenuItem>
                                </TextField>
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3} md={3}>
                        <Controller
                            name="firstName"
                            control={control}
                            defaultValue={directorData?.firstName}
                            rules={{ required: 'First Name is required' }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="First Name *"
                                    variant="outlined"
                                    InputLabelProps={{
                                        style: { ...theme.inputLabelProps },
                                    }}
                                    onChange={(e) => {
                                        field.onChange(e);
                                        setDirectorFirstName(e.target.value);
                                        uncheckConfirmationBoxes();
                                    }}
                                    margin="normal"
                                    fullWidth
                                    size="small"
                                    error={!!formState.errors.firstName}
                                    helperText={formState.errors.firstName ? formState.errors.firstName.message : ''}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3} md={3}>
                        <Controller
                            name="middleNames"
                            control={control}
                            defaultValue={directorData?.middleNames}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="Middle Name(s)"
                                    variant="outlined"
                                    InputLabelProps={{
                                        style: { ...theme.inputLabelProps },
                                    }}
                                    onChange={(e) => {
                                        field.onChange(e);
                                        setDirectorMiddleNames(e.target.value);
                                        uncheckConfirmationBoxes();
                                    }}
                                    margin="normal"
                                    fullWidth
                                    size="small"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3} md={3}>
                        <Controller
                            name="lastName"
                            control={control}
                            defaultValue={directorData?.lastName}
                            rules={{ required: 'Last Name is required' }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="Last Name *"
                                    variant="outlined"
                                    InputLabelProps={{
                                        style: { ...theme.inputLabelProps },
                                    }}
                                    onChange={(e) => {
                                        field.onChange(e);
                                        setDirectorLastName(e.target.value);
                                        uncheckConfirmationBoxes();
                                    }}
                                    margin="normal"
                                    fullWidth
                                    size="small"
                                    error={!!formState.errors.lastName}
                                    helperText={formState.errors.lastName ? formState.errors.lastName.message : ''}
                                />
                            )}
                        />
                    </Grid>
                </Grid>


                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6}>
                        <Controller
                            name="nationality"
                            control={control}
                            defaultValue={directorData?.nationality}
                            rules={{ required: 'Nationality is required' }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="Nationality *"
                                    variant="outlined"
                                    InputLabelProps={{
                                        style: { ...theme.inputLabelProps },
                                    }}
                                    margin="normal"
                                    fullWidth
                                    size="small"
                                    error={!!formState.errors.nationality}
                                    helperText={formState.errors.nationality ? formState.errors.nationality.message : ''}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <Controller
                            name="dateOfBirth"
                            control={control}
                            defaultValue={directorData?.dateOfBirth ? dayjs(directorData?.dateOfBirth) : null}
                            rules={{ required: 'Date of Birth is required' }}
                            render={({ field }) => (
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        {...field}
                                        format={"DD/MM/YYYY"}
                                        margin="normal"
                                        sx={{mt:2}}
                                        variant="outlined"
                                        slotProps={{
                                            textField: {
                                                placeholder: 'Date of Birth *',
                                                size: 'small',
                                                fullWidth: true,
                                                mt:2,
                                                style: { color: 'blue' },
                                                error: !!formState.errors.dateOfBirth,
                                                helperText: formState.errors.dateOfBirth ? formState.errors.dateOfBirth.message : ''
                                            }
                                        }}
                                    />
                                </LocalizationProvider>
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <Controller
                            name="phoneNumber"
                            control={control}
                            defaultValue={directorData?.phoneNumber}
                            rules={{ required: 'Phone number is required' }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="Phone *"
                                    variant="outlined"
                                    InputLabelProps={{
                                        style: { ...theme.inputLabelProps },
                                    }}
                                    margin="normal"
                                    fullWidth
                                    size="small"
                                    error={!!formState.errors.phoneNumber}
                                    helperText={formState.errors.phoneNumber ? formState.errors.phoneNumber.message : ''}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <Controller
                            name="email"
                            control={control}
                            defaultValue={directorData?.email}
                            rules={{ required: 'Email is required' }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="Email *"
                                    variant="outlined"
                                    InputLabelProps={{
                                        style: { ...theme.inputLabelProps },
                                    }}
                                    margin="normal"
                                    fullWidth
                                    size="small"
                                    error={!!formState.errors.email}
                                    helperText={formState.errors.email ? formState.errors.email.message : ''}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <Controller
                            name="occupation"
                            control={control}
                            defaultValue={directorData?.occupation}
                            rules={{ required: 'Occupation is required' }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="Occupation *"
                                    variant="outlined"
                                    InputLabelProps={{
                                        style: { ...theme.inputLabelProps },
                                    }}
                                    margin="normal"
                                    fullWidth
                                    size="small"
                                    error={!!formState.errors.occupation}
                                    helperText={formState.errors.occupation ? formState.errors.occupation.message : ''}
                                />
                            )}
                        />
                    </Grid>
                </Grid>

                <Typography component={"div"} align={"left"} variant="formationLabelHeader" sx={{mt:2}}>
                    Home Address
                </Typography>
                <Typography component={"div"} align={"left"} variant="formationLabelSubHeader">
                    This is the directors residential address. This address will be kept private.
                </Typography>

                <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                    <Controller
                        name="homeAddress.addressLine1"
                        control={control}
                        defaultValue={directorData?.homeAddress?.addressLine1}
                        rules={{ required: 'Address Line 1 is required' }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label="Address Line 1 *"
                                variant="outlined"
                                InputLabelProps={{
                                    style: { ...theme.inputLabelProps },
                                }}
                                onChange={(e) => {
                                    field.onChange(e);
                                    setHomeAddressLine1(e.target.value);
                                    uncheckConfirmationBoxes();
                                }}
                                margin="normal"
                                fullWidth
                                size="small"
                                error={!!formState.errors.homeAddress?.addressLine1}
                                helperText={formState.errors.homeAddress?.addressLine1 ? formState.errors.homeAddress.addressLine1.message : ''}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Controller
                        name="homeAddress.addressLine2"
                        control={control}
                        defaultValue={directorData?.homeAddress?.addressLine2}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label="Address Line 2"
                                variant="outlined"
                                InputLabelProps={{
                                    style: { ...theme.inputLabelProps },
                                }}
                                onChange={(e) => {
                                    field.onChange(e);
                                    setHomeAddressLine2(e.target.value);
                                    uncheckConfirmationBoxes();
                                }}
                                margin="normal"
                                fullWidth
                                size="small"
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Controller
                        name="homeAddress.locality"
                        control={control}
                        defaultValue={directorData?.homeAddress?.locality}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label="Town / City"
                                variant="outlined"
                                InputLabelProps={{
                                    style: { ...theme.inputLabelProps },
                                }}
                                onChange={(e) => {
                                    field.onChange(e);
                                    setHomeAddressLocality(e.target.value);
                                    uncheckConfirmationBoxes();
                                }}
                                margin="normal"
                                fullWidth
                                size="small"
                                error={!!formState.errors.homeAddress?.locality}
                                helperText={formState.errors.homeAddress?.locality ? formState.errors.homeAddress.locality.message : ''}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Controller
                        name="homeAddress.country"
                        control={control}
                        defaultValue={directorData?.homeAddress?.country}
                        rules={{ required: 'Country is required' }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                select
                                label="Country"
                                variant="outlined"
                                InputLabelProps={{
                                    style: {...theme.inputLabelProps},
                                }}
                                onChange={(e) => {
                                    field.onChange(e);
                                    setHomeAddressCountry(e.target.value);
                                    uncheckConfirmationBoxes();
                                }}
                                margin="normal"
                                fullWidth
                                size="small"
                            >
                                <MenuItem key="0" value={0}>&nbsp;</MenuItem>

                                {countries.map((country) => (
                                    <MenuItem key={country.code} value={country.label}>
                                        {country.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Controller
                        name="homeAddress.postalCode"
                        control={control}
                        defaultValue={directorData?.homeAddress?.postalCode}
                        rules={{ required: 'Post Code is required' }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label="Post Code *"
                                variant="outlined"
                                InputLabelProps={{
                                    style: { ...theme.inputLabelProps },
                                }}
                                onChange={(e) => {
                                    field.onChange(e);
                                    setHomeAddressPostalCode(e.target.value);
                                    uncheckConfirmationBoxes();
                                }}
                                margin="normal"
                                fullWidth
                                size="small"
                                error={!!formState.errors.homeAddress?.postalCode}
                                helperText={formState.errors.homeAddress?.postalCode ? formState.errors.homeAddress.postalCode.message : ''}
                            />
                        )}
                    />
                </Grid>
            </Grid>

                
                {formationData.useRaOfficeAddress === false ? (
                    <>
                        <Typography component={"div"} align={"left"} variant="formationLabelHeader" sx={{mt:2}}>
                            What is the Directors correspondence address?  *
                        </Typography>
                        <Typography component={"div"} align={"left"} variant="formationLabelSubHeader">
                            This address will be publicly available on the Companies House online register. You can use a business or home address and it doesn't have to be in the UK.
                        </Typography>
                        {correspondenceAddressSection}
                    </>
                ): formationData.useRaOfficeAddress === true ? (
                    <>
                        <Typography component={"div"} align={"left"} variant="formationLabelHeader" sx={{mt:2}}>
                            This is the Directors correspondence address
                        </Typography>
                        <Typography component={"div"} align={"left"} variant="formationLabelSubHeader">
                            This address will be publicly available on the Companies House online register. You can use a business or home address and it doesn't have to be in the UK.
                        </Typography>
                        {correspondenceAddressSection}
                    </>
                ) : null}


                <Typography component={"div"} align={"left"} variant="formationLabelHeader" sx={{mt:2}}>
                    Will the director be a shareholder of the company? *
                </Typography>
                <Controller
                    name="directorIsShareholder"
                    control={control}
                    defaultValue={directorData === null || directorData.directorIsShareholder === null ? null : directorData.directorIsShareholder.toString()}
                    rules={{required: `Please select if this director will be a shareholder`}}
                    render={({field}) => (
                        <FormControl error={!!formState.errors.directorIsShareholder}>
                            <RadioGroup {...field} row>
                                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                <FormControlLabel value={false} control={<Radio />} label="No" />
                            </RadioGroup>
                            {formState.errors.directorIsShareholder && (
                                <FormHelperText>{formState.errors.directorIsShareholder.message}</FormHelperText>
                            )}
                        </FormControl>
                    )}
                />
                <Typography component={"div"} align={"left"} variant="formationLabelHeader" sx={{mt:2}}>
                    Right to appoint or remove Directors
                </Typography>
                <Typography component={"div"} align={"left"} variant="formationLabelSubHeader">
                    Will this director hold the right to appoint or remove the majority of the board of directors.
                </Typography>
                <Controller
                    name="rightToAppointOrRemoveDirectors"
                    control={control}
                    defaultValue={directorData === null || directorData.rightToAppointOrRemoveDirectors === null ? null : directorData.rightToAppointOrRemoveDirectors.toString()}
                    rules={{required: `Please select if this director will be a shareholder`}}
                    render={({field}) => (
                        <FormControl error={!!formState.errors.rightToAppointOrRemoveDirectors}>
                            <RadioGroup {...field} row>
                                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                <FormControlLabel value={false} control={<Radio />} label="No" />
                            </RadioGroup>
                            {formState.errors.rightToAppointOrRemoveDirectors && (
                                <FormHelperText>{formState.errors.rightToAppointOrRemoveDirectors.message}</FormHelperText>
                            )}
                        </FormControl>
                    )}
                />
                <Typography component={"div"} align={"left"} variant="formationLabelHeader" sx={{mt:2}}>
                    Ownership of voting rights
                </Typography>
                <Typography component={"div"} align={"left"} variant="formationLabelSubHeader">
                    Please select the following percentage of voting rights in the director holds directly or indirectly in the company.
                </Typography>
                <Controller
                    name="percentageOfVotingRights"
                    control={control}
                    defaultValue={directorData?.percentageOfVotingRights}
                    rules={{required: `Please select the ownership of voting rights`}}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            select
                            variant="outlined"
                            InputLabelProps={{
                                style: { ...theme.inputLabelProps },
                            }}
                            margin="normal"
                            fullWidth
                            size="small"
                            error={!!formState.errors.percentageOfVotingRights}
                            helperText={formState.errors.percentageOfVotingRights ? formState.errors.percentageOfVotingRights.message : ''}
                        >
                            <MenuItem key={FORMATION_DIRECTOR_PERCENTAGE_OF_VOTING_RIGHTS.LESS_THAN_25.toString()} value={FORMATION_DIRECTOR_PERCENTAGE_OF_VOTING_RIGHTS.LESS_THAN_25}>Less than 25%</MenuItem>
                            <MenuItem key={FORMATION_DIRECTOR_PERCENTAGE_OF_VOTING_RIGHTS.BETWEEN_25_AND_50.toString()} value={FORMATION_DIRECTOR_PERCENTAGE_OF_VOTING_RIGHTS.BETWEEN_25_AND_50}>Between 25% and 50%</MenuItem>
                            <MenuItem key={FORMATION_DIRECTOR_PERCENTAGE_OF_VOTING_RIGHTS.GREATER_THAN_50_LESS_THAN_75.toString()} value={FORMATION_DIRECTOR_PERCENTAGE_OF_VOTING_RIGHTS.GREATER_THAN_50_LESS_THAN_75}>Greater than 50%, but less than 75%</MenuItem>
                            <MenuItem key={FORMATION_DIRECTOR_PERCENTAGE_OF_VOTING_RIGHTS.GREATER_THAN_75.toString()} value={FORMATION_DIRECTOR_PERCENTAGE_OF_VOTING_RIGHTS.GREATER_THAN_75}>75% or more</MenuItem>
                        </TextField>
                    )}
                />
                <Typography component={"div"} align={"left"} variant="formationLabelHeader" sx={{mt:2}}>
                    Confirm where the Director lives
                </Typography>
                <Controller
                    name="addressConfirmed"
                    control={control}
                    defaultValue={directorData != null && directorData.addressConfirmed !== null ? directorData.addressConfirmed : false}
                    rules={{ required: 'Please confirm that this is where the director lives' }}
                    render={({ field }) => (
                        <FormControl error={!!formState.errors.addressConfirmed}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        {...field}
                                        color="primary"
                                        checked={field.value}
                                    />
                                }
                                label={`I confirm that ${[directorFirstName?.toUpperCase(), directorMiddleNames?.toUpperCase(), directorLastName?.toUpperCase()].filter(Boolean).join(" ")} lives at ${[homeAddressLine1?.toUpperCase(), homeAddressLine2?.toUpperCase(), homeAddressLocality?.toUpperCase(), homeAddressCountry?.toUpperCase(), homeAddressPostalCode?.toUpperCase()].filter(Boolean).join(",")}.`}
                            />
                            <FormHelperText>
                                {formState.errors.addressConfirmed ? formState.errors.addressConfirmed.message : ''}
                            </FormHelperText>
                        </FormControl>
                    )}
                />
                
                <Typography component={"div"} align={"left"} variant="formationLabelHeader" sx={{mt:2}}>
                    Agree to become a director
                </Typography>
                <Controller
                    name="directorAgreed"
                    control={control}
                    defaultValue={directorData != null && directorData.directorAgreed !== null ? directorData.directorAgreed : false}
                    rules={{ required: 'Please confirm that the person named here agrees to become a director' }}
                    render={({ field }) => (
                        <FormControl error={!!formState.errors.directorAgreed}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        {...field}
                                        color="primary"
                                        checked={field.value}
                                    />
                                }
                                label={`I confirm that the person named here agrees to become a director of the company.`}
                            />
                            <FormHelperText>
                                {formState.errors.directorAgreed ? formState.errors.directorAgreed.message : ''}
                            </FormHelperText>
                        </FormControl>
                    )}
                />

                
                
                <br/>
                <Stack spacing={2} direction="row" sx={{mt: 2}}>
                    <Button variant="contained" color="secondary" sx={{mt: 4}} onClick={(e) => {
                        back();
                    }}>Back</Button>
                    <Button variant="contained" color="secondary" sx={{mt: 4}} type="submit"
                    >Confirm & Continue</Button>
                </Stack>
            </form>
        );
    }


    return (
        <Box maxWidth={"100vh"}>
            {content}
        </Box>
    );
}