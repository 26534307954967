import * as React from 'react';
import Box from "@mui/material/Box";
import {
    Checkbox,
    CircularProgress, Collapse,
    FormControlLabel, FormHelperText,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import {
    COMPANY_FORMATION_TABS,
    FORMATION_DIRECTOR_SHAREHOLDER_TYPE
} from "../enums";
import {Controller, useForm} from "react-hook-form";
import {useTheme} from "@mui/material/styles";
import Button from "@mui/material/Button";
import {FormGroup} from "reactstrap";
import FormationShareholderPersonDetails from "./FormationShareholderPersonDetails";
import FormationShareholderCorporateDetails from "./FormationShareholderCorporateDetails";
import FormationDirectorPersonDetails from "./FormationDirectorPersonDetails";
import FormationSecretaryPersonDetails from "./FormationSecretaryPersonDetails";
import FormationDirectorCorporateDetails from "./FormationDirectorCorporateDetails";
import FormationSecretaryCorporateDetails from "./FormationSecretaryCorporateDetails";
import {Edit} from "@mui/icons-material";
import HtmlTooltip from "../layout/HtmlTooltip";
import TextField from "@mui/material/TextField";

export default function FormationPscs({formationData, createOrUpdateFormationData,parentBack}) {
    const {  handleSubmit, control, formState, setValue } = useForm();
    const theme = useTheme();
    const [isLoading, setIsLoading] = React.useState(false);
    const [selectedDirectorType, setSelectedDirectorType] = React.useState(FORMATION_DIRECTOR_SHAREHOLDER_TYPE.NONE);
    const [directorType, setDirectorType] = React.useState(FORMATION_DIRECTOR_SHAREHOLDER_TYPE.NONE);
    const [editDirectorData, setEditDirectorData] = React.useState(null);
    
    const confirmAndContinue = async (formData) => {
        const newFormationData = {
            ...formationData,
            completedTab: COMPANY_FORMATION_TABS.PSCS,
            controlConfirmed: formData.controlConfirmed
        };
        
        await createOrUpdateFormationData(newFormationData);
    }
    
    const createOrUpdateDirectors = async (directorData) => {
        const newFormationData = {
            ...formationData,
            directorShareholders: directorData
        };
        await createOrUpdateFormationData(newFormationData, false);
        setEditDirectorData(null);
    }

    const editDirector = async (directorId) => {
        const director = formationData.directorShareholders.find(director => director.id === directorId);
        setEditDirectorData(director);
        setDirectorType(director.type);
    }
    
    const back = async () => {
        setDirectorType(FORMATION_DIRECTOR_SHAREHOLDER_TYPE.NONE);
        setEditDirectorData(null);
    }
    
    let content;
    
    if (isLoading) {
        content = (
            <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                <CircularProgress />
            </Box>
        );
    }
    else {
        
        if(directorType === FORMATION_DIRECTOR_SHAREHOLDER_TYPE.NONE) {
            content = (
                <form onSubmit={handleSubmit(confirmAndContinue)}>
                    <Typography component={"div"} align={"left"} sx={{mb: 2, mt:4,fontSize:"24px",fontWeight:"bolder"}}>
                        Persons of Significant Control (PSC) Details
                    </Typography>
                    <Typography component={"div"} align={"left"} variant="formationLabelSubHeader">
                        Based on the shareholder information you’ve already given, we know some information about the company’s PSCs.
                    </Typography>
                    <Typography component={"div"} align={"left"} variant="formationLabelHeader" sx={{mt:2}}>
                        Ownership of shares
                        <HtmlTooltip title={"Who is the PSC when shareholders have equal ownership?"}
                                     message={"If 2 shareholders own half of the company each, both shareholders are in control of the company equally and both are considered a PSC. This is because they each own more than 25% of shares."}/>

                    </Typography>
                    <Typography component={"div"} align={"left"} variant="formationLabelSubHeader">
                        A company PSC is a shareholder that holds over 25% of shares in the company
                    </Typography>
                    
                    <TableContainer>
                        <Table sx={{ minWidth: 650 }} aria-label="Ownership of Shares">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{fontWeight:"bolder"}}>Name</TableCell>
                                    <TableCell sx={{fontWeight:"bolder"}}>Share Percentage</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {formationData.ownershipOfShares.map((row) => {
                                    return (
                                        <TableRow
                                            key={row.directorId}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {row.fullName}
                                            </TableCell>
                                            <TableCell>
                                                {row.sharePercentage}
                                            </TableCell>
                                            <TableCell>
                                                <Stack direction="row" spacing={2}>
                                                    <Edit onClick={(e) => {
                                                        parentBack();
                                                    }} sx={{
                                                        color: theme.palette.primary.main,
                                                        cursor: "pointer"
                                                    }}/>
                                                </Stack>

                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>


                    <Typography component={"div"} align={"left"} variant="formationLabelHeader" sx={{mt:2}}>
                        Ownership of voting rights
                        <HtmlTooltip title={"What is a Person with Significant Control?"}
                                     message={"A Person with Significant Control (PSC) is an individual or company that has at least one of the following:<br/><br/>- more than 25% of the company's shares and voting rights<br/>- the right to appoint or remove directors<br/>- the right to make decisions or influence decisions that affect the company"}/>
                    </Typography>
                    <Typography component={"div"} align={"left"} variant="formationLabelSubHeader">
                        A company PSC holds more than 25% of the voting rights in the company
                    </Typography>

                    <TableContainer>
                        <Table sx={{ minWidth: 650 }} aria-label="Ownership of Shares">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{fontWeight:"bolder"}}>Name</TableCell>
                                    <TableCell sx={{fontWeight:"bolder"}}>Voting Rights</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {formationData.votingRights.map((row) => {
                                    return (
                                        <TableRow
                                            key={row.directorId}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {row.fullName}
                                            </TableCell>
                                            <TableCell>
                                                {row.votingRights}
                                            </TableCell>
                                            <TableCell>
                                                <Stack direction="row" spacing={2}>
                                                    <Edit onClick={(e) => {
                                                        editDirector(row.directorId);
                                                    }} sx={{
                                                        color: theme.palette.primary.main,
                                                        cursor: "pointer"
                                                    }}/>
                                                </Stack>

                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Typography component={"div"} align={"left"} variant="formationLabelHeader" sx={{mt:2}}>
                        Right to appoint or remove Directors
                        <HtmlTooltip title={"What is a Person with Significant Control?"}
                                     message={"A Person with Significant Control (PSC) is an individual or company that has at least one of the following:<br/><br/>- more than 25% of the company's shares and voting rights<br/>- the right to appoint or remove directors<br/>- the right to make decisions or influence decisions that affect the company"}/>
                    </Typography>
                    <Typography component={"div"} align={"left"} variant="formationLabelSubHeader">
                        A company PSC holds the right to appoint and resign the majority of the board of directors.
                    </Typography>

                    <TableContainer>
                        <Table sx={{ minWidth: 650 }} aria-label="Right to appoint or remove directors">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{fontWeight:"bolder"}}>Name</TableCell>
                                    <TableCell sx={{fontWeight:"bolder"}}>Can appoint or remove Directors</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {formationData.canAppointDirectors.map((row) => {
                                    return (
                                        <TableRow
                                            key={row.directorId}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {row.fullName}
                                            </TableCell>
                                            <TableCell>
                                                {row.canAppointDirectors}
                                            </TableCell>
                                            <TableCell>
                                                <Stack direction="row" spacing={2}>
                                                    <Edit onClick={(e) => {
                                                        editDirector(row.directorId);
                                                    }} sx={{
                                                        color: theme.palette.primary.main,
                                                        cursor: "pointer"
                                                    }}/>
                                                </Stack>

                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    
                    <Typography component={"div"} align={"left"} variant="formationLabelHeader" sx={{mt:2}}>
                        Confirm control of the company
                    </Typography>
                    <Controller
                        name="controlConfirmed"
                        control={control}
                        defaultValue={formationData?.controlConfirmed}
                        rules={{required: `Please confirm control of the company`}}
                        render={({ field }) => (
                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox {...field} checked={field.value}  onChange={(e) => {
                                        // Update react-hook-form's form state
                                        field.onChange(e.target.checked);
                                    }}/>}
                                    label={`I confirm that the persons named here are in control of ${formationData.companyName}. They understand that their information will be listed on the public record.`}
                                />
                                {formState.errors.controlConfirmed && (
                                    <FormHelperText error={true}>{formState.errors.controlConfirmed.message}</FormHelperText>
                                )}
                            </FormGroup>
                        )}
                    />
                    <br/>
                    <Stack direction="row" spacing={2} sx={{mt: 2}}>
                        <Button variant="contained" color="secondary" onClick={(e) =>{
                            parentBack();
                        }}>Back</Button>
                        <Button variant="contained" color="secondary" sx={{mt: 4}} type="submit">Confirm & Continue</Button>
                    </Stack>
                </form>
            );
        }
        else if(directorType === FORMATION_DIRECTOR_SHAREHOLDER_TYPE.SHAREHOLDER_PERSON) {
            content = (
                <FormationShareholderPersonDetails formationData={formationData} createOrUpdateDirectors={createOrUpdateDirectors} directorData={editDirectorData} back={back} directorShareholderType={directorType} />
            );
        }
        else if(directorType === FORMATION_DIRECTOR_SHAREHOLDER_TYPE.SHAREHOLDER_COMPANY) {
            content = (
                <FormationShareholderCorporateDetails formationData={formationData} createOrUpdateDirectors={createOrUpdateDirectors} directorData={editDirectorData} back={back} directorShareholderType={directorType} />
            );
        }
        else if(directorType === FORMATION_DIRECTOR_SHAREHOLDER_TYPE.DIRECTOR_PERSON) {
            content = (
                <FormationDirectorPersonDetails formationData={formationData} createOrUpdateDirectors={createOrUpdateDirectors} directorData={editDirectorData} back={back} directorShareholderType={directorType}/>
            );
        }
        else if(directorType === FORMATION_DIRECTOR_SHAREHOLDER_TYPE.SECRETARY_PERSON) {
            content = (
                <FormationSecretaryPersonDetails formationData={formationData} createOrUpdateDirectors={createOrUpdateDirectors} directorData={editDirectorData} back={back} directorShareholderType={directorType} />
            );
        }
        else if(directorType === FORMATION_DIRECTOR_SHAREHOLDER_TYPE.DIRECTOR_COMPANY) {
            content = (
                <FormationDirectorCorporateDetails formationData={formationData} createOrUpdateDirectors={createOrUpdateDirectors} directorData={editDirectorData} back={back} directorShareholderType={directorType}/>
            );
        }
        else if(directorType === FORMATION_DIRECTOR_SHAREHOLDER_TYPE.SECRETARY_COMPANY){
            content = (
                <FormationSecretaryCorporateDetails formationData={formationData} createOrUpdateDirectors={createOrUpdateDirectors} directorData={editDirectorData} back={back} directorShareholderType={directorType}/>
            );
        }
    }


    return (
        <Box maxWidth={"100vh"}>
            {content}
        </Box>
    );
}