import React, {useContext, useEffect, useState} from 'react'
import TextField from "@mui/material/TextField";
import {CircularProgress, Grid, InputAdornment, Pagination} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import {useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TransitionAlert from "../layout/TransitionAlert";
import {COMPANY_TABS} from "../enums";
import companyService from "./CompanyService";
import {enqueueSnackbar} from "notistack";
import AuthContext from "../api-authorization/AuthContext";
import NavigationContext from "../layout/NavigationContext";

export default function AllCompanyDisplay() {
    const { histNavigate } = useContext(NavigationContext);
    const { antiForgeryToken, refreshUser} = useContext(AuthContext);
    const [isLoading, setIsLoading] = React.useState(true);
    const [companies, setCompanies] = React.useState([]);
    const navigate = useNavigate();
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(120); // set this according to how many companies you want on each page
    const [groups, setGroups] = useState([]);

    useEffect(() => {
        const interval = setInterval(() => {
            setPage((prevPage) => prevPage === groups.length ? 1 : prevPage + 1);
        }, 1000 * 2); // change every 60 seconds
        return () => clearInterval(interval);
    }, [groups.length]);

    
    const fetchData = async () => {
        let searchRequest = {
            appScope: 1,
        };
        const result = await companyService.listCompanies(antiForgeryToken, searchRequest);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        let data = await result.json();
        if (result.ok) {
            const sortedCompanies = data.companies.sort((a, b) => {
                const companyA = a.name.toUpperCase();
                const companyB = b.name.toUpperCase();
                if (companyA < companyB) {
                    return -1;
                }
                if (companyA > companyB) {
                    return 1;
                }
                return 0;
            });
            const groups = Array.from({length: Math.ceil(sortedCompanies.length/pageSize)}, (_,i) => sortedCompanies.slice(i*pageSize, i*pageSize+pageSize));
            setGroups(groups);
            setCompanies(sortedCompanies);
            setIsLoading(false);
        } else {
            enqueueSnackbar(data.description);
        }
    };


    React.useEffect(() => {
        fetchData();
    }, []);

    let content = (
        <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
            <CircularProgress />
        </Box>
    );
    
    if (!isLoading) {
        const visibleCompanies = groups[page-1] || [];
        const NUMBER_IN_EACH_COLUMN = 30;

        const groupedCompanies = [];
        for(let i = 0; i < visibleCompanies.length; i += NUMBER_IN_EACH_COLUMN) {
            groupedCompanies.push(visibleCompanies.slice(i, i + NUMBER_IN_EACH_COLUMN));
        }
        content = (
            <Box style={{ height: 400 }}>
                <Typography variant="h5" noWrap component="div" align={"left"} sx={{mb:4}}>
                    All Companies
                </Typography>
                <Grid container spacing={2}>
                    {groupedCompanies.map((group, index) => (
                        <Grid item xs={3} key={index}>
                            {group.map(company => (
                                <Box key={company.id}>
                                    {company.name}
                                </Box>
                            ))}
                        </Grid>
                    ))}
                </Grid>
            </Box>
        );
        
    }
    
    return content;
}
