import React, {useContext, useEffect, useState} from 'react';
import AuthContext from "../api-authorization/AuthContext";
import Typography from "@mui/material/Typography";
import companyService from "../companies/CompanyService";
import {enqueueSnackbar} from "notistack";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {Card, CardContent, CircularProgress, Grid, InputAdornment, Stack} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import {useNavigate} from "react-router-dom";
import Divider from "@mui/material/Divider";
import {Business, ContactsOutlined, MailOutlined, PersonOutlined} from "@mui/icons-material";
import {ACCOUNT_TABS, COMPANY_TABS, COMPANY_TYPE, POST_BOX_NAME_TYPE, ROLES} from "../enums";
import NavigationContext from "../layout/NavigationContext";
import Breadcrumb from "../layout/Breadcrumb";
import InfoTooltip from "../layout/InfoTooltip";

export default function CompanyQuickSearch() {
    const { histNavigate,back } = useContext(NavigationContext);
    const { user, antiForgeryToken, refreshUser } = useContext(AuthContext);
    const [searchTerm, setSearchTerm] = useState("");
    const [isLoading, setIsLoading] = React.useState(false);
    const [hasSearched, setHasSearched] = React.useState(false);
    const [companies, setCompanies] = React.useState([]);

    const navigate = useNavigate();
    
    const fetchData = async () => {
        setIsLoading(true);
        let appScope = 0;
        if(user.role === ROLES.TENANT_ADMIN || user.role === ROLES.TENANT_STANDARD){
            appScope = 1;
        }
        else if(user.role === ROLES.CLIENT_ADMIN || user.role === ROLES.CLIENT_STANDARD){
            appScope = 2;
        }
        else if(user.role === ROLES.ACCOUNT_ADMIN || user.role === ROLES.ACCOUNT_STANDARD){
            appScope = 3;
        }
        let searchRequest = {
            appScope: appScope,
            searchTerm: searchTerm
        };
        const result = await companyService.findCompaniesQuickSearch(antiForgeryToken, searchRequest);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        let data = await result.json();
        if (result.ok) {
            setCompanies(data.companies);
            setHasSearched(true);
        } else {
            enqueueSnackbar(data.description);
        }
        setIsLoading(false);
    };
    
    useEffect(() => {
        if(user.role === ROLES.ACCOUNT_ADMIN || user.role === ROLES.ACCOUNT_STANDARD){
            fetchData();
        }
    }, [])
    
    const handleSearch = (event) => {
        event.preventDefault();
        fetchData();
    };
    
    let companyResultsContent = (
      <></>  
    );
    
    if(isLoading){
        companyResultsContent = (
            <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                <CircularProgress />
            </Box>
        );
    }
    else if(hasSearched){
        companyResultsContent = (
            companies.length === 0 ?
                <Typography sx={{mt: 2, ml: 2}}><i>None found</i></Typography>
                : (
                    <Box sx={{mt: 2}}>
                        {companies.map(company => (
                            <Card sx={{mb:2}}>
                                <CardContent>
                                    <Typography>{company.companyFound ? company.accountSummary.email : company.name}</Typography>
                                    <Divider sx={{ borderBottomWidth: 1, borderColor: "black", mt:2,mb:2}}  />
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={12} lg={3} xl={3} sx={{position: "relative",
                                            pr:2,
                                            "&::before": {
                                                content: "\" \"",
                                                position: "absolute",
                                                height: "80%",
                                                right: 0,
                                                top: "0%",
                                                borderRight: {
                                                    lg: '1px solid #BFBFBF',
                                                    xl: '1px solid #BFBFBF'
                                                }
                                            }}}>
                                            <Grid container spacing={0}>
                                                <Grid item xs={11} sm={11} md={11}>
                                                    <Typography variant="companyQuickSearchHeader">Account Summary</Typography>
                                                </Grid>
                                                <Grid item xs={1} sm={1} md={1} justifyContent="flex-end" >
                                                    <PersonOutlined />
                                                </Grid>
                                                <Grid item xs={4} sm={4} md={4} sx={{mt: 2}}>
                                                    <Typography variant="companyQuickSearchBodyHeader">Email:</Typography>
                                                </Grid>
                                                <Grid item xs={8} sm={8} md={8} sx={{mt: 2}}>
                                                    <Typography variant="companyQuickSearchSummaryLink" onClick={
                                                        () => histNavigate(`/Account/Update/${company.accountSummary.id}/${ACCOUNT_TABS.DETAILS}`,'Update Account')
                                                    }>{company.accountSummary.email}</Typography>
                                                </Grid>
                                                {
                                                    (user.role === ROLES.TENANT_ADMIN || user.role === ROLES.TENANT_STANDARD) &&
                                                    <>
                                                        <Grid item xs={4} sm={4} md={4} >
                                                            <Typography variant="companyQuickSearchBodyHeader">Account Name:</Typography>
                                                        </Grid>
                                                        <Grid item xs={8} sm={8} md={8} >
                                                            <Typography variant="companyQuickSearchBodyText">{company.accountSummary.accountName}</Typography>
                                                        </Grid>
                                                    </>
                                                }

                                                <Grid item xs={4} sm={4} md={4} >
                                                    <Typography variant="companyQuickSearchBodyHeader">Phone:</Typography>
                                                </Grid>
                                                <Grid item xs={8} sm={8} md={8} >
                                                    <Typography variant="companyQuickSearchBodyText">{company.accountSummary.phoneNumber}</Typography>
                                                </Grid>
                                                {
                                                    (user.role === ROLES.TENANT_ADMIN || user.role === ROLES.TENANT_STANDARD) &&
                                                    <>
                                                        <Grid item xs={4} sm={4} md={4}>
                                                            <Typography variant="companyQuickSearchBodyHeader">Client:</Typography>
                                                        </Grid>
                                                        <Grid item xs={8} sm={8} md={8}>
                                                            <Typography variant="companyQuickSearchBodyText">{company.accountSummary.clientName}</Typography>
                                                        </Grid>
                                                    </>
                                                }
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={3} xl={3} sx={{position: "relative",
                                            pr:2,
                                            "&::before": {
                                                content: "\" \"",
                                                position: "absolute",
                                                height: "80%",
                                                right: 0,
                                                top: "0%",
                                                borderRight: {
                                                    lg: '1px solid #BFBFBF',
                                                    xl: '1px solid #BFBFBF'
                                                }
                                            }, ml: 0}}>
                                            {company.companyType === COMPANY_TYPE.COMPANY &&
                                                <Grid container spacing={0}>
                                                    <Grid item xs={10} sm={10} md={11} >
                                                        <Typography variant="companyQuickSearchHeader">Company Summary</Typography>
                                                    </Grid>
                                                    <Grid item xs={2} sm={2} md={1}>
                                                        <Business />
                                                    </Grid>
                                                    {company.companyFound ?
                                                        <>
                                                            <Grid item xs={4} sm={4} md={4} sx={{mt: 2}}>
                                                                <Typography variant="companyQuickSearchBodyHeader">Name:</Typography>
                                                            </Grid>
                                                            <Grid item xs={8} sm={8} md={8} sx={{mt: 2}}>
                                                                <Typography variant="companyQuickSearchSummaryLink"  onClick={
                                                                    () => histNavigate(`/Company/Update/${company.id}/${COMPANY_TABS.DETAILS}`,'Update Company')
                                                                }>{company.name}</Typography>
                                                            </Grid>
                                                            <Grid item xs={4} sm={4} md={4} >
                                                                <Typography variant="companyQuickSearchBodyHeader">Number:</Typography>
                                                            </Grid>
                                                            <Grid item xs={8} sm={8} md={8} >
                                                                <Typography variant="companyQuickSearchBodyText">{company.number}</Typography>
                                                            </Grid>
                                                            <Grid item xs={4} sm={4} md={4} >
                                                                <Typography variant="companyQuickSearchBodyHeader">Status:</Typography>
                                                            </Grid>
                                                            <Grid item xs={8} sm={8} md={8} >
                                                                <Typography variant="companyQuickSearchBodyText">{company.status}</Typography>
                                                            </Grid>
                                                            <Grid item xs={4} sm={4} md={4} >
                                                                <Typography variant="companyQuickSearchBodyHeader">T/A:</Typography>
                                                            </Grid>
                                                            <Grid item xs={8} sm={8} md={8} >
                                                                <Typography variant="companyQuickSearchBodyText">{company.tradingNames}</Typography>
                                                            </Grid>
                                                            <Grid item xs={4} sm={4} md={4} >
                                                                <Typography variant="companyQuickSearchBodyHeader">Previous Names:</Typography>
                                                            </Grid>
                                                            <Grid item xs={8} sm={8} md={8} >
                                                                <Typography variant="companyQuickSearchBodyText">{company.previousNames}</Typography>
                                                            </Grid>
                                                        </>
                                                        :
                                                        <Grid item xs={10} sm={6} md={4} sx={{mt: 2}}>
                                                            <Typography variant="companyQuickSearchBodyText"><i>None found</i></Typography>
                                                        </Grid>
                                                    }
                                                </Grid>
                                            }
                                            {company.companyType === COMPANY_TYPE.POST_BOX &&
                                                <Grid container spacing={0}>
                                                    <Grid item xs={10} sm={10} md={11} >
                                                        <Typography variant="companyQuickSearchHeader">Post Box Summary</Typography>
                                                    </Grid>
                                                    <Grid item xs={2} sm={2} md={1}>
                                                        <Business />
                                                    </Grid>
                                                    {company.companyFound ?
                                                        <>
                                                            <Grid item xs={4} sm={4} md={4} sx={{mt: 2}}>
                                                                <Typography variant="companyQuickSearchBodyHeader">
                                                                    {company.postBoxNameType === POST_BOX_NAME_TYPE.NAME ? "Name:" : "Number:"}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={8} sm={8} md={8} sx={{mt: 2}}>
                                                                <Typography variant="companyQuickSearchSummaryLink"  onClick={
                                                                    () => histNavigate(`/Company/Update/${company.id}/${COMPANY_TABS.DETAILS}`,'Update Company')
                                                                }>{company.postBoxName}</Typography>
                                                            </Grid>
                                                            <Grid item xs={4} sm={4} md={4} >
                                                                <Typography variant="companyQuickSearchBodyHeader">Status:</Typography>
                                                            </Grid>
                                                            <Grid item xs={8} sm={8} md={8} >
                                                                <Typography variant="companyQuickSearchBodyText">{company.postBoxStatus}</Typography>
                                                            </Grid>
                                                            <Grid item xs={4} sm={4} md={4} >
                                                                <Typography variant="companyQuickSearchBodyHeader">Previous Names:</Typography>
                                                            </Grid>
                                                            <Grid item xs={8} sm={8} md={8} >
                                                                <Typography variant="companyQuickSearchBodyText">{company.previousNames}</Typography>
                                                            </Grid>
                                                        </>
                                                        :
                                                        <Grid item xs={10} sm={6} md={4} sx={{mt: 2}}>
                                                            <Typography variant="companyQuickSearchBodyText"><i>None found</i></Typography>
                                                        </Grid>
                                                    }
                                                </Grid>
                                            }
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={3} xl={3} sx={{position: "relative",
                                            pr:2,
                                            "&::before": {
                                                content: "\" \"",
                                                position: "absolute",
                                                height: "80%",
                                                right: 0,
                                                top: "0%",
                                                borderRight: {
                                                    lg: '1px solid #BFBFBF',
                                                    xl: '1px solid #BFBFBF'
                                                }
                                            }, ml: 0}}>
                                            <Grid container spacing={0}>
                                                <Grid item xs={6} sm={6} md={11} >
                                                    <Typography variant="companyQuickSearchHeader">Primary Contact</Typography>
                                                </Grid>
                                                <Grid item xs={6} sm={6} md={1} >
                                                    <ContactsOutlined />
                                                </Grid>
                                                {company.primaryContactSummary ? <>
                                                    <Grid item xs={4} sm={4} md={4} sx={{mt: 2}}>
                                                        <Typography variant="companyQuickSearchBodyHeader">Name:</Typography>
                                                    </Grid>
                                                    <Grid item xs={8} sm={8} md={8} sx={{mt: 2}}>
                                                        <Typography variant="companyQuickSearchSummaryLink" onClick={
                                                            () => histNavigate(`/Person/Update/${company.primaryContactSummary.id}`)
                                                        }>{company.primaryContactSummary.name}</Typography>
                                                    </Grid>
                                                    <Grid item xs={4} sm={4} md={4} >
                                                        <Typography variant="companyQuickSearchBodyHeader">Number:</Typography>
                                                    </Grid>
                                                    <Grid item xs={8} sm={8} md={8} >
                                                        <Typography variant="companyQuickSearchBodyText">{company.primaryContactSummary.phoneNumber}</Typography>
                                                    </Grid>
                                                    <Grid item xs={4} sm={4} md={4} >
                                                        <Typography variant="companyQuickSearchBodyHeader">Email:</Typography>
                                                    </Grid>
                                                    <Grid item xs={8} sm={8} md={8} >
                                                        <Typography variant="companyQuickSearchBodyText">{company.primaryContactSummary.email}</Typography>
                                                    </Grid>
                                                    <Grid item xs={4} sm={4} md={4} >
                                                        <Typography variant="companyQuickSearchBodyHeader">Role:</Typography>
                                                    </Grid>
                                                    <Grid item xs={8} sm={8} md={8} >
                                                        <Typography variant="companyQuickSearchBodyText">{company.primaryContactSummary.role}</Typography>
                                                    </Grid>
                                                    </>
                                                :
                                                <Grid item xs={10} sm={6} md={4} sx={{mt: 2}}>
                                                    <Typography variant="companyQuickSearchBodyText"><i>None found</i></Typography>
                                                </Grid> }
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={3} xl={3} sx={{ml: 0, pr:2}}>
                                            <Grid container spacing={0}>
                                                <Grid item xs={6} sm={6} md={11} >
                                                    <Typography variant="companyQuickSearchHeader">Mail Service</Typography>
                                                </Grid>
                                                <Grid item xs={6} sm={6} md={1} >
                                                    <MailOutlined />
                                                </Grid>
                                                {company.mailForwardingSummary ? <>
                                                        <Grid item xs={4} sm={4} md={4} sx={{mt: 2}}>
                                                            <Typography variant="companyQuickSearchBodyHeader">Product name:</Typography>
                                                        </Grid>
                                                        <Grid item xs={8} sm={8} md={8} sx={{mt: 2}}>
                                                            <Typography variant="companyQuickSearchSummaryLink" onClick={(e) => {
                                                                if(user.role == ROLES.ACCOUNT_ADMIN || user.role == ROLES.ACCOUNT_STANDARD){
                                                                    histNavigate("/Mail/ListMyMail",'Mail Search');
                                                                }
                                                                else{
                                                                    histNavigate(`/Mail/CompanyMail/${company.id}`,'Company Mail');
                                                                }
                                                            }}>{company.mailForwardingSummary.productName}</Typography>
                                                        </Grid>
                                                        <Grid item xs={4} sm={4} md={4} >
                                                            <Typography variant="companyQuickSearchBodyHeader">Next Invoice:</Typography>
                                                        </Grid>
                                                        <Grid item xs={8} sm={8} md={8} >
                                                            <Typography variant="companyQuickSearchBodyText">{company.mailForwardingSummary.nextInvoiceDate}</Typography>
                                                        </Grid>
                                                        <Grid item xs={4} sm={4} md={4} >
                                                            <Typography variant="companyQuickSearchBodyHeader">Amount:</Typography>
                                                        </Grid>
                                                        <Grid item xs={8} sm={8} md={8} >
                                                            <Typography variant="companyQuickSearchBodyText">{company.mailForwardingSummary.amount}</Typography>
                                                        </Grid>
                                                        <Grid item xs={4} sm={4} md={4} >
                                                            <Typography variant="companyQuickSearchBodyHeader">Interval:</Typography>
                                                        </Grid>
                                                        <Grid item xs={8} sm={8} md={8} >
                                                            <Typography variant="companyQuickSearchBodyText">{company.mailForwardingSummary.interval}</Typography>
                                                        </Grid>
                                                        <Grid item xs={4} sm={4} md={4} >
                                                            <Typography variant="companyQuickSearchBodyHeader">Status:</Typography>
                                                        </Grid>
                                                        <Grid item xs={8} sm={8} md={8} >
                                                            <Typography variant="companyQuickSearchSubscriptionStatus" color={company.mailForwardingSummary.status == "ACTIVE" || company.mailForwardingSummary.status == "TRIALING" ? "green" : "red"}>
                                                                {company.mailForwardingSummary.status}
                                                            </Typography>
                                                        </Grid>
                                                    </>
                                                    :  
                                                    <Grid item xs={10} sm={6} md={4} sx={{mt: 2}}>
                                                        <Typography variant="companyQuickSearchBodyText"><i>None found</i></Typography>
                                                    </Grid> }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        ))}
                    </Box>
                )
        );
    }
    
    let content;
    content = (
        <>
            <Box style={{ height: 400 }}>
                <Stack direction="row" justifyContent="space-between">
                    <Breadcrumb flexGrow={1}/>
                    <InfoTooltip title="Account and Company Search" message="This is where you can search accounts and companies you manage"/>
                </Stack>
                <Typography variant="h5" noWrap component="div" align={"left"} sx={{mb:4}}>
                    Welcome {user.firstName},
                </Typography>
                <form onSubmit={handleSearch}>
                    <TextField
                        id="search-bar"
                        className="text"
                        value={searchTerm}
                        onChange={(e) => {
                            setSearchTerm(e.target.value);
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="end">
                                    <IconButton type="submit">
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        label="Search"
                        variant="outlined"
                        size="small"
                        style={{ width: '100%' }}
                    />
                </form>
                {companyResultsContent}
            </Box>
        </>
    );

    return (
        <>
            {content}
        </>
    );
}
