export function generateMailLabelXml(mailLabel = {}) {
    
    var reEncloseNumber = mailLabel.reEncloseNumber ? 
            ` <TextObject>
              <Name>TextObject1</Name>
              <Brushes>
                <BackgroundBrush>
                  <SolidColorBrush>
                    <Color A="0" R="0" G="0" B="0"></Color>
                  </SolidColorBrush>
                </BackgroundBrush>
                <BorderBrush>
                  <SolidColorBrush>
                    <Color A="1" R="0" G="0" B="0"></Color>
                  </SolidColorBrush>
                </BorderBrush>
                <StrokeBrush>
                  <SolidColorBrush>
                    <Color A="1" R="0" G="0" B="0"></Color>
                  </SolidColorBrush>
                </StrokeBrush>
                <FillBrush>
                  <SolidColorBrush>
                    <Color A="0" R="0" G="0" B="0"></Color>
                  </SolidColorBrush>
                </FillBrush>
              </Brushes>
              <Rotation>Rotation0</Rotation>
              <OutlineThickness>1</OutlineThickness>
              <IsOutlined>False</IsOutlined>
              <BorderStyle>SolidLine</BorderStyle>
              <Margin>
                <DYMOThickness Left="0" Top="0" Right="0" Bottom="0" />
              </Margin>
              <HorizontalAlignment>Center</HorizontalAlignment>
              <VerticalAlignment>Middle</VerticalAlignment>
              <FitMode>AlwaysFit</FitMode>
              <IsVertical>False</IsVertical>
              <FormattedText>
                <FitMode>AlwaysFit</FitMode>
                <HorizontalAlignment>Center</HorizontalAlignment>
                <VerticalAlignment>Middle</VerticalAlignment>
                <IsVertical>False</IsVertical>
                <LineTextSpan>
                  <TextSpan>
                    <Text>${mailLabel.reEncloseNumber}</Text>
                    <FontInfo>
                      <FontName>Segoe UI</FontName>
                      <FontSize>6.3</FontSize>
                      <IsBold>True</IsBold>
                      <IsItalic>False</IsItalic>
                      <IsUnderline>False</IsUnderline>
                      <FontBrush>
                        <SolidColorBrush>
                          <Color A="1" R="0" G="0" B="0"></Color>
                        </SolidColorBrush>
                      </FontBrush>
                    </FontInfo>
                  </TextSpan>
                </LineTextSpan>
                <LineTextSpan>
                  <TextSpan>
                    <Text />
                    <FontInfo>
                      <FontName>Segoe UI</FontName>
                      <FontSize>6.3</FontSize>
                      <IsBold>True</IsBold>
                      <IsItalic>False</IsItalic>
                      <IsUnderline>False</IsUnderline>
                      <FontBrush>
                        <SolidColorBrush>
                          <Color A="1" R="0" G="0" B="0"></Color>
                        </SolidColorBrush>
                      </FontBrush>
                    </FontInfo>
                  </TextSpan>
                </LineTextSpan>
              </FormattedText>
              <ObjectLayout>
                <DYMOPoint>
                  <X>0.0454234</X>
                  <Y>0.936805</Y>
                </DYMOPoint>
                <Size>
                  <Width>0.1945463</Width>
                  <Height>0.2439082</Height>
                </Size>
              </ObjectLayout>
            </TextObject>`
        
        : "";

    var addressXml = mailLabel.addressLines.map((addressLine) =>
        `            <LineTextSpan>
                      <TextSpan>
                        <Text>${addressLine}</Text>
                        <FontInfo>
                          <FontName>Segoe UI</FontName>
                          <FontSize>14.1</FontSize>
                          <IsBold>True</IsBold>
                          <IsItalic>False</IsItalic>
                          <IsUnderline>False</IsUnderline>
                          <FontBrush>
                            <SolidColorBrush>
                              <Color A="1" R="0" G="0" B="0"></Color>
                            </SolidColorBrush>
                          </FontBrush>
                        </FontInfo>
                      </TextSpan>
                    </LineTextSpan>`).join("");
    

    var labelXml = `<?xml version="1.0" encoding="utf-8"?>
                            <DesktopLabel Version="1">
                              <DYMOLabel Version="3">
                                <Description>DYMO Label</Description>
                                <Orientation>Portrait</Orientation>
                                <LabelName>S0722540 multipurpose</LabelName>
                                <InitialLength>0</InitialLength>
                                <BorderStyle>SolidLine</BorderStyle>
                                <DYMORect>
                                  <DYMOPoint>
                                    <X>0.03999997</X>
                                    <Y>0.06</Y>
                                  </DYMOPoint>
                                  <Size>
                                    <Width>2.17</Width>
                                    <Height>1.13</Height>
                                  </Size>
                                </DYMORect>
                                <BorderColor>
                                  <SolidColorBrush>
                                    <Color A="1" R="0" G="0" B="0"></Color>
                                  </SolidColorBrush>
                                </BorderColor>
                                <BorderThickness>1</BorderThickness>
                                <Show_Border>False</Show_Border>
                                <DynamicLayoutManager>
                                  <RotationBehavior>ClearObjects</RotationBehavior>
                                  <LabelObjects>
                                    <AddressObject>
                                      <Name>AddressObject0</Name>
                                      <Brushes>
                                        <BackgroundBrush>
                                          <SolidColorBrush>
                                            <Color A="0" R="0" G="0" B="0"></Color>
                                          </SolidColorBrush>
                                        </BackgroundBrush>
                                        <BorderBrush>
                                          <SolidColorBrush>
                                            <Color A="1" R="0" G="0" B="0"></Color>
                                          </SolidColorBrush>
                                        </BorderBrush>
                                        <StrokeBrush>
                                          <SolidColorBrush>
                                            <Color A="1" R="0" G="0" B="0"></Color>
                                          </SolidColorBrush>
                                        </StrokeBrush>
                                        <FillBrush>
                                          <SolidColorBrush>
                                            <Color A="0" R="0" G="0" B="0"></Color>
                                          </SolidColorBrush>
                                        </FillBrush>
                                      </Brushes>
                                      <Rotation>Rotation0</Rotation>
                                      <OutlineThickness>1</OutlineThickness>
                                      <IsOutlined>False</IsOutlined>
                                      <BorderStyle>SolidLine</BorderStyle>
                                      <Margin>
                                        <DYMOThickness Left="0" Top="0" Right="0" Bottom="0" />
                                      </Margin>
                                      <HorizontalAlignment>Center</HorizontalAlignment>
                                      <VerticalAlignment>Middle</VerticalAlignment>
                                      <FitMode>AlwaysFit</FitMode>
                                      <IsVertical>False</IsVertical>
                                      <FormattedText>
                                        <FitMode>AlwaysFit</FitMode>
                                        <HorizontalAlignment>Center</HorizontalAlignment>
                                        <VerticalAlignment>Middle</VerticalAlignment>
                                        <IsVertical>False</IsVertical>
                                        ${addressXml}
                                      </FormattedText>
                                      <BarcodePosition>None</BarcodePosition>
                                      <ObjectLayout>
                                        <DYMOPoint>
                                          <X>0.0454234</X>
                                          <Y>0.2593316</Y>
                                        </DYMOPoint>
                                        <Size>
                                          <Width>2.164577</Width>
                                          <Height>0.79014</Height>
                                        </Size>
                                      </ObjectLayout>
                                    </AddressObject>
                                   ${reEncloseNumber}
                                  </LabelObjects>
                                </DynamicLayoutManager>
                              </DYMOLabel>
                              <LabelApplication>Blank</LabelApplication>
                              <DataTable>
                                <Columns></Columns>
                                <Rows></Rows>
                              </DataTable>
                            </DesktopLabel>`;
    return labelXml.replaceAll("&", "&amp;");
}