async function getMail(antiForgeryToken, mailId) {
    return fetch(`/api/Mail/GetMail/${mailId}`, {
        method: 'GET',
        headers: buildFormAuthHeaders(antiForgeryToken)
    });
}

async function downloadMail(antiForgeryToken, mailId) {
    return fetch(`/api/Mail/DownloadMail/${mailId}`, {
        method: 'GET',
        headers: buildAuthHeaders(antiForgeryToken)
    });
}

const removeMail  = async (antiForgeryToken, mailId) => {
    return fetch(`api/Mail/RemoveMail/${mailId}`, {
        method: 'DELETE',
        headers: buildAuthHeaders(antiForgeryToken)
    });
};

const updateMail  = async (antiForgeryToken, updateMailRequest) => {
    return fetch(`api/Mail/UpdateMail`, {
        method: 'PATCH',
        body: JSON.stringify(updateMailRequest),
        headers: buildAuthHeaders(antiForgeryToken)
    });
};

async function checkInMail(antiForgeryToken, formData) {
    return fetch(`/api/Mail/CheckInMail`, {
        method: 'POST',
        body: formData,
        headers: buildFormAuthHeaders(antiForgeryToken)
    });
}

async function checkoutMail(antiForgeryToken, checkoutMailRequest) {
    return fetch(`/api/Mail/CheckoutMail`, {
        method: 'POST',
        body: JSON.stringify(checkoutMailRequest),
        headers: buildAuthHeaders(antiForgeryToken)
    });
}

async function checkInAndCheckoutMail(antiForgeryToken, formData) {
    return fetch(`/api/Mail/CheckInAndCheckoutMail`, {
        method: 'POST',
        body: formData,
        headers: buildFormAuthHeaders(antiForgeryToken)
    });
}

async function getCheckoutCoverSheet(antiForgeryToken, mailCheckoutId) {
    return fetch(`/api/Mail/GetCheckoutCoverSheet/${mailCheckoutId}`, {
        method: 'GET',
        headers: buildAuthHeaders(antiForgeryToken)
    });
}

const listCheckoutMail  = async (antiForgeryToken, findCheckoutMailRequest) => {
    return fetch(`api/Mail/FindCheckoutMail`, {
        method: 'POST',
        body: JSON.stringify(findCheckoutMailRequest),
        headers: buildAuthHeaders(antiForgeryToken)
    });
};

const listMailCheckouts  = async (antiForgeryToken) => {
    return fetch(`api/Mail/ListMailCheckouts`, {
        method: 'GET',
        headers: buildAuthHeaders(antiForgeryToken)
    });
};


const listMail  = async (antiForgeryToken, findMailRequest) => {
    return fetch(`api/Mail/FindMail`, {
        method: 'POST',
        body: JSON.stringify(findMailRequest),
        headers: buildAuthHeaders(antiForgeryToken)
    });
};

const listCompanyMail  = async (antiForgeryToken, companyId, searchTerm) => {
    return fetch(`api/Mail/FindCompanyMail/${companyId}`, {
        method: 'GET',
        headers: buildAuthHeaders(antiForgeryToken)
    });
};

function buildAuthHeaders(antiForgeryToken) {
    return !antiForgeryToken ? {} : {
        [antiForgeryToken.tokenName] : antiForgeryToken.token,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    };
}

function buildFormAuthHeaders(antiForgeryToken){
    return !antiForgeryToken ? {} : {
        [antiForgeryToken.tokenName] : antiForgeryToken.token
    };
}

const mailService = Object.freeze({
    checkInMail,
    checkoutMail,
    listMail,
    downloadMail,
    listCheckoutMail,
    listCompanyMail,
    getMail,
    removeMail,
    updateMail,
    listMailCheckouts,
    getCheckoutCoverSheet,
    checkInAndCheckoutMail,
});
export default mailService;