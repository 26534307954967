import * as React from 'react';
import {useNavigate, useParams} from "react-router-dom";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {useContext, useEffect, useState} from "react";
import {enqueueSnackbar} from "notistack";
import AddCompanyDetails from "./AddCompanyDetails";
import {Checkbox, CircularProgress, FormControlLabel, Stack} from "@mui/material";
import Button from "@mui/material/Button";
import AddCompanyPrimaryContact from "./AddCompanyPrimaryContact";
import {useForm} from "react-hook-form";
import {FormGroup} from "reactstrap";
import {ACCOUNT_TABS, COMPANY_TYPE, ROLES} from "../enums";
import accountService from "../accounts/AccountService";
import companiesHouseService from "../companiesHouse/CompaniesHouseService";
import AuthContext from "../api-authorization/AuthContext";
import companyService from "../companies/CompanyService";
import inputService from "../util/InputService";
import NavigationContext from "../layout/NavigationContext";
import Breadcrumb from "../layout/Breadcrumb";
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function AddCompanyParent() {
    const { histNavigate, back } = useContext(NavigationContext);
    const { antiForgeryToken, user, refreshUser} = useContext(AuthContext);
    const {  handleSubmit, control, formState,setValue } = useForm();
    const [tabValue, setTabValue] = React.useState(0);
    const { accountId, companyNumber } = useParams();
    const [disableSubmit, setDisableSubmit] = useState(false);
    let [correctCompanyConfirmation, setCorrectCompanyConfirmation] = React.useState(false);
    const [isDisablePrimaryContactChecked, setIsDisablePrimaryContactChecked] = useState(false);
    const navigate = useNavigate();

    const [isCompanyLoading, setCompanyLoading] = useState(true);
    const [isAccountLoading, setAccountLoading] = useState(true);
    const [companyDetails, setCompanyDetails] = useState();
    const [isComplianceApproved, setIsComplianceApproved] = useState(false);
    const [isAddressApproved, setIsAddressApproved] = useState(false);
    const [accountData, setAccountData] = useState();
    const handleChange = (event, newTabValue) => {
        setTabValue(newTabValue);
    };

    const onChangeHandler = event => {
        setCorrectCompanyConfirmation(event.target.checked);
    };




    const handleFormSubmit  = async (primaryContactData) => {
        setDisableSubmit(true);
        let addCompanyToAccountRequest = companyDetails;
        addCompanyToAccountRequest.accountId = accountId;
        addCompanyToAccountRequest.primaryContact = primaryContactData;
        primaryContactData = inputService.trimFields(primaryContactData);
        const result = await companyService.addCompanyToAccount(antiForgeryToken, addCompanyToAccountRequest);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                enqueueSnackbar("Successfully added company to account");
                if(user.role == ROLES.ACCOUNT_ADMIN){
                    refreshUser();
                }
                back(2);
            } else {
                enqueueSnackbar(data.description);
            }
        }
        setDisableSubmit(false);
    };

    useEffect( () => {
        async function fetchAccountData() {
            const result = await accountService.getAccount(antiForgeryToken, accountId);
            if (result.status === 401) {
                refreshUser();
                navigate('/login');
            }
            else {
                let data = await result.json();
                if (result.ok) {
                    setAccountData(data);
                    setAccountLoading(false);
                } else {
                    enqueueSnackbar(data.description);
                }
            }
        }
        async function fetchCompanyData() {
            const result = await companiesHouseService.getCompany(antiForgeryToken, companyNumber);
            if (result.status === 401) {
                refreshUser();
                navigate('/login');
            }
            else {
                let data = await result.json();
                if (result.ok) {
                    setCompanyDetails(data);
                    setCompanyLoading(false);
                } else {
                    enqueueSnackbar(data.description);
                }
            }
        }
        fetchAccountData();
        fetchCompanyData();
    }, [accountId, companyNumber]);
    
    
    let content;
    if (isCompanyLoading || isAccountLoading) {
        content = (
            <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                <CircularProgress />
            </Box>
        );
    } else {
        let breadcrumbItems = [
            {description: `Account - ${accountData.email}`, url: `/Account/Update/${accountData.id}/${ACCOUNT_TABS.DETAILS}`},
            {description: `Companies`, url: `/Account/Update/${accountData.id}/${ACCOUNT_TABS.COMPANIES}`}
        ];
        let label= (
            <>
                <Typography>Confirm this is the correct company and list of directors you want to add to account - <strong>{accountData.email}</strong> before proceeding </Typography>
            </>
        );
        content = (
            <Box sx={{ width: '100%' }}>
                {(user.role === ROLES.TENANT_ADMIN || user.role === ROLES.TENANT_STANDARD || user.role === ROLES.CLIENT_ADMIN || user.role === ROLES.CLIENT_STANDARD) &&(
                    <Breadcrumb breadcrumbItems={breadcrumbItems}/>
                )}
                <Typography variant="h5" noWrap component="div" align={"left"} sx={{ mt: 2, mb:4}}>
                    Company - {companyDetails.name}
                </Typography>
                <Box>
                    <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Details" {...a11yProps(0)}/>
                        <Tab label="Primary Contact" {...a11yProps(1)} disabled={correctCompanyConfirmation === false}/>
                    </Tabs>
                </Box>
                <TabPanel value={tabValue} index={0}>
                    <AddCompanyDetails companyDetails={companyDetails} />
                    <br/>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox checked={correctCompanyConfirmation} onChange={onChangeHandler}/>}  label={label}/>
                    </FormGroup>
                    <Stack direction="row" spacing={2} marginTop={2}>
                        <Button variant="contained" color="secondary" onClick={(e) => {
                            back();
                        }}>
                            Back
                        </Button>
                        <Button variant="contained" color="secondary" disabled={correctCompanyConfirmation === false} onClick={(e) => {
                            setTabValue(1);
                        }}>
                            Next
                        </Button>
                    </Stack>
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    <form onSubmit={handleSubmit(handleFormSubmit)}>
                        <AddCompanyPrimaryContact setValue={setValue} formState={formState} control={control} setIsComplianceApproved={setIsComplianceApproved} 
                                                  setIsAddressApproved={setIsAddressApproved} accountData={accountData} 
                                                  isDisablePrimaryContactChecked={isDisablePrimaryContactChecked} setIsDisablePrimaryContactChecked={setIsDisablePrimaryContactChecked} 
                                                  companyType={COMPANY_TYPE.COMPANY}
                        />
                        <Stack direction="row" spacing={2} marginTop={4}>
                            <Button variant="contained" color="secondary" onClick={(e) => {
                                setTabValue(0);
                            }}>
                                Back
                            </Button>
                            <Button variant="contained" color="secondary" type="submit" disabled={disableSubmit || isComplianceApproved === false || isAddressApproved === false}>
                                Submit
                            </Button>
                        </Stack>
                    </form>
                </TabPanel>
            </Box>
        );
    }

    return (
        <>
            {content}
        </>
    );
}