import React, {useContext, useState} from 'react';
import {useStripe, useElements, PaymentElement, AddressElement} from '@stripe/react-stripe-js';
import Button from "@mui/material/Button";
import {enqueueSnackbar} from "notistack";
import Typography from "@mui/material/Typography";
import {CircularProgress, Stack} from "@mui/material";
import {useNavigate} from "react-router-dom";
import TransitionAlert from "../layout/TransitionAlert";
import Box from "@mui/material/Box";
import NavigationContext from "../layout/NavigationContext";

const SetupForm = ({resourceType, resourceId}) => {
    const { histNavigate,history,back } = useContext(NavigationContext);
    const [isPaymentElementReady, setIsPaymentElementReady] = useState(false);
    const [isAddressElementReady, setIsAddressElementReady] = useState(false);
    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();
    const [disableSubmit, setDisableSubmit] = useState(false);

    const [errorMessage, setErrorMessage] = useState(null);
    

    const handlePaymentElementReady = () => {
        setIsPaymentElementReady(true);
    };

    const handleAddressElementReady = () => {
        setIsAddressElementReady(true);
    };
    
    const handleSubmit = async (event) => {
        setDisableSubmit(true);
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return null;
        }

        localStorage.setItem("navigationHistory", JSON.stringify(history));
        
        const {error} = await stripe.confirmSetup({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: `${window.location.protocol}//${window.location.host}/Billing/FinishCardSetup/${resourceType}/${resourceId}`,
            },
        });

        if (error) {
            // This point will only be reached if there is an immediate error when
            // confirming the payment. Show error to your customer (for example, payment
            // details incomplete)
            enqueueSnackbar("Error adding card " + error.message);
            setDisableSubmit(false);
        } else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
            enqueueSnackbar("Successfully added card");
        }
    };
    
    return (
            <form onSubmit={handleSubmit}>
                {(!isPaymentElementReady || !isAddressElementReady) &&             
                    <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                    <CircularProgress />
                </Box>}
                
                <Typography variant="h6" noWrap component="div" align={"left"} sx={{mb:2}}>
                    Payment card
                </Typography>
                <PaymentElement onReady={handlePaymentElementReady} />
                <Typography variant="h6" noWrap component="div" align={"left"} sx={{mb:1, mt:2}}>
                    Billing Address
                </Typography>
                <Typography noWrap component="div" align={"left"} sx={{mb:2, WebkitTextStroke: "0.4px", fontSize:"0.8rem",}}>
                    This is the billing address associated to the payment method
                </Typography>
                <AddressElement options={{mode: 'shipping', allowedCountries: ['GBR']}} onReady={handleAddressElementReady} />

                <br />
                <Stack direction="row" spacing={2} marginTop={2}>
                    <Button variant="contained" color="secondary" onClick={(e) => {
                            back();
                    }}>
                        Back
                    </Button>
                    <Button variant="contained" color="secondary" type="submit" disabled={disableSubmit}>
                        Submit
                    </Button>
                </Stack>
            </form>
    );
}

export default SetupForm;