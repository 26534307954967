//TODO refactor this out so it's in seperate files

export const ROLES = {
    TENANT_ADMIN: "TenantAdmin",
    TENANT_STANDARD: "TenantStandard",
    CLIENT_ADMIN: "ClientAdmin", 
    CLIENT_STANDARD: "ClientStandard",
    ACCOUNT_ADMIN : "AccountAdmin",
    ACCOUNT_STANDARD: "AccountStandard",
}

export const TAX_RATE ={
    UK: 0.2
}

export const ACCOUNT_TABS = {
    DETAILS: 0,
    USERS: 1,
    BILLING: 2,
    COMPANIES: 3,
    PO_BOXES: 4,
    PRODUCTS: 5
}

export const COMPANY_TABS = {
    DETAILS: 0,
    CONTACTS: 1,
    BILLING: 2,
    PRODUCTS: 3,
    MAIL: 4
}

export const CLIENT_TABS = {
    DETAILS: 0,
    USERS: 1
}

export const BILLING_TABS = {
    PAYMENT_METHOD: 0,
    PAYMENT_HISTORY: 1
}

export const MAIL_TABS = {
    MAIL_HISTORY: 0,
    MAIL_SETTINGS: 1
}

export const COMPANY_FORMATION_TABS = {
    NAME_CHECK: 0,
    PACKAGE_SELECT:1,
    YOUR_COMPANY: 2,
    REGISTERED_OFFICE: 3,
    DIRECTORS_AND_SECRETARIES: 4,
    SHAREHOLDERS: 5,
    PSCS: 6,
    ELECTRONIC_SIGNATURE: 7,
    CONFIRMATION:8
}

export const FORMATION_DIRECTOR_SHAREHOLDER_TYPE ={
    NONE: 0,
    DIRECTOR_PERSON: 1,
    DIRECTOR_COMPANY : 2,
    SECRETARY_PERSON: 3,
    SECRETARY_COMPANY: 4,
    SHAREHOLDER_PERSON: 5,
    SHAREHOLDER_COMPANY: 6
}

export const FORMATION_DIRECTOR_PERCENTAGE_OF_VOTING_RIGHTS = {
    NONE: 0,
    LESS_THAN_25: 1,
    BETWEEN_25_AND_50: 2,
    GREATER_THAN_50_LESS_THAN_75: 3,
    GREATER_THAN_75: 4
}

export const FORMATION_OTHER_SHARE_CURRENCY = {
    GBP: 1,
    EUR: 2,
    USD: 3
}

export const RESOURCE_TYPE = {
    ACCOUNT: 1,
    COMPANY: 2,
    POST_BOX: 3
}

export const COMPANY_TYPE = {
    COMPANY: 1,
    POST_BOX: 2
}

export const SCANNED_MAIL_TYPE = {
    SCANNED: 1,
    UNKNOWN: 2,
    PRE_SORT: 3
}

export const MAIL_PROCESS_STATUS = {
    RECEIVED: 1,
    SCANNED : 2,
    SENT : 3
}

export const USER_ROLES = {
    TENANT_ADMIN : 1,
    TENANT_STANDARD : 2,
    CLIENT_ADMIN : 3,
    CLIENT_STANDARD : 4,
    ACCOUNT_ADMIN : 5,
    ACCOUNT_STANDARD : 6,
}

export const COMPANY_MAIL_SETTINGS_INSTRUCTIONS_TYPE = {
    LETTER: 1,
    SPECIAL: 2,
    PARCEL: 3,
    PRINTED: 4
}

export const COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE = {
    NONE: 0,
    SCAN_HOLD: 1,
    SCAN_SHRED: 2,
    SCAN_FORWARD: 3,
    FORWARD_ALL_MAIL: 4,
    FORWARD_OFFICIAL_ONLY: 5,
    HOLD: 6,
    REJECT: 7
}

export const MAIL_TYPE = {
    NONE: 0,
    ROYAL_MAIL_LETTER: 1,
    ROYAL_MAIL_LARGE_LETTER: 2,
    ROYAL_MAIL_SMALL_PARCEL: 3,
    ROYAL_MAIL_MEDIUM_PARCEL: 4,
    ROYAL_MAIL_LARGE_PARCEL: 5,
    COMPANIES_HOUSE: 6,
    THE_PENSION_REGULATOR: 7,
    HMRC: 8,
    OTHER_SPECIAL_DELIVERY: 9,
    OTHER_STATUTORY_MAIL: 10,
    PRINTED_MATTER: 11,
    INFORMATION_COMMISSIONERS_OFFICE: 12,
    OFFICE_OF_NATIONAL_STATISTICS: 13,
    DVLA: 14
}

export const FIND_COMPANIES_MAIL_SETTINGS_RESULT_TYPE = {
    COMPANY: 1,
    PERSON: 2,
    POST_BOX: 3
}

export const POST_BOX_NAME_TYPE = {
    NONE: 0,
    NUMBER: 1,
    NAME: 2
}

export const MAIL_PROCESSING_ACTIONS = {
    NEXT: 1,
    UNKNOWN: 2,
    RTS: 3,
    CHECK_IN: 4,
    EXPRESS_CHECKOUT: 5,
}

export const MAIL_PROCESSING_PAGE = {
    PRE_SORT: 1,
    SCANNED_MAIL: 2,
    CHECK_IN: 3,
    UNKNOWN: 4,
}

