
const findPaymentDisputes  = async (antiForgeryToken,clientId) => {
    return fetch(`/api/Reports/FindPaymentDisputes?clientId=${clientId}`, {
        method: 'GET',
        headers: buildAuthHeaders(antiForgeryToken)
    });
};

const findVirtualSquatters  = async (antiForgeryToken) => {
    return fetch(`/api/Reports/FindVirtualSquatters`, {
        method: 'GET',
        headers: buildAuthHeaders(antiForgeryToken)
    });
};

const companyReport  = async (antiForgeryToken, companyReportRequest) => {
    return fetch(`/api/Reports/CompanyReport`, {
        method: 'POST',
        headers: buildAuthHeaders(antiForgeryToken),
        body: JSON.stringify(companyReportRequest),
    });
};

function buildAuthHeaders(antiForgeryToken) {
    return !antiForgeryToken ? {} : {
        [antiForgeryToken.tokenName] : antiForgeryToken.token,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    };
}

const reportService = Object.freeze({
    findPaymentDisputes,
    findVirtualSquatters,
    companyReport
});
export default reportService;