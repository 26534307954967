import * as React from 'react';
import {useNavigate, useParams} from "react-router-dom";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {useContext, useEffect, useState} from "react";
import {enqueueSnackbar} from "notistack";
import AddCompanyDetails from "../addCompany/AddCompanyDetails";
import {CircularProgress, Stack} from "@mui/material";
import Button from "@mui/material/Button";
import companiesHouseService from "./CompaniesHouseService";
import AuthContext from "../api-authorization/AuthContext";
import NavigationContext from "../layout/NavigationContext";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function ViewCompaniesHouse() {
    const { histNavigate, back } = useContext(NavigationContext);
    const { antiForgeryToken, refreshUser} = useContext(AuthContext);
    const { companyNumber } = useParams();
    const [tabValue, setTabValue] = React.useState(0);
    const navigate = useNavigate();

    const [isCompanyLoading, setCompanyLoading] = useState(true);
    const [companyDetails, setCompanyDetails] = useState();
    const handleChange = (event, newTabValue) => {
        setTabValue(newTabValue);
    };
    
    

    useEffect(() => {

        async function fetchCompanyData() {
            const result = await companiesHouseService.getCompany(antiForgeryToken, companyNumber);
            if (result.status === 401) {
                refreshUser();
                navigate('/login');
            }
            else {
                let data = await result.json();
                if (result.ok) {
                    setCompanyDetails(data);
                    setCompanyLoading(false);
                } else {
                    enqueueSnackbar(data.description);
                }
            }
        }
        fetchCompanyData();
    }, [companyNumber])

    let content;
    if (isCompanyLoading) {
        content = (
            <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                <CircularProgress />
            </Box>
        );
    } else {
        content = (
            <Box sx={{ width: '100%' }}>
                <Typography variant="h5" noWrap component="div" align={"left"} sx={{ mt: 2, mb:4 }}>
                    Companies House Search - {companyDetails.name}
                </Typography>
                <Box>
                    <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Details" {...a11yProps(0)}/>
                    </Tabs>
                </Box>
                <TabPanel value={tabValue} index={0}>
                    
                    <AddCompanyDetails companyDetails={companyDetails} />
                    <Stack direction="row" spacing={2} marginTop={2}>
                        <Button variant="contained" color="secondary" onClick={(e) => {
                            histNavigate(`/CompaniesHouse/List`,'Companies House Search');
                        }}>
                            Back
                        </Button>
                    </Stack>
                </TabPanel>
            </Box>
        );
    }

    return (
        <>
            {content}
        </>
    );
}