import React from 'react';
import Typography from "@mui/material/Typography";
import {Paper} from "@mui/material";

export default function AddCompanyDetails({companyDetails}) {
    
    let content;
    
        let officers = companyDetails.officers.length === 0 ?
            (<Typography variant="companyDetailsBody">
                None found
            </Typography>)
            : companyDetails.officers.map(officer => (
            <div key={officer.id}>
                <Typography variant="companyDetailsBody">
                    {officer.name}
                </Typography>
                <Typography variant="companyDetailsBody">
                    {officer.address.oneLine}
                </Typography>
                <br/>
            </div>
        ));
        
       let pscs = companyDetails.pscs.length === 0 ?
        (<Typography variant="companyDetailsBody">
            None found
        </Typography>)
        : companyDetails.pscs.map(psc => (
            <div key={psc.id}>
                <Typography variant="companyDetailsBody">
                    {psc.name}
                </Typography>
                <Typography variant="companyDetailsBody">
                    {psc.address.oneLine}
                </Typography>
                {psc.natureOfControls.map((natureOfControl, index) => (
                    <Typography key={index} variant="companyDetailsBody">
                        {natureOfControl.natureOfControlReadable}
                    </Typography>
                ))}
                <br/>
            </div>
        ));

        let sicCodes = companyDetails.sicCodes.length === 0 ?
        (<Typography variant="companyDetailsBody">
            None found
        </Typography>)
        : companyDetails.sicCodes.map(sicCode => (
            <div key={sicCode.id}>
                <Typography variant="companyDetailsBody">
                    {sicCode.code} - {sicCode.description}
                </Typography>
                <br/>
            </div>
        ));
        
        content = (
            <>
            <Paper style={{maxHeight: 400, overflow: 'auto', padding: '10px'}} variant="outlined" >
                <Typography variant="companyDetailsHeader">
                    Company Name & Number
                </Typography>
                <Typography variant="companyDetailsBody">
                    {companyDetails.name} - {companyDetails.number}
                </Typography>
                <br />
                <Typography variant="companyDetailsHeader">
                    Registered office address
                </Typography>
                <Typography variant="companyDetailsBody">
                    {companyDetails.registeredOfficeAddress.oneLine}
                </Typography>
                <br />
                <Typography variant="companyDetailsHeader">
                    Company status
                </Typography>
                <Typography variant="companyDetailsBody">
                    {companyDetails.status}
                </Typography>
                <br />
                <Typography variant="companyDetailsHeader">
                    Incorporated on
                </Typography>
                <Typography variant="companyDetailsBody">
                    {companyDetails.incorporationDate}
                </Typography>
                <br />
                <Typography variant="companyDetailsHeader">
                    Officers
                </Typography>
                {officers}
                <br />
                <Typography variant="companyDetailsHeader">
                    PSCs
                </Typography>
                {pscs}
                <br />
                <Typography variant="companyDetailsHeader">
                    Nature of business (SIC)
                </Typography>
                {sicCodes}
                
            </Paper>
            </>
        );

    return (
        <>
            {content}
        </>
    );

}