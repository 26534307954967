import React, {useContext, useState} from 'react'
import {DataGridPro } from '@mui/x-data-grid-pro';
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Pagination, Stack} from "@mui/material";
import {useNavigate} from 'react-router-dom';
import {enqueueSnackbar} from "notistack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {SCANNED_MAIL_TYPE} from "../enums";
import AuthContext from "../api-authorization/AuthContext";
import NavigationContext from "../layout/NavigationContext";
import scannedMailService from "./ScannedMailService";
import {Controller, useForm} from "react-hook-form";
import Button from "@mui/material/Button";
import {useTheme} from "@mui/material/styles";
import Divider from "@mui/material/Divider";

export default function ListScanBatches({scannedMailType}) {
    const { histNavigate, back } = useContext(NavigationContext);
    const { handleSubmit, control, formState,reset } = useForm();
    const { antiForgeryToken, refreshUser } = useContext(AuthContext);
    
    const [isLoading, setIsLoading] = React.useState(true);
    const [scanBatches, setScanBatches] = React.useState([]);
    const [rowCountState, setRowCountState] = React.useState(0);
    const [paginationModel, setPaginationModel] = React.useState({
        page: 0,
        pageSize: 20,
    });
    
    const [batchResults, setBatchResults] = useState(null);
    
    const navigate = useNavigate();
    const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
    const [scanBatchToDelete, setScanBatchToDelete] = React.useState(null);
    
    const fetchData = async () => {
        setPaginationModel((prevModel) => ({...prevModel, page: 0}));
        setIsLoading(true);
        const result = await scannedMailService.listScanBatches(antiForgeryToken, scannedMailType);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                setScanBatches(data.scanBatches);
                setRowCountState(data.scanBatches.length);
                setIsLoading(false);
            } else {
                enqueueSnackbar(data.description);
            }
        }
        setIsLoading(false);
    };

    const uploadScans  = async (data) => {
        setIsLoading(true);
        const formData = new FormData();
        formData.append('scannedMailType', scannedMailType);

        for (var i = 0; i < data.files.length; i++) {
            formData.append("files", data.files[i]);
        }
        const result = await scannedMailService.uploadScans(antiForgeryToken, formData);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let responseData = await result.json();
            if (result.ok) {
                let queueType = responseData.scannedMailType == SCANNED_MAIL_TYPE.SCANNED ? 'Scanned' : 'Pre-Sort';
                setBatchResults(`Successfully uploaded ${responseData.fileCount} scans to the ${queueType} queue`);
                enqueueSnackbar('Scans uploaded successfully.');
                reset({ files: null });
                await fetchData();
            } else {
                enqueueSnackbar(responseData.description);
            }
        }
        setIsLoading(false);
    };

    const unlockScanBatch = async (scanBatchId) => {
        setIsLoading(true);
        const result = await scannedMailService.unlockScanBatch(antiForgeryToken, scanBatchId);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                enqueueSnackbar("Successfully unlocked scan batch");
                await fetchData();
            } else {
                enqueueSnackbar(data.description);
            }
        }
        setIsLoading(false);
    };

    const deleteScanBatch = async () => {
        setIsLoading(true);
        const result = await scannedMailService.deleteScanBatch(antiForgeryToken, scanBatchToDelete);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                handleDeleteDialogClose();
                enqueueSnackbar("Successfully deleted scan batch");
                await fetchData();
            } else {
                enqueueSnackbar(data.description);
            }
        }
        setIsLoading(false);
    };

    const handleDeleteDialogOpen = (scanBatchId) => {
        setScanBatchToDelete(scanBatchId);
        setDeleteDialogOpen(true);
    };

    const handleDeleteDialogClose = () => {
        setDeleteDialogOpen(false);
        setScanBatchToDelete(null);
    };


    React.useEffect(() => {
        fetchData();
    }, [scannedMailType]);

    const handlePageChange = (params) => {
        setPaginationModel({
            page: params.page,
            pageSize: params.pageSize,
        });
    };

    const handleRowClick = (params) => {
        if(params.row.uploadComplete === false) {
            enqueueSnackbar(`Upload is currently in progress, please wait until it has completed.`);
        }
        else if(params.row.batchComplete === true) {
            enqueueSnackbar(`Batch has been completed and there are no items remaining to process.`);
        }
        else if(params.row.canBeActioned === false) {
            enqueueSnackbar(`Batch is currently locked by another user, please unlock before proceeding.`);
        }
        else{
            if(scannedMailType == SCANNED_MAIL_TYPE.SCANNED){
                histNavigate(`Mail/ProcessScannedMail/${params.id}`,'Process Scanned Mail');
            }
            else if(scannedMailType == SCANNED_MAIL_TYPE.PRE_SORT){
                histNavigate(`Mail/PreSortMail/${params.id}`,'Process PreSort Mail');
            }
            if(scannedMailType == SCANNED_MAIL_TYPE.UNKNOWN){
                histNavigate(`Mail/ProcessUnknownScannedMail/${params.id}`,'Process Unknown Mail');
            }
        }
    };

    const dateFormatter = (params) => {
        const date = new Date(params.value);
        if (isNaN(date.getTime()) || date.getFullYear() <= 1) {
            return "None";
        }
        return date.toLocaleDateString('en-GB');
    };

    const getTitle = (scannedMailType) => {
        if (scannedMailType === SCANNED_MAIL_TYPE.SCANNED) {
            return 'Scanned Mail Batches';
        } else if (scannedMailType === SCANNED_MAIL_TYPE.PRE_SORT) {
            return 'Pre-Sort Mail Batches';
        } else if (scannedMailType === SCANNED_MAIL_TYPE.UNKNOWN) { // Assuming you have a 'unknown' value in your SCANNED_MAIL_TYPE enum
            return 'Unknown Mail Batches';
        } else {
            throw new Error(`Invalid scannedMailType: ${scannedMailType}`);
        }
    };

    return (
        <Box sx={{height: 400}}>
            <Stack direction="row" justifyContent="space-between">
                <Typography variant="h5" noWrap component="div" align={"left"} sx={{mb:4}} flexGrow={1}>
                    {getTitle(scannedMailType)}
                </Typography>
            </Stack>
            {scannedMailType != SCANNED_MAIL_TYPE.UNKNOWN &&
                <>
                    <Divider/>
                    <Box sx={{ maxWidth: 400 }}>
                        <form onSubmit={handleSubmit(uploadScans)}>
                            <Stack direction="row" spacing={2} marginTop={2} marginBottom={2}>
                                <Controller
                                    name="files"
                                    control={control}
                                    rules={{ required: 'File upload is required' }}
                                    render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                        <Button
                                            variant="outlined"
                                            component="label"
                                        >
                                            <input
                                                type="file"
                                                multiple
                                                hidden
                                                accept="application/pdf"
                                                name={name}
                                                onBlur={onBlur}
                                                ref={ref}
                                                onChange={e => {
                                                    onChange(e.target.files);
                                                    //handleFile(e.target.files[0]);
                                                }}
                                            />
                                            {value ? `${value.length} file(s) selected` : 'Select Files'}
                                        </Button>
                                    )}
                                />
                                <Controller
                                    name="files"
                                    control={control}
                                    render={({ field: { value } }) => (
                                        <Button variant="contained" color="secondary" type="submit" disabled={value ? value.length === 0 : true}>
                                            Upload
                                        </Button>
                                    )}
                                />
    
                            </Stack>
                            {batchResults &&
                                <Typography variant="body1" noWrap component="div" align={"left"} sx={{mb:4}}>
                                    {batchResults}
                                </Typography>
                            }
                        </form>
                    </Box>
                    <Divider/>
                </>
            }

            <Stack direction="row" spacing={2} marginTop={ scannedMailType=== SCANNED_MAIL_TYPE.UNKNOWN ? 0 : 2}>
                <Button variant="contained" color="secondary" onClick={(e) => {
                    fetchData();
                }}>
                    Refresh Batches
                </Button>
            </Stack>
            <br />
            <DataGridPro
                rows={scanBatches.slice(
                    paginationModel.page * paginationModel.pageSize,
                    paginationModel.page * paginationModel.pageSize + paginationModel.pageSize
                )}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            batchNumber: scannedMailType != SCANNED_MAIL_TYPE.UNKNOWN,
                            createdByEmail: true,
                            createdAt: true,
                            uploadStatus: scannedMailType != SCANNED_MAIL_TYPE.UNKNOWN,
                            lockedByEmail: true,
                            unlock: true
                        }
                    }
                }}
                columns={[
                    { field: 'batchNumber', headerName: 'Batch Number', flex: 1  },
                    {field: 'createdByEmail', headerName: 'Uploaded By', flex: 2},
                    { field: 'createdAt', headerName: 'Created', flex: 1, valueFormatter: dateFormatter },
                    { field: 'uploadStatus', headerName: 'Upload Status', flex: 2},
                    { field: 'lockedByEmail', headerName: 'Locked By', flex: 2},
                    { field: 'itemsRemaining', headerName: 'Items Left To Process', flex: 1},
                    {
                        field: 'unlock', headerName: '', flex: 1,
                        renderCell: (params) => (
                            params.row.batchLocked === true && params.row.uploadComplete === true && params.row.batchComplete === false ?
                                <Button variant="contained" color="secondary"
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            unlockScanBatch(params.row.id);
                                        }}>Unlock</Button>
                                : null
                        ),
                    },
                    {
                        field: 'delete', headerName: '', flex: 1,
                        renderCell: (params) => (
                            params.row.canBeDeleted == true ?
                                <Button variant="contained" color="secondary"
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            handleDeleteDialogOpen(params.row.id);
                                        }}>Delete</Button>
                                : null
                        ),
                    }
                ]}
                rowCount={paginationModel.pageSize}
                pageSize={paginationModel.pageSize}
                hideFooter={true}
                page={paginationModel.page}
                onPageChange={handlePageChange}
                loading={isLoading}
                onRowClick={handleRowClick}
                getRowClassName={() => 'rowCursorPointer'}
                autoHeight
                sx={{
                    '.MuiDataGrid-columnHeaderTitle': {
                        WebkitTextStroke: '0.75px'
                    },
                }}
            />
            <Box display="flex" justifyContent="flex-end">
                <Pagination
                    count={Math.ceil(rowCountState / paginationModel.pageSize)}
                    page={paginationModel.page + 1}
                    onChange={(event, page) => handlePageChange({ page: page - 1, pageSize: paginationModel.pageSize })}
                />
            </Box>
            <Dialog
                open={deleteDialogOpen}
                onClose={handleDeleteDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Warning"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you wish to delete this batch?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteDialogClose}>No</Button>
                    <Button onClick={(e) => {
                        e.preventDefault();
                        deleteScanBatch();
                    }} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}