import React, {useContext} from 'react';
import Button from "@mui/material/Button";
import {
    Stack, Pagination, Link
} from "@mui/material";
import {enqueueSnackbar} from "notistack";
import {useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";
import productService from "./ProductService";
import Typography from "@mui/material/Typography";
import NavigationContext from "../layout/NavigationContext";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {RESOURCE_TYPE} from "../enums";
import {FormGroup} from "reactstrap";

export default function ListCustomerProducts({antiForgeryToken, resourceType, resourceId, refreshUser}) {
    const { histNavigate, back } = useContext(NavigationContext);
    const [products, setProducts] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const [rowCountState, setRowCountState] = React.useState(0);
    const [paginationModel, setPaginationModel] = React.useState({
        page: 0,
        pageSize: 10,
    });
    const [showCancelled, setShowCancelled] = React.useState(false);
    const navigate = useNavigate();

    const fetchData = async () => {
        setIsLoading(true);
        setPaginationModel((prevModel) => ({...prevModel, page: 0}));
        const result = await productService.listCustomerProducts(antiForgeryToken, resourceType, resourceId);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        let data = await result.json();
        if (result.ok) {
            let products = data.products;
            if(!showCancelled){
                products = data.products.filter((product) => product.status.toLowerCase() !== "cancelled");
            }
            setProducts(products);
            setRowCountState(products.length);
            setIsLoading(false);
        } else {
            enqueueSnackbar(data.description);
        }
    };

    const handleChange = (event) => {
        setShowCancelled(event.target.checked);
    };

    React.useEffect(() => {
        fetchData();
    }, [showCancelled]);

    const handlePageChange = (params) => {
        setPaginationModel({
            page: params.page,
            pageSize: params.pageSize,
        });
    };

    const dateFormatter = (params) => {
        if(params.value === null){
            return "";
        }
        const date = new Date(params.value);
        if (isNaN(date.getTime()) || date.getFullYear() <= 1) {
            return "None";
        }
        return date.toLocaleDateString('en-GB');
    };

    const handleRowClick = (params) => {
        if(params.row.oneTimePurchase===true){
            return;
        }
        histNavigate(`/Billing/Subscription/${resourceType}/${resourceId}/${params.row.subscriptionId}`,'Product Details');
    };



    return (
            <Box style={{ height: 400, width: '100%' }}>
                <DataGridPro
                    rows={products.slice(
                        paginationModel.page * paginationModel.pageSize,
                        paginationModel.page * paginationModel.pageSize + paginationModel.pageSize
                    )}
                    initialState={{
                        columns: {
                            columnVisibilityModel: {
                                companyNameOrPostBoxName: resourceType === RESOURCE_TYPE.ACCOUNT
                            },
                        },
                    }}
                    columns={[
                        { field: 'purchaseDate', headerName: 'Purchase Date', flex: 1, valueFormatter: dateFormatter },
                        { field: 'companyNameOrPostBoxName', headerName: 'Company / Post Box', flex: 3 },
                        { field: 'productName', headerName: 'Product name', flex: 3 },
                        {
                            field: 'amountDue',
                            headerName: 'Amount ',
                            flex: 1,
                            renderHeader: (params) => (
                                <>
                                    <Typography sx={{WebkitTextStroke: "0.65px"}}>Amount</Typography>
                                    <Typography variant="caption">&nbsp;(ex VAT)</Typography>
                                </>
                            ),
                        },
                        { field: 'nextPaymentDate', headerName: 'Next Payment', flex: 1, valueFormatter: dateFormatter },
                        { field: 'trialPeriodEnd', headerName: 'Trial Period End', flex: 1, valueFormatter: dateFormatter },
                        {
                            field: 'status',
                            headerName: 'Status',
                            renderHeader: (params) => (
                                <>
                                    <Typography sx={{WebkitTextStroke: "0.65px"}}>Status&nbsp;</Typography>
                                    <Link
                                        component="button"
                                        variant="caption"
                                        onClick={(event) => {
                                            // Prevent event propagation
                                            event.stopPropagation();
                                            setShowCancelled(!showCancelled);
                                        }}
                                    >
                                        {showCancelled ? "Hide Cancelled" : "Show All"}
                                    </Link>
                                </>
                            ),
                            flex: 3,
                            renderCell: (params) => (
                                    <Typography style={{fontWeight:950, fontSize:"0.85rem"}} color={params.value?.toUpperCase() == "ACTIVE" || params.value.toUpperCase() == "TRIALING" ? "green" : "red"}>
                                        {params.value?.toUpperCase()}
                                    </Typography>
                            )
                        }
                    ]}
                    rowCount={rowCountState}
                    pagination
                    pageSize={paginationModel.pageSize}
                    page={paginationModel.page}
                    onPageChange={handlePageChange}
                    hideFooter={true}
                    loading={isLoading}
                    autoHeight
                    onRowClick={handleRowClick}
                    getRowClassName={() => 'rowCursorPointer'}
                    sx={{
                        '.MuiDataGrid-columnHeaderTitle': {
                            WebkitTextStroke: "0.75px"
                        }
                    }}
                />
                <Box display="flex" justifyContent="flex-end">
                    <Pagination
                        count={Math.ceil(rowCountState / paginationModel.pageSize)}
                        page={paginationModel.page + 1}
                        onChange={(event, page) => handlePageChange({ page: page - 1, pageSize: paginationModel.pageSize })}
                    />
                </Box>

                {
                    (resourceType === RESOURCE_TYPE.COMPANY || resourceType === RESOURCE_TYPE.POST_BOX)  &&
                    <Stack direction="row" spacing={2} marginTop={2}>
                        <Button variant="contained" color="secondary"   onClick={(e) => {
                            e.preventDefault();
                            histNavigate(`/Product/AddNew/${resourceType}/${resourceId}`,'Add New Product');
                        }}>
                            Add
                        </Button>
                    </Stack>
                }
            </Box>
        );
}