import MenuItem from '@mui/material/MenuItem';
import {COMPANY_MAIL_SETTINGS_INSTRUCTIONS_TYPE, COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE} from "../enums";
import * as React from "react";


/*TODO will probably be cleaner to have every possible type value in it's own JS enum, but, this requirement is probably going to change,
*  or be abandoned completely, so go with this for now*/

export const mailSettingsMenuDefaults =[
    {
        mailHandlingType : COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.SCAN_HOLD,
        mailForwardingInstructionDefaultValue : 0,
        mailHoldTypeDefaultValue : 1,
        mailForwardingFrequencyDefaultValue : 0,
        mailForwardingDayDefaultValue : 0,
        mailClassDefaultValue : 0
    },
    {
        mailHandlingType : COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.SCAN_SHRED,
        mailForwardingInstructionDefaultValue : 0,
        mailHoldTypeDefaultValue : 3,
        mailForwardingFrequencyDefaultValue : 0,
        mailForwardingDayDefaultValue : 0,
        mailClassDefaultValue : 0
    },
    {
        mailHandlingType : COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.SCAN_FORWARD,
        mailForwardingInstructionDefaultValue : 2,
        mailHoldTypeDefaultValue : 0,
        mailForwardingFrequencyDefaultValue : 1,
        mailForwardingDayDefaultValue : 0,
        mailClassDefaultValue : 1
    },
    {
        mailHandlingType : COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.FORWARD_ALL_MAIL,
        mailForwardingInstructionDefaultValue : 2,
        mailHoldTypeDefaultValue : 0,
        mailForwardingFrequencyDefaultValue : 1,
        mailForwardingDayDefaultValue : 0,
        mailClassDefaultValue : 1
    },
    {
        mailHandlingType : COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.FORWARD_OFFICIAL_ONLY,
        mailForwardingInstructionDefaultValue : 2,
        mailHoldTypeDefaultValue : 2,
        mailForwardingFrequencyDefaultValue : 1,
        mailForwardingDayDefaultValue : 0,
        mailClassDefaultValue : 1
    },
    {
        mailHandlingType : COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.HOLD,
        mailForwardingInstructionDefaultValue : 0,
        mailHoldTypeDefaultValue : 1,
        mailForwardingFrequencyDefaultValue : 0,
        mailForwardingDayDefaultValue : 0,
        mailClassDefaultValue : 0
    },
    {
        mailHandlingType : COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.REJECT,
        mailForwardingInstructionDefaultValue : 0,
        mailHoldTypeDefaultValue : 0,
        mailForwardingFrequencyDefaultValue : 0,
        mailForwardingDayDefaultValue : 0,
        mailClassDefaultValue : 0
    }
]

export const mailSettingsMenuOptions = [
    //First the mail handling types
    {
        type: COMPANY_MAIL_SETTINGS_INSTRUCTIONS_TYPE.LETTER,
        mailHandlingMenuItems: [
            <MenuItem key={COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.SCAN_HOLD.toString()} value={COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.SCAN_HOLD}>Scan & Hold</MenuItem>,
            <MenuItem key={COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.SCAN_SHRED.toString()} value={COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.SCAN_SHRED}>Scan & Shred</MenuItem>,
            <MenuItem key={COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.SCAN_FORWARD.toString()} value={COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.SCAN_FORWARD}>Scan & Forward</MenuItem>,
            <MenuItem key={COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.FORWARD_ALL_MAIL.toString()} value={COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.FORWARD_ALL_MAIL}>Forward - All mail</MenuItem>,
            <MenuItem key={COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.FORWARD_OFFICIAL_ONLY.toString()} value={COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.FORWARD_OFFICIAL_ONLY}>Forward - Official Only</MenuItem>,
            <MenuItem key={COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.HOLD.toString()} value={COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.HOLD}>Hold</MenuItem>,
            <MenuItem key={COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.REJECT.toString()} value={COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.REJECT}>Reject</MenuItem>
        ],
        mailHoldDisabled:{
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.SCAN_HOLD]: false,
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.SCAN_SHRED]: false,
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.SCAN_FORWARD]: true,
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.FORWARD_ALL_MAIL]: true,
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.FORWARD_OFFICIAL_ONLY]: false,
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.HOLD]: false,
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.REJECT]: true
        },
        mailHoldMenuItems: {
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.SCAN_HOLD]: [
                <MenuItem key="1" value={1}>All</MenuItem>
            ],
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.SCAN_SHRED]: [
                <MenuItem key="3" value={3}>Shred (30)</MenuItem>,
                <MenuItem key="4" value={4}>Shred (60)</MenuItem>,
                <MenuItem key="5" value={5}>Shred (90)</MenuItem>,
            ],
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.SCAN_FORWARD]: [
                <MenuItem key="0" value={0}>None</MenuItem>
            ],
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.FORWARD_ALL_MAIL]: [
                <MenuItem key="0" value={0}>None</MenuItem>
            ],
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.FORWARD_OFFICIAL_ONLY]: [
                <MenuItem key="2" value={2}>Official Only</MenuItem>
            ],
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.HOLD]: [
                <MenuItem key="1" value={1}>All</MenuItem>
            ],
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.REJECT]: [
                <MenuItem key="0" value={0}>None</MenuItem>
            ]
        },
        mailForwardingInstructionsDisabled:{
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.SCAN_HOLD]: true,
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.SCAN_SHRED]: true,
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.SCAN_FORWARD]: false,
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.FORWARD_ALL_MAIL]: false,
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.FORWARD_OFFICIAL_ONLY]: false,
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.HOLD]: true,
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.REJECT]: true
        },
        mailForwardingInstructionsMenuItems:{
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.SCAN_HOLD]: [
                <MenuItem key="0" value={0}>None</MenuItem>
            ],
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.SCAN_SHRED]: [
                <MenuItem key="0" value={0}>None</MenuItem>
            ],
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.SCAN_FORWARD]: [
                <MenuItem key="1" value={1}>Re-Direct</MenuItem>,
                <MenuItem key="2" value={2}>Re-Enclose</MenuItem>
            ],
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.FORWARD_ALL_MAIL]: [
                <MenuItem key="1" value={1}>Re-Direct</MenuItem>,
                <MenuItem key="2" value={2}>Re-Enclose</MenuItem>
            ],
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.FORWARD_OFFICIAL_ONLY]: [
                <MenuItem key="1" value={1}>Re-Direct</MenuItem>,
                <MenuItem key="2" value={2}>Re-Enclose</MenuItem>
            ],
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.HOLD]: [
                <MenuItem key="0" value={0}>None</MenuItem>
            ],
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.REJECT]: [
                <MenuItem key="0" value={0}>None</MenuItem>
            ]
        },
        mailForwardingFrequencyDisabled: {
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.SCAN_HOLD]: true,
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.SCAN_SHRED]: true,
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.SCAN_FORWARD]: false,
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.FORWARD_ALL_MAIL]: false,
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.FORWARD_OFFICIAL_ONLY]: false,
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.HOLD]: true,
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.REJECT]: true
        },
        mailForwardingFrequencyMenuItems: {
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.SCAN_HOLD]: [
                <MenuItem key="0" value={0}>None</MenuItem>
            ],
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.SCAN_SHRED]: [
                <MenuItem key="0" value={0}>None</MenuItem>
            ],
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.SCAN_FORWARD]: [
                <MenuItem key="1" value={1}>Daily</MenuItem>,
                <MenuItem key="2" value={2}>Weekly</MenuItem>,
                <MenuItem key="3" value={3}>Monthly (Day)</MenuItem>,
                <MenuItem key="4" value={4}>Monthly (Date)</MenuItem>,
                <MenuItem key="5" value={5}>Bi-Weekly</MenuItem>,
            ],
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.FORWARD_ALL_MAIL]: [
                <MenuItem key="1" value={1}>Daily</MenuItem>,
                <MenuItem key="2" value={2}>Weekly</MenuItem>,
                <MenuItem key="3" value={3}>Monthly (Day)</MenuItem>,
                <MenuItem key="4" value={4}>Monthly (Date)</MenuItem>,
                <MenuItem key="5" value={5}>Bi-Weekly</MenuItem>,
            ],
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.FORWARD_OFFICIAL_ONLY]: [
                <MenuItem key="1" value={1}>Daily</MenuItem>,
                <MenuItem key="2" value={2}>Weekly</MenuItem>,
                <MenuItem key="3" value={3}>Monthly (Day)</MenuItem>,
                <MenuItem key="4" value={4}>Monthly (Date)</MenuItem>,
                <MenuItem key="5" value={5}>Bi-Weekly</MenuItem>,
            ],
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.HOLD]: [
                <MenuItem key="0" value={0}>None</MenuItem>
            ],
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.REJECT]: [
                <MenuItem key="0" value={0}>None</MenuItem>
            ]
        },
        mailClassDisabled: {
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.SCAN_HOLD]: true,
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.SCAN_SHRED]: true,
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.SCAN_FORWARD]: false,
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.FORWARD_ALL_MAIL]: false,
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.FORWARD_OFFICIAL_ONLY]: false,
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.HOLD]: true,
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.REJECT]: true
        },
        mailClassMenuItems: {
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.SCAN_HOLD]: [
                <MenuItem key="0" value={0}>None</MenuItem>
            ],
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.SCAN_SHRED]: [
                <MenuItem key="0" value={0}>None</MenuItem>
            ],
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.SCAN_FORWARD]: [
                <MenuItem key="0" value={0}>None</MenuItem>,
                <MenuItem key="1" value={1}>1st Class</MenuItem>,
                <MenuItem key="2" value={2}>2nd Class</MenuItem>,
                <MenuItem key="3" value={3}>International</MenuItem>,
                <MenuItem key="4" value={4}>Special</MenuItem>,
                <MenuItem key="5" value={5}>International Tracked & Signed</MenuItem>,
                <MenuItem key="6" value={6}>International Signed</MenuItem>,
                <MenuItem key="7" value={7}>International Tracked</MenuItem>,
                <MenuItem key="8" value={8}>Overseas Airmail</MenuItem>,
                <MenuItem key="9" value={9}>Courier</MenuItem>
            ],
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.FORWARD_ALL_MAIL]: [
                <MenuItem key="0" value={0}>None</MenuItem>,
                <MenuItem key="1" value={1}>1st Class</MenuItem>,
                <MenuItem key="2" value={2}>2nd Class</MenuItem>,
                <MenuItem key="3" value={3}>International</MenuItem>,
                <MenuItem key="4" value={4}>Special</MenuItem>,
                <MenuItem key="5" value={5}>International Tracked & Signed</MenuItem>,
                <MenuItem key="6" value={6}>International Signed</MenuItem>,
                <MenuItem key="7" value={7}>International Tracked</MenuItem>,
                <MenuItem key="8" value={8}>Overseas Airmail</MenuItem>,
                <MenuItem key="9" value={9}>Courier</MenuItem>
            ],
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.FORWARD_OFFICIAL_ONLY]: [
                <MenuItem key="0" value={0}>None</MenuItem>,
                <MenuItem key="1" value={1}>1st Class</MenuItem>,
                <MenuItem key="2" value={2}>2nd Class</MenuItem>,
                <MenuItem key="3" value={3}>International</MenuItem>,
                <MenuItem key="4" value={4}>Special</MenuItem>,
                <MenuItem key="5" value={5}>International Tracked & Signed</MenuItem>,
                <MenuItem key="6" value={6}>International Signed</MenuItem>,
                <MenuItem key="7" value={7}>International Tracked</MenuItem>,
                <MenuItem key="8" value={8}>Overseas Airmail</MenuItem>,
                <MenuItem key="9" value={9}>Courier</MenuItem>
            ],
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.HOLD]: [
                <MenuItem key="0" value={0}>None</MenuItem>
            ],
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.REJECT]: [
                <MenuItem key="0" value={0}>None</MenuItem>
            ]
        }
    },
    {
        type: COMPANY_MAIL_SETTINGS_INSTRUCTIONS_TYPE.SPECIAL,
        mailHandlingMenuItems: [
            <MenuItem key={COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.SCAN_HOLD.toString()} value={COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.SCAN_HOLD}>Scan & Hold</MenuItem>,
            <MenuItem key={COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.SCAN_SHRED.toString()} value={COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.SCAN_SHRED}>Scan & Shred</MenuItem>,
            <MenuItem key={COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.SCAN_FORWARD.toString()} value={COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.SCAN_FORWARD}>Scan & Forward</MenuItem>,
            <MenuItem key={COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.FORWARD_ALL_MAIL.toString()} value={COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.FORWARD_ALL_MAIL}>Forward - All mail</MenuItem>,
            <MenuItem key={COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.FORWARD_OFFICIAL_ONLY.toString()} value={COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.FORWARD_OFFICIAL_ONLY}>Forward - Official Only</MenuItem>,
            <MenuItem key={COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.HOLD.toString()} value={COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.HOLD}>Hold</MenuItem>,
            <MenuItem key={COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.REJECT.toString()} value={COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.REJECT}>Reject</MenuItem>
        ],
        mailHoldDisabled:{
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.SCAN_HOLD]: false,
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.SCAN_SHRED]: false,
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.SCAN_FORWARD]: true,
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.FORWARD_ALL_MAIL]: true,
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.FORWARD_OFFICIAL_ONLY]: false,
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.HOLD]: false,
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.REJECT]: true
        },
        mailHoldMenuItems: {
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.SCAN_HOLD]: [
                <MenuItem key="1" value={1}>All</MenuItem>
            ],
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.SCAN_SHRED]: [
                <MenuItem key="3" value={3}>Shred (30)</MenuItem>,
                <MenuItem key="4" value={4}>Shred (60)</MenuItem>,
                <MenuItem key="5" value={5}>Shred (90)</MenuItem>,
            ],
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.SCAN_FORWARD]: [
                <MenuItem key="0" value={0}>None</MenuItem>
            ],
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.FORWARD_ALL_MAIL]: [
                <MenuItem key="0" value={0}>None</MenuItem>
            ],
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.FORWARD_OFFICIAL_ONLY]: [
                <MenuItem key="2" value={2}>Official Only</MenuItem>
            ],
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.HOLD]: [
                <MenuItem key="1" value={1}>All</MenuItem>
            ],
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.REJECT]: [
                <MenuItem key="0" value={0}>None</MenuItem>
            ]
        },
        mailForwardingInstructionsDisabled:{
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.SCAN_HOLD]: true,
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.SCAN_SHRED]: true,
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.SCAN_FORWARD]: false,
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.FORWARD_ALL_MAIL]: false,
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.FORWARD_OFFICIAL_ONLY]: false,
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.HOLD]: true,
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.REJECT]: true
        },
        mailForwardingInstructionsMenuItems:{
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.SCAN_HOLD]: [
                <MenuItem key="0" value={0}>None</MenuItem>
            ],
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.SCAN_SHRED]: [
                <MenuItem key="0" value={0}>None</MenuItem>
            ],
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.SCAN_FORWARD]: [
                <MenuItem key="1" value={1}>Re-Direct</MenuItem>,
                <MenuItem key="2" value={2}>Re-Enclose</MenuItem>
            ],
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.FORWARD_ALL_MAIL]: [
                <MenuItem key="1" value={1}>Re-Direct</MenuItem>,
                <MenuItem key="2" value={2}>Re-Enclose</MenuItem>
            ],
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.FORWARD_OFFICIAL_ONLY]: [
                <MenuItem key="1" value={1}>Re-Direct</MenuItem>,
                <MenuItem key="2" value={2}>Re-Enclose</MenuItem>
            ],
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.HOLD]: [
                <MenuItem key="0" value={0}>None</MenuItem>
            ],
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.REJECT]: [
                <MenuItem key="0" value={0}>None</MenuItem>
            ]
        },
        mailForwardingFrequencyDisabled: {
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.SCAN_HOLD]: true,
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.SCAN_SHRED]: true,
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.SCAN_FORWARD]: false,
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.FORWARD_ALL_MAIL]: false,
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.FORWARD_OFFICIAL_ONLY]: false,
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.HOLD]: true,
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.REJECT]: true
        },
        mailForwardingFrequencyMenuItems: {
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.SCAN_HOLD]: [
                <MenuItem key="0" value={0}>None</MenuItem>
            ],
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.SCAN_SHRED]: [
                <MenuItem key="0" value={0}>None</MenuItem>
            ],
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.SCAN_FORWARD]: [
                <MenuItem key="1" value={1}>Daily</MenuItem>,
                <MenuItem key="2" value={2}>Weekly</MenuItem>,
                <MenuItem key="3" value={3}>Monthly (Day)</MenuItem>,
                <MenuItem key="4" value={4}>Monthly (Date)</MenuItem>,
                <MenuItem key="5" value={5}>Bi-Weekly</MenuItem>,
            ],
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.FORWARD_ALL_MAIL]: [
                <MenuItem key="1" value={1}>Daily</MenuItem>,
                <MenuItem key="2" value={2}>Weekly</MenuItem>,
                <MenuItem key="3" value={3}>Monthly (Day)</MenuItem>,
                <MenuItem key="4" value={4}>Monthly (Date)</MenuItem>,
                <MenuItem key="5" value={5}>Bi-Weekly</MenuItem>,
            ],
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.FORWARD_OFFICIAL_ONLY]: [
                <MenuItem key="1" value={1}>Daily</MenuItem>,
                <MenuItem key="2" value={2}>Weekly</MenuItem>,
                <MenuItem key="3" value={3}>Monthly (Day)</MenuItem>,
                <MenuItem key="4" value={4}>Monthly (Date)</MenuItem>,
                <MenuItem key="5" value={5}>Bi-Weekly</MenuItem>,
            ],
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.HOLD]: [
                <MenuItem key="0" value={0}>None</MenuItem>
            ],
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.REJECT]: [
                <MenuItem key="0" value={0}>None</MenuItem>
            ]
        },
        mailClassDisabled: {
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.SCAN_HOLD]: true,
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.SCAN_SHRED]: true,
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.SCAN_FORWARD]: false,
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.FORWARD_ALL_MAIL]: false,
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.FORWARD_OFFICIAL_ONLY]: false,
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.HOLD]: true,
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.REJECT]: true
        },
        mailClassMenuItems: {
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.SCAN_HOLD]: [
                <MenuItem key="0" value={0}>None</MenuItem>
            ],
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.SCAN_SHRED]: [
                <MenuItem key="0" value={0}>None</MenuItem>
            ],
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.SCAN_FORWARD]: [
                <MenuItem key="0" value={0}>None</MenuItem>,
                <MenuItem key="1" value={1}>1st Class</MenuItem>,
                <MenuItem key="2" value={2}>2nd Class</MenuItem>,
                <MenuItem key="3" value={3}>International</MenuItem>,
                <MenuItem key="4" value={4}>Special</MenuItem>,
                <MenuItem key="5" value={5}>International Tracked & Signed</MenuItem>,
                <MenuItem key="6" value={6}>International Signed</MenuItem>,
                <MenuItem key="7" value={7}>International Tracked</MenuItem>,
                <MenuItem key="8" value={8}>Overseas Airmail</MenuItem>,
                <MenuItem key="9" value={9}>Courier</MenuItem>
            ],
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.FORWARD_ALL_MAIL]: [
                <MenuItem key="0" value={0}>None</MenuItem>,
                <MenuItem key="1" value={1}>1st Class</MenuItem>,
                <MenuItem key="2" value={2}>2nd Class</MenuItem>,
                <MenuItem key="3" value={3}>International</MenuItem>,
                <MenuItem key="4" value={4}>Special</MenuItem>,
                <MenuItem key="5" value={5}>International Tracked & Signed</MenuItem>,
                <MenuItem key="6" value={6}>International Signed</MenuItem>,
                <MenuItem key="7" value={7}>International Tracked</MenuItem>,
                <MenuItem key="8" value={8}>Overseas Airmail</MenuItem>,
                <MenuItem key="9" value={9}>Courier</MenuItem>
            ],
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.FORWARD_OFFICIAL_ONLY]: [
                <MenuItem key="0" value={0}>None</MenuItem>,
                <MenuItem key="1" value={1}>1st Class</MenuItem>,
                <MenuItem key="2" value={2}>2nd Class</MenuItem>,
                <MenuItem key="3" value={3}>International</MenuItem>,
                <MenuItem key="4" value={4}>Special</MenuItem>,
                <MenuItem key="5" value={5}>International Tracked & Signed</MenuItem>,
                <MenuItem key="6" value={6}>International Signed</MenuItem>,
                <MenuItem key="7" value={7}>International Tracked</MenuItem>,
                <MenuItem key="8" value={8}>Overseas Airmail</MenuItem>,
                <MenuItem key="9" value={9}>Courier</MenuItem>
            ],
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.HOLD]: [
                <MenuItem key="0" value={0}>None</MenuItem>
            ],
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.REJECT]: [
                <MenuItem key="0" value={0}>None</MenuItem>
            ]
        }
    },
    {
        type: COMPANY_MAIL_SETTINGS_INSTRUCTIONS_TYPE.PARCEL,
        mailHandlingMenuItems: [
            <MenuItem key={COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.FORWARD_ALL_MAIL.toString()} value={COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.FORWARD_ALL_MAIL}>Forward - All mail</MenuItem>,
            <MenuItem key={COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.HOLD.toString()} value={COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.HOLD}>Hold</MenuItem>,
            <MenuItem key={COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.REJECT.toString()} value={COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.REJECT}>Reject</MenuItem>
        ],
        mailHoldDisabled: {
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.HOLD]: false,
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.FORWARD_ALL_MAIL]: true,
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.REJECT]: true
        },
        mailHoldMenuItems: {
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.HOLD]: [
                <MenuItem key="1" value={1}>All</MenuItem>
            ],
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.FORWARD_ALL_MAIL]: [
                <MenuItem key="0" value={0}>None</MenuItem>
            ],
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.REJECT]: [
                <MenuItem key="0" value={0}>None</MenuItem>
            ]
        },
        mailForwardingInstructionsDisabled:{
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.HOLD]: true,
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.FORWARD_ALL_MAIL]: false,
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.REJECT]: true
        },
        mailForwardingInstructionsMenuItems:{
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.HOLD]: [
                <MenuItem key="0" value={0}>None</MenuItem>
            ],
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.FORWARD_ALL_MAIL]: [
                <MenuItem key="1" value={1}>Re-Direct</MenuItem>,
                <MenuItem key="2" value={2}>Re-Enclose</MenuItem>
            ],
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.REJECT]: [
                <MenuItem key="0" value={0}>None</MenuItem>
            ]
        },
        mailForwardingFrequencyDisabled: {
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.HOLD]: true,
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.FORWARD_ALL_MAIL]: false,
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.REJECT]: true
        },
        mailForwardingFrequencyMenuItems: {
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.HOLD]: [
                <MenuItem key="0" value={0}>None</MenuItem>
            ],
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.FORWARD_ALL_MAIL]: [
                <MenuItem key="1" value={1}>Daily</MenuItem>,
                <MenuItem key="2" value={2}>Weekly</MenuItem>,
                <MenuItem key="3" value={3}>Monthly (Day)</MenuItem>,
                <MenuItem key="4" value={4}>Monthly (Date)</MenuItem>,
                <MenuItem key="5" value={5}>Bi-Weekly</MenuItem>,
            ],
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.REJECT]: [
            ]
        },
        mailClassDisabled: {
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.HOLD]: true,
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.FORWARD_ALL_MAIL]: false,
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.REJECT]: true
        },
        mailClassMenuItems: {
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.HOLD]: [
                <MenuItem key="0" value={0}>None</MenuItem>
            ],
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.FORWARD_ALL_MAIL]: [
                <MenuItem key="0" value={0}>None</MenuItem>,
                <MenuItem key="1" value={1}>1st Class</MenuItem>,
                <MenuItem key="2" value={2}>2nd Class</MenuItem>,
                <MenuItem key="3" value={3}>International</MenuItem>,
                <MenuItem key="4" value={4}>Special</MenuItem>,
                <MenuItem key="5" value={5}>International Tracked & Signed</MenuItem>,
                <MenuItem key="6" value={6}>International Signed</MenuItem>,
                <MenuItem key="7" value={7}>International Tracked</MenuItem>,
                <MenuItem key="8" value={8}>Overseas Airmail</MenuItem>,
                <MenuItem key="9" value={9}>Courier</MenuItem>
            ],
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.REJECT]: [
                <MenuItem key="0" value={0}>None</MenuItem>
            ]
        }
    },
    {
        type: COMPANY_MAIL_SETTINGS_INSTRUCTIONS_TYPE.PRINTED,
        mailHandlingMenuItems: [
            <MenuItem key={COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.FORWARD_ALL_MAIL.toString()} value={COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.FORWARD_ALL_MAIL}>Forward - All mail</MenuItem>,
            <MenuItem key={COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.HOLD.toString()} value={COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.HOLD}>Hold</MenuItem>,
            <MenuItem key={COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.REJECT.toString()} value={COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.REJECT}>Reject</MenuItem>
        ],
        mailHoldDisabled: {
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.HOLD]: false,
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.FORWARD_ALL_MAIL]: true,
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.REJECT]: true
        },
        mailHoldMenuItems: {
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.HOLD]: [
                <MenuItem key="1" value={1}>All</MenuItem>
            ],
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.FORWARD_ALL_MAIL]: [
                <MenuItem key="0" value={0}>None</MenuItem>
            ],
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.REJECT]: [
                <MenuItem key="0" value={0}>None</MenuItem>
            ]
        },
        mailForwardingInstructionsDisabled:{
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.HOLD]: true,
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.FORWARD_ALL_MAIL]: false,
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.REJECT]: true
        },
        mailForwardingInstructionsMenuItems:{
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.HOLD]: [
                <MenuItem key="0" value={0}>None</MenuItem>
            ],
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.FORWARD_ALL_MAIL]: [
                <MenuItem key="1" value={1}>Re-Direct</MenuItem>,
                <MenuItem key="2" value={2}>Re-Enclose</MenuItem>
            ],
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.REJECT]: [
                <MenuItem key="0" value={0}>None</MenuItem>
            ]
        },
        mailForwardingFrequencyDisabled: {
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.HOLD]: true,
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.FORWARD_ALL_MAIL]: false,
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.REJECT]: true
        },
        mailForwardingFrequencyMenuItems: {
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.HOLD]: [
                <MenuItem key="0" value={0}>None</MenuItem>
            ],
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.FORWARD_ALL_MAIL]: [
                <MenuItem key="1" value={1}>Daily</MenuItem>,
                <MenuItem key="2" value={2}>Weekly</MenuItem>,
                <MenuItem key="3" value={3}>Monthly (Day)</MenuItem>,
                <MenuItem key="4" value={4}>Monthly (Date)</MenuItem>,
                <MenuItem key="5" value={5}>Bi-Weekly</MenuItem>,
            ],
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.REJECT]: [
                <MenuItem key="0" value={0}>None</MenuItem>
            ]
        },
        mailClassDisabled: {
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.HOLD]: true,
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.FORWARD_ALL_MAIL]: false,
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.REJECT]: true
        },
        mailClassMenuItems: {
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.HOLD]: [
                <MenuItem key="0" value={0}>None</MenuItem>
            ],
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.FORWARD_ALL_MAIL]: [
                <MenuItem key="0" value={0}>None</MenuItem>,
                <MenuItem key="1" value={1}>1st Class</MenuItem>,
                <MenuItem key="2" value={2}>2nd Class</MenuItem>,
                <MenuItem key="3" value={3}>International</MenuItem>,
                <MenuItem key="4" value={4}>Special</MenuItem>,
                <MenuItem key="5" value={5}>International Tracked & Signed</MenuItem>,
                <MenuItem key="6" value={6}>International Signed</MenuItem>,
                <MenuItem key="7" value={7}>International Tracked</MenuItem>,
                <MenuItem key="8" value={8}>Overseas Airmail</MenuItem>,
                <MenuItem key="9" value={9}>Courier</MenuItem>
            ],
            [COMPANY_MAIL_SETTINGS_MAIL_HANDLING_TYPE.REJECT]: [
                <MenuItem key="0" value={0}>None</MenuItem>
            ]
        }
    }
];