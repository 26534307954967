
const getAccount  = async (antiForgeryToken, accountId) => {
    return fetch(`/api/Accounts/${accountId}`, {
        method: 'GET',
        headers: buildAuthHeaders(antiForgeryToken)
    });
};

const listAccounts  = async (antiForgeryToken, findAccountsRequest) => {
    return fetch(`api/Accounts/FindAccounts`, {
        method: 'POST',
        body: JSON.stringify(findAccountsRequest),
        headers: buildAuthHeaders(antiForgeryToken)
    });
};

async function createAccount(antiForgeryToken, formData) {
    return fetch(`/api/Accounts`, {
        method: 'POST',
        headers: buildAuthHeaders(antiForgeryToken),
        body: JSON.stringify(formData)
    });
}

async function updateAccount(antiForgeryToken, formData) {
    return fetch(`/api/Accounts`, {
        method: 'PATCH',
        headers: buildAuthHeaders(antiForgeryToken),
        body: JSON.stringify(formData)
    });
}

async function confirmDetailsForUsersAccount(antiForgeryToken) {
    return fetch(`/api/Accounts/ConfirmDetailsForUsersAccount`, {
        method: 'PATCH',
        headers: buildAuthHeaders(antiForgeryToken)
    });
}

async function createOrUpdateStripeAccount(antiForgeryToken, formData) {
    return fetch(`/api/Accounts/CreateOrUpdateStripeAccount`, {
        method: 'POST',
        headers: buildAuthHeaders(antiForgeryToken),
        body: JSON.stringify(formData)
    });
}

async function removeAccount(antiForgeryToken, accountId) {
    return fetch(`/api/Accounts/${accountId}`, {
        method: 'DELETE',
        headers: buildAuthHeaders(antiForgeryToken)
    });
}

function buildAuthHeaders(antiForgeryToken) {
    return !antiForgeryToken ? {} : {
        [antiForgeryToken.tokenName] : antiForgeryToken.token,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    };
}


const accountService = Object.freeze({
    getAccount,
    updateAccount,
    listAccounts,
    createAccount,
    createOrUpdateStripeAccount,
    confirmDetailsForUsersAccount,
    removeAccount
});
export default accountService;