import * as React from 'react';
import {useNavigate, useParams} from "react-router-dom";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {useContext, useEffect, useState} from "react";
import {enqueueSnackbar} from "notistack";
import {Checkbox, CircularProgress, FormControlLabel, Stack} from "@mui/material";
import Button from "@mui/material/Button";
import AddCompanyPrimaryContact from "./AddCompanyPrimaryContact";
import {useForm} from "react-hook-form";
import {FormGroup} from "reactstrap";
import {ACCOUNT_TABS, COMPANY_TYPE, POST_BOX_NAME_TYPE, ROLES} from "../enums";
import accountService from "../accounts/AccountService";
import AuthContext from "../api-authorization/AuthContext";
import companyService from "../companies/CompanyService";
import inputService from "../util/InputService";
import NavigationContext from "../layout/NavigationContext";
import Breadcrumb from "../layout/Breadcrumb";
import AddPostBoxDetails from "./AddPostBoxDetails";
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function AddPostBoxParent() {
    const { histNavigate, back } = useContext(NavigationContext);
    const { antiForgeryToken, user, refreshUser} = useContext(AuthContext);
    const {  handleSubmit, control, formState,setValue,trigger } = useForm();
    const [tabValue, setTabValue] = React.useState(0);
    const { accountId } = useParams();
    const [disableSubmit, setDisableSubmit] = useState(false);
    let [correctCompanyConfirmation, setCorrectCompanyConfirmation] = React.useState(false);
    const [isDisablePrimaryContactChecked, setIsDisablePrimaryContactChecked] = useState(false);
    const navigate = useNavigate();
    
    const [isAccountLoading, setAccountLoading] = useState(true);
    const [isComplianceApproved, setIsComplianceApproved] = useState(true);
    const [isAddressApproved, setIsAddressApproved] = useState(false);
    const [accountData, setAccountData] = useState();
    const [selectedPostBoxNameType, setSelectedPostBoxNameType] = useState(POST_BOX_NAME_TYPE.NUMBER);
    
    const handleChange = (event, newTabValue) => {
        setTabValue(newTabValue);
    };

    const onChangeHandler = event => {
        setCorrectCompanyConfirmation(event.target.checked);
    };

    
    const handleNext = async () =>{
        const result = await trigger();  // trigger validation for all fields
        if (result) {   // if all field validations are successful 
            setTabValue(1);
        }
    }



    const handleFormSubmit  = async (primaryContactData) => {
        setDisableSubmit(true);
        let request = {};
        request.accountId = accountId;
        primaryContactData = inputService.trimFields(primaryContactData);
        request.primaryContact = primaryContactData;
        request.postBoxName = primaryContactData.postBoxName;
        request.previousNames = primaryContactData.previousNames;
        request.postBoxStartDate = primaryContactData.startDate;
        request.postBoxEndDate = primaryContactData.endDate;
        request.postBoxNameType = selectedPostBoxNameType;
        const result = await companyService.addPostBoxToAccount(antiForgeryToken, request);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                enqueueSnackbar("Successfully added post box to account");
                if(user.role == ROLES.ACCOUNT_ADMIN){
                    refreshUser();
                }
                back();
            } else {
                enqueueSnackbar(data.description);
            }
        }
        setDisableSubmit(false);
    };

    useEffect( () => {
        async function fetchAccountData() {
            const result = await accountService.getAccount(antiForgeryToken, accountId);
            if (result.status === 401) {
                refreshUser();
                navigate('/login');
            }
            else {
                let data = await result.json();
                if (result.ok) {
                    setAccountData(data);
                    setAccountLoading(false);
                } else {
                    enqueueSnackbar(data.description);
                }
            }
        }

        fetchAccountData();
    }, [accountId]);
    
    
    let content;
    if (isAccountLoading) {
        content = (
            <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                <CircularProgress />
            </Box>
        );
    } else {
        let breadcrumbItems = [
            {description: `Account - ${accountData.email}`, url: `/Account/Update/${accountData.id}/${ACCOUNT_TABS.DETAILS}`},
            {description: `Post Boxes`, url: `/Account/Update/${accountData.id}/${ACCOUNT_TABS.PO_BOXES}`}
        ];
        let label= (
            <>
                <Typography>Confirm this is the correct Post Box name you want to add to account - <strong>{accountData.email}</strong> before proceeding </Typography>
            </>
        );
        content = (
            <Box sx={{width: '100%'}}>
                {(user.role === ROLES.TENANT_ADMIN || user.role === ROLES.TENANT_STANDARD || user.role === ROLES.CLIENT_ADMIN || user.role === ROLES.CLIENT_STANDARD) && (
                    <Breadcrumb breadcrumbItems={breadcrumbItems}/>
                )}
                <Typography variant="h5" noWrap component="div" align={"left"} sx={{mt: 2, mb: 4}}>
                    Post Box - {accountData.email}
                </Typography>
                <Box>
                    <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Details" {...a11yProps(0)}/>
                        <Tab label="Primary Contact" {...a11yProps(1)} disabled={correctCompanyConfirmation === false}/>
                    </Tabs>
                </Box>
                <form onSubmit={handleSubmit(handleFormSubmit)}>
                    <TabPanel value={tabValue} index={0}>
                        <AddPostBoxDetails formState={formState} control={control} selectedPostBoxNameType={selectedPostBoxNameType} setSelectedPostBoxNameType={setSelectedPostBoxNameType}/>
                        <br/>
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox checked={correctCompanyConfirmation} onChange={onChangeHandler}/>}
                                label={label}/>
                        </FormGroup>
                        <Stack direction="row" spacing={2} marginTop={2}>
                            <Button variant="contained" color="secondary" onClick={(e) => {
                                back();
                            }}>
                                Back
                            </Button>
                            <Button variant="contained" color="secondary"
                                    disabled={correctCompanyConfirmation === false} onClick={(e) => {
                                handleNext();
                            }}>
                                Next
                            </Button>
                        </Stack>
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                        <AddCompanyPrimaryContact setValue={setValue} formState={formState} control={control}
                                                  setIsComplianceApproved={setIsComplianceApproved}
                                                  setIsAddressApproved={setIsAddressApproved} accountData={accountData}
                                                  isDisablePrimaryContactChecked={isDisablePrimaryContactChecked}
                                                  setIsDisablePrimaryContactChecked={setIsDisablePrimaryContactChecked}
                                                  companyType={COMPANY_TYPE.POST_BOX}
                        />
                        <Stack direction="row" spacing={2} marginTop={4}>
                            <Button variant="contained" color="secondary" onClick={(e) => {
                                setTabValue(0);
                            }}>
                                Back
                            </Button>
                            <Button variant="contained" color="secondary" type="submit"
                                    disabled={disableSubmit || isComplianceApproved === false || isAddressApproved === false}>
                                Submit
                            </Button>
                        </Stack>
                    </TabPanel>
                </form>
            </Box>
        );
    }

    return (
        <>
        {content}
        </>
    );
}