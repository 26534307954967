import React, {useContext, useState} from 'react'
import TextField from "@mui/material/TextField";
import {InputAdornment, Pagination} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import {useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TransitionAlert from "../layout/TransitionAlert";
import {COMPANY_TABS} from "../enums";
import companyService from "./CompanyService";
import {enqueueSnackbar} from "notistack";
import AuthContext from "../api-authorization/AuthContext";
import NavigationContext from "../layout/NavigationContext";
import {DataGridPro} from "@mui/x-data-grid-pro";

export default function ListTenantPostBoxes() {
    const { histNavigate } = useContext(NavigationContext);
    const { antiForgeryToken, refreshUser} = useContext(AuthContext);
    const [searchTerm, setSearchTerm] = useState("");
    const [isLoading, setIsLoading] = React.useState(true);
    const [postBoxes, setPostBoxes] = React.useState([]);
    const [rowCountState, setRowCountState] = React.useState(0);
    const [paginationModel, setPaginationModel] = React.useState({
        page: 0,
        pageSize: 20,
    });
    const navigate = useNavigate();

    const fetchData = async () => {
        setIsLoading(true);
        setPaginationModel((prevModel) => ({...prevModel, page: 0}));
        let searchRequest = {
            appScope: 1,
            searchTerm: searchTerm
        };
        const result = await companyService.listPostBoxes(antiForgeryToken, searchRequest);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        let data = await result.json();
        if (result.ok) {
            setPostBoxes(data.postBoxes);
            setRowCountState(data.postBoxes.length);
            setIsLoading(false);
        } else {
            enqueueSnackbar(data.description);
        }
    };


    React.useEffect(() => {
        fetchData();
    }, []);

    const handlePageChange = (params) => {
        setPaginationModel({
            page: params.page,
            pageSize: params.pageSize,
        });
    };

    const handleSearch = (event) => {
        event.preventDefault();
        fetchData();
    };

    const handleRowClick = (params) => {
        histNavigate(`/Company/Update/${params.id}/${COMPANY_TABS.DETAILS}`,'Update Company');
    };

    const dateFormatter = (params) => {
        const date = new Date(params.value);
        if (isNaN(date.getTime()) || date.getFullYear() <= 1) {
            return "None";
        }
        return date.toLocaleDateString('en-GB');
    };
    
    return (
        <Box style={{ height: 400 }}>
            <Typography variant="h5" noWrap component="div" align={"left"} sx={{mb:4}}>
                Post Box Search
            </Typography>
            <TransitionAlert message={"NOTE:   For admin user accounts only - searches for post boxes that have been registered already on the application"}/>
            <form onSubmit={handleSearch}>
                <TextField
                    id="search-bar"
                    className="text"
                    value={searchTerm}
                    onChange={(e) => {
                        setSearchTerm(e.target.value);
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="end">
                                <IconButton type="submit">
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    label="Search"
                    variant="outlined"
                    size="small"
                    style={{ width: '100%' }}
                />
            </form>
            <br />
            <DataGridPro
                rows={postBoxes.slice(
                    paginationModel.page * paginationModel.pageSize,
                    paginationModel.page * paginationModel.pageSize + paginationModel.pageSize
                )}
                columns={[
                    { field: 'accountEmail', headerName: 'Account Email', flex: 1 },
                    { field: 'postBoxName', headerName: 'Post Box Name', flex: 1 },
                    { field: 'previousNames', headerName: 'Previous Names', flex: 1 },
                    { field: 'postBoxStartDate', headerName: 'Start Date', flex: 1, valueFormatter: dateFormatter },
                    { field: 'postBoxEndDate', headerName: 'End Date', flex: 1, valueFormatter: dateFormatter },
                    { field: 'status', headerName: 'Status', flex: 1 },
                    { field: 'clientName', headerName: 'Client', flex: 1 },
                ]}
                rowCount={paginationModel.pageSize}
                pageSize={paginationModel.pageSize}
                hideFooter={true}
                page={paginationModel.page}
                onPageChange={handlePageChange}
                loading={isLoading}
                onRowClick={handleRowClick}
                getRowClassName={() => 'rowCursorPointer'}
                autoHeight
                sx={{
                    '.MuiDataGrid-columnHeaderTitle': {
                        WebkitTextStroke: '0.75px'
                    },
                }}
            />
            <Box display="flex" justifyContent="flex-end">
                <Pagination
                    count={Math.ceil(rowCountState / paginationModel.pageSize)}
                    page={paginationModel.page + 1}
                    onChange={(event, page) => handlePageChange({ page: page - 1, pageSize: paginationModel.pageSize })}
                />
            </Box>
        </Box>
    );
}
