import React, {useContext, useState} from 'react';
import Button from "@mui/material/Button";
import {Checkbox, CircularProgress, Pagination, Stack} from "@mui/material";
import {enqueueSnackbar} from "notistack";
import {useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";
import billingService from "./BillingService";
import {RESOURCE_TYPE} from "../enums";
import NavigationContext from "../layout/NavigationContext";
import {DataGridPro} from "@mui/x-data-grid-pro";
export default function ListPaymentMethods({antiForgeryToken, resourceType, resourceId, resourceData, refreshUser}) {
    const { histNavigate,back } = useContext(NavigationContext);
    const [cards, setCards] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const [rowCountState, setRowCountState] = React.useState(0);
    const [paginationModel, setPaginationModel] = React.useState({
        page: 0,
        pageSize: 5,
    });
    const navigate = useNavigate();
    const [isBusy, setIsBusy] = useState(false);

    const fetchData = async () => {
        setPaginationModel((prevModel) => ({...prevModel, page: 0}));
        const result = await billingService.listPaymentMethods(antiForgeryToken, resourceType, resourceId);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        let data = await result.json();
        if (result.ok) {
            setCards(data.cards);
            setRowCountState(data.cards.length);
            setIsLoading(false);
        } else {
            enqueueSnackbar(data.description);
        }
    };

    const removePaymentMethod = async (paymentMethodId) => {
        setIsBusy(true);
        const result = await billingService.removePaymentMethod(antiForgeryToken, resourceType, resourceId, paymentMethodId);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        let data = await result.json();
        if (result.ok) {
            enqueueSnackbar("Successfully removed payment method");
            await fetchData();
        } else {
            enqueueSnackbar(data.description);
        }
        setIsBusy(false);
    };

    const setPaymentMethodAsDefault = async (paymentMethodId) => {
        setIsBusy(true);
        const result = await billingService.setPaymentMethodAsDefault(antiForgeryToken, resourceType, resourceId, paymentMethodId);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        let data = await result.json();
        if (result.ok) {
            enqueueSnackbar("Successfully updated default card");
            await fetchData();
        } else {
            enqueueSnackbar(data.description);
        }
        setIsBusy(false);
    };
    

    React.useEffect(() => {
        fetchData();
    }, []);
    
    const handlePageChange = (params) => {
        setPaginationModel({
            page: params.page,
            pageSize: params.pageSize,
        });
    };
    
    let content;
    
    if (isBusy) {
        content = (
            <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                <CircularProgress />
            </Box>
        );
    } else {
        content = (
            <Box style={{ height: 400, width: '100%' }}>
              <DataGridPro
                    rows={cards.slice(
                        paginationModel.page * paginationModel.pageSize,
                        paginationModel.page * paginationModel.pageSize + paginationModel.pageSize
                    )}
                    columns={[
                        { field: 'brand', headerName: 'Type', flex: 1 },
                        { field: 'last4', headerName: 'Card', flex: 2 },
                        { field: 'name', headerName: 'Name on card', flex: 2},
                        { field: 'expiryDate', headerName: 'Expiry date', flex: 1 },
                        { field: 'status', headerName: 'Status', flex: 1 },
                        {
                            field: "default",
                            headerName: "Default",
                            flex: 1,
                            disableClickEventBubbling: true,
                            renderCell: (params) => {
                                const isDefault = params.value;

                                return (
                                    <div className="d-flex justify-content-between align-items-center">
                                        <Checkbox checked={isDefault} disabled={isDefault}  onChange={() => {
                                            setPaymentMethodAsDefault(params.row.id);
                                        }}/>
                                    </div>
                                );
                            }
                        },
                        {
                            field: 'remove', headerName: '', flex: 1,
                            renderCell: (params) => (
                                !params.row.default ?
                                    <Button variant="contained" color="secondary"
                                            onClick={() => {
                                                // Function call or action on the button click
                                                removePaymentMethod(params.row.id);
                                            }}>Remove</Button>
                                    : null
                            ),
                        }
                    ]}
                    rowCount={rowCountState}
                    pagination
                    pageSize={paginationModel.pageSize}
                    page={paginationModel.page}
                    onPageChange={handlePageChange}
                    hideFooter={true}
                    loading={isLoading}
                    autoHeight
                    sx={{
                        '.MuiDataGrid-columnHeaderTitle': {
                            WebkitTextStroke: "0.75px"
                        }
                    }}
                />
                <Box display="flex" justifyContent="flex-end">
                    <Pagination
                        count={Math.ceil(rowCountState / paginationModel.pageSize)}
                        page={paginationModel.page + 1}
                        onChange={(event, page) => handlePageChange({ page: page - 1, pageSize: paginationModel.pageSize })}
                    />
                </Box>
                <Stack direction="row" spacing={2} marginTop={2}>
                    <Button variant="contained" color="secondary" onClick={(e) => {
                        back();
                    }}>
                        Back
                    </Button>
                    <Button variant="contained" color="secondary"   onClick={(e) => {
                        e.preventDefault();
                        histNavigate(`/${resourceType === RESOURCE_TYPE.ACCOUNT.toString() ? "Account":"Company"}/AddBilling/${resourceId}`, 'Add Payment Method');

                    }}>
                        Add
                    </Button>
                </Stack>
            </Box>
        );
    }


    return (
        <>
            {content}
        </>
    );
}