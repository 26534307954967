
//TODO pass DTO's instead here
async function getSetUpIntentSecret(antiForgeryToken, resourceType, resourceId) {
    return fetch(`/api/Billing/GetSetUpIntentSecret/${resourceType}/${resourceId}`, {
        method: 'GET',
        headers: buildAuthHeaders(antiForgeryToken)
    });
}

async function finishCardSetUp(antiForgeryToken, resourceType, resourceId, setUpIntentId) {
    return fetch(`/api/Billing/FinishCardSetup/${resourceType}/${resourceId}/${setUpIntentId}`, {
        method: 'POST',
        headers: buildAuthHeaders(antiForgeryToken)
    });
}

async function listPaymentHistory(antiForgeryToken, resourceType, resourceId) {
    return fetch(`/api/Billing/ListPaymentHistory/${resourceType}/${resourceId}`, {
        method: 'GET',
        headers: buildAuthHeaders(antiForgeryToken)
    });
}

async function listPaymentMethods(antiForgeryToken, resourceType, resourceId) {
    return fetch(`/api/Billing/ListPaymentMethods/${resourceType}/${resourceId}`, {
        method: 'GET',
        headers: buildAuthHeaders(antiForgeryToken)
    });
}

async function removePaymentMethod(antiForgeryToken, resourceType, resourceId, paymentMethodId) {
    return fetch(`/api/Billing/RemovePaymentMethod/${resourceType}/${resourceId}/${paymentMethodId}`, {
        method: 'DELETE',
        headers: buildAuthHeaders(antiForgeryToken)
    });
}

async function setPaymentMethodAsDefault(antiForgeryToken, resourceType, resourceId, paymentMethodId) {
    return fetch(`/api/Billing/SetPaymentMethodAsDefault/${resourceType}/${resourceId}/${paymentMethodId}`, {
        method: 'PATCH',
        headers: buildAuthHeaders(antiForgeryToken)
    });
}

async function updateSubscriptionPaymentMethod(antiForgeryToken, resourceType, resourceId, subscriptionId,paymentMethodId) {
    return fetch(`/api/Billing/UpdateSubscriptionPaymentMethod/${resourceType}/${resourceId}/${subscriptionId}/${paymentMethodId}`, {
        method: 'PATCH',
        headers: buildAuthHeaders(antiForgeryToken)
    });
}

async function getCheckoutDetails(antiForgeryToken, resourceType, resourceId) {
    return fetch(`/api/Billing/GetCheckoutDetails/${resourceType}/${resourceId}`, {
        method: 'GET',
        headers: buildAuthHeaders(antiForgeryToken)
    });
}

async function getSubscriptionDetails(antiForgeryToken, resourceType, resourceId, subscriptionId) {
    return fetch(`/api/Billing/GetSubscriptionDetails/${resourceType}/${resourceId}/${subscriptionId}`, {
        method: 'GET',
        headers: buildAuthHeaders(antiForgeryToken)
    });
}

async function cancelSubscription(antiForgeryToken, resourceType, resourceId, subscriptionId) {
    return fetch(`/api/Billing/CancelSubscription/${resourceType}/${resourceId}/${subscriptionId}`, {
        method: 'DELETE',
        headers: buildAuthHeaders(antiForgeryToken)
    });
}


const reAttemptInvoices  = async (antiForgeryToken, resourceType, resourceId, invoiceIds) => {
    return fetch(`api/Billing/ReAttemptInvoices`, {
        method: 'POST',
        headers: buildAuthHeaders(antiForgeryToken),
        body: JSON.stringify({
            resourceType : parseInt(resourceType),
            resourceId : resourceId,
            invoiceIds : invoiceIds
        })
    });
};


function buildAuthHeaders(antiForgeryToken) {
    return !antiForgeryToken ? {} : {
        [antiForgeryToken.tokenName] : antiForgeryToken.token,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    };
}


const billingService = Object.freeze({
    getSetUpIntentSecret,
    finishCardSetUp,
    listPaymentHistory,
    listPaymentMethods,
    removePaymentMethod,
    setPaymentMethodAsDefault,
    getCheckoutDetails,
    getSubscriptionDetails,
    reAttemptInvoices,
    cancelSubscription,
    updateSubscriptionPaymentMethod
});
export default billingService;