import React, { useState } from 'react';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Button from "@mui/material/Button";
import { QuestionMark } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";

export default function InfoTooltip({title,message}) {
    const theme = useTheme();
    const [drawerOpen, setDrawerOpen] = useState(false);

    const handleDrawerOpen = () => {
        setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setDrawerOpen(false);
    };

    return (
        <Box>
            <IconButton onClick={handleDrawerOpen}
                        sx={{
                            backgroundColor: theme.palette.primary.main,
                            color: "white",
                            '&:hover': {
                                color: theme.palette.primary.main
                            }
                        }}
            >
                <QuestionMark/>
            </IconButton>
            <Drawer
                anchor="right"
                open={drawerOpen}
                onClose={handleDrawerClose}
            >
                <Box
                    sx={{ width: 350,
                          height:"100%",
                          bgcolor:theme.palette.secondary.main,
                          color:theme.palette.text.primary,
                          padding:4,
                    }} // Set the width of your Drawer
                    role="presentation"
                    onClick={handleDrawerClose}
                    onKeyDown={handleDrawerClose}
                >
                    <h2>{title}</h2>
                    <br/>
                    <p>{message}</p>
                    <Button onClick={handleDrawerClose} variant="contained" color="secondary">Close</Button>
                </Box>
            </Drawer>
        </Box>
    );
}