

const getUnprocessedScannedMail = async (antiForgeryToken,scanBatchId) => {
    return fetch(`api/ScannedMail/GetUnprocessedScannedMail/${scanBatchId}`, {
        method: 'GET',
        headers: buildAuthHeaders(antiForgeryToken)
    });
};

const getUnprocessedPreSortMail = async (antiForgeryToken,scanBatchId) => {
    return fetch(`api/ScannedMail/GetUnprocessedPreSortMail/${scanBatchId}`, {
        method: 'GET',
        headers: buildAuthHeaders(antiForgeryToken)
    });
};

const getUnknownScannedMail = async (antiForgeryToken, getUnknownScannedMailRequest) => {
    return fetch(`api/ScannedMail/GetUnknownScannedMail`, {
        method: 'POST',
        body: JSON.stringify(getUnknownScannedMailRequest),
        headers: buildAuthHeaders(antiForgeryToken),
    });
};


const submitScannedMail  = async (antiForgeryToken, submitScannedMailRequest) => {
    return fetch(`api/ScannedMail/SubmitScannedMail`, {
        method: 'POST',
        body: JSON.stringify(submitScannedMailRequest),
        headers: buildAuthHeaders(antiForgeryToken)
    });
};

const submitPreSortMail = async (antiForgeryToken, submitPreSortMailRequest) => {
    return fetch(`api/ScannedMail/SubmitPreSortMail`, {
        method: 'POST',
        body: JSON.stringify(submitPreSortMailRequest),
        headers: buildAuthHeaders(antiForgeryToken)
    });
};

const rtsScannedMail  = async (antiForgeryToken, rtsScannedMailRequest) => {
    return fetch(`api/ScannedMail/RtsScannedMail`, {
        method: 'POST',
        headers: buildAuthHeaders(antiForgeryToken),
        body: JSON.stringify(rtsScannedMailRequest)
    });
};

const unlockScanBatch  = async (antiForgeryToken, scanBatchId) => {
    return fetch(`api/ScannedMail/UnlockScanBatch/${scanBatchId}`, {
        method: 'POST',
        headers: buildAuthHeaders(antiForgeryToken)
    });
};

const createUnknownScannedMail  = async (antiForgeryToken, messageId) => {
    return fetch(`api/ScannedMail/CreateUnknownScannedMail/${messageId}`, {
        method: 'POST',
        headers: buildAuthHeaders(antiForgeryToken)
    });
};

const deleteScannedMail  = async (antiForgeryToken, messageId) => {
    return fetch(`api/ScannedMail/DeleteScannedMail/${messageId}`, {
        method: 'DELETE',
        headers: buildAuthHeaders(antiForgeryToken)
    });
};

const deleteScanBatch  = async (antiForgeryToken, scanBatchId) => {
    return fetch(`api/ScannedMail/DeleteScanBatch/${scanBatchId}`, {
        method: 'DELETE',
        headers: buildAuthHeaders(antiForgeryToken)
    });
};

const uploadScans  = async (antiForgeryToken, formData) => {
    return fetch(`api/ScannedMail/UploadScans`, {
        method: 'POST',
        body: formData,
        headers: buildFormAuthHeaders(antiForgeryToken)
    });
};

const listScanBatches  = async (antiForgeryToken, scannedMailType) => {
    return fetch(`api/ScannedMail/ListScanBatches/${scannedMailType}`, {
        method: 'GET',
        headers: buildAuthHeaders(antiForgeryToken)
    });
};

function buildAuthHeaders(antiForgeryToken) {
    return !antiForgeryToken ? {} : {
        [antiForgeryToken.tokenName] : antiForgeryToken.token,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    };
}

function buildFormAuthHeaders(antiForgeryToken){
    return !antiForgeryToken ? {} : {
        [antiForgeryToken.tokenName] : antiForgeryToken.token
    };
}

const scannedMailService = Object.freeze({
    getUnprocessedScannedMail,
    getUnprocessedPreSortMail,
    submitScannedMail,
    submitPreSortMail,
    rtsScannedMail,
    createUnknownScannedMail,
    getUnknownScannedMail,
    deleteScannedMail,
    deleteScanBatch,
    unlockScanBatch,
    uploadScans,
    listScanBatches
});
export default scannedMailService;