import * as React from 'react';
import Box from '@mui/material/Box';
import {useContext} from "react";
import {Paper} from "@mui/material";
import AuthContext from "../api-authorization/AuthContext";
import CssBaseline from "@mui/material/CssBaseline";
import {useTheme} from "@mui/material/styles";
import {useNavigate} from "react-router-dom";
import TermsAndConditionsTabs from "./TermsAndConditionsTabs";

export default function GuestTermsAndConditions() {
    const theme = useTheme();
    const { antiForgeryToken} = useContext(AuthContext);
    const navigate = useNavigate();


    return  (
        <>
            <CssBaseline />
            <Box
                sx={{
                    backgroundImage: `url(${theme.loginBackgroundImage})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    width: '100%',
                    height: '100vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                    <Paper sx={{mt: 2, padding:4,maxHeight: 800, minHeight: 800, overflow: 'auto', width:"1000px",border:2,borderColor:'grey.200'}} variant="outlined" >
                     <TermsAndConditionsTabs/>
                    </Paper>
            </Box>
        </>
    );
}